import React, { Component } from "react";
import { connect } from "react-redux";
import { watchlistNameCheck } from "../utils/helperFunctions";
import ConfirmModal from "../layout/ConfirmModal";
import axios from 'axios'
import { BASE_URL } from "../utils/const";
import uuid from 'react-uuid';


import {
  RadioGroup,
  ReversedRadioButton,
} from "react-radio-buttons";

class SaveWatchlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      name: this.props.transactionWatchlist?.watchlistname || '',
      // watchlist notes are empty for transactionWatchlist?
      notes: this.props.transactionWatchlist?.watchlistnotes || "",
      disabled: false,
      showConfirmModal: false,
      usePrevValue: true,
      valueUpdated: false,
      maxAllocation: this.props.transactionWatchlist?.maxallocation || '0',
      timingType: this.props.transactionWatchlist?.timingtype || 'LONG_ONLY',
      managed:false,
      savedWatchlists: [],
      loadingUserWatchlists: true,
      disableSaveButton: false
    };
  }

  componentDidMount(){
    // console.log("PROPS -> ", this.props)
    // this.setState({maxAllocation: this.props?.portfolio?.maxAllocation})
    this.isWatchlistSubscribed()
    // let userWatchlists = this.getUserWatchlists()
    // let existWatchlist = userWatchlists.findIndex((watchlistName) => watchlistName === this.state.name)
    // let watchlistList = this.state.savedWatchlists[existWatchlist]
    // if(watchlistList.isSubscribed === true) this.setState({name: `Copy of ${this.state.name}`})
    // // console.log("SAVE MODAL MOUNTED ", watchlistList, watchlistList[0]?.isSubscribed)
  }

  isWatchlistSubscribed = async () => {
    this.setState({loadingUserWatchlists: true})
    let userWatchlists = await this.getUserWatchlists()
    let existWatchlist = userWatchlists.findIndex((watchlistName) => watchlistName === this.state.name)
    let watchlistList = this.state.savedWatchlists[existWatchlist]
    if(watchlistList?.isSubscribed === true) this.setState({name: `Copy of ${this.state.name}`})
    this.setState({loadingUserWatchlists: false})
  }

  // componentDidUpdate(prevProps) {
  //   const { transactionWatchlist } = this.props;

  //   if (this.state.name === '' && transactionWatchlist && this.state.usePrevValue && !this.state.valueUpdated) {
  //     this.setState({
  //       name: transactionWatchlist?.watchlistname || '',
  //       notes: transactionWatchlist?.watchlistnotes || '',
  //       timingType: transactionWatchlist.timingtype || 'LONG_SHORT',
  //       maxAllocation: transactionWatchlist.maxallocation || '0'
  //     })
  //   }

  //   if (prevProps.watchlistname === '' && this.props.watchlistname === '' && !this.state.valueUpdated && this.state.name !== '') {
  //     if (transactionWatchlist === '' || transactionWatchlist === undefined) {
  //       this.setState({
  //         name: '',
  //         notes: '',
  //         timingType: 'LONG_SHORT',
  //         maxAllocation: '0',
  //         usePrevValue: false
  //       })
  //     }
  //   }

  //   if (transactionWatchlist !== '' && transactionWatchlist !== undefined) {
  //     if (this.state.name !== transactionWatchlist.watchlistname && !this.state.valueUpdated) {
  //       this.setState({
  //         name: transactionWatchlist.watchlistname,
  //         notes: transactionWatchlist.watchlistnotes || "",
  //         timingType: transactionWatchlist.timingtype || "LONG_SHORT",
  //         maxAllocation: transactionWatchlist.maxallocation?transactionWatchlist.maxallocation:'0'
  //       })
  //     }
  //   }
  // }

  close = () => {
    if (this.props.onClose) {
      this.setState({
        show: false,
        name: "",
        notes: "",
        disabled: false,
        usePrevValue: true,
        valueUpdated: false,
        maxAllocation: '0',
        timingType: 'LONG_ONLY',
        allocError:'',
        managed:false,
        hashkey:null
      });

      this.props.onClose();
    }
  };
  
  getUserWatchlists = async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let savedWatchlistNames = []
    let savedWatchlists = []
    let axiosConfig = {
        headers: {
        Authorization: `Bearer ${accessToken}`,
        idtoken: `${localStorage.getItem("idToken")}`,
        ContentType: 'application/json'
      }
    }
    let staticUuid = uuid()
    let data = {
      uuid: staticUuid,
      email: userEmail,
      useremail: userEmail,
    }
    await axios.post(`${BASE_URL}/signal/v1/portfolio/watchlist?uuid=${staticUuid}`, data, axiosConfig).then((res) => {
      // console.log("response", res)
      res.data.portfolios.forEach((watchlist, index) => {
        savedWatchlists.push(watchlist)
        savedWatchlistNames.push(watchlist.watchlistname)
      })
    }).catch((err) => console.log(err))
    this.setState({savedWatchlists: savedWatchlists})
    return savedWatchlistNames
  }

  checkUnique = async (name) => {
    // let savedWatchlists = await this.getUserWatchlists()
    this.setState({disabled: true})
    let savedWatchlistNames = []
    savedWatchlistNames = await this.getUserWatchlists().then(this.setState({disabled: false}))
    // let { savedWatchlist } = this.props;
    // console.log(savedWatchlistNames, name)
    let existWatchlist = savedWatchlistNames.findIndex((watchlistName) => watchlistName === name)
    // let existWatchlist = savedWatchlist.findIndex(
    //   (watchlist) => watchlist.watchlistname.toLowerCase() === name.toLowerCase()
    // );
    // console.log(existWatchlist)

    return existWatchlist;
  };

  afterWatchlistSaved = () => {
    this.setState({
      name: "",
      notes: "",
      disabled: false,
      timingType: 'LONG_ONLY',
      maxAllocation: '0',
      usePrevValue: true,
      valueUpdated: false
    });
  }

  onSaveWatchlist = (operation) => {
    const { name, notes, hashkey, watchlist, maxAllocation, timingType,managed } = this.state;
    let timing = timingType;
    let manage = managed;
    let hash = hashkey;
    if(operation ==="new"){
      manage = false;
      hash = null;
    }
    if (timingType === '')
      timing = 'LONG_ONLY';

    this.props.onSave(
      name.trim(), 
      notes, 
      operation, 
      maxAllocation, 
      timing, 
      hash, 
      watchlist, 
      manage,
      this.afterWatchlistSaved);
    this.setState({
      disabled: true,
    })
  };

  handleSaveWatchlist = async () => {
    const { name, maxAllocation } = this.state;

    if (maxAllocation === '' || (maxAllocation < 0 || maxAllocation > 100)) {
      this.setState({
        allocError: 'Please enter a Max Allocation amount as a percentage between 0 and 100.'
      })
      return false
    }

    let existWatchlist = await this.checkUnique(name.trim());
    if (existWatchlist !== -1) {
      //returns kosha watchlist not user watchlist
      // let watchlist = this.props.savedWatchlist[existWatchlist]
      let watchlist = this.state.savedWatchlists[existWatchlist]
      // console.log("watchlists", watchlist)
      let hashkey = watchlist.hashkey;
      let managed = watchlist.managed;
      // console.log("this.props.savedWatchlist", this.props.savedWatchlist, hashkey)
      if(watchlist?.isSubscribed === true){
        let errorDiv = document.getElementById("name-error-div");
        errorDiv.style.visibility = "visible";
        errorDiv.innerHTML = "You already possess a Subscribed Kosha Watchlist with this name. Please change the name field and try again."
        errorDiv.style.height = "100px"
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
          errorDiv.innerHTML = "Character limit of 100. Allowed: A-Z, 0-9, _"
          errorDiv.style.height = "50px"
        }, 5000);
        return
      }

      this.setState({
        showConfirmModal: true,
        show: false,
        hashkey,
        managed,
        watchlist,
        timingType: this.state.timingType
      });
    }
    else {
      this.setState({
        hashkey:null,
        managed:false,
      });
      this.onSaveWatchlist("new");
    }
  };

  handleChange = (e) => {
    let value = e.target.value;
    let type = e.target.name;
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = watchlistNameCheck(asciiValue);
    let errorDiv = document.getElementById("name-error-div");

    if (
      (type === "name" && asciiCheck && value.length <= 100) ||
      type === "notes" ||
      value === ""
    ) {
      if (errorDiv)
        errorDiv.style.visibility = "hidden";

      this.setState(
        {
          [e.target.name]: value,
          valueUpdated: true
        },
        () => {
          if (this.state.name !== "" && this.state.notes !== "") {
            this.setState({
              disabled: false,
            });
          } else {
            this.setState({
              usePrevValue: false,
              valueUpdated: true
            });
          }
        }
      );
    } else {
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    }
  };

  handleAllocChange = (e) => {
    this.setState({
      maxAllocation: e.target.value,
      allocError: '',
      valueUpdated: true
    })
  }

  timingSelect = (timing) => {
    this.setState({
      timingType: timing,
      timingError: ''
    })
  }

  render() {
    const {
      show,
      buttonName,
      koshaWatchlist,
      transactionWatchlist,
    } = this.props;

    // console.log(this.props.savedWatchlist)
    const { name, notes, showConfirmModal, usePrevValue, maxAllocation, allocError, timingError, timingType, disabled } = this.state;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";

    let prevName = !koshaWatchlist ? transactionWatchlist?.watchlistname : null;
    let prevNotes = !koshaWatchlist ? transactionWatchlist?.watchlistnotes : null;

    return (
      <>
        <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          style={{ display: display }}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                <h4 className="modal-title symbols-modal-title">
                  Save Watchlist
                  <button
                    disabled={disabled}
                    type="button"
                    onClick={this.close}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>

              <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                <div className="login-form save-watchlist-form">
                  <input
                    value={(name) || (usePrevValue ? prevName : "")}
                    autoComplete="off"
                    id="search"
                    type="text"
                    name="name"
                    placeholder="Watchlist Name"
                    onChange={this.handleChange.bind(this)}
                    disabled={disabled || this.state.loadingUserWatchlists}
                    style={{color: (this.state.loadingUserWatchlists ? "gray": "white")}}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                    }}
                  />

                  <div className="symbol-data-div">
                    <span className="tooltiptext" id="name-error-div">
                      Character limit of 100. Allowed: A-Z, 0-9, _
                    </span>
                  </div>

                  <textarea
                    value={notes || (usePrevValue ? prevNotes?prevNotes:'': "")}
                    rows="3"
                    cols="50"
                    style={{ resize: "none" }}
                    autoComplete="off"
                    type="text"
                    name="notes"
                    placeholder="Watchlist Notes"
                    disabled={disabled}
                    onChange={this.handleChange.bind(this)}
                  />

                  <div className="password-modal-username">Max Free Money Redistribution %</div>
                  <input
                    className={allocError ? 'error-input' : ''}
                    onWheel={(event) => event.currentTarget.blur()}
                    value={maxAllocation}
                    onKeyDown={(evt) =>
                      ["e", "E", "+","-"].includes(evt.key) && evt.preventDefault()
                    }
                    id="allocation"
                    type="number"
                    min="0"
                    disabled={disabled}
                    placeholder="Max Allocation (%)"
                    onChange={this.handleAllocChange.bind(this)}
                  />
                  <span className={allocError ? 'show-error' : 'hide-error'}>{allocError}</span>

                  <div className="password-modal-username" style={{ margin: '20px 0 10px' }}>Timing Type</div>
                  <div>
                    <RadioGroup onChange={this.timingSelect} value={timingType || 'LONG_ONLY'}>
                      <ReversedRadioButton
                        value="LONG_ONLY"
                        iconSize={20}
                        iconInnerSize={10}
                        pointColor={"#3BC796"}
                        disabled={disabled}
                        padding={8}
                      >
                        LONG_ONLY
                      </ReversedRadioButton>
                      <ReversedRadioButton
                        value="SHORT_ONLY"
                        iconSize={20}
                        iconInnerSize={10}
                        pointColor={"#3BC796"}
                        disabled={disabled}
                        padding={8}
                      >
                        SHORT_ONLY
                      </ReversedRadioButton>
                      <ReversedRadioButton
                        value="LONG_SHORT"
                        iconSize={20}
                        iconInnerSize={10}
                        pointColor={"#3BC796"}
                        disabled={disabled}
                        padding={8}
                      >
                        LONG_SHORT
                      </ReversedRadioButton>
                    </RadioGroup>
                    <span className={timingError ? 'show-error' : 'hide-error'}>{timingError}</span>
                  </div>
                </div>

                <button
                  onClick={this.handleSaveWatchlist}
                  disabled={
                    (name === "") || buttonName.includes("Saving")
                  }
                  className="btn btn-outline waves-effect waves-light m-b-5 m-t-20"
                >
                  {buttonName}
                  {buttonName.includes("Saving") && (
                    <i className="fa fa-spinner fa-spin" style={{marginLeft: "1rem"}}></i>
                  )}
                </button>
                <div className="clearfix"></div>
              </div> 
            </div>
          </div>
        </div>

        <ConfirmModal
          show={showConfirmModal}
          confirm={() => {
            this.setState({ showConfirmModal: false, show: true });
            this.onSaveWatchlist("update");
          }}
          saveWatchlistCheck={true}
          selectedWatchlistName ={this.state.name}
          cancel={() => {
            this.setState({ showConfirmModal: false, show: true });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  portfolio: state.PortfolioReducer.portfolio,
  userWatchlists: state.PortfolioReducer.userWatchlists
});

export default connect(mapStateToProps)(SaveWatchlist);
