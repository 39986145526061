import React from 'react';
import AnalyzeDataCard from './AnalyzeDataCard';

export default function AnalyzedData({ assetdata, price, familydata, familyPrice,currentRoute }) {
    return (
        <>
            <div className="row">
                <AnalyzeDataCard
                    title="Timing"
                    assetValue={assetdata.timing || 'N/A'}
                    familyValue={familydata.timing || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />
                  <AnalyzeDataCard
                    title="VVOL"
                    assetValue={assetdata.vvol || 'N/A'}
                    familyValue={familydata.vvol || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />
                 <AnalyzeDataCard
                    title="Volatility"
                    assetValue={assetdata.volatility.toFixed(3) || 'N/A'}
                    familyValue={familydata.volatility.toFixed(3) || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />
                <AnalyzeDataCard
                    title="Market Risk"
                    assetValue={assetdata.riskprofile || 'N/A'}
                    familyValue={familydata.riskprofile || 'N/A'}
                    vdiffValue = {assetdata.vdiff || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute ={currentRoute}
                    displayChartData={true}
                />
                {/* <AnalyzeDataCard
                    title="VDIFF"
                    assetValue={assetdata.vdiff || 'N/A'}
                    familyValue={familydata.vdiff || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute ={currentRoute}
                /> */}
            </div>


               
            <div className="row">
                <AnalyzeDataCard
                    title="Avg. Move Up %"
                    assetValue={assetdata.pricemovementup || 'N/A'}
                    familyValue={familydata.pricemovementup || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />

                <AnalyzeDataCard
                    title="Avg. Move Down %"
                    assetValue={assetdata.pricemovementdown || 'N/A'}
                    familyValue={familydata.pricemovementdown || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />

                <AnalyzeDataCard
                    title="Std. Deviation Up"
                    assetValue={assetdata.stddevup || 'N/A'}
                    familyValue={familydata.stddevup || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />

                <AnalyzeDataCard
                    title="Std. Deviation Down"
                    assetValue={assetdata.stddevdown || 'N/A'}
                    familyValue={familydata.stddevdown || 'N/A'}
                    assetSymbol={assetdata.symbol}
                    familySymbol={familydata.symbol}
                    currentRoute = {currentRoute}
                    displayChartData={true}
                />
            </div>
        </>
    )
}
