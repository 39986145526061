import React, { Component } from 'react'
import TimingDiffTable from '../components/TimingDiffTable';
import { formatDownloadDate } from '../utils/helperFunctions';
import { ExportToCsv } from 'export-to-csv';

class TimingDiffModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            loading: false,
            fileLoading: false
        }
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            show: false
        });
    }

    onChange = (e) => {
        this.setState({
            period: e.target.value
        })
    }

    onSave = () => {
        this.props.onSave(this.state.period);
    }

    getTimingDiffData = () => {
        const { data, assetData } = this.props;
        let tableData = [];

        data.forEach((asset, index) => {
            let singleAssetData = assetData.filter((singleAsset) => {
                return singleAsset.symbol === asset.analyzed.assetdata.symbol
            })
            let allocationValue = singleAssetData.length !== 0 ? singleAssetData[0].allocation : '';

            if (asset.analyzed.assetdata.timingschangedatalist) {
                let assetData = asset.analyzed.assetdata;
                let value = assetData.timingschangedatalist;
                if (value.length !== 0) {
                    let subRows = [];
                    if (asset.analyzed.listofasset.length !== 0) {
                        let rowData = asset.analyzed.listofasset;
                        rowData.forEach((subAsset) => {
                            let subAssetData = subAsset.data;
                            let subAssetTimingData = subAssetData.timingschangedatalist;

                            subRows.push({
                                asset: subAssetData.symbol.toUpperCase(),
                                todayValue: subAssetTimingData.length !== 0 ? subAssetTimingData[0].timing : 'N/A',
                                yesterdayValue: subAssetTimingData.length !== 0 ? subAssetTimingData[1].timing : 'N/A',
                                currentDate: subAssetTimingData.length !== 0 ? subAssetTimingData[0].date : 'N/A',
                                previousDate: subAssetTimingData.length !== 0 ? subAssetTimingData[1].date : 'N/A',
                                change: subAssetTimingData.length !== 0 && subAssetTimingData[0].timing !== subAssetTimingData[1].timing ? 'Yes' : 'No',
                                isSubRow: true,
                                allocation: subAsset.allocation
                            })
                        })
                    }

                    tableData.push({
                        asset: assetData.symbol.toUpperCase(),
                        todayValue: value[0].timing,
                        yesterdayValue: value[1].timing,
                        currentDate: value[0].date,
                        previousDate: value[1].date,
                        change: value[0].timing !== value[1].timing ? 'Yes' : 'No',
                        subRows: subRows,
                        allocation: allocationValue
                    })
                }
            }
        })

        return tableData
    }

    exportCsv = () => {
        const { data } = this.props;
        let tableData = [];

        let currentDate = new Date();
        currentDate = formatDownloadDate(currentDate);

        let filename = 'Timing-Difference-' + currentDate;
        data.forEach((asset) => {
            if (asset.analyzed.assetdata.timingschangedatalist) {
                let assetData = asset.analyzed.assetdata;
                let value = assetData.timingschangedatalist;
                if (value.length !== 0) {
                    tableData.push({
                        asset: assetData.symbol.toUpperCase(),
                        previousDate: value[1].timing + " (" + value[1].date + ")",
                        currentDate: value[0].timing + " (" + value[0].date + ")",
                        change: value[0].timing !== value[1].timing ? 'Yes' : 'No',
                    })
                }
            }
        })

        const exportOptions = {
            fieldSeparator: ',',
            quoteStrings: '',
            decimalSeparator: '.',
            filename: filename,
            showLabels: true,
            showTitle: false,
            title: 'Timing Difference',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
            headers: ['Asset', 'Previous Timing', 'Current Timing', 'Change']
        };

        const csvExporter = new ExportToCsv(exportOptions);
        csvExporter.generateCsv(tableData);
    }

    downloadTrading = () => {
        this.props.onDownloadTrading();
    }

    render() {
        const { show, data, loading, fileLoading, bucketLoading } = this.props;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : "";

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm timing-diff-modal-dialog">
                        <div className="modal-content timing-diff-modal-content">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">

                                <h4 className="modal-title">

                                    <button
                                        onClick={this.exportCsv}
                                        disabled={loading || fileLoading}
                                        className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                                        id="timingExportBtn">
                                        Export to CSV
                                    </button>

                                    <span className="timingExportTitle">Timing Difference</span>
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>

                                    <button
                                        onClick={this.downloadTrading}
                                        disabled={loading || fileLoading}
                                        className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                                        id="bucketDetailsBtn">
                                        Bucket Trading
                                        {(bucketLoading) && <i className="fa fa-spinner fa-spin"></i>}
                                    </button>
                                </h4>
                            </div>
                            {
                                data ?
                                    <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                                        <TimingDiffTable data={this.getTimingDiffData()} />
                                    </div>
                                    :
                                    <div className="card-loader card-loader--tabs m-l-30 m-r-20 m-b-10 video-load-tabs"></div>
                            }

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default TimingDiffModal
