import React, { Component } from "react";
import Masonry from "react-masonry-css";
import ReactPaginate from "react-paginate";
import { sortArray, sortArrayDesc } from "../utils/helperFunctions";
import ConfirmModal from "./ConfirmModal";
import ErrorModal from "./ErrorModal";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DynamicIcon from '../assets/img/Filled_D_alpha.png';
import WatchlistSummary from '../assets/pdf/Watchlist-Summary-as-of-5-16-2022.pdf'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomTooltip from '../components/common/CustomTooltip'

class MyWatchlistModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      currentIndex: 0,
      nextDisabled: false,
      prevDisabled: true,
      search: "",
      currentPage: 1,
      pageCount: 0,
      sortType: "asc",
      sortLabel: "Sort (A-Z)",
      showConfirmModal: false,
      selectedWatchlist: null,
      managedWatchlistName: null,
      gridView: false,
      error: "",
    };
  }
  componentDidUpdate() {
    // if (this.props.assets.length) {
    //   let watchlist = this.props.assets?.find(
    //     (asset) => asset?.managed === true
    //   );
    //   if (this.state.managedWatchlistName != watchlist?.watchlistname)
    //     this.setState({
    //       managedWatchlistName: watchlist?.watchlistname,
    //     });
    // }
  }
  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      show: false,
      currentPage: 1,
    });
  };

  onWatchlistClick = (watchlist) => {
    this.props.onSelect(watchlist);
    this.setState({ currentPage: 1 });
  };

  manageWatchlistClick = (watchlist) => {
    let assets = watchlist.holdings.map((asset) => {
      return asset.symbol;
    });
    let $AssetIndex = assets.findIndex((asset) => asset.includes("$"));
    let assetIndex = assets.findIndex((asset) => asset.includes("^"));
    if ($AssetIndex !== -1 || assetIndex !== -1) {
      this.setState({
        error:
          "Watchlists containing “$” or “^” assets cannot be designated as a “Selected” Watchlist.",
        showViewLog: false,
      });
    } else {
      this.setState({
        showConfirmModal: true,
        show: false,
        selectedWatchlist: watchlist,
      });
      this.setState({ currentPage: 1 });
    }
  };
  truncateText = (text) => {
    return text?.length > 53 ? text?.substring(0, 50) + "..." : text;
  };

  getAssets = () => {
    const { watchlistModalTitle } = this.props;
    let addClassName = watchlistModalTitle === "Kosha " ? "kosha" : "";
    let portfolios = JSON.parse(JSON.stringify(this.props.assets));

    if (portfolios.length !== 0) {
      const { currentPage, search } = this.state;
      let finalAssets = this.getFinalAssets(portfolios);

      let managedWatchlistIndex = finalAssets.findIndex((asset) => {
        return (
          asset?.managed && (asset.managed === true || asset.managed === "true")
        );
      });
      if (managedWatchlistIndex !== -1) {
        let selectedWatchlist = finalAssets[managedWatchlistIndex];
        finalAssets.splice(managedWatchlistIndex, 1);
        finalAssets.unshift(selectedWatchlist);
      }

      let finalPageIndex = currentPage * 9;
      let firstPageIndex = (currentPage - 1) * 9;
      let paginatedAssets = [];

      if (search === "") {
        paginatedAssets = finalAssets.filter((asset, index) => {
          return index < finalPageIndex && index >= firstPageIndex;
        });
      } else {
        paginatedAssets = finalAssets;
      }

      if (paginatedAssets.length === 0) {
        if (search === "")
          return (
            <div className="no-results-message">
              There are no saved watchlists.
            </div>
          );
        else {
          return (
            <div className="no-results-message">No matching results found.</div>
          );
        }
      }
      return (this.state.gridView ? paginatedAssets : finalAssets).map(
        (watchlist, index) => {
          if (watchlist) {
            //this gets called too much from one click on a watchlist (100+ times) 11 when modal opens, rest after it closes
            // console.log("watchlist modal title", watchlistModalTitle, watchlistModalTitle.length)
            return (
              <React.Fragment key={`${watchlist.watchlistname}_${index}`}>
                {this.state.gridView ? (
                  <div className={addClassName}>
                    <div
                      className="saved-div"
                      onClick={this.onWatchlistClick.bind(this, watchlist)}
                    >
                      <div className="watchlist-name">
                        {watchlist?.watchlistname.includes("Dyn") ? 
                          <p style={{color: "#3bc796"}}><sup><AutoGraphIcon style={{marginRight: "2px"}}/></sup> {watchlist?.watchlistname}</p>:
                          watchlist?.watchlistname}
                        {/* <p style={{color: "green !important"}}>{watchlist?.watchlistname} TESTEST</p> */}
                      </div>
                      <div className="watchlist-notes">
                        {this.truncateText(watchlist?.watchlistnotes)}
                      </div>
                    </div>
                    <div className="watchlist-info">
                      <div style={{ textAlign: "start", fontWeight: "400" }}>
                        {"Timing Type"}
                      </div>
                      <div style={{ textAlign: "start", fontWeight: "400" }}>
                        {"Redistribution %"}
                      </div>
                      {/* <div style={{ textAlign: "start", fontWeight: "400" }}>
                        {"Subscribed"}
                      </div> */}
                    </div>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div style={{marginRight: "20px" }}>
                        {watchlist?.timingtype}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {watchlist?.maxallocation}
                      </div>
                      {/* <div style={{ textAlign: "center" }}>
                        {watchlist?.isSubscribed}
                      </div> */}
                    </div>
                    {watchlistModalTitle !== "Kosha " && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "16px",
                          }}
                        >
                          {watchlist.managed ? (
                            // <div
                            //   style={{
                            //     color: "#3bc796",
                            //     fontWeight: "bold",
                            //     cursor: "pointer",
                            //     textShadow:
                            //       "-6px 6px 15px rgba(0,0,0,0.5),6px -6px 15px rgba(255,255,255,0.1)",
                            //   }}
                            //   onClick={this.manageWatchlistClick.bind(this, watchlist)}
                            // >
                            //   Selected
                            // </div>
                            <button
                              onClick={this.manageWatchlistClick.bind(
                                this,
                                watchlist
                              )}
                              // disabled={loading || fileLoading}
                              className="watchlist-btn-selected btn-outline-selected waves-effect waves-light"
                            >
                              Selected
                            </button>
                          ) : (
                            // <div
                            //   onClick={this.manageWatchlistClick.bind(this, watchlist)}
                            //   style={{
                            //     color: "#96A2B4",
                            //     textShadow:
                            //       "-6px 6px 15px rgba(0,0,0,0.5),6px -6px 15px rgba(255,255,255,0.1)",
                            //     fontWeight: "bold",
                            //     cursor: "pointer",
                            //   }}
                            // >
                            //   Not Selected
                            // </div>
                            <button
                              onClick={this.manageWatchlistClick.bind(
                                this,
                                watchlist
                              )}
                              // disabled={loading || fileLoading}
                              className="watchlist-btn-notSelected
                    btn-outline-notSelected waves-effect waves-light"
                            >
                              Not Selected
                            </button>
                          )}
                          <i
                            className="fa fa-trash"
                            onClick={this.onDeleteClick.bind(this, watchlist)}
                          ></i>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className={addClassName}>
                    <div className="saved-div-list">
                      <div
                        className="saved-div-list-items"
                        onClick={this.onWatchlistClick.bind(this, watchlist)}
                      >
                        {/* <div
                          style={{ textAlign: "start" }}
                          className="watchlist-name"
                        >
                          {watchlist?.watchlistname}
                        </div> */}
                        <div className="watchlist-name" style={{ textAlign: "start" }}>
                          {((watchlist?.watchlistname.toLowerCase().includes("dyn") && watchlistModalTitle === "Kosha ") || (watchlist?.watchlistname.toLowerCase().includes("dyn") && watchlist?.isSubscribed === true))  ? 
                            // <span style={{color: "#3bc796"}}><sup><AutoGraphIcon style={{marginRight: "2px"}}/></sup> {watchlist?.watchlistname}</span>
                            <span style={{color: "#3bc796"}}><sup><img  src={DynamicIcon} alt="dynamic-icon" style={{width:"15px", height:"auto"}}/></sup> {watchlist?.watchlistname}</span>
                            : watchlist?.watchlistname}
                        </div>
                        <CustomTooltip title={watchlist?.watchlistnotes} variant="long">
                          <div
                            style={{ textAlign: "start" }}
                            className="watchlist-notes"
                          >
                            {this.truncateText(watchlist?.watchlistnotes)}
                          </div>
                        </CustomTooltip>
                        <div
                          style={{ textAlign: "start" }}
                          className="watchlist-notes"
                        >
                          {watchlist?.isSubscribed ? <span>( Kosha Watchlist Subscription )</span>: <span></span> }
                        </div>
                      </div>
                      {watchlistModalTitle !== "Kosha" ? (
                        <>
                          <div style={{ width: "15%", textAlign: "center" }}>
                            {watchlist?.timingtype}
                          </div>
                          <div style={{ width: "20%", textAlign: "center" }}>
                            {watchlist?.maxallocation}
                          </div>
                          {/* <div style={{ width: "10%", textAlign: "center" }}>
                            {watchlist?.isSubscribed}
                          </div> */}
                        </>
                      ) : (
                        <>
                          <div>{watchlist?.timingtype}</div>
                          <div>{watchlist?.maxallocation}</div>
                          {/* <div>{watchlist?.isSubscribed}</div> */}
                        </>
                      )}
                      {watchlistModalTitle !== "Kosha " && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              textAlign: "center",
                              // marginTop: "16px",
                              width: "17%",
                            }}
                          >
                            {watchlist?.managed ? (
                              <button
                                onClick={this.manageWatchlistClick.bind(
                                  this,
                                  watchlist
                                )}
                                // disabled={loading || fileLoading}
                                className="watchlist-btn-selected btn-outline-selected waves-effect waves-light"
                              >
                                Selected
                              </button>
                            ) : (
                              <button
                                onClick={this.manageWatchlistClick.bind(
                                  this,
                                  watchlist
                                )}
                                // disabled={loading || fileLoading}
                                className="watchlist-btn-notSelected 
                    btn-outline-notSelected waves-effect waves-light"
                              >
                                Not Selected
                              </button>
                            )}
                            <i
                              className="fa fa-trash"
                              onClick={this.onDeleteClick.bind(this, watchlist)}
                            ></i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          }
        }
      );
    } else {
      return (
        <div className="no-results-message">There are no saved watchlists.</div>
      );
    }
  };
  closeError = () => {
    this.setState({
      error: "",
    });
  };

  onDeleteClick = (watchlist) => {
    this.props.deleteWatchlist(watchlist);
  };

  getFinalAssets = (assets) => {
    const { search, sortType } = this.state;
    let final = [];
    let finalSymbols = [];
    if (search !== "") {
      final = assets.filter((asset) => {
        return (
          asset?.watchlistname?.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      });
    } else final = assets;

    if (sortType === "asc") finalSymbols = final.sort(sortArray);
    else finalSymbols = final.sort(sortArrayDesc);

    return finalSymbols;
  };

  handleChange = (e) => {
    let search = e.target.value;

    this.setState({
      search,
    });
  };

  handlePageClick = (data) => {
    this.setState({
      currentPage: data.selected + 1,
    });
  };

  onSortClick = () => {
    if (this.state.sortType === "asc") {
      this.setState({
        sortType: "desc",
        sortLabel: "Sort (Z-A)",
      });
    } else {
      this.setState({
        sortType: "asc",
        sortLabel: "Sort (A-Z)",
      });
    }
  };

  render() {
    const {
      show,
      assets,
      watchlistModalTitle,
      watchlistsLoaded,
      manageWatchlist,
    } = this.props;
    const {
      search,
      sortLabel,
      sortType,
      showConfirmModal,
      selectedWatchlist,
      error,
    } = this.state;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";

    let pageCountValue = Math.ceil(assets.length / 9);
    return (
      <>
        <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          style={{ display: display }}
        >
          <div className="modal-dialog modal-sm tutorial-modal-dialog">
            <div
              className="modal-content tutorial-modal-content"
              style={{ height: "auto", minHeight: "auto" }}
            >
              <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                <h4 className="modal-title saved-modal-title" style={{display:"flex", justifyContent: "space-between"}}>
                  <span style={{display: "flex", alignItems: "end"}}><span>{watchlistModalTitle} Watchlists </span>
                    {watchlistModalTitle === "Kosha " && <a href={WatchlistSummary} download style={{color: "#3bc796", display: "flex", alignItems: "center", marginLeft: "10px", fontSize:"0.5em", marginBottom: "4px"}}><FileDownloadOutlinedIcon sx={{marginLeft: "5px"}}/> Download Watchlist Summary</a>}
                    {/* New url for distributing files using cloudfront */}
                    {/* <a href={"https://d13stp2imnuntk.cloudfront.net/Watchlist-Summary.pdf"} rel="noreferrer" target="_blank" > Download Watchlist Summary</a> */}
                  </span>
                  <button
                    type="button"
                    onClick={this.close}
                    className="close api-details-modal-close"
                    disabled={!watchlistsLoaded}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>

              <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                <div className="login-form symbols-modal-asset-search">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!this.state.gridView ? (
                      <div
                        className="fa fa-th-large"
                        area-hidden="true"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => this.setState({ gridView: true })}
                      ></div>
                    ) : (
                      <div
                        className="fa fa-list-ul"
                        area-hidden="true"
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => this.setState({ gridView: false })}
                      ></div>
                    )}
                    <div
                      className="symbols-modal-sort"
                      onClick={this.onSortClick}
                    >
                      <span className="th-icon">
                        {sortLabel}
                        <i
                          className={
                            sortType === "asc"
                              ? "fa fa-caret-down sort-icon"
                              : "fa fa-caret-up sort-icon"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <input
                    value={search}
                    autoComplete="off"
                    id="search"
                    type="search"
                    placeholder="Search Watchlist by name"
                    onChange={this.handleChange.bind(this)}
                    disabled={!watchlistsLoaded}
                  />
                </div>
                {!this.state.gridView && watchlistsLoaded &&
                (
                        <div className="list-view">
                          {watchlistModalTitle.includes("Kosha") ? (
                            <div className="kosha-list-header">
                              <div
                                style={{
                                  width: "50%",
                                  textAlign: "start",
                                  marginRight: "10px",
                                }}
                              >
                                {"Name & Description"}
                              </div>
                              <div style={{ width: "15%", textAlign: "start" }}>
                                {"Timing Type"}
                              </div>
                              <div style={{ width: "16%", textAlign: "start" }}>
                                {"Redistribution %"}
                              </div>
                            </div>
                          ) : (
                            <div className="watchlist-list-header">
                              <div
                                style={{
                                  width: "50%",
                                  textAlign: "start",
                                  marginRight: "10px",
                                }}
                              >
                                {"Name & Description"}
                              </div>
                              <div style={{ width: "15%", textAlign: "start" }}>
                                {"Timing Type"}
                              </div>
                              <div style={{ width: "16%", textAlign: "start", marginRight: "10px" }}>
                                {"Redistribution %"}
                              </div>
                              <div style={{ width: "10%", textAlign: "start" }}>
                                {"Subscribed"}
                              </div>
                            </div>
                          )}
                        </div>
                )}
                <div className="saved-watchlist-div">
                  {watchlistsLoaded ? (
                    <>
                      {this.state.gridView ? (
                        <Masonry
                          breakpointCols={3}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column"
                        >
                          {this.getAssets()}
                        </Masonry>
                      ) : (
                        <div className="my-masonry-grid list-view">
                          {this.getAssets()}
                        </div>
                      )}
                      {assets.length !== 0 && (
                        <div
                          className={
                            search === ""
                              ? "symbols-pagination"
                              : "symbols-pagination symbols-pagination-hidden"
                          }
                        >
                          {this.state.gridView && (
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCountValue}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={
                                "pagination watchlist-pagination"
                              }
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="card-loader card-loader--tabs m-b-30"></div>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        {error && (
          <ErrorModal
            show={error ? true : false}
            onClose={this.closeError}
            message={error}
          />
        )}
        <ConfirmModal
          show={showConfirmModal}
          confirm={() => {
            this.setState({
              showConfirmModal: false,
              show: true,
              managedWatchlistName: selectedWatchlist?.watchlistname,
            });

            manageWatchlist(selectedWatchlist);
          }}
          manageWatchlistCheck={true}
          managed={selectedWatchlist?.managed}
          unmanagedWatchlistName={selectedWatchlist?.watchlistname}
          managedWatchlistName={this.state.managedWatchlistName}
          cancel={() => {
            this.setState({
              showConfirmModal: false,
              show: true,
            });
          }}
        />
      </>
    );
  }
}

export default MyWatchlistModal;
