import { BASE_URL } from "../utils/const";
import { ACTIONS } from "./ActionTypes";
import uuid from "react-uuid";
import { today } from "../utils/helperFunctions";
import { seriesType } from "highcharts";
import accessTokenUpdate from './accessTokenUpdate'
import versionUpdate from './VersionUpdate'
export const getMarketData = (symbols, success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
  
    let requestUrl = BASE_URL + "/signal/v1/market?uuid=" + today();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        symbols: symbols,
        uuid: today(),
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
       else {
        dispatch({
          type: ACTIONS.FETCH_MARKET_DATA,
          payload: { result, fetchRequest: requestBody },
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchChartData = (vsips, chartType, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/chart/daily?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        vsips: vsips,
        uuid: uuid(),
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
       else {
        dispatch({
          type: ACTIONS.FETCH_CHART_DATA,
          payload: { result, fetchRequest: requestBody },
          chartType,
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      failure(err, requestBody);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
    }
  })();
};
