import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import "../home/Home.css";
import ErrorModal from "../../layout/ErrorModal";
import ApiModal from "../../layout/ApiModal";
import {
  validateEmail,
  validatePassword,
  today,
  getTooltipText
} from "../../utils/helperFunctions";

import {
  login,
  clearLogin,
  validateKey,
  createKey,
  updateApiKey,
  getAboutData,
  checkCognito,
  setUserGroup
} from "../../actions/AboutAction";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import poolData from "./UserPool";
import firebaseObj from "../../utils/firebase";
import packageJson from "../../../package.json";
import uuid from "react-uuid";
import ForbiddenErrorModal from "../../layout/ForbiddenErrorModal";

const analytics = firebaseObj.analytics();

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      apiModalShow: false,
      apiDetails: [],
      apiResponse: [],
      loading: false,
      username: "",
      password: "",
      emailError: null,
      passwordError: null,
      loginStatus: 1,
      confirmPassword: "",
      confirmError: null,
      oldPassword: "",
      checkCognito: "",
    };
  }

  componentDidMount() {
    const { loginDetails, checkCognito } = this.props.about;

    let prevRoute = localStorage.getItem("currentRoute");

    if (prevRoute === "resetPassword") {
      this.props.clearLogin(
        () => {
          window.sessionStorage.setItem("token", "");
          window.sessionStorage.setItem("reload", "false");
          localStorage.clear();
        },
        (err) => {
          console.log("err", err);
        }
      );
    }

    let tabCount = localStorage.getItem("tab");
    let autoLogin = localStorage.getItem("autoLogin");

    if (loginDetails && Object.keys(loginDetails).length !== 0) {
      if (autoLogin === "true" || tabCount === "many") {
        if (checkCognito === 0) {
          if (localStorage.getItem("forbidden")) {
            this.props.history.goBack();
            // localStorage.removeItem("forbidden");
          } else {
            this.props.history.push("/watchlist?uuid="+uuid());
            window.sessionStorage.removeItem("forbidden");
          }
        } else this.onCheckAWSAPIExist(loginDetails);
      } else {
        this.props.clearLogin(
          () => {
            window.sessionStorage.setItem("token", "");
            window.sessionStorage.setItem("reload", "false");
            localStorage.clear();
          },
          (err) => {
            console.log("err", err);
          }
        );
      }
    }

    let host = window.location.host;
    let hostName = "dev";
    let prevAbout = this.props.about;
    let currentVersion = packageJson.version;

    if (
      host !== "dev.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com" &&
      host !== "localhost:3000"
    )
      hostName = "beta";
    
      // TODO: Move into header to remove duplicate calls to the about api?
    // this.props.getAboutData(
    //   prevAbout?.about?.id,
    //   () => {},
    //   () => {}
    // );

    analytics.logEvent("Login");
    localStorage.setItem("currentRoute", "login");
  }

  /**
   * on error show modal
   */
  setErrorState = (err, request) => {
    let apiDetails = [];
    let apiResponse = [];
    let error = err.message ? err.message : "Error Occured !!";
    let errorJson = err;
    apiDetails.push(request);
    apiResponse.push({ error });

    this.setState({
      loading: false,
      error,
      apiDetails,
      apiResponse,
      errorJson: errorJson,
    });
  };

  /**
   * on API info modal click
   */
  getApiInfo = () => {
    this.setState({
      apiModalShow: true,
    });
  };

  /**
   * on Modal Close handle
   */
  onApiModalClose = () => {
    this.setState({
      apiModalShow: false,
    });
  };

  /**
   * On View Log Click
   */
  viewLog = () => {
    this.setState({
      apiModalShow: true,
      error: false,
    });
  };

  /**
   * on input field change handle
   * @param {*} type
   * @param {*} e
   */
  handleChange = (type, e) => {
    let value = e.target.value;

    this.setState({
      [type]: value,
      emailError: null,
      passwordError: null,
    });
  };

  /**
   * On Login click handle
   */
  onLogin = () => {
    const { username, password } = this.state;
    let emailCheck = false;
    let passwordCheck = false;

    this.setState({
      loading: true,
    });

    if (username === "") {
      this.setState({
        emailError: "Please enter username",
        loading: false,
      });
    } else {
      emailCheck = validateEmail(username);
    }

    if (emailCheck) {
      this.setState({
        emailError: "Please enter a valid email address",
        loading: false,
      });
      return false;
    }

    if (password.length === 0) {
      this.setState({
        passwordError: "Please enter password",
        loading: false,
      });
      return false;
    } else {
      passwordCheck = validatePassword(password);
    }

    if (passwordCheck) {
      this.setState({
        passwordError: "Please enter a password with 8 or more characters",
        loading: false,
      });
      return false;
    }

    if (!emailCheck && !passwordCheck) {
      let thisVal = this;

      let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });
      let count = 1;
      // this.props.checkCognito(username, password,
      //     (response) => {
      if (count === 1) {
        user.authenticateUser(authDetails, {
          onSuccess: (result) => {
            if (result) {
              console.log(result)
              localStorage.setItem("accessToken", result?.accessToken?.jwtToken);
              localStorage.setItem("refreshToken", result?.refreshToken?.token);
              localStorage.setItem("idToken", result?.idToken.jwtToken);
              window.sessionStorage.setItem("SESSION_TIME", Date.now());
              // check user already exsist or API Validation
              this.onCheckAWSAPIExist(authDetails);
              count += 1;
              this.props.setUserGroup(result?.idToken?.payload['cognito:groups'][0] || '')
            }
          },

          onFailure: (err) => {
            const { message, code } = err;

            if (code === "PasswordResetRequiredException") {
              analytics.setUserProperties({
                email: username,
                App_Version: packageJson.version,
              });
              analytics.logEvent("Reset Password");

              thisVal.props.history.push({
                pathname: "/resetPassword",
                state: { username: username },
              });
            } else {
              this.setState({
                emailError: message,
                loading: false,
              });
            }
          },
          // eslint-disable-next-line no-unused-vars
          newPasswordRequired(userAttributes, requiredAttributes) {
            thisVal.setNewPassword(password, username);
          },
        });
      } else {
        //redirect to market
        window.sessionStorage.setItem("token", uuid());
        localStorage.setItem("autoLogin", "true");
        this.props.history.push("/watchlist?uuid="+uuid());
      }
      // this.props.getAboutData(this.props.about.id,
      //   () => {},
      //   () => {});
      //     },
      //     (err) => {
      //         console.log('err', err);
      //     }
      // )
    }
  };

  /**
   *
   * On new password required
   */
  setNewPassword = (oldPassword, username) => {
    analytics.setUserProperties({
      email: username,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Set New Password");

    this.setState({
      loading: false,
      loginStatus: 2,
      password: "",
      oldPassword,
    });
  };

  /**
   * On Create API Key
   */
  onCreateAPIKey = (email) => {
    if (email !== "") {
      this.props.createKey(
        email,
        () => {
          const { loginDetails } = this.props.about;
          this.onCreateAPIKeySuccess(loginDetails);
        },
        (err) => {
          this.onErrorFunc(err);
        }
      );
    } else {
      let err = { message: "Something went wrong! Please login again" };
      this.onErrorFunc(err);
    }
  };

  /**
   * AWS APIKEY Exist check
   */
  onCheckAWSAPIExist = (authdetails) => {
    this.setState({
      loading: true,
    });
    let thisVal = this;
    let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool?.getCurrentUser();

    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log("err", err);
      } else {
        cognitoUser.getUserAttributes(function (err, user) {
          if (err) {
            console.log("err", err);
          } else {
            let userAttribute = user;
            let apiKeyValue = "";
            let userEmail = "";

            userAttribute.forEach((attribute) => {
              if (attribute.Name === "custom:apikey")
                apiKeyValue = attribute.Value;
              if (attribute.Name === "email") userEmail = attribute.Value;
            });

            if (apiKeyValue !== "" && userEmail !== "") {
              thisVal.props.validateKey(
                userEmail,
                apiKeyValue,
                () => {
                  const { loginDetails } = thisVal.props.about;
                  if (loginDetails.status === "ACTIVE")
                    thisVal.onCheckAWSAPIExistSuccess("success");
                  else {
                    thisVal.props.history.push("/resetPassword");
                  }
                },
                (err) => {
                  console.log("err", err);
                }
              );
            } else {
              let err = { message: "Something went wrong! Please login again" };
              this.onErrorFunc(err);
            }
          }
        });
      }
    });
  };

  /**
   * On Create API Key Success
   */
  onCreateAPIKeySuccess = (data) => {
    // update API Key
    this.onUpdateToAWSAPIkey(data.apikey);
  };

  /**
   * On Create API Key Success
   */
  onCheckAWSAPIExistSuccess = (data) => {
    const { loginDetails } = this.props.about;
    if (data === "success") {
      analytics.setUserProperties({
        email: loginDetails?.email,
        App_Version: packageJson.version,
      });
      analytics.logEvent("Login Success");
      window.sessionStorage.setItem("token", loginDetails.apikey);
      localStorage.setItem("autoLogin", "true");
      //redirect to market overview
      if (localStorage.getItem("forbidden")) {
        this.props.history.goBack();
        // localStorage.removeItem("forbidden");
      } else {
        this.props.history.push("/watchlist?uuid="+uuid());
        window.sessionStorage.removeItem("forbidden");
      }
    } else {
      this.onCreateAPIKey(loginDetails.email);
    }
  };

  /**
   * API Key Update to Aws Cogito
   */
  onUpdateToAWSAPIkey = (apiKey) => {
    let thisVal = this;
    let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const { loginDetails } = this.props.about;

    const attributes = [
      new CognitoUserAttribute({
        Name: "custom:apikey",
        Value: apiKey,
      }),
    ];

    const cognitoUser = userPool.getCurrentUser();

    cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log("err", err);
      } else {
        cognitoUser.updateAttributes(attributes, (err, result) => {
          if (err) console.error("err", err);
          if (result) {
            thisVal.props.updateApiKey(
              apiKey,
              () => {
                analytics.setUserProperties({
                  email: loginDetails.email,
                  App_Version: packageJson.version,
                });
                analytics.logEvent("Login Success");

                window.sessionStorage.setItem("token", loginDetails.apikey);
                localStorage.setItem("autoLogin", "true");
                if (localStorage.getItem("forbidden")) {
                  thisVal.props.history.goBack();
                  //   localStorage.removeItem("forbidden");
                } else {
                  thisVal.props.history.push("/watchlist?uuid="+uuid());
                  window.sessionStorage.removeItem("forbidden");
                }
              },
              (err) => {
                console.log("err", err);
              }
            );
          }
        });
      }
    });
  };

  /**
   * Error Function Handle
   */
  onErrorFunc = (err) => {
    if (err.message) {
      this.setState({
        emailError: err.message,
        loading: false,
      });
      return;
    }
    this.setState({
      emailError: "Something went wrong! Please try again later.",
      loading: false,
    });
  };

  /**
   * Reset Password Handle
   */
  onResetPassword = () => {
    const { password, confirmPassword, username, oldPassword } = this.state;
    let passwordCheck = false;

    this.setState({
      loading: true,
    });

    if (password.length === 0) {
      this.setState({
        passwordError: "Please enter password",
        loading: false,
      });
      return false;
    } else {
      passwordCheck = validatePassword(password, username);
    }

    if (passwordCheck) {
      this.setState({
        passwordError: "Please enter a password with 8 or more characters",
        loading: false,
      });
      return false;
    }

    if (password !== confirmPassword) {
      this.setState({
        confirmError: "Passwords do not match!",
        loading: false,
      });
      return false;
    }

    if (!passwordCheck) {
      this.setState({
        loading: true,
      });
      this.onNewPasswordSubmit(oldPassword, password, username);
    }
  };

  /**
   * On Password update submit
   */
  onNewPasswordSubmit = (oldPassword, password, username) => {
    let thisVal = this;
    let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: oldPassword,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        if (result) {
          localStorage.setItem("accessToken", result?.accessToken?.jwtToken);
          localStorage.setItem("idToken", result?.idToken.jwtToken);
          // this.props.setUserGroup(session?.idToken?.payload['cognito:groups'][0] || '')
          // history.push('/');
        }
      },

      onFailure: (err) => {
        this.onErrorFunc(err);
      },

      // eslint-disable-next-line no-unused-vars
      newPasswordRequired(userAttributes, requiredAttributes) {
        // the api doesn't accept this field back
        // eslint-disable-next-line no-param-reassign
        delete userAttributes.email_verified;
        delete userAttributes.email;

        user.completeNewPasswordChallenge(password, userAttributes, {
          onSuccess(result) {
            analytics.setUserProperties({
              email: username,
              App_Version: packageJson.version,
            });
            analytics.logEvent("Set New Password Complete");

            if (result) {
              localStorage.setItem("accessToken", result?.accessToken?.jwtToken);
              localStorage.setItem("idToken", result?.idToken.jwtToken);
              thisVal.onCreateAPIKey(username);
            }
          },
          onFailure(err) {
            thisVal.onErrorFunc(err);
          },
        });
      },
    });
  };

  /**
   * On Enter key press
   * @param {*} type
   * @param {*} e
   */
  handleKeyPress = (type, e) => {
    if (e.target.value && e.key === "Enter") {
      this.onLogin();
    }
  };

  render() {
    const { history, about } = this.props;
    const {
      apiModalShow,
      apiResponse,
      apiDetails,
      loading,
      error,
      errorJson,
      confirmError,
      username,
      password,
      emailError,
      passwordError,
      loginStatus,
      confirmPassword,
    } = this.state;

    let errorView =
      !loading && error ? (
        <>
          <ErrorModal
            show={true}
            message={error}
            viewLog={this.viewLog}
            showViewLog={true}
          />
        </>
      ) : null;

    return (
      <>
        <Header history={this.props.history} />
        <div id="page-wrapper" className="m-l-0">
          <div className="container">
            {window.sessionStorage.getItem("forbidden") && (
              <div className="login-expire-error">
                {/* <b>Sorry!</b> You have been logged out of Kosha since your
                session expired. Please login again. */}
                Your session has expired. Please log in again.
              </div>
            )}
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12">
                <div
                  className={`white-box ${
                    window.sessionStorage.getItem("forbidden")
                      ? "login-expire"
                      : "login-white-box"
                  }`}
                >
                  {loginStatus === 1 ? (
                    <div className="login-form">
                      <input
                        className={emailError ? "error-input" : ""}
                        value={username}
                        disabled={loading}
                        autoComplete="off"
                        id="username"
                        type="search"
                        placeholder="Enter Email Address"
                        onChange={this.handleChange.bind(this, "username")}
                        onKeyUp={this.handleKeyPress.bind(this, "username")}
                      />
                      <span
                        className={emailError ? "show-error" : "hide-error"}
                      >
                        {emailError}
                      </span>

                      <input
                        className={passwordError ? "error-input" : ""}
                        value={password}
                        disabled={loading}
                        autoComplete="new-password"
                        id="password"
                        type="password"
                        placeholder="Enter password"
                        onChange={this.handleChange.bind(this, "password")}
                        onKeyUp={this.handleKeyPress.bind(this, "username")}
                      />
                      <span
                        className={passwordError ? "show-error" : "hide-error"}
                      >
                        {passwordError}
                      </span>

                      <button
                        disabled={loading}
                        onClick={this.onLogin}
                        className="btn login-btn"
                      >
                        Login{" "}
                        {loading && <i className="fa fa-spinner fa-spin"></i>}
                      </button>
                      <div className="tooltipx">
                      <Link to="/resetPassword" className="forgot-pwd-link">
                        Forgot Password?
                      </Link>
                      <div className="tooltiptext-div">{"click if you forgot your password"}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="login-form">
                      <input
                        className={passwordError ? "error-input" : ""}
                        value={password}
                        disabled={loading}
                        autoComplete="new-password"
                        id="password"
                        type="password"
                        placeholder="Enter new password"
                        onChange={this.handleChange.bind(this, "password")}
                      />
                      <span
                        className={passwordError ? "show-error" : "hide-error"}
                      >
                        {passwordError}
                      </span>

                      <input
                        className={passwordError ? "error-input" : ""}
                        value={confirmPassword}
                        disabled={loading}
                        autoComplete="new-password"
                        id="password"
                        type="password"
                        placeholder="Confirm password"
                        onChange={this.handleChange.bind(
                          this,
                          "confirmPassword"
                        )}
                      />
                      <span
                        className={confirmError ? "show-error" : "hide-error"}
                      >
                        {confirmError}
                      </span>
                      <div className ="tooltipx">
                      <button
                        disabled={loading}
                        onClick={this.onResetPassword}
                        className="btn login-btn"
                      >
                        Submit{" "}
                        {loading && <i className="fa fa-spinner fa-spin"></i>}
                      </button>
                      <div className="tooltiptext-div">{"Click here if you forgot your password"}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer location="home" onClick={this.getApiInfo} />

          {errorView}

          <ApiModal
            show={apiModalShow}
            apiDetails={apiDetails}
            onClose={this.onApiModalClose}
            apiResponse={apiResponse}
            history={this.props.history}
            errorJson={errorJson}
            screen="home"
          />
          <ForbiddenErrorModal history={history} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  about: state.AboutReducer,
  ETLStatus: state.AboutReducer?.about?.code || 0,
});

export default connect(mapStateToProps, {
  login,
  clearLogin,
  createKey,
  validateKey,
  updateApiKey,
  getAboutData,
  checkCognito,
  setUserGroup
})(Login);
