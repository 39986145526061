import { BASE_URL } from "../utils/const";
import { ACTIONS } from "./ActionTypes";
import uuid from "react-uuid";
import accessTokenUpdate from "./accessTokenUpdate";
import versionUpdate from "./VersionUpdate";
export const login = (username, password, hashedIp, success, failure) => (
  dispatch
) => {
  (async () => {
    try {
      dispatch({
        type: ACTIONS.LOGIN,
        payload: { username, password },
      });

      success();
    } catch (err) {
      failure(err);
    }
  })();
};

export const clearLogin = (success, failure) => (dispatch) => {
  try {
    dispatch({
      type: ACTIONS.CLEAR_LOGIN,
    });
    success();
  } catch (err) {
    failure(err);
  }
};

export const checkCognito = (username, password, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/checkstatus?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: "Basic dGVzdHVzZXI6cGFzc3dvcmQ=",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      dispatch({
        type: ACTIONS.COGNITO_CHECK,
        payload: { result: { result, username, password } },
      });

      success(result);
      // accessTokenUpdate;
    } catch (err) {
      failure(err);
    }
  })();
};

export const updateApiKey = (apikey, success, failure) => (dispatch) => {
  try {
    dispatch({
      type: ACTIONS.LOGIN,
      payload: apikey,
    });
    success();
  } catch (err) {
    failure(err);
  }
};

export const getAboutData = (id, success, failure) => (dispatch) => {
  (async () => {
    // const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/about?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        about: uuid(),
        uuid: uuid(),
        // "useremail":userEmail
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        if (id !== result.id) {
          dispatch({
            type: ACTIONS.ABOUT,
            payload: { result, fetchRequest: requestBody },
          });
        }
        success(result);
        // accessTokenUpdate;
      }
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //     type: ACTIONS.FORBIDDEN_ERROR,
      //     payload: err,
      //   });
      // failure(err, requestBody);
    }
  })();
};

export const getLogs = (success, failure) => (dispatch) => {
  try {
    dispatch({
      type: ACTIONS.GET_LOGS,
    });
    success();
  } catch (err) {
    failure(err);
  }
};

export const validateKey = (email, apikey, success, failure) => (dispatch) => {
  (async () => {
    console.log("VALIDATE KEY with Token");
    const accessToken = localStorage.getItem("accessToken");
    // const deviceToken = localStorage.getItem("deviceToken");
    let requestUrl = BASE_URL + "/signal/v1/validatekey?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        apikey: apikey,
        uuid: uuid(),
        // "token": deviceToken
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      dispatch({
        type: ACTIONS.VALIDATE_KEY,
        payload: { result, fetchRequest: requestBody },
      });

      success();
    } catch (err) {
      // dispatch({
      //     type: ACTIONS.FORBIDDEN_ERROR,
      //     payload: err,
      //   });
      failure(err, requestBody);
    }
  })();
};

export const createKey = (email, success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/createkey?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        uuid: uuid(),
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      dispatch({
        type: ACTIONS.CREATE_KEY,
        payload: { result, fetchRequest: requestBody },
      });

      success();
    } catch (err) {
      // dispatch({
      //     type: ACTIONS.FORBIDDEN_ERROR,
      //     payload: err,
      //   });
      failure(err, requestBody);
    }
  })();
};

export const getTrainingVideo = (success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/training?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      } else {
        dispatch({
          type: ACTIONS.GET_TRAINING_VIDEO,
          payload: { result, fetchRequest: requestBody },
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //     type: ACTIONS.FORBIDDEN_ERROR,
      //     payload: err,
      //   });
      failure(err, requestBody);
    }
  })();
};
export const forbiddenErrorModal = () => (dispatch) => {
  (async () => {
    try {
      dispatch({
        type: ACTIONS.FORBIDDEN_ERROR,
        payload: "token expired...",
      });
    } catch (err) {
      console.log("err", err);
    }
  })();
};
export const closeForbiddenErrorModal = () => (dispatch) => {
  (async () => {
    try {
      dispatch({
        type: ACTIONS.FORBIDDEN_ERROR,
        payload: null,
      });
    } catch (err) {
      console.log("err", err);
    }
  })();
};

export const versionUpdateModal = () => (dispatch) => {
  (async () => {
    try {
      dispatch({
        type: ACTIONS.VERSION_UPDATE,
        payload: true,
      });
    } catch (err) {
      console.log("err", err);
    }
  })();
};
export const closeVersionUpdateModal = () => (dispatch) => {
  (async () => {
    try {
      dispatch({
        type: ACTIONS.VERSION_UPDATE,
        payload: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log("err", err);
    }
  })();
};

export const sendFirebaseToken = (token) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/notification/token?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: userEmail,
        token: token,
        uuid: uuid(),
        // "useremail": userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      } else {
        dispatch({
          type: ACTIONS.FIREBASE_TOKEN_SEND,
          payload: { result, fetchRequest: requestBody },
        });
      }
      // success();
    } catch (err) {
      // dispatch({
      //     type: ACTIONS.FORBIDDEN_ERROR,
      //     payload: err,
      //   });
      // failure(err, requestBody);
    }
  })();
};

export const getTooltipsJson = () => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/tooltips";
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // email: userEmail,
        // token: token,
        uuid: uuid(),
        "useremail": userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      } else {
        dispatch({
          type: ACTIONS.TOOLTIPS_JSON,
          payload: { result, fetchRequest: requestBody },
        });
      }
      // success();
    } catch (err) {
      // dispatch({
      //     type: ACTIONS.FORBIDDEN_ERROR,
      //     payload: err,
      //   });
      // failure(err, requestBody);
    }
  })();
};

export const setUserGroup = (group) => (dispatch) => {
  (async () => {
    console.log(group)
    try {
      dispatch({
        type: ACTIONS.SET_USER_GROUP,
        payload: group,
      });
    } catch (err) {
      console.log("err", err);
    }
  })();
};