import { BASE_URL } from "../utils/const";
import { ACTIONS } from "./ActionTypes";
import uuid from 'react-uuid';
import accessTokenUpdate from './accessTokenUpdate'
import versionUpdate from './VersionUpdate';

export const fetchPortfolio = (assets, success, failure) => dispatch => {
  (async () => {
    let requestBodyArray = [];
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    try {

      let result = await Promise.all(assets.map(asset => {

        let requestUrl = BASE_URL + '/signal/v1/symbol?uuid=' + uuid();
        let requestBody = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "uuid": uuid(),
            "symbols": [{ "symbol": asset.symbol }],
            "useremail": userEmail,
          })
        }

        let fetchRequest = new Request(requestUrl, requestBody)

        requestBody.url = requestUrl;
        requestBodyArray.push(requestBody);

        return fetch(fetchRequest)
          .then(res => res.json());

      }));
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        dispatch({
          type: ACTIONS.FETCH_PORTFOLIO,
          payload: { result, fetchRequest: requestBodyArray },
          assets: assets
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBodyArray);
    }

  })()
}

export const clearPortfolio = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_PORTFOLIO
  })
}

export const fetchFamilyData = (family, success, failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/symbol?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "symbols": [family],
        "useremail": userEmail,
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl

    try {

      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        dispatch({
          type: ACTIONS.FETCH_FAMILY_DATA,
          payload: { result, fetchRequest: requestBody }
        });

        success();
        // accessTokenUpdate;
      }
    }
    catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }

  })()
}

export const fetchNewPortfolio = (
  watchlistname, 
  watchlistEmail,
  hashkey, 
  holdings, 
  maxallocation, 
  timingtype, 
  publisherWatchlistName,
  publisherHashKey,
  publisherEmail, 
  watchlistChanged, 
  fetchError,
  success, 
  failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/analyze?uuid=' + uuid();
    if(watchlistChanged || !watchlistname || fetchError) requestUrl = BASE_URL + '/signal/v1/portfolio?uuid=' + uuid();

    // console.log(fetchError)

    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          'watchlistname': watchlistname,
          'hashkey': hashkey,
          'holdings': holdings,
          'maxallocation':maxallocation,
          'timingtype':timingtype,
          'publisherWatchlistName': publisherWatchlistName,
          'publisherHashKey': publisherHashKey,
          'publisherEmail': publisherEmail, 
          'useremail': watchlistEmail
        },
        "useremail": userEmail,
      })
    }

    if (watchlistname === null){
      requestBody = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          "idtoken": `${localStorage.getItem("idToken")}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "uuid": uuid(),
          "portfolio": {
            'holdings': holdings,
            'maxallocation': maxallocation,
            'timingtype':timingtype,
            // 'useremail': userEmail 
          },
          "useremail": userEmail,
        })
      }
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl

    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        result.portfolio.daily.performance.percentagegainsdata.map((data)=>{
          data.performancedatalist.map((item)=>{
            item.percentagegain = item.percentagegain*100;
          })
        });
     
        result.portfolio.daily.timings.timingsdata.map((data)=>{
          data.timingsdatadetailslist.map((item)=>{
            item.timingsdata = item.timingsdata*100;;
          })
        })
        dispatch({
          type: ACTIONS.FETCH_PORTFOLIO_NEW,
          payload: { result: result.portfolio, fetchRequest: requestBody, holdings }
        });

        success();
      }
    }
    catch (err) {
      console.log('err', err);
      failure(err, requestBody);
    }
  })()
}

export const optimizeWatchlist = (holdings, option, benchmark, success, failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/divider-chartfolio/watchlist/optimize/sforward?uuid=' + uuid();
    if (option === 'backward')
      requestUrl = BASE_URL + '/divider-chartfolio/watchlist/optimize/sbackward?uuid=' + uuid();

    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          'holdings': holdings,
          'benchmark': benchmark,
        },
        "useremail": userEmail,
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl

    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        result.benchmarkdaily.performance.percentagegainsdata.map((data)=>{
          data.performancedatalist.map((item)=>{
            item.percentagegain = item.percentagegain*100;
          })
        });
     
        result.benchmarkdaily.timings.timingsdata.map((data)=>{
          data.timingsdatadetailslist.map((item)=>{
            item.timingsdata = item.timingsdata*100;
          })
        })
        result.portfolio.daily.performance.percentagegainsdata.map((data)=>{
          data.performancedatalist.map((item)=>{
            item.percentagegain = item.percentagegain*100;
          })
        });
     
        result.portfolio.daily.timings.timingsdata.map((data)=>{
          data.timingsdatadetailslist.map((item)=>{
            item.timingsdata = item.timingsdata*100;
          })
        })
        dispatch({
          type: ACTIONS.FETCH_PORTFOLIO_NEW,
          payload: { result: result.portfolio, fetchRequest: requestBody, holdings }
        });

        success(result);
      }
    }
    catch (err) {
      console.log('err', err);
      failure(err, requestBody);
    }
  })()
}

export const fetchFamilyChartData = (vsips, chartType, success, failure) => dispatch => {

  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/chart/daily?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "vsips": vsips,
        "useremail": userEmail,
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)

    try {

      let result = await fetch(fetchRequest)
        .then(res => res.json());

      requestBody.url = requestUrl
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        result.performance.percentagegainsdata.map((data)=>{
          data.performancedatalist.map((item)=>{
            item.percentagegain = item.percentagegain*100;
          })
        });
     
        result.timings.timingsdata.map((data)=>{
          data.timingsdatadetailslist.map((item)=>{
            item.timingsdata = item.timingsdata*100;;
          })
        })
        dispatch({
          type: ACTIONS.FETCH_FAMILY_CHART_DATA,
          payload: { result, fetchRequest: requestBody },
          chartType
        });

        success();
        // accessTokenUpdate;

      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }

  })()

}

export const getTimingDiff = (holdings, success, failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/holdings?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          'holdings': holdings
        },
        "useremail": userEmail,
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl

    try {

      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        dispatch({
          type: ACTIONS.FETCH_TIMING_DATA,
          payload: { result: result, fetchRequest: requestBody, holdings }
        });

        success();
        // accessTokenUpdate;

      }
    }
    catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })()
}

export const getBucketDetails = (holdings, maxallocation, timingtype, success, failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/bucketdetails?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          'holdings': holdings,
          'maxallocation': maxallocation,
          'timingtype': timingtype
        },
        "useremail": userEmail,
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl

    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        dispatch({
          type: ACTIONS.GET_BUCKET_DETAILS,
          payload: { result: result, fetchRequest: requestBody, holdings }
        });

        success();
        // accessTokenUpdate;

      }
    }
    catch (err) {
      console.log('err', err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })()
}

export const updateAssets = assets => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_ASSETS,
    payload: assets
  });
}

export const resetPortfolioChart = () => dispatch => {
  dispatch({
    type: ACTIONS.RESET_PORTFOLIO_CHART
  });
}

export const updatePortfolioProps = (assets, filename) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_PORTFOLIO_PROPS,
    payload: { assets, filename }
  })
}

export const saveWatchlist = (name, notes, assets, operation, maxAllocation, timingType, email, hashkey, benchmark,managed, success, failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/watchlist/save?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          "holdings": assets,
          'watchlistname': name,
          'watchlistnotes': notes,
          'useremail': email,
          'operation': operation,
          'optimization': 'none',
          'hashkey': hashkey,
          'maxallocation': maxAllocation,
          'timingtype': timingType,
          'benchmark': benchmark,
          'managed':managed,
        },
        "useremail": email
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl
    let code = 0;
    let message ="success";
    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        code = result.code;
        message = result.message;
        if (result.error) {
          failure(result.error, requestBody)
        }
        else {
          if (operation === 'delete') {
            dispatch({
              type: ACTIONS.SAVE_WATCHLIST,
              payload: { result: result, fetchRequest: requestBody }
            });
            success(result);
          }
          else {
            // console.log(result,'in save api..')
            result.benchmarkdaily.performance.percentagegainsdata.map((data)=>{
              data.performancedatalist.map((item)=>{
                item.percentagegain = item.percentagegain*100;
              })
            });
         
            result.benchmarkdaily.timings.timingsdata.map((data)=>{
              data.timingsdatadetailslist.map((item)=>{
                item.timingsdata = item.timingsdata*100;
              })
            })
            result.portfolio.daily.performance.percentagegainsdata.map((data)=>{
              data.performancedatalist.map((item)=>{
                item.percentagegain = item.percentagegain*100;
              })
            });
         
            result.portfolio.daily.timings.timingsdata.map((data)=>{
              data.timingsdatadetailslist.map((item)=>{
                item.timingsdata = item.timingsdata*100;
              })
            })
            let holdings = assets;
            dispatch({
              type: ACTIONS.GET_WATCHLIST_DATA,
              payload: { result: result, fetchRequest: requestBody, holdings }
            });
            success(result);
            // accessTokenUpdate;
          }
        }
      }
    }
    catch (err) {
      console.log('err', err);
      if(code===0){
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
        failure(err, requestBody);
      }
      else{
        // console.log(message,'message...')
        failure(message,requestBody);
      }
    }
  })()
}

export const subscribeToWatchlist = (
  name, 
  notes, 
  assets, 
  operation, 
  maxAllocation, 
  timingType, 
  email, 
  hashkey, 
  benchmark, 
  managed, 
  publisherWatchlistName, 
  publisherEmail, 
  publisherHashKey, 
  isSubscribed, 
  success, 
  failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/watchlist/subscribe?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          "holdings": assets,
          'watchlistname': name,
          'watchlistnotes': notes,
          'useremail': email,
          'operation': operation,
          'optimization': 'none',
          'hashkey': hashkey,
          'maxallocation': maxAllocation,
          'timingtype': timingType,
          'benchmark': benchmark,
          'managed':managed,
          "publisherWatchlistName": name,
          "publisherEmail": publisherEmail,
          "publisherHashKey":publisherHashKey,
          "isSubscribed": isSubscribed
        },
        "useremail": email
      })
    }
    // console.log(name)

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl
    let code = 0;
    let message ="success";
    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        code = result.code;
        message = result.message;
        if (result.error) {
          failure(result.error, requestBody)
        }
        else {
          if (operation === 'delete') {
            dispatch({
              type: ACTIONS.SAVE_WATCHLIST,
              payload: { result: result, fetchRequest: requestBody }
            });
            success(result);
          }
          else {
            // console.log(result,'in subscribe api..')
            result.benchmarkdaily.performance.percentagegainsdata.map((data)=>{
              data.performancedatalist.map((item)=>{
                item.percentagegain = item.percentagegain*100;
              })
            });
         
            result.benchmarkdaily.timings.timingsdata.map((data)=>{
              data.timingsdatadetailslist.map((item)=>{
                item.timingsdata = item.timingsdata*100;
              })
            })
            result.portfolio.daily.performance.percentagegainsdata.map((data)=>{
              data.performancedatalist.map((item)=>{
                item.percentagegain = item.percentagegain*100;
              })
            });
         
            result.portfolio.daily.timings.timingsdata.map((data)=>{
              data.timingsdatadetailslist.map((item)=>{
                item.timingsdata = item.timingsdata*100;
              })
            })
            let holdings = assets;
            dispatch({
              type: ACTIONS.GET_WATCHLIST_DATA,
              payload: { result: result, fetchRequest: requestBody, holdings }
            });
            success(result);
            // accessTokenUpdate;
          }
        }
      }
    }
    catch (err) {
      console.log('err', err);
      if(code===0){
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
        failure(err, requestBody);
      }
      else{
        // console.log(message,'message...')
        failure(message,requestBody);
      }
    }
  })()
}

export const manageWatchlist = (
  watchlistname,
  watchlistnotes,
  holdings,
  operation,
  maxallocation,
  timingtype,
  useremail,
  hashkey,
  managed,
  success,
  failure) => dispatch => {
    (async () => {
      const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
      const accessToken = localStorage.getItem("accessToken");
      let requestUrl = BASE_URL + '/signal/v1/portfolio/watchlist/save/managed?uuid=' + uuid();
      let requestBody = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "uuid": uuid(),
          "portfolio": {
            "holdings": holdings,
            'watchlistname': watchlistname,
            'watchlistnotes': watchlistnotes,
            'useremail': useremail,
            'operation': operation,
            'optimization': 'none',
            'hashkey': hashkey,
            'maxallocation': maxallocation,
            'timingtype': timingtype,
            "managed": managed
          },
          "useremail": userEmail,
        })
      }

      let fetchRequest = new Request(requestUrl, requestBody)
      requestBody.url = requestUrl
      let code =0;
      let message = "success";

      try {
        let result = await fetch(fetchRequest)
          .then(res => res.json());
        if (result.status === 403 || result["Expired JWT"]) {
          dispatch({
            type: ACTIONS.FORBIDDEN_ERROR,
            payload: result
          })
        }
        else if(versionUpdate(result.currentBuildVersions)){
          dispatch({
            type:ACTIONS.VERSION_UPDATE,
            payload:true,
          })
        }
        else {
          code = result.code;
          message = result.message;
          if (result.error) {
            failure(result.error, requestBody)
          }
          else {
            dispatch({
              type: ACTIONS.GET_WATCHLIST_DATA,
              payload: { result: result, fetchRequest: requestBody, holdings }
            });
            success(result);
            // accessTokenUpdate;
          }
        }
      }
      catch (err) {
        if(code ===0){
        failure(err, requestBody);
        }
        else{
          failure(message,requestBody)
        }
      }
    })()
  }

export const getSavedWatchlists = (email, success, failure) => dispatch => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/watchlist?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        'email': email,
        "useremail": userEmail,
        // "hashkey": hashkey
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl

    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        dispatch({
          type: ACTIONS.GET_WATCHLIST,
          payload: { result: result, fetchRequest: requestBody }
        });
        dispatch({
          type: ACTIONS.GET_USER_WATCHLISTS,
          payload: { result: result, fetchRequest: requestBody }
        });

        success(result);
        // accessTokenUpdate;
      }
    }
    catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })()
}

export const getWatchlistData = (email, name, maxallocation, timingtype, benchmark, hashkey, success, failure) => dispatch => {
  //add maxallocation and timingtype
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + '/signal/v1/portfolio/watchlist/details?uuid=' + uuid();
    let requestBody = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "uuid": uuid(),
        "portfolio": {
          'watchlistname': name,
          'useremail': email,
          'benchmark': benchmark.symbol,
          'maxallocation': maxallocation,
          'timingtype': timingtype,
          'hashkey': hashkey
        },
        "useremail": userEmail,
      })
    }

    let fetchRequest = new Request(requestUrl, requestBody)
    requestBody.url = requestUrl
    let code =0;
    let message = "success";
    try {
      let result = await fetch(fetchRequest)
        .then(res => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result
        })
      }
      else if(versionUpdate(result.currentBuildVersions)){
        dispatch({
          type:ACTIONS.VERSION_UPDATE,
          payload:true,
        })
      }
      else {
        code = result.code;
        message = result.message;
        if (result.portfolio.holdings.length === 0 && result.portfolio.daily.closingprice.length === 0) {
          //all neutral causes this error
          failure(result);
        }
        else {
          result.benchmarkdaily.performance.percentagegainsdata.map((data)=>{
            data.performancedatalist.map((item)=>{
              item.percentagegain = item.percentagegain*100;
            })
          });
       
          result.benchmarkdaily.timings.timingsdata.map((data)=>{
            data.timingsdatadetailslist.map((item)=>{
              item.timingsdata = item.timingsdata*100;
            })
          })
          result.portfolio.daily.performance.percentagegainsdata.map((data)=>{
            data.performancedatalist.map((item)=>{
              item.percentagegain = item.percentagegain*100;
            })
          });
       
          result.portfolio.daily.timings.timingsdata.map((data)=>{
            data.timingsdatadetailslist.map((item)=>{
              item.timingsdata = item.timingsdata*100;
            })
          })
          dispatch({
            type: ACTIONS.GET_WATCHLIST_DATA,
            payload: { result: result, fetchRequest: requestBody }
          });

          success(result);
          // accessTokenUpdate;
        }
      }
    }
    catch (err) {
      console.log('err', err);
      if(code === 0){
      failure(err, requestBody);
    }
    else{
      failure(message,requestBody)
  } 
  }
  })()
}

export const updateAllPerfValues = (newReturn, newDrawdown, newRecovery, newSharpe, success, failure) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_WATCHLIST_PERF_VALUES,
    payload: [newReturn, newDrawdown, newRecovery, newSharpe]
  });

  success();
}

export const updateAllTimingValues = (newReturn, newDrawdown, newRecovery, newSharpe, success, failure) => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_WATCHLIST_TIMING_VALUES,
    payload: [newReturn, newDrawdown, newRecovery, newSharpe]
  });

  success();
}