import { ACTIONS } from '../actions/ActionTypes';
import { CHART_TYPE } from '../utils/const';

const initialState = {
  asset: null,
  assetChartData: null,
  familyChartData: null,
  assetRequest: null,
  assetChartRequest: null,
  familyChartRequest: null,
  supportedSymbols: [],
  supportedSymbolsRequest: null,
  familyData: null,
  familyRequest: null,
  timingData: null,
  performanceData: null,
  timingRequest: null,
  performanceRequest: null,
  portfolioMove: null,
  zoomedChartSeries: [],
};

export function AssetReducer(state = initialState, action) {

  switch (action.type) {
    case ACTIONS.LOGIN:
      return initialState;

    case ACTIONS.CREATE_KEY:
      return initialState;

    case ACTIONS.CLEAR_ASSET_DATA:
      return {
        ...state,
        assetChartData: null,
        familyChartData: null,
        assetRequest: null,
        assetChartRequest: null,
        familyChartRequest: null,
        timingData: null,
        performanceData: null,
        timingRequest: null,
        performanceRequest: null,
        mainPerformanceData: null,
        mainTimingData: null
      }

    case ACTIONS.FETCH_ASSET:
      let listofasset = action.payload.result.analyzed.listofasset;

      listofasset.forEach((value) => {
        value.data.timings = [];
      })
      // console.log("FETCH_ASSET reducer ", action.payload.result.analyzed.assetdata.symbol, action.payload.result)

      return {
        ...state,
        asset: action.payload.result,
        assetRequest: action.payload.fetchRequest,
        timingData: null,
        performanceData: null,
        timingRequest: null,
        performanceRequest: null,
        mainPerformanceData: null,
        mainTimingData: null
      };

    case ACTIONS.FETCH_ASSET_FAMILY_DATA:
      return {
        ...state,
        familyData: action.payload.result.analyzed.assetdata,
        familyRequest: action.payload.fetchRequest
      }

    case ACTIONS.FETCH_CHART_DATA:
      if (action.chartType === CHART_TYPE.ASSET_CHART) {
        return {
          ...state,
          assetChartData: action.payload.result,
          assetChartRequest: action.payload.fetchRequest,
          timingData: action.payload.result.timings,
          performanceData: action.payload.result.performance,
        };
      } else {
        return {
          ...state,
          familyChartData: action.payload.result,
          familyChartRequest: action.payload.fetchRequest
        };
      }

    case ACTIONS.SUPPORTED_SYMBOLS:
      return {
        ...state,
        supportedSymbols: action.payload.result.symbolsvsips,
        supportedSymbolsRequest: action.payload.fetchRequest
      }

    case ACTIONS.ASSET_TIMING_DATA:
      return {
        ...state,
        timingData: action.payload.result,
        timingRequest: action.payload.fetchRequest
      }

    case ACTIONS.ASSET_PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: action.payload.result,
        performanceRequest: action.payload.fetchRequest
      }

    case ACTIONS.GET_LOGS:
      return { ...state };

    case ACTIONS.UPDATE_VALUES:
      let newPerformanceData = state.performanceData;

      if (newPerformanceData) {
        newPerformanceData.return = action.payload[0];
        newPerformanceData.drawdown = action.payload[1];
        newPerformanceData.daystorecover = action.payload[2];
        newPerformanceData.sharpratio = action.payload[3];
      }

      return {
        ...state,
        performanceData: newPerformanceData,
      }

    case ACTIONS.UPDATE_COMPARE_VALUES:
      let newTimingData = state.timingData;
      // console.log("UPDATE_COMPARE_VALUES reducer ", newTimingData)

      if (newTimingData) {
        newTimingData.return = action.payload[0];
        newTimingData.drawdown = action.payload[1];
        newTimingData.daystorecover = action.payload[2];
        newTimingData.sharpratio = action.payload[3];
      }

      return {
        ...state,
        timingData: newTimingData,
      }

    case ACTIONS.UPDATE_BENCHMARK_VALUES:
      let newBenchmarkData = state.familyChartData;

      if (newBenchmarkData.performance) {
        newBenchmarkData.performance.return = action.payload[0];
        newBenchmarkData.performance.drawdown = action.payload[1];
        newBenchmarkData.performance.daystorecover = action.payload[2];
        newBenchmarkData.performance.sharpratio = action.payload[3];
      }

      return {
        ...state,
        familyChartData: newBenchmarkData,
      }

    case ACTIONS.UPDATE_CHART_ZOOM_VALUES:
      //want to keep graph values here
      return{
        ...state,
        zoomedChartSeries: action.payload
      }

    default:
      return state;
  }
}
