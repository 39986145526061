// export const BASE_URL = 'http://ec2-44-228-173-248.us-west-2.compute.amazonaws.com:8080';

//test server
export const BASE_URL = 'http://dev.kosha.vistalytics.com:3456'
export const OPERATOR_BASE_URL = 'http://dev.kosha.vistalytics.com:1234'
export const currBranch = 'Dev'

//dev
// export const BASE_URL = 'https://webapi.dev.kosha.vistalytics.com'
// export const OPERATOR_BASE_URL = 'https://operator.dev.kosha.vistalytics.com'
// http://localhost:3456 kosha 1234->operator
// 6543 autotrader
// export const currBranch = 'Dev'

//beta
// export const BASE_URL = 'http://Vistalytics-beta-lb-1514218512.us-west-2.elb.amazonaws.com';
// export const OPERATOR_BASE_URL = 'https://operator.beta.kosha.vistalytics.com'
// export const currBranch = 'Beta'

//ui/prod
// export const BASE_URL = 'https://kosha-api.vistalytics.com'
// export const OPERATOR_BASE_URL = 'https://operator.beta.kosha.vistalytics.com'
// export const currBranch = 'Prod'

export const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const CHART_TYPE = Object.freeze({
  ASSET_CHART: "ASSET_CHART",
  FAMILY_CHART: "FAMILY_CHART"
});

export const CHART_TITLE = Object.freeze({
  DAILY_VFLOW: "DAILY VFLOW",
  VVOL: "VVOL",
  CLOSING_PRICE: "Closing Price",
  INDICATOR_3: "Indicator 3",
  INDICATOR_5: "Indicator 5",
  INDICATOR_6: "Indicator 6"
});

export const POSITION_VALUES = ['LONG', 'SHORT', 'CASH'];

export const FEEDBACK_SCRIPT_URL = 'https://vistalytics.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-egccmf/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=d269adfc';
export const BUG_SCRIPT_URL = 'https://vistalytics.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/vd1cif/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=2c783612';

export const TIMING_VALUES = Object.freeze({
  LONG: 'LONG',
  NEUTRAL: 'NEUTRAL',
  SHORT: 'SHORT',
  CASH: 'CASH'
})
