import { BASE_URL } from "../utils/const";
import { ACTIONS } from "./ActionTypes";
import uuid from "react-uuid";
import download from "downloadjs";
import accessTokenUpdate from "./accessTokenUpdate";
import versionUpdate from "./VersionUpdate";
export const getWatchlistTimings = (type, timing, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/watchliststiming?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        category: "system",
        uuid: uuid(),
        type: type,
        timing: timing === "true" || timing === "True" ? "True" : "False",
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = "";
      let filename = type + ".csv";
      await fetch(fetchRequest)
        .then(function (response) {
          if (response.status === 403 || response["Expired JWT"]) {
            dispatch({
              type: ACTIONS.FORBIDDEN_ERROR,
              payload: response,
            });
            return;
          } else if (versionUpdate(result.currentBuildVersions)) {
            dispatch({
              type: ACTIONS.VERSION_UPDATE,
              payload: true,
            });
          } else {
            return response.blob();
          }
        })
        .then(function (resp) {
          if (resp) download(resp, filename, "text/plain");
        });

      dispatch({
        type: ACTIONS.GET_WATCHLIST_TIMINGS,
        payload: { result, fetchRequest: requestBody },
      });

      success();
      // accessTokenUpdate;
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const getKoshaWatchlists = (email, success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl =
      BASE_URL + "/signal/v1/portfolio/watchlist/etfs?uuid=" + uuid();
    let requestBody = {
      method: "POST", 
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        email: email,
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        dispatch({
          type: ACTIONS.GET_KOSHA_WATCHLIST,
          payload: { result: result, fetchRequest: requestBody },
        });
        dispatch({
          type: ACTIONS.GET_KOSHA_WATCHLISTS,
          payload: { result: result, fetchRequest: requestBody },
        });
        let newResult = JSON.parse(JSON.stringify(result));
        success(newResult);
        // ;
      }
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const getTransactionSimulation = (
  maxAllocation,
  timingType,
  holdings,
  benchmark,
  watchlist,
  success,
  failure
) => (dispatch) => {
  (async () => {
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl =
      BASE_URL + "/signal/v1/transactionsimulation?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        timingtype: timingType,
        maxallocation: maxAllocation,
        holdings,
        benchmarksymbol: benchmark,
        hashkey: watchlist?.hashkey,
        useremail: watchlist?.useremail,
        watchlistname: watchlist?.watchlistname,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = "";
      let filename = "TransactionSimulation.csv";
      await fetch(fetchRequest)
        .then(function (response) {
          if (response.status === 403 || response["Expired JWT"]) {
            dispatch({
              type: ACTIONS.FORBIDDEN_ERROR,
              payload: response,
            });
            return;
          } else if (versionUpdate(result.currentBuildVersions)) {
            dispatch({
              type: ACTIONS.VERSION_UPDATE,
              payload: true,
            });
          } else {
            return response.blob();
          }
        })
        .then(function (resp) {
          if (resp) download(resp, filename, "text/plain");
        });

      success(result);
      // accessTokenUpdate;
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const getTransactionSimulationSummary = (
  maxAllocation,
  timingType,
  holdings,
  benchmark,
  watchlist,
  success,
  failure
) => (dispatch) => {
  (async () => {
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl =
      BASE_URL + "/signal/v1/transactionsimulationsummary?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        timingtype: timingType,
        maxallocation: maxAllocation,
        holdings,
        benchmarksymbol: benchmark,
        hashkey: watchlist?.hashkey,
        useremail: watchlist?.useremail,
        watchlistname: watchlist?.watchlistname,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = "";
      let filename = "TransactionSimulationSummary.csv";
      await fetch(fetchRequest)
        .then(function (response) {
          if (response.status === 403 || response["Expired JWT"]) {
            dispatch({
              type: ACTIONS.FORBIDDEN_ERROR,
              payload: response,
            });
            return;
          } else if (versionUpdate(result.currentBuildVersions)) {
            dispatch({
              type: ACTIONS.VERSION_UPDATE,
              payload: true,
            });
          } else {
            return response.blob();
          }
        })
        .then(function (resp) {
          if (resp) download(resp, filename, "text/plain");
        });

      success(result);
      // accessTokenUpdate;
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};
