import React, { Component } from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import * as Moment from 'moment'
import * as MomentTimeZone from "moment-timezone";

import PropTypes from 'prop-types';
import { currBranch, OPERATOR_BASE_URL } from '../../utils/const';
import Typography from '@mui/material/Typography'
import axios from 'axios'
import { connect } from "react-redux";
import {
  getSupportedSymbols,
} from "../../actions/AssetAction";
import Table from './Table/Table'
import CopyTimingsModal from './operatorModals/CopyTimingsModal';
import SetTimingsToNeutralModal from './operatorModals/SetTimingsToNeutralModal'
import RegenerateWatchlistsModal from './operatorModals/RegenerateWatchlistsModal'
import ConfirmSelectionModal from './operatorModals/ConfirmSelectionModal'
import UserCommunication from './operatorModals/UserCommunicationModal';
import SendWatchlistMessageModal from './operatorModals/SendWatchlistMessageModal'

import LinearProgress from '@mui/material/LinearProgress';
import SyncIcon from '@mui/icons-material/Sync';
import styles from './Operator.module.css'
import UserCommunicationModal from './operatorModals/UserCommunicationModal';
import ForbiddenErrorModal from '../../layout/ForbiddenErrorModal'

class Operator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showCopyTimingsModal: false,
			showSetTimingsToNeutralModal: false,
			showConfirmSelectionModal: false,
			showRegenerateWatchlistsModal: false,
			fromBranch: currBranch,
			symbolList: [],
			tables: [],
			confirmAllOrSelected:'all',
			confirmSelectionModalMessage: '',
			confirmType: 'red',
			confirmClickActionMessage: '',
			confirmClickSuccessMessage: '',
			confirmClickErrorMessage: '',
			confirmClickRedErrorMessage: '',
			confirmClickOrangeErrorMessage: '',
			supportedSymbolsLoading: true,
			supportedSymbolsError: '',
			message: '',
			buttonsLoading: false,
			redButtonLoading: false,
			orangeButtonLoading: false,
			operatorLogs: [],
			operatorLogsLoading: false,
			operatorLogsError: '',
			operatorHeaders: ['Time (EST)', 'Action', 'User', 'Other'],
			showUserCommunicationModal: false,
			showSendWatchlistMessageModal: false,
			messageType: 'email',
			messageSubject: '',
			messageBody: '',
			tabValue: 0,
			operatorCheckersLoading: false,
			operatorCheckers: [],
			operatorCheckerHeaders:['Start Time (EST)', 'End Time (EST)', 'Script Name', 'Status', 'Message', 'Error Reason'],
			operatorCheckersError: '',
			operatorExecutionsLoading: false,
			operatorExecutions: [],
			operatorExecutionsHeaders:['Started (EST)', 'Finished (EST)', 'Email', 'Watchlist Name', 'Run Type', 'Status', 'Message'],
			operatorExecutionsError: ''
		};
	}

	style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 500,
		// bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		color: 'rgb(150, 162, 180)',
		backgroundColor: 'rgb(37, 39, 52)',
	};

	componentDidMount(){
		// call supported symbol list
		// console.log(this.props.supportedSymbols)
		if(this.props.supportedSymbols.length === 0){
			this.props.getSupportedSymbols(
				()=>{this.setState({supportedSymbolsLoading: false})}, 
				()=> {
					console.log("Error in supported symmbols")
					this.setState({
						supportedSymbolsLoading: false,
						supportedSymbolsError: 'An Error Occurred while fetching the supported symbols - Due to this, The Orange Button is unavailable. Please refresh your page if you wish to use the Orange Button. If this problem persists, please log in again.'
					})
				})
		}else{
			this.setState({supportedSymbolsLoading: false})
		}
		this.getOperatorLogs()
		this.getOperatorCheckers()
		this.getOperatorExecutions()
	}

	getOperatorLogs(){
		this.setState({operatorLogsLoading: true, operatorLogsError: ''})
		let operatorLogs = ''
		const params = {useremail: this.props.loginDetails?.email}
		let accessToken = localStorage.getItem("accessToken")
		let idToken = localStorage.getItem("idToken")
		axios.post(
			'https://operator.dev.kosha.vistalytics.com/operator/v1/eject/activity', 
			params,
			{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
			operatorLogs = res.data
			this.setState({operatorLogsLoading: false})
			const formattedOperatorLogs = this.formatOperatorLogs(operatorLogs)
			this.setState({operatorLogs: formattedOperatorLogs})
		}).catch((err) => {
			console.log(err)
			operatorLogs = ''
			this.setState({operatorLogsLoading: false, operatorLogsError: `Fetching operator logs failed. ${err.message}`})
		})
	}

	formatOperatorLogs(logs){
		if(logs === '') return []

		let formattedLogs = []
		logs.forEach(action => {
			// let date = new Date(action.time)
			let date = Moment(action.time.toString()).tz('America/New_York').format("MMMM Do YYYY, h:mm:ssa")
			let otherString = ``
			formattedLogs.push(
				[
					{value: date.toString(), label: "time", type: 'string'},
					{value: action.description, label: "action", type: 'string'},
					{value: action.operator, label: "operator", type: 'string'},
					{value: otherString, label: "other", type: 'string'}
				]
			)
		})
		formattedLogs.sort((a,b) => {
			if(a[0].value > b[0].value) return -1
			if(a[0].value < b[0].value) return 1
			return 0
		})
		return formattedLogs
	}

	getOperatorCheckers = () => {
		this.setState({operatorCheckersLoading: true, operatorCheckersError: ''})
		let operatorCheckers = ''
		const params = {useremail: this.props.loginDetails?.email}
		let accessToken = localStorage.getItem("accessToken")
		let idToken = localStorage.getItem("idToken")
		axios.post(
			`${OPERATOR_BASE_URL}/operator/v1/eject/checker`, 
			params,
			{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
			operatorCheckers = res.data
			this.setState({operatorCheckersLoading: false})
			const formattedOperatorCheckers = this.formatOperatorCheckers(JSON.parse(operatorCheckers))
			this.setState({operatorCheckers: formattedOperatorCheckers})
		}).catch((err) => {
			console.log(err)
			operatorCheckers = ''
			this.setState({operatorCheckersLoading: false, operatorCheckersError: `Fetching operator checkers failed. ${err.message}`})
		})
	}

	formatOperatorCheckers(checkers){
		if(checkers === '') return []

		let formattedCheckers = []
		checkers.forEach(check => {
			let startDate = Moment(check.start_timestamp.toString()).tz('America/New_York').format("MMMM Do YYYY, h:mm:ssa")
			let endDate = Moment(check.end_timestamp.toString()).tz('America/New_York').format("MMMM Do YYYY, h:mm:ssa")

			formattedCheckers.push(
				[
					{value: startDate, label: "start_time", type: 'string'},
					{value: endDate, label: "end_time", type: 'string'},
					{value: check.script_name, label: "script_name", type: 'string'},
					{value: check.status, label: "status", type: 'string', color: (check.status === "Go") ? '#2ea179': 'red'},
					{value: check.message || '', label: "message", type: 'string'},
					{value: check.error_reason || '', label: "error_reason", type: 'string'}
				]
			)
		})
		formattedCheckers.sort((a,b) => {
			if(a[0].value > b[0].value) return -1
			if(a[0].value < b[0].value) return 1
			return 0
		})
		return formattedCheckers
	}

	getOperatorExecutions = () => {
		this.setState({operatorExecutionsLoading: true, operatorExecutionsError: ''})
		let operatorExecutions = ''
		const params = {useremail: this.props.loginDetails?.email}
		let accessToken = localStorage.getItem("accessToken")
		let idToken = localStorage.getItem("idToken")
		axios.post(
			`${OPERATOR_BASE_URL}/operator/v1/eject/autotrader/executions`, 
			params,
			{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
			operatorExecutions = res.data
			this.setState({operatorExecutionsLoading: false})
			const formattedOperatorExecutions = this.formatOperatorExecutions(operatorExecutions)
			this.setState({operatorExecutions: formattedOperatorExecutions})
		}).catch((err) => {
			console.log(err)
			operatorExecutions = ''
			this.setState({operatorExecutionsLoading: false, operatorExecutionsError: `Fetching operator Executions failed. ${err.message}`})
		})
	}

	formatOperatorExecutions(executions){
		if(executions === '') return []
		// console.log(executions)

		let formattedExecutions = []
		executions.forEach(execution => {
			// console.log(execution)
			let startDate = Moment(execution.request_time.toString()).tz('America/New_York').format("MMMM Do YYYY, h:mm:ssa")
			let endDate = Moment(execution.response_time.toString()).tz('America/New_York').format("MMMM Do YYYY, h:mm:ssa")

			//'Started (EST)', 'Finished (EST)', 'User Email', 'Watchlist Name', 'Run Type', 'Status', 'Message']
			formattedExecutions.push(
				[
					{value: startDate, label: "request_time", type: 'string'},
					{value: endDate, label: "response_time", type: 'string'},
					{value: execution.user_email, label: "user_email", type: 'string'},
					{value: execution.watchlist_name, label: "watchlist_name", type: 'string'},
					{value: execution.run_type, label: "run_type", type: 'string'},
					{value: execution.status, label: "status", type: 'string', color: (execution.status === "completed") ? '#2ea179': 'red'},
					{value: execution.message, label: "message", type: 'string'},
				]
			)
		})
		formattedExecutions.sort((a,b) => {
			if(a[0].value > b[0].value) return -1
			if(a[0].value < b[0].value) return 1
			return 0
		})
		return formattedExecutions
	}


	setShowCopyTimingsModal = () => {
		this.setState({showCopyTimingsModal: false})
	}
	setShowSetTimingsToNeutralModal = () => {
		this.setState({showSetTimingsToNeutralModal: false})
	}
	setSetTimingsToNeutralBranch = (fromBranch) => {
		this.setState({fromBranch: fromBranch})
	}
	setConfirmSelectionModalMessage = (message) => {
		this.setState({confirmSelectionModalMessage: message})
	}
	setConfirmType = (type) => {
		this.setState({confirmType: type})
	}
	setConfirmAllOrSelected = (type) => {
		this.setState({confirmAllOrSelected: type})
	}
	setTables = (tables) => {
		this.setState({tables: tables})
	}
	setMessage = (message) => {
		this.setState({message: message})
	}
	setMessageType = (type) => {
		this.setState({messageType: type})
	}
	setMessageSubject = (subject) => {
		this.setState({messageSubject: subject})
	}
	setMessageBody = (body) => {
		this.setState({messageBody: body})
	}
	handleChangeTabValue = (event, newValue) => {
		this.setState({tabValue: newValue})
	}

	onConfirmClick = () => {
		this.setState({confirmClickSuccessMessage: '', confirmClickRedErrorMessage: ''})
		this.setState({confirmClickSuccessMessage: '', confirmClickOrangeErrorMessage: ''})
		this.setState({confirmClickErrorMessage: ''})
		this.setState({redButtonLoading: false, orangeButtonLoading: false, buttonsLoading: false})

		console.log(this.state.symbolList)
		let accessToken = localStorage.getItem("accessToken")

		let idToken = localStorage.getItem("idToken")
		this.setState({buttonsLoading: true})
		if(this.state.confirmType === 'red'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Red Button Protocol is in Progress'})
			const params = {
				useremail: this.props.loginDetails.email,
				allorselected: this.state.confirmAllOrSelected.toUpperCase(),
				message: this.state.message,
				symbols: [],
				todb: currBranch.toLowerCase()
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/red`, 
				params, 
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
					console.log(res.data.message)
					this.setState({
						confirmClickSuccessMessage: `The red button protocol has been executed with status code ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`,
						buttonsLoading: false
					})
					this.getOperatorLogs()
			}).catch(err => {
				this.setState({buttonsLoading: false, redButtonLoading: false})
				this.setState({confirmClickErrorMessage: `The red button protocol has failed. ${err.message}`})
			})
		}else if(this.state.confirmType === 'orange'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Red Button Protocol is in Progress'})
			const params = {
				useremail: this.props.loginDetails.email,
				allorselected: this.state.confirmAllOrSelected.toUpperCase(),
				message: '',
				symbols: this.state.symbolList,
				fromdb: this.state.fromBranch.toLowerCase(),
				todb: currBranch.toLowerCase(),
				tables: this.state.tables
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/orange`, 
				params,
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
				this.setState({confirmClickSuccessMessage: `The orange button protocol has been executed with status ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`})
				this.setState({buttonsLoading: false, orangeButtonLoading: false})
				this.getOperatorLogs()
			}).catch(err => {
				console.log(err)
				this.setState({buttonsLoading: false, orangeButtonLoading: false})
				this.setState({confirmClickErrorMessage: `The orange button protocol has failed. ${err.message}`})
			})
		}else if(this.state.confirmType === 'restart'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Restart Button Protocol is in Progress'})
			const params = {
				useremail: this.props.loginDetails.email,
				server: currBranch.toLowerCase(),
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/restart`, 
				params,
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
				this.setState({confirmClickSuccessMessage: `The restart button protocol has been executed with status ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`})
				this.setState({buttonsLoading: false})
				this.getOperatorLogs()
			}).catch(err => {
				console.log(err)
				this.setState({buttonsLoading: false})
				this.setState({confirmClickErrorMessage: `The restart button protocol has failed. ${err.message}`})
			})
		}else if(this.state.confirmType === 'watchlist'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Watchlist Button Protocol is in Progress'})
			const params = {
				useremail: this.props.loginDetails.email,
				server: currBranch.toLowerCase(),
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/watchlistgen`, 
				params,
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
				this.setState({confirmClickSuccessMessage: `The Watchlist Regeneration button protocol has been executed with status ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`})
				this.setState({buttonsLoading: false})
				this.getOperatorLogs()
			}).catch(err => {
				console.log(err)
				this.setState({buttonsLoading: false})
				this.setState({confirmClickErrorMessage: `The Watchlist Regeneration button protocol has failed. ${err.message}`})
			})
		}else if(this.state.confirmType === 'watchlistMessage'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Watchlist Message Button Protocol is in Progress'})
			const params = {
				useremail: this.props.loginDetails.email,
				server: currBranch.toLowerCase(),
				message: this.state.message
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/watchlistemail`, 
				params,
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
				this.setState({confirmClickSuccessMessage: `The Watchlist Email button protocol has been executed with status ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`})
				this.setState({buttonsLoading: false})
				this.getOperatorLogs()
			}).catch(err => {
				console.log(err)
				this.setState({buttonsLoading: false})
				this.setState({confirmClickErrorMessage: `The Watchlist Email button protocol has failed. ${err.message}`})
			})
		}else if(this.state.confirmType === 'userCommunication'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Watchlist Email Button Protocol is in Progress'})
			console.log(`messageSubject: ${this.state.messageSubject}, messageBody: ${this.state.messageBody}`)
			const params = {
				useremail: this.props.loginDetails.email,
				server: currBranch.toLowerCase(),
				comtype: this.state.messageType,
				allorselected: 'ALL',
				users: [],
				subject: this.state.messageSubject,
				body: this.state.messageBody,
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/com`, 
				params,
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
				this.setState({confirmClickSuccessMessage: `The SMS/Email button protocol has been executed with status ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`})
				this.setState({buttonsLoading: false})
				this.getOperatorLogs()
			}).catch(err => {
				console.log(err)
				this.setState({buttonsLoading: false})
				this.setState({confirmClickErrorMessage: `The SMS/Email button protocol has failed. ${err.message}`})
			})
		}else if(this.state.confirmType === 'testFlow'){
			this.setState({buttonsLoading: true, confirmClickActionMessage: 'The Test Flow Button Protocol is in Progress'})
			const params = {
				useremail: this.props.loginDetails.email,
				server: currBranch.toLowerCase(),
			}
			axios.post(`${OPERATOR_BASE_URL}/operator/v1/eject/watchlist/test`, 
				params,
				{headers: {'Authorization': `Bearer ${accessToken}`, 'idtoken': idToken}}).then((res) => {
				this.setState({confirmClickSuccessMessage: `The Test Flow Button protocol has been executed with status ${res.status}. Status: ${res.data.status}, Message: ${res.data.message}`})
				this.setState({buttonsLoading: false})
				this.getOperatorLogs()
			}).catch(err => {
				console.log(err)
				this.setState({buttonsLoading: false})
				this.setState({confirmClickErrorMessage: `The Test Flow Button protocol has failed. ${err.message}`})
			})
		}else{
			this.setState({confirmClickErrorMessage: 'Error: No Protocol Specified, Please Retry Your Request', 
				buttonsLoading: false,
				redButtonLoading: false,
				orangeButtonLoading: false
			})
		}
	}

  render() {
    return (
		<>
			<Header history={this.props.history} />
			<div id="page-wrapper" className="m-l-0">
				<h2 style={{margin: 'auto', padding: '2rem', width: 'fit-content'}}>Operator Dashboard</h2>
				<Tabs value={this.state.tabValue} onChange={this.handleChangeTabValue} centered>
					<Tab label="Controls" style={{color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.09)', padding: '0 4rem 0 4rem'}} />
					<Tab label="Checkers" style={{color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.09)', padding: '0 4rem 0 4rem'}} />
					<Tab label="Executions" style={{color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.09)', padding: '0 4rem 0 4rem'}} />
				</Tabs>
				<TabPanel value={this.state.tabValue} index={0}>
					<div className="container">
						<div>
							<div>
								{/* logs table */}
								<div style={{display: 'flex', justifyContent: 'center'}}>
									<Typography sx={{fontSize:'1em'}}>Daily Operator Logs: <SyncIcon className={this.state.operatorLogsLoading ? styles.spinning : ''} style={{color:'#3bc796', cursor: 'pointer'}} onClick={() => this.getOperatorLogs()}/></Typography>
								</div>
								{this.state.operatorLogsLoading && <LinearProgress sx={{width: '60%', margin: '1rem auto 0 auto'}}/>}
								{this.state.operatorLogs.length !== 0 && <Table header={this.state.operatorHeaders} body={this.state.operatorLogs} width={'60%'}/>}
								{this.state.operatorLogs.length === 0 && this.state.operatorLogsError === '' &&<Typography sx={{textAlign: 'center', fontSize: '1em', margin:'1rem 0 2rem 0'}}>No Logs for Today.</Typography>}
								{this.state.operatorLogsError && <Typography sx={{textAlign: 'center', fontSize: '1em', margin:'1rem 0 2rem 0', color: 'red'}}>{this.state.operatorLogsError}</Typography>}
							</div>
							<div style={{width: '60%', margin: 'auto', display:'flex', justifyContent: 'center', textAlign:'center'}}>
								{/* current actions */}
								<Typography sx={{fontSize: '1.2em', marginRight:'1rem'}}> Current Action:  
								{this.state.confirmClickActionMessage && this.state.buttonsLoading && ` ${this.state.confirmClickActionMessage}`}
								{!this.state.buttonsLoading && !this.state.confirmClickSuccessMessage && !this.state.confirmClickErrorMessage && " No Action Taken."}
								
								{/* Possible errors */}
								{this.state.confirmClickSuccessMessage && <span style={{color:'#3bc796'}}> {this.state.confirmClickSuccessMessage}</span>}
								{this.state.confirmClickErrorMessage && <span style={{color: 'red'}}> {this.state.confirmClickErrorMessage}</span>}

								</Typography>
							</div>
							{/* Supported Symbols Errors */}
							<div style={{width: '60%', textAlign: 'center', margin: '10px auto 0 auto'}}>
								<div>{this.state.supportedSymbolsLoading && <Typography sx={{fontSize: '1em'}}> Supported Symbols are currently loading - The Orange Button is Temporarily Unavailable.</Typography>}</div>
								<div>{this.state.supportedSymbolsError && <Typography sx={{fontSize: '1em', color: 'red'}}> {this.state.supportedSymbolsError}</Typography>}</div>
							</div>
								
							{/* Loading bar for actions */}
							{this.state.buttonsLoading && <div style={{height:'10px', width: '60%', margin:'1rem auto 0 auto'}}><LinearProgress /></div>}
							<div>
								{/* special errors */}
								{/* {this.state.supportedSymbolsLoading && <Typography>Supported Symbols are currently loading - The Orange Button is Temporarily Unavailable.</Typography>}	 */}
							</div>
							<div style={{display: 'flex', justifyContent: 'center', marginTop: '4rem'}}>
								{/* Buttons that request changes server */}
								<button className="btn waves-effect waves-light m-b-5 " style={{backgroundColor: "#ff9000", color: "white", marginRight:'2rem'}} disabled={this.state.supportedSymbolsLoading || this.state.supportedSymbolsError !== '' || this.state.buttonsLoading} onClick={() => this.setState({showCopyTimingsModal: true})}>Copy Timings from another Server</button>
								<button className="btn waves-effect waves-light m-b-5 " style={{backgroundColor: "red", color: "white"}} disabled={this.state.buttonsLoading} onClick={() => this.setState({showSetTimingsToNeutralModal: true})}>Set all timings to neutral</button>
							</div>
							<div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
								{/* Buttons related to test/email/server function */}
								<button className="btn waves-effect waves-light m-b-5 " style={{marginRight:'2rem'}} disabled={this.state.buttonsLoading} onClick={() => this.setState({confirmType: 'testFlow', confirmSelectionModalMessage: `Test the Watchlist regenerationa and email flow on ${currBranch}`, showConfirmSelectionModal : true})}>Test Flow</button>
								<button className="btn waves-effect waves-light m-b-5 " style={{marginRight:'2rem'}} disabled={this.state.buttonsLoading} onClick={() => this.setState({confirmType: 'watchlist', confirmSelectionModalMessage: `Regenerate watchlists on ${currBranch}`, showRegenerateWatchlistsModal : true})}>Regenerate Watchlists</button>
								<button className="btn waves-effect waves-light m-b-5 " style={{marginRight:'2rem'}} disabled={this.state.buttonsLoading} onClick={() => this.setState({confirmType: 'restart', confirmSelectionModalMessage: `Restart Server (${currBranch})`, showConfirmSelectionModal: true})}>Restart Server</button>
							</div>
							<div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
								<button className="btn waves-effect waves-light m-b-5 " style={{marginRight:'2rem'}} disabled={this.state.buttonsLoading} onClick={() => this.setState({confirmType: 'watchlistMessage', showSendWatchlistMessageModal : true})}>Send Watchlist Email</button>
								<button className="btn waves-effect waves-light m-b-5 "  disabled={this.state.buttonsLoading} onClick={() => this.setState({confirmType: 'userCommunication', showUserCommunicationModal: true})}>Send SMS/Email</button>
							</div>
						</div>
						
						
						{this.state.showCopyTimingsModal && 
							<CopyTimingsModal 
								open={this.state.showCopyTimingsModal} 
								onClose={this.setShowCopyTimingsModal}
								setConfirmAllOrSelected = {this.setConfirmAllOrSelected}
								setConfirmationMessage={this.setConfirmSelectionModalMessage}
								showConfirmSelectionModal={() => this.setState({showConfirmSelectionModal: true})}
								setConfirmType={this.setConfirmType}
								setTables = {this.setTables}
								setfromBranch = {this.setSetTimingsToNeutralBranch}
								supportedSymbols = {this.props.supportedSymbols}
								setSupportedSymbols = {(supportedSymbols) => this.setState({symbolList: supportedSymbols})}
								style={this.style}/>}
						{this.state.showSetTimingsToNeutralModal && 
							<SetTimingsToNeutralModal 
								open={this.state.showSetTimingsToNeutralModal}
								onClose={() => this.setState({showSetTimingsToNeutralModal: false})}
								setConfirmAllOrSelected={this.setConfirmAllOrSelected}
								setConfirmationMessage={this.setConfirmSelectionModalMessage}
								showConfirmSelectionModal={() => this.setState({showConfirmSelectionModal: true})}
								setConfirmType={this.setConfirmType}
								setMessage ={this.setMessage}
								style={this.style}/>}
						{this.state.showRegenerateWatchlistsModal && 
							<RegenerateWatchlistsModal 
								open={this.state.showRegenerateWatchlistsModal}
								onClose={() => this.setState({showRegenerateWatchlistsModal: false})}
								setConfirmAllOrSelected={this.setConfirmAllOrSelected}
								setConfirmationMessage={this.setConfirmSelectionModalMessage}
								showConfirmSelectionModal={() => this.setState({showConfirmSelectionModal: true})}
								setConfirmType={this.setConfirmType}
								setMessage ={this.setMessage}
								style={this.style}	
							/>
						}
						{this.state.showUserCommunicationModal &&
							<UserCommunicationModal
								open={this.state.showUserCommunicationModal}
								onClose={() => this.setState({showUserCommunicationModal: false})}
								setMessageType={this.setMessageType}
								setMessageSubject={this.setMessageSubject}
								setMessageBody={this.setMessageBody}
								showConfirmSelectionModal={() => this.setState({showConfirmSelectionModal: true})}
								setConfirmationMessage={this.setConfirmSelectionModalMessage}
								style={this.style}
								/>}
						{this.state.showSendWatchlistMessageModal && 
							<SendWatchlistMessageModal 
								open={this.state.showSendWatchlistMessageModal} 
								onClose={() => this.setState({showSendWatchlistMessageModal: false})}
								setConfirmationMessage={this.setConfirmSelectionModalMessage}
								showConfirmSelectionModal={() => this.setState({showConfirmSelectionModal: true})}
								setMessage ={this.setMessage}
								// setConfirmType={this.setConfirmType}
								style={this.style} />}
						{this.state.showConfirmSelectionModal && 
							<Box>
								<ConfirmSelectionModal 
									open={this.state.showConfirmSelectionModal} 
									onConfirmClick = {this.onConfirmClick}
									onClose={() => this.setState({showConfirmSelectionModal: false})}
									message={this.state.confirmSelectionModalMessage}
									style={this.style}/>
							</Box>}
					</div>
				</TabPanel>
				<TabPanel value={this.state.tabValue} index={1}>
					<div className="container">
						<div style={{display: 'flex', flexDirection: 'column', justifyContent:  'center'}}>
							<div>
								<Typography sx={{fontSize:'1em', margin: 'auto', textAlign: 'center'}}>Daily Checkers Table: <SyncIcon className={this.state.operatorCheckersLoading ? styles.spinning : ''} style={{color:'#3bc796', cursor: 'pointer'}} onClick={() => this.getOperatorCheckers()}/></Typography>
								{this.state.operatorCheckersLoading && <LinearProgress sx={{width: '60%', margin: '1rem auto 0 auto'}}/>}
								{this.state.operatorCheckers.length !== 0 && <Table header={this.state.operatorCheckerHeaders} body={this.state.operatorCheckers} />}
								{this.state.operatorCheckers.length === 0 && this.state.operatorCheckersError === '' &&<Typography sx={{textAlign: 'center', fontSize: '1em', margin:'1rem 0 2rem 0'}}>No Checkers for Today.</Typography>}
								{this.state.operatorCheckersError && <Typography sx={{textAlign: 'center', fontSize: '1em', margin:'1rem 0 2rem 0', color: 'red'}}>{this.state.operatorCheckersError}</Typography>}
							</div>
						</div>
					</div>
				</TabPanel>
				<TabPanel value={this.state.tabValue} index={2}>
					<div className="container">
						<div style={{display: 'flex', flexDirection: 'column', justifyContent:  'center'}}>
							<div>
								<Typography sx={{fontSize:'1em', margin: 'auto', textAlign: 'center'}}>Daily Executions Table: <SyncIcon className={this.state.operatorExecutionsLoading ? styles.spinning : ''} style={{color:'#3bc796', cursor: 'pointer'}} onClick={() => this.getOperatorExecutions()}/></Typography>
								{this.state.operatorExecutionsLoading && <LinearProgress sx={{width: '60%', margin: '1rem auto 0 auto'}}/>}
								{this.state.operatorExecutions.length !== 0 && <Table header={this.state.operatorExecutionsHeaders} body={this.state.operatorExecutions} />}
								{this.state.operatorExecutions.length === 0 && this.state.operatorExecutionsError === '' &&<Typography sx={{textAlign: 'center', fontSize: '1em', margin:'1rem 0 2rem 0'}}>No Executions for Today.</Typography>}
								{this.state.operatorExecutionsError && <Typography sx={{textAlign: 'center', fontSize: '1em', margin:'1rem 0 2rem 0', color: 'red'}}>{this.state.operatorExecutionsError}</Typography>}
							</div>
						</div>
					</div>
				</TabPanel>
				<Footer location="home" onClick={this.getApiInfo} />
			</div>
			<ForbiddenErrorModal
          		history={this.props.history}/>
		</>
    )
  }
}

const mapStateToProps = (state) => ({
	about: state.AboutReducer.about,
  	loginDetails: state.AboutReducer.loginDetails,
	supportedSymbols: state.AssetReducer.supportedSymbols
});

export default connect(mapStateToProps, {
  	getSupportedSymbols
})(Operator)

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box sx={{ p: 3 }}>
			{children}
		  </Box>
		)}
	  </div>
	);
  }
  
  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }