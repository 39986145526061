import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { semverGreaterThan } from './CacheBuster';
import { useHistory } from "react-router-dom";

import './assets/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/colors/default.css';

import './App.css';
import Home from './components/home/Home';
import Asset from './components/asset/Asset';
import Portfolio from './components/portfolio/Portfolio';
import Logs from './components/logs/Logs';
import ChartView from './components/charts/ChartFullView';
import Login from './components/login/Login';
import ResetPassword from './components/resetPassword/ResetPassword';
import SystemWatchlist from './components/systemWatchlist/SystemWatchlist';
import Alberto from './components/alberto/Alberto';
import firebaseObj from './utils/firebase';
import Market from './components/market/Market';
import NotFound from './components/NotFound';
import {sendFirebaseToken,getTooltipsJson} from './actions/AboutAction';
import {connect} from 'react-redux'
import Header from './layout/Header'
import Banner from './layout/Banner'
import Operator from './components/operator/Operator'
// import firebase from './firebase';

class App extends Component {
  componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    // this.getVersionStatus();
    firebaseObj.analytics();
    this.props.getTooltipsJson();
    // console.log("Fetching FCM TOken");
    // const messaging = firebaseObj.messaging();
    // messaging.getToken().then(token =>{
    //   console.log("Token", token);
    //   // this.props.sendFirebaseToken(token);
    //   localStorage.setItem("deviceToken", token);
    // }).catch(err=>{
    //   console.log('error :-',err)
    // })
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus)
  }

  onFocus = async () => {
    // this.getVersionStatus();
  }

  // TODO: Delete this cachbuster in favor of already existing versionUpdate
  getVersionStatus() {
    // fetch('/meta.json')
    //   .then((response) => response.json())
    //   .then((meta) => {
    //     const latestVersion = meta.version;
    //     const currentVersion = global.appVersion;

    //     const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
    //     if (shouldForceRefresh) {
    //       // window.location.reload(true);
    //     }
    //   });
  }

  render() {
    const history = createBrowserHistory();
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }

    return (
      <>
        <Router>
          {/* <Header history={this.props.history}/> */}
          {/* message === "Started" */}
          {/* {(this.props.ETLStatus === 511) && 
            <div>
              <Banner variant={"refresh"}/>
              <div style={{height: '55px'}}/>
            </div>}
          {(this.props.serverRestart) && 
            <div>
              <Banner variant={"reload"}/>
              <div style={{height: '55px'}}/>
            </div>} */}
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact key="home" path="/market" component={Market} />
            <Route exact key="asset" path="/asset" component={Asset} />
            <Route exact key="search" path="/asset/:subasset" component={Asset} />
            <Route exact key="subasset" path="/asset/:parentasset/:subasset" component={Asset} />
            <Route exact path="/watchlist" component={Portfolio} />
            <Route exact path="/watchlist/:subasset" component={Asset} />
            <Route exact path="/watchlist/:parentasset/:subasset" component={Asset} />
            <Route exact path="/logs" component={Logs} />
            <Route exact path="/category" component={Home} />
            <Route exact path="/subcategory" component={Home} />
            <Route exact path="/subsubcategory" component={Home} />
            <Route exact path="/subsubcategoryAsset" component={Home} />
            <Route exact path="/chartView" component={ChartView} />
            <Route exact path="/systemWatchlistTiming" component={SystemWatchlist} />
            <Route exact path="/alberto" component={Alberto} />
            {this.props.isOperator && <Route exact path="/operator" component={Operator} />}
            <Route path="*" component={NotFound} />
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ETLStatus: state.AboutReducer?.about?.code || 0,
  isOperator: state.AboutReducer.isOperator,
  serverRestart: state.AboutReducer.serverRestart
});

export default connect(mapStateToProps,{sendFirebaseToken,getTooltipsJson})(App);
