import React, { Component } from 'react';
import { connect } from "react-redux";

import Header from '../layout/Header';
import Footer from '../layout/Footer';

class NotFound extends Component {
    componentDidMount() {
        if (!this.props.loginDetails) {
            this.props.history.push('/')
        }
    }

    render() {
        const { history } = this.props;
        return (
            <>
                <Header history={history} location={'notfound'} />
                <div id="page-wrapper" className="m-l-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12">
                                <div className="white-box login-white-box" style={{ textAlign: 'center' }}>
                                    <h4 className="page-title">404 Page Not Found</h4>
                                    <div>The page you are looking for doesn't exist or could not be found.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

const mapStateToProps = state => ({
    loginDetails: state.AboutReducer.loginDetails,
});

export default connect(mapStateToProps, {})(NotFound)