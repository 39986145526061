export const templateData = [
    { asset: 'AAPL', weight: 10 },
    { asset: 'TSLA', weight: 20 },
    { asset: 'AMD', weight: 30 }
]

export const albertoTemplate = [
    { asset: 'AAPL', position: 'Cash' },
    { asset: 'TSLA', position: 'Long' },
    { asset: 'AMD', position: 'Short' }
]

export const quantityTemplate = [
    { asset: 'AAPL', quantity: 10 },
    { asset: 'TSLA', quantity: 20 },
    { asset: 'AMD', quantity: 30 }
]