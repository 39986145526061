import { BASE_URL } from "../utils/const";
import { ACTIONS } from "./ActionTypes";
import uuid from "react-uuid";
import { today } from "../utils/helperFunctions";
import accessTokenUpdate from "./accessTokenUpdate";
import versionUpdate from "./VersionUpdate";

export const fetchAsset = (search, success, failure) =>  (dispatch) => {
  return new Promise((res,rej) => {

  (async () => {
    // console.log("FETCHING ASSET in AssetAction.js", search)
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/symbol?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        symbols: [{ symbol: search }],
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;
  
    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        dispatch({
          type: ACTIONS.FETCH_ASSET,
          payload: { result, fetchRequest: requestBody },
        });
        // success();
        // accessTokenUpdate;
      }
      res(result)
      
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      // failure(err, requestBody);
      rej(err, requestBody)
    }
  })();
  })
};

export const fetchAssetWithDate = (search, success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/symbol?uuid=" + today();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        symbols: [{ symbol: search }],
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        dispatch({
          type: ACTIONS.FETCH_ASSET,
          payload: { result, fetchRequest: requestBody },
        });
        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchCompareAsset = (search, success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/symbol?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        symbols: [{ symbol: search }],
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);
    requestBody.url = requestUrl;

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        dispatch({
          type: ACTIONS.FETCH_ASSET_FAMILY_DATA,
          payload: { result, fetchRequest: requestBody },
        });
        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchChartData = (vsips, chartType, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/chart/daily?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        vsips: vsips,
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        result.performance.percentagegainsdata.map((data)=>{
          data.performancedatalist.map((item)=>{
            item.percentagegain = item.percentagegain*100;
          })
        });
     
        result.timings.timingsdata.map((data)=>{
          data.timingsdatadetailslist.map((item)=>{
            item.timingsdata = item.timingsdata*100;;
          })
        })
        dispatch({
          type: ACTIONS.FETCH_CHART_DATA,
          payload: { result, fetchRequest: requestBody },
          chartType,
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchChartDataWithDate = (vsips, chartType, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/chart/daily?uuid=" + today();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        vsips: vsips,
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        dispatch({
          type: ACTIONS.FETCH_CHART_DATA,
          payload: { result, fetchRequest: requestBody },
          chartType,
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchTimingData = (holdings, route, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/timing?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        holdings: holdings,
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        if (route === "asset") {
          dispatch({
            type: ACTIONS.ASSET_TIMING_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else if (route === "watchlist") {
          dispatch({
            type: ACTIONS.WATCHLIST_TIMING_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else if (route === "alberto") {
          dispatch({
            type: ACTIONS.ALBERTO_TIMING_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else {
          dispatch({
            type: ACTIONS.SINGLE_ASSET_TIMING,
            payload: { result, fetchRequest: requestBody },
          });
        }

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchTimingDataWithDate = (holdings, route, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/timing?uuid=" + today();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        holdings: holdings,
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        if (route === "asset") {
          dispatch({
            type: ACTIONS.ASSET_TIMING_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else if (route === "watchlist") {
          dispatch({
            type: ACTIONS.WATCHLIST_TIMING_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else if (route === "alberto") {
          dispatch({
            type: ACTIONS.ALBERTO_TIMING_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else {
          dispatch({
            type: ACTIONS.SINGLE_ASSET_TIMING,
            payload: { result, fetchRequest: requestBody },
          });
        }

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const fetchPerformanceData = (holdings, route, success, failure) => (
  dispatch
) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/performance?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid: uuid(),
        holdings: holdings,
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        if (route === "asset") {
          dispatch({
            type: ACTIONS.ASSET_PERFORMANCE_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else if (route === "watchlist") {
          dispatch({
            type: ACTIONS.WATCHLIST_PERFORMANCE_DATA,
            payload: { result, fetchRequest: requestBody },
          });
        } else {
          dispatch({
            type: ACTIONS.SINGLE_ASSET_PERFORMANCE,
            payload: { result, fetchRequest: requestBody },
          });
        }
        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      console.log("err", err);
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const getSupportedSymbols = (success, failure) => (dispatch) => {
  (async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let requestUrl = BASE_URL + "/signal/v1/supported?uuid=" + uuid();
    let requestBody = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "idtoken": `${localStorage.getItem("idToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        SymbolRequest: "woeiqpwoeiur1032987120394sdlasdjh",
        uuid: uuid(),
        useremail: userEmail,
      }),
    };

    let fetchRequest = new Request(requestUrl, requestBody);

    try {
      let result = await fetch(fetchRequest).then((res) => res.json());

      requestBody.url = requestUrl;
      if (result.status === 403 || result["Expired JWT"]) {
        failure()
        dispatch({
          type: ACTIONS.FORBIDDEN_ERROR,
          payload: result,
        });
      } else if (versionUpdate(result.currentBuildVersions)) {
        dispatch({
          type: ACTIONS.VERSION_UPDATE,
          payload: true,
        });
      } else {
        dispatch({
          type: ACTIONS.SUPPORTED_SYMBOLS,
          payload: { result, fetchRequest: requestBody },
        });

        success();
        // accessTokenUpdate;
      }
    } catch (err) {
      console.log("Err in getting supported symbols ", err)
      // dispatch({
      //   type: ACTIONS.FORBIDDEN_ERROR,
      //   payload: err,
      // });
      failure(err, requestBody);
    }
  })();
};

export const updateAssets = () => (dispatch) => {
  dispatch({
    type: ACTIONS.CLEAR_ASSET_DATA,
  });
};

export const clearSingleData = () => (dispatch) => {
  dispatch({
    type: ACTIONS.CLEAR_SINGLE_DATA,
  });
};

export const updateValues = (
  newReturn,
  newDrawdown,
  newRecovery,
  newSharpe,
  route,
  success,
  failure
) => (dispatch) => {
  if (route === "asset") {
    dispatch({
      type: ACTIONS.UPDATE_VALUES,
      payload: [newReturn, newDrawdown, newRecovery, newSharpe],
    });
  } else {
    dispatch({
      type: ACTIONS.UPDATE_WATCHLIST_VALUES,
      payload: [newReturn, newDrawdown, newRecovery, newSharpe],
    });
  }

  success();
};

export const updateCompareValues = (
  newReturn,
  newDrawdown,
  newRecovery,
  newSharpe,
  route,
  success,
  failure
) => (dispatch) => {
  if (route === "asset") {
    dispatch({
      type: ACTIONS.UPDATE_COMPARE_VALUES,
      payload: [newReturn, newDrawdown, newRecovery, newSharpe],
    });
  } else {
    dispatch({
      type: ACTIONS.UPDATE_WATCHLIST_COMPARE_VALUES,
      payload: [newReturn, newDrawdown, newRecovery, newSharpe],
    });
  }

  success();
};

export const updateBenchmarkValues = (
  newReturn,
  newDrawdown,
  newRecovery,
  newSharpe,
  route,
  success,
  failure
) => (dispatch) => {
  if (route === "asset") {
    dispatch({
      type: ACTIONS.UPDATE_BENCHMARK_VALUES,
      payload: [newReturn, newDrawdown, newRecovery, newSharpe],
    });
  } else {
    dispatch({
      type: ACTIONS.UPDATE_WATCHLIST_BENCHMARK_VALUES,
      payload: [newReturn, newDrawdown, newRecovery, newSharpe],
    });
  }

  success();
};

export const updateChartZoomValues = (
  chartSeries,
  route,
) => (dispatch) => {
  if (route === "asset") {
    dispatch({
      type: ACTIONS.UPDATE_CHART_ZOOM_VALUES,
      payload: chartSeries
    })
  } else {

    dispatch({
      type: ACTIONS.UPDATE_CHART_ZOOM_VALUES,
      payload: chartSeries
    })
  }
}