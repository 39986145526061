import React, { useEffect, useState } from 'react'
import {Box, Typography} from '@mui/material'
import styles from './Table.module.css'

function Table(props) {
    const [body, setBody] = useState(props.body)

    useEffect(() => {
        // console.log("data table props.body -> ", props.body)
        console.log(props)
        setBody(props.body)
    }, [props])

    return (
        <Box className={styles.table__container} style={{width: props?.width || '80%'}}>
            <table className={styles.logTable}>
                <thead style={{position: 'sticky', top: '0', border: '1px solid black'}}>
                    <tr>
                        {props.header.map((item, index) => (
                            <th key={item} 
                            ><Typography sx={{fontSize: '0.8em'}}>{item}</Typography></th>
                        ))}
                    </tr>
                </thead>
                <tbody style={{maxHeight: '100px'}}>
                    {body.map((row, index) => (
                        // TODO: row[row.length-1].value is the key of the row
                        <tr className={styles.table__row} key={`${row[0].value}`}>
                            {row.map((item, itemIndex) => (
                                item.label !== "key" &&
                                <td 
                                    // make use of the key+label to avoid duplicates
                                    style={{backgroundColor: item?.color}}
                                    key={`${item.value}_${item.label}_${row[0].value}_${row[1].value}_${row[2].value}`} 
                                    className={(item.type === "number") ? styles.alignRight: ''}>
                                    <Typography sx={{fontSize: '0.9em'}}>{item.value}</Typography>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    )
}

export default Table
