import React from "react";
import { useTable, useSortBy } from "react-table";
import uuid from "react-uuid";

import searchIcon from "../../assets/img/lens-light.png";
import { asciiValueCheck } from "../../utils/helperFunctions";
import AutocompleteField from "../AutocompleteField";
import "../portfolio/PortfolioTable.css";
import "./Alberto.css";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

const COLUMNS = Object.freeze({
  ASSET: "Asset",
  POSITION: "Position",
  ACTION: "",
});

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  onItemSelect,
  onSearchChange,
  handleKeyPress,
  symbols,
  deleteRow,
  editable,
  placeholder,
  type,
  history,
  analyzed,
  action,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }
    if (!valueCheck) {
      if (!numberCheck && (asciiCheck || value === "")) {
        setValue(e.target.value.toUpperCase());
      }
    }
    onSearchChange(e, placeholder);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    handleKeyPress(id, e);
    updateMyData(index, id, value);
  };

  const onClick = () => {
    deleteRow(index, id);
  };

  const searchSymbol = () => {
    history.push(`/watchlist/${value}?uuid=${uuid()}`);
  };

  const onSelect = (e) => {
    let value = e;
    if (id === "position") value = e.target.innerHTML;
    onItemSelect(index, id, value);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      let valueIndex = symbols.findIndex(
        (symbolValue) => symbolValue && symbolValue.symbol === value
      );
      if (valueIndex === -1) {
        setValue("");
        handleKeyPress(id, e);
      }
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    if (action) {
      return <i className="fa fa-trash" onClick={onClick}></i>;
    }
    return value ? `${value}` : `${""}`;
  }

  if (placeholder === "Symbol") {
    return (
      <div
        className="portfolio-editable-cell asset-edit-cell"
        id="portfolio-autocomplete-input"
      >
        <img
          className="portfolio-search"
          style={{
            visibility: type === "text" && analyzed ? "visible" : "hidden",
          }}
          onClick={searchSymbol}
          height="20"
          width="20"
          src={searchIcon}
          alt="Symbol"
        ></img>

        <AutocompleteField
          placeholder={placeholder}
          onSearchChange={onChange}
          onItemSelect={onSelect}
          symbols={symbols}
          search={value}
          onKeyUp={onKeyPress}
          onBlur={onBlur}
          menuStyle={{
            position: "absolute",
            top: "100%",
            left: 0,
            height: "100px",
            overflow: "auto",
          }}
          onPaste={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
          }}
        />
        <div className="symbol-data-div">
          <span className="tooltiptext" id="error-div">
            Maximum length is 6 characters.
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="portfolio-editable-cell asset-edit-cell">
        <DropdownButton
          id={value === "" ? "empty-dropdown-button" : "dropdown-basic-button"}
          title={value === "" ? "Select" : value}
        >
          <Dropdown.Item value="CASH" onClick={onSelect}>
            CASH
          </Dropdown.Item>
          <Dropdown.Item value="LONG" onClick={onSelect}>
            LONG
          </Dropdown.Item>
          <Dropdown.Item value="SHORT" onClick={onSelect}>
            SHORT
          </Dropdown.Item>
        </DropdownButton>
      </div>
    );
  }
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

function AlbertoTable({
  data,
  history,
  updateMyData,
  deleteRow,
  symbols,
  onSearchChange,
  onItemSelect,
  handleKeyPress,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Asset",
        accessor: "symbol",
        width: 100,
        sortType: "basic",
      },
      {
        Header: "Position",
        accessor: "position",
        sortType: "basic",
      },
      {
        Header: "Timing Change",
        accessor: "timingChange",
        sortType: "basic",
      },
      {
        Header: "Latest Timing",
        accessor: "latestTiming",
        sortType: "basic",
      },
      {
        Header: "Action",
        accessor: "userAction",
        sortType: "basic",
      },
      {
        Header: COLUMNS.ACTION,
        accessor: "action",
        disableSortBy: true,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      updateMyData,
      deleteRow,
    },
    useSortBy
  );

  return (
    <>
      <div
        className="table-responsive m-t-10 alberto-table"
        id="portfolio-table"
        style={{ minHeight: "200px", paddingBottom: "100px" }}
      >
        <table {...getTableProps()} className="table">
          <colgroup>
            <col width="15%" />
            <col width="15%" />
            <col width="25%" />
            <col span="3" width="25%" />
            <col span="4" width="20%" />
            <col width="5%" />
          </colgroup>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="asset-table-header">
                      <p className="m-b-0 th-text">{column.render("Header")}</p>
                      {/* Add a sort direction indicator */}
                      <span className="th-icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i> // ' 🔽'
                          ) : (
                            <i
                              className="fa fa-caret-up"
                              aria-hidden="true"
                            ></i>
                          ) // ' 🔼'
                        ) : column.disableSortBy ? (
                          ""
                        ) : (
                          <i
                            className="fa fa-caret-down non-sorted"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.Header === COLUMNS.ASSET) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            data[i].valid
                              ? "asset-name-column"
                              : "asset-name-column invalid-asset"
                          }
                        >
                          {cell.render("Cell", {
                            editable: true,
                            placeholder: "Symbol",
                            type: "text",
                            history: history,
                            analyzed: data[i].vsip ? true : false,
                            symbols: symbols,
                            onSearchChange,
                            onItemSelect,
                            handleKeyPress,
                          })}
                        </td>
                      );
                    } else if (cell.column.Header === COLUMNS.POSITION) {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: true,
                            placeholder: "Position",
                            type: "text",
                            history: history,
                            symbols: symbols,
                            onSearchChange,
                            onItemSelect,
                            handleKeyPress,
                          })}
                        </td>
                      );
                    } else if (cell.column.Header === COLUMNS.ACTION) {
                      return (
                        <td className="text-right" {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: false,
                            action: "delete",
                          })}
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell", { editable: false })}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default React.memo(AlbertoTable);
