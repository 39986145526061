import React, { Component } from 'react';

import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official'

import '../charts/SynchChart.css';
import { MONTHS } from '../../utils/const';
import { timingDateFormat } from '../../utils/helperFunctions';

class TimingsModelChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValues: [],
            chartNames: [],
            portfolioIndex: null,
            chartValues: [],
            chartWithoutPortfolio: [],
            showPercentage: false
        };
    }

    onFullView = () => {
        this.props.showChartInModal('percentageGains');
    }

    goBack = () => {
        this.props.goBack();
    }

    render() {
        const { data, showViewButton } = this.props;

        let chartData = [];
        let min = 0;
        let max = 0;

        const priceDeltaData = data.map(value => {
            return isNaN(value.assetpricedelta) ?
                [] :
                [new Date(value.date).getTime(), value.assetpricedelta]
        })
            .sort((a, b) => a[0] - b[0]);

        const maxPrice = priceDeltaData.reduce((max, price) => {
            if (max > price[1]) {
                return max;
            } else {
                return price[1];
            }
        }, priceDeltaData[0][1]);

        const minPrice = priceDeltaData.reduce((min, price) => {
            if (min < price[1]) {
                return min;
            } else {
                return price[1];
            }
        }, priceDeltaData[0][1]);

        if (minPrice < min)
            min = minPrice;
        if (maxPrice > max)
            max = maxPrice;

        const strategyData = data.map(value => {
            return isNaN(value.strategy) ?
                [] :
                [new Date(value.date).getTime(), value.strategy]
        })
            .sort((a, b) => a[0] - b[0]);

        const maxStrategy = strategyData.reduce((max, strategy) => {
            if (max > strategy[1]) {
                return max;
            } else {
                return strategy[1];
            }
        }, strategyData[0][1]);

        const minStrategy = strategyData.reduce((min, strategy) => {
            if (min < strategy[1]) {
                return min;
            } else {
                return strategy[1];
            }
        }, strategyData[0][1]);

        if (minStrategy < min)
            min = minStrategy;
        if (maxStrategy > max)
            max = maxStrategy;

        chartData.push({
            name: 'Price Delta',
            data: priceDeltaData,
            visible: true,
            lineWidth: 1,
            min: min,
            max: max
        }, {
            name: 'Strategy',
            data: strategyData,
            visible: true,
            lineWidth: 1,
            min: min,
            max: max
        })

        const deltaA = Math.abs(max - min) * 0.05;

        const calibratedMin = Math.floor(Math.min(min - deltaA, 0));
        const calibratedMax = Math.ceil(Math.max(max + deltaA, 0));

        let options = {
            chart: {
                backgroundColor: '#37394d',
                height: '300px',
                zoomType: 'xy'
            },

            title: {
                text: "",
            },

            colors: ['#4572A7', '#de817e'],

            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                gridLineColor: '#4b4d5e',
                gridLineDashStyle: 'Dot',
                crosshair: {
                    snap: false
                },
                labels: {
                    formatter: function () {
                        let date = new Date(this.value)
                        return String(date.getDate()) + " " + MONTHS[date.getMonth()] + " " + String(date.getFullYear()).slice(-2);
                    },
                    enabled: true,
                    style: {
                        color: '#9ba2ab',
                    }
                }
            },

            tooltip: {
                shared: true,
                formatter: function () {
                    return this.points.reduce(function (s, point) {
                        return '<span>' + timingDateFormat(point.x) + '</span><br/>' +
                            '<span style="color:' + point.color + '">● </span>' + point.series.name + ': <b>' + point.y.toFixed(2) +
                            '</b><br/>' +
                            '<span style="color:' + s.color + '">● </span>' + s.series.name + ': <b>' + s.y.toFixed(2) + '</b>'
                    })
                }
            },

            time: {
                useUTC: false
            },

            yAxis: {
                visible: true,
                gridLineColor: '#4b4d5e',
                gridLineDashStyle: 'Dot',
                labels: {
                    style: {
                        color: '#9ba2ab',
                    }
                },
                min: calibratedMin,
                max: calibratedMax,
            },

            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'top',
                itemStyle: {
                    color: '#fff'
                }
            },

            credits: {
                enabled: false
            },

            series: chartData,

            plotOptions: {
                area: {
                    marker: {
                        enabled: true,
                        radius: 0
                    }
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: 1
                        }
                    },
                    dataLabels: {
                        allowOverlap: false,
                        overflow: 'none',
                        crop: false
                    },
                    turboThreshold: 5000,
                    threshold: null,
                    borderWidth: 0,
                }
            },

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };

        return (
            <>
                <div className="white-box p-0 percentage-gains-white-box p-t-10">
                    <div className="row chart-title p-b-10">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <h3 className="box-title" style={{ paddingLeft: '10px' }}>
                                {!showViewButton
                                    &&
                                    <span style={{ cursor: 'pointer' }} onClick={this.goBack}>
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                    </span>
                                }
                                Timing Model Performance
                                {
                                    showViewButton &&
                                    <i onClick={this.onFullView} className="fa fa-external-link external-link-img"></i>
                                }
                            </h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12" id="percentage-gains-div">
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'chart'}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default TimingsModelChart