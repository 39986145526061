import React, { Component } from 'react'

class RangeSelectorModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false,
            period: props.period
        }
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            show: false
        });
    }

    onChange = (e) => {
        this.setState({
            period: e.target.value
        })
    }

    onSave = () => {
        this.props.onSave(this.state.period);
    }

    render() {
        const { show } = this.props;
        const { period } = this.state;

        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : ""

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title">Simple Moving Average
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                                <div className="enter-period-div">
                                    <h3 className="vista-chart-title">Period</h3>
                                    <input
                                        type='number'
                                        placeholder='Enter Period'
                                        className="b-0"
                                        value={period}
                                        onChange={this.onChange}
                                    />
                                </div>

                                <span className="input-group-btn" id="view-log-group">
                                    <button onClick={this.onSave} className="btn">Save</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default RangeSelectorModal
