import { combineReducers } from 'redux';
import { AssetReducer } from './AssetReducer';
import { PortfolioReducer } from './PortfolioReducer';
import { MarketReducer } from './MarketReducer';
import { AboutReducer } from './AboutReducer';

export default combineReducers({
  AssetReducer,
  PortfolioReducer,
  MarketReducer,
  AboutReducer
});