import React, { Component } from 'react';

import '../charts/SynchChart.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import PercentageGainsChart from './PercentageGainsChart';
import { TabContainer } from 'react-bootstrap';

class ChartTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValues: [],
            chartNames: [],
            portfolioIndex: null,
            chartValues: [],
            chartWithoutPortfolio: [],
            showPercentage: false,
        };

        this.style = {
            chips: {
                background: "#313345",
                border: 'solid 1px #3bc796',
                color: '#3bc796'
            },
            multiselectContainer: {
                color: "#3bc796"
            }
        }
    }

    checkTimingData = (timingData) => {
        if (timingData.timingsdata.length === 0)
            return false;
        else {
            let timings = timingData.timingsdata;
            let nonZero = false;

            timings.forEach((value) => {
                if (value.timingsdatadetailslist.length !== 0) {
                    value.timingsdatadetailslist.forEach((timing) => {
                        if (timing.timingsdata !== 0)
                            nonZero = true;
                    })
                }
                else
                    return false
            })

            if (nonZero)
                return true
            else
                return false
        }
    }

    checkPerformanceTiming = (performanceData) => {
        if (performanceData.percentagegainsdata.length === 0)
            return false;
        else {
            let performance = performanceData.percentagegainsdata;
            let nonZero = false;

            performance.forEach((value) => {
                if (value.performancedatalist.length !== 0) {
                    value.performancedatalist.forEach((perf) => {
                        if (perf.percentagegain !== 0)
                            nonZero = true;
                    })
                }
                else
                    return false
            })

            if (nonZero)
                return true
            else
                return false
        }
    }

    getSinglePerformance = (symbol) => {
        this.props.getSingleAsset(symbol);
    }

    updateOtherValues = (newReturn, newDrawdown, newRecovery, newSharpe) => { 
        this.props.updateValues(newReturn, newDrawdown, newRecovery, newSharpe);
    }

    updateOtherCompareValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
        this.props.updateCompareValues(newReturn, newDrawdown, newRecovery, newSharpe);
    }

    updateBenchmarkValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
        this.props.updateBenchmarkValues(newReturn, newDrawdown, newRecovery, newSharpe);
    }

    onTransactionsClick = () => {
        this.props.onTransactionsClick();
    }

    render() {
        const { performanceData, holdings, showViewButton, showChartInModal, timingData, showMultiSelect, assetSymbol,
            singleAssetPerformance, singleAssetTiming, singleLoading, currentRoute, familyChartData,
            benchmark, selectedAssets, apiModalShow, modelOpenCheck, showConfirmModal,
            showBenchmarkChangeModal, showTransactionsModal, timingDiffData,
            showReloadModal,
            showDeleteModel,
            showExtraAsset,
            showTimingDiff,
            showSaveWatchlistModal,
            showSavedWatchlistModal,
            showOptimizeModal, showRangeModal, aboutData, aboutCheck } = this.props;

        let perfData = [];
        let timingDataPresent = null;
        let performanceDataPresent = null;
        let compareData = null;
        let asset = assetSymbol;
        if (performanceData.percentagegainsdata.length !== 0 && performanceData.percentagegainsdata[0].performancedatalist.length !== 0)
            perfData = performanceData.percentagegainsdata[1];
        else if (timingData?.timingsdata?.length !== 0 && timingData?.timingsdata[0]?.timingsdatadetailslist?.length !== 0)
            perfData = timingData.timingsdata[1];

        timingDataPresent = this.checkTimingData(timingData);
        performanceDataPresent = this.checkPerformanceTiming(performanceData);

        compareData = performanceData;
        compareData.timingsdata = timingData?.timingsdata;
        // let asset = assetSymbol;

        if (perfData?.length !== 0 && timingData?.timingsdata?.length !== 0) {
            if (singleAssetPerformance !== null && singleAssetTiming !== null && !singleLoading) {
                let newTiming = singleAssetTiming?.timingsdata[0]?.timingsdatadetailslist;

                compareData = singleAssetPerformance;
                compareData.timingsdata = singleAssetTiming?.timingsdata;

                compareData.percentagegainsdata[0] = performanceData.percentagegainsdata[0];
                compareData.timingsdata[0] = timingData?.timingsdata[0];
                if (compareData.timingsdata[1])
                    compareData.timingsdata[1].timingsdatadetailslist = newTiming;
            }
        }

        if (currentRoute !== 'asset')
            asset = 'Watchlist';

        return (
            <>
                {
                    <Tabs defaultActiveKey="versus">
                        {
                            showMultiSelect &&
                            <Tab eventKey="performance" title="Watchlist">
                                <TabContainer
                                >
                                    {
                                        performanceDataPresent && !singleLoading ?
                                            <PercentageGainsChart
                                                data={performanceData.percentagegainsdata}
                                                holdings={holdings}
                                                showViewButton={showViewButton}
                                                showChartInModal={showChartInModal}
                                                tabName='performance'
                                                // otherValues={performanceData}
                                                performanceValues={performanceData}
                                                performance={performanceData}
                                                showMultiSelect={showMultiSelect}
                                                assetSymbol={assetSymbol}
                                                currentRoute={currentRoute}
                                                updateOtherValues={this.updateOtherValues.bind(this)}
                                                updateOtherCompareValues={this.updateOtherCompareValues.bind(this)}
                                                updateBenchmarkValues={this.updateBenchmarkValues.bind(this)}
                                                familyChartData={familyChartData}
                                                benchmark={benchmark}
                                                selectedAssets={selectedAssets}
                                                apiModalShow={apiModalShow}
                                                modelOpenCheck={modelOpenCheck}
                                                showRangeModal={showRangeModal}
                                                showConfirmModal={showConfirmModal}
                                                showBenchmarkChangeModal={showBenchmarkChangeModal}
                                                showReloadModal={showReloadModal}
                                                showDeleteModel={showDeleteModel}
                                                showExtraAsset={showExtraAsset}
                                                showTimingDiff={showTimingDiff}
                                                showSaveWatchlistModal={showSaveWatchlistModal}
                                                showSavedWatchlistModal={showSavedWatchlistModal}
                                                showOptimizeModal={showOptimizeModal}
                                                showTransactionsModal={showTransactionsModal}
                                                onTransactionsClick={this.onTransactionsClick.bind(this)}
                                                timingDiffData={timingDiffData}
                                                aboutData={aboutData}
                                                aboutCheck={aboutCheck}
                                            />
                                            :
                                            <div className="white-box p-0 percentage-gains-white-box p-t-10" style={{ 'height': 'auto' }}>
                                                <div className="row">
                                                    <div className="col-md-12 m-t-20" style={{ textAlign: 'center' }} id="percentage-gains-div">
                                                        <h3 className="box-title">Performance Data not available</h3>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </TabContainer>
                            </Tab>
                        }

                        {
                            showMultiSelect &&
                            <Tab eventKey="timing" title="Timing Model for Watchlist">
                                <TabContainer
                                >
                                    {
                                        timingDataPresent && !singleLoading ?
                                            <PercentageGainsChart
                                                data={timingData.timingsdata}
                                                holdings={holdings}
                                                showViewButton={showViewButton}
                                                showChartInModal={showChartInModal}
                                                tabName='timing'
                                                otherValues={timingData}
                                                showMultiSelect={showMultiSelect}
                                                assetSymbol={assetSymbol}
                                                currentRoute={currentRoute}
                                                updateOtherValues={this.updateOtherValues.bind(this)}
                                                updateOtherCompareValues={this.updateOtherCompareValues.bind(this)}
                                                updateBenchmarkValues={this.updateBenchmarkValues.bind(this)}
                                                familyChartData={familyChartData}
                                                benchmark={benchmark}
                                                selectedAssets={selectedAssets}
                                                apiModalShow={apiModalShow}
                                                modelOpenCheck={modelOpenCheck}
                                                performanceData={this.props.performanceData}
                                                performance={performanceData}
                                                showRangeModal={showRangeModal}
                                                showConfirmModal={showConfirmModal}
                                                showBenchmarkChangeModal={showBenchmarkChangeModal}
                                                showReloadModal={showReloadModal}
                                                showDeleteModel={showDeleteModel}
                                                showExtraAsset={showExtraAsset}
                                                showTimingDiff={showTimingDiff}
                                                showSaveWatchlistModal={showSaveWatchlistModal}
                                                showSavedWatchlistModal={showSavedWatchlistModal}
                                                showOptimizeModal={showOptimizeModal}
                                                showTransactionsModal={showTransactionsModal}
                                                timingData={this.props.timingData}
                                                onTransactionsClick={this.onTransactionsClick.bind(this)}
                                                timingDiffData={timingDiffData}
                                                aboutData={aboutData}
                                                aboutCheck={aboutCheck}
                                            />
                                            :
                                            <div className="white-box p-0 percentage-gains-white-box p-t-10" style={{ 'height': 'auto' }}>
                                                <div className="row">
                                                    <div className="col-md-12 m-t-20" style={{ textAlign: 'center' }} id="percentage-gains-div">
                                                        <h3 className="box-title">Timing Data not available</h3>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </TabContainer>
                            </Tab>
                        }

                        <Tab
                            eventKey="versus"
                            title={
                                !showMultiSelect && !timingDataPresent ?
                                    `${asset} Market Performance`
                                    : `${asset} vs. Timing Model for ${asset} `}>
                            <TabContainer
                            >
                                {
                                    timingDataPresent && performanceDataPresent && !singleLoading ?
                                        <PercentageGainsChart
                                            compareData={compareData}
                                            holdings={holdings}
                                            showViewButton={showViewButton}
                                            showChartInModal={showChartInModal}
                                            tabName='versus'
                                            performance={performanceData}
                                            timing={timingData}
                                            showMultiSelect={showMultiSelect}
                                            otherValues={performanceData}
                                            timingDataPresent={timingDataPresent}
                                            singleAssetPerformance={singleAssetPerformance}
                                            singleAssetTiming={singleAssetTiming}
                                            getSinglePerformance={this.getSinglePerformance}
                                            singleLoading={singleLoading}
                                            assetSymbol={assetSymbol}
                                            currentRoute={currentRoute}
                                            updateOtherValues={this.updateOtherValues.bind(this)}
                                            updateOtherCompareValues={this.updateOtherCompareValues.bind(this)}
                                            updateBenchmarkValues={this.updateBenchmarkValues.bind(this)}
                                            familyChartData={familyChartData}
                                            benchmark={benchmark}
                                            selectedAssets={selectedAssets}
                                            apiModalShow={apiModalShow}
                                            modelOpenCheck={modelOpenCheck}
                                            showRangeModal={showRangeModal}
                                            showConfirmModal={showConfirmModal}
                                            showBenchmarkChangeModal={showBenchmarkChangeModal}
                                            showReloadModal={showReloadModal}
                                            showDeleteModel={showDeleteModel}
                                            showExtraAsset={showExtraAsset}
                                            showTimingDiff={showTimingDiff}
                                            showSaveWatchlistModal={showSaveWatchlistModal}
                                            showSavedWatchlistModal={showSavedWatchlistModal}
                                            showOptimizeModal={showOptimizeModal}
                                            showTransactionsModal={showTransactionsModal}
                                            onTransactionsClick={this.onTransactionsClick.bind(this)}
                                            timingDiffData={timingDiffData}
                                            aboutData={aboutData}
                                            aboutCheck={aboutCheck}
                                        />
                                        :
                                        singleLoading ?
                                            <div className="white-box p-0 percentage-gains-white-box p-t-10">
                                                <div className="row">
                                                    <div className="col-md-12" id="percentage-gains-div">
                                                        <div className="card-loader card-loader--tabs m-b-30"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="white-box p-0 percentage-gains-white-box p-t-10"
                                                style={{ 'height': 'auto' }}>
                                                <div className="row">
                                                    <div className="col-md-12 m-t-20"
                                                        style={{ textAlign: 'center' }}
                                                        id="percentage-gains-div">
                                                        <h3 className="box-title">Performance and Timing Data not available</h3>
                                                    </div>
                                                </div>
                                            </div>
                                }
                            </TabContainer>
                        </Tab>
                    </Tabs>
                }
            </>
        )
    }
}

export default ChartTabs