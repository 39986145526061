import React from 'react';
import { useTable, useSortBy, useBlockLayout } from 'react-table';
import { Link } from 'react-router-dom';
import './AssetList.css';
import { FixedSizeList } from 'react-window';
import { calculateSentimentValue, formatVflow, getHeader } from '../../utils/helperFunctions';
// TODO: try using emotion instead and see if it works
// import styled from "styled-components";
import styled from "@emotion/styled";
import { useSticky } from "react-table-sticky";
import uuid from 'react-uuid';
import 'react-rangeslider/lib/index.css';

let sentimentId = '';
let sentimentText = '';

const Styles = styled.div`
  padding: 1rem;
  width: fit-content;
  display: inline-block;
  max-width: 100%;

  .table {
    width: fit-content;
    max-width: 100%;

    .body{
      .tr{
        height: 40px !important;
        .td{
          padding: 0 8px;
          height: 40px;
        }
      }
    }

    .tr {
      .th{
        background-color: #252734;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
      }
      .th-icon{
        margin-left: 10px;
      }
    }

    .th,
    .td {
      padding: 8px;
      border-bottom: 1px solid rgba(95,96,112,.6)!important;
      background-color: #37394d;
      display: flex !important;
      align-items:center !important;

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header{
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        .tr:first-child{
          .th{
            background-color: #37394d;
            display: none !important;
          }
        }
        z-index: 4;
        top: 0;
      }

      [data-sticky-td] {
        position: sticky;
        z-index: 3 !important;
      }

      [data-sticky-last-left-td]{
        border-right: 1px solid rgba(95,96,112,.6)!important;
        z-index: 2 !important;
      }
    }
  }
`;

function getColumns(cols, parentAsset, path) {
  let newCols = [];

  cols.forEach((col, index) => {
    if (col === 'vflow') {
      newCols.push({
        Header: col.toUpperCase(),
        accessor: col,
        sortType: "basic",
        width: 135,
        Cell: (props) => formatVflow(props.cell.value)
      })
    }
    else {
      if (index > 2) {
        newCols.push({
          Header: getHeader(col),
          accessor: col,
          sortType: "basic",
          width: 138
        })
      }
      else {
        newCols.push({
          Header: getHeader(col),
          accessor: col,
          sortType: "basic"
        })
      }
    }
  })

  let finalCols = [
    {
      Header: ' ',
      sticky: 'left',
      columns: [
        {
          Header: 'Asset',
          accessor: 'symbol',
          width: 90,
          sortType: "basic",
          Cell: (props) => {
            return (
              <>
                <Link
                  className="holdings-link"
                  to={{
                    pathname: `/${path}/${parentAsset}/${props.cell.value}`,
                    search: 'uuid=' + uuid()
                  }}>
                  {props.cell.value}
                  <span className="tooltiptext">{props.row.original.company}</span>
                </Link>
              </>
            )
          }
        },
        {
          Header: 'Weight',
          accessor: 'allocation',
          sortType: "basic",
          width: 100,
          Cell: (props) => `${props.cell.value}`
        }
      ]
    },
    {
      Header: ' ',
      columns: newCols
    }
  ];

  return finalCols
}

function getSentimentValue(data) {
  let longAssets = data.filter((asset) => {
    return asset.timing === 'Long'
  })

  let shortAssets = data.filter((asset) => {
    return asset.timing === 'Short'
  })

  let aggregate = 0;
  data.forEach((asset) => {
    aggregate += asset.allocation
  })

  let sentiment = calculateSentimentValue(longAssets, shortAssets, aggregate);
  sentimentText = sentiment.value;
  sentimentId = sentiment.id;
}

function AssetList({ data, parentAsset, path, cols, exportCsv }) {
  const defaultColumn = React.useMemo(
    () => ({
      width: 85,
      maxWidth: 400
    }),
    []
  )

  const columns = React.useMemo(() =>
    getColumns(cols, parentAsset, path),
    [cols, parentAsset, path]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useSortBy,
    useBlockLayout,
    useSticky
  )

  function renderRow({ index, style }) {
    const row = rows[index];
    if (!row) return <div>Loading...</div>;
    prepareRow(row);
    const { style: rowStyle, ...restRow } = row.getRowProps({ style });

    return (
      <div
        {...restRow}
        style={{ ...rowStyle, width: totalColumnsWidth, top: style.top + 75 }}
        className="tr"
      >
        {row.cells.map((cell) => {
          return (
            cell.value ?
              <div {...cell.getCellProps()} className="td">
                {cell.render("Cell")}
              </div>
              :
              <div {...cell.getCellProps()} className="td">
                N/A
              </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {getSentimentValue(data)}
      <div className="white-box">
        <div className="row">
          <div className="col-md-12 asset-holdings-table">
            <div className="col-md-6">
              <h3 className="box-title m-b-10 m-t-10">ETF Holdings</h3>
              <p>Note: This list only contains the holdings symbols for which there is a sufficient amount of options data and activity.</p>
            </div>

            <div className="col-md-6 export-btn-div no-padding">
              <button
                onClick={exportCsv}
                className="btn btn-outline waves-effect waves-light">
                Export to CSV
              </button>
            </div>
          </div>
        </div>

        <Styles>
          <div {...getTableProps()} className="table sticky">
            <div style={{ position: "relative" }}>
              <FixedSizeList
                height={500}
                itemCount={rows.length}
                itemSize={40}
                width="100%"
                innerElementType={({ children, style, ...rest }) => (
                  <>
                    <div className="header">
                      <div style={{ width: totalColumnsWidth }}>
                        {headerGroups.map((headerGroup) => (
                          <div
                            {...headerGroup.getHeaderGroupProps()}
                            className="tr"
                          >
                            {headerGroup.headers.map((column) => (
                              <div
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className="th"
                              >
                                {column.render("Header")}
                                <span className="th-icon">
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? <i className="fa fa-caret-down" aria-hidden="true"></i> // ' 🔽'
                                      : <i className="fa fa-caret-up" aria-hidden="true"></i> // ' 🔼'
                                    : column.disableSortBy ? "" : <i className="fa fa-caret-down non-sorted" aria-hidden="true"></i>}
                                </span>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ height: 500 }} className="body">
                      <div {...getTableBodyProps()} {...rest} style={style}>
                        {children}
                      </div>
                    </div>
                  </>
                )}
              >
                {renderRow}
              </FixedSizeList>
            </div>
          </div>
        </Styles>

        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6 sentiment-div">
              <h3 className="box-title" style={{ textAlign: 'left' }}>Weighted Average Sentiment of Holdings</h3>
            </div>

            <div className="col-md-7" style={{ display: 'grid' }}>
              <div className="col-md-5 sentiment-gradient-div">
                <div className="sentiment-indicator" id={sentimentId}></div>
              </div>
              <div className="col-md-5 col">
                <h3 className="box-title m-t-10" id="sentiment" style={{ textAlign: 'center' }}>{sentimentText}</h3>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12" style={{ display: 'grid' }}>
          </div> */}
        </div>
      </div >
    </>
  )
}

export default React.memo(AssetList)