import React, { Component } from "react";
import { connect } from "react-redux";
import packageJson from "../../package.json";
import firebaseObj from "../utils/firebase";
import { closeForbiddenErrorModal, clearLogin } from "../actions/AboutAction";
const analytics = firebaseObj.analytics();

class ForbiddenErrorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.forbiddenError,
    };
  }
  componentDidUpdate() {
    if (this.props.forbiddenError !== null && this.state.show === null) {
      localStorage.clear();
      window.sessionStorage.setItem("token", "");
      window.sessionStorage.setItem("reload", "false");
      window.sessionStorage.setItem("forbidden",true);
      console.log("forbidden....")
      this.props.closeForbiddenErrorModal();
      this.props.history.push("/");
      // this.setState({
      //     show:this.props.forbiddenError
      // })
    }
  }
  componentDidMount() {
    if (!this.props.loginDetails) {
      this.props.history.push("/");
    }
  }
  message_broadcast = (lastActiveTime) => {
    if (lastActiveTime === "logout") {
      localStorage.setItem("timeout", "logout");
      localStorage.setItem("autoLogin", "false");
    } else if (lastActiveTime === "new_tab") {
      localStorage.setItem("timeout", "new_tab");
      localStorage.setItem("tab", "many");
    } else if (lastActiveTime === "tab_close") {
      localStorage.setItem("timeout", "tab_close");
      localStorage.setItem("tab", "one");
      localStorage.setItem("autoLogin", "false");
    } else {
      localStorage.setItem("timeout", lastActiveTime);
      localStorage.setItem("tab", "one");
    }
  };
  onLoginClick = () => {
    localStorage.clear();
    window.sessionStorage.setItem("token", "");
    window.sessionStorage.setItem("reload", "false");
    localStorage.clear();
    this.props.closeForbiddenErrorModal();
    this.props.history.push("/");

    // analytics.setUserProperties({
    //   email: loginDetails?.email,
    //   App_Version: packageJson.version
    // });
    // analytics.logEvent('Logout');

    // this.props.clearLogin(
    //   () => {
    //     window.sessionStorage.setItem('token', '');
    //     window.sessionStorage.setItem('reload', 'false');
    //     localStorage.clear();
    //     // this.message_broadcast('logout');
    //     localStorage.setItem("forbidden",true);
    //   },
    //   (err) => {
    //     console.log('err', err)
    //   }
    // )
  };

  render() {
    const { show } = this.state;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";
    return (
      <>
        <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          style={{ display: display }}
        >
          <div className="modal-dialog modal-m">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">
                <h4 className="modal-title">
                  Sorry!
                  {/* <button
                    type="button"
                    // onClick={this.close}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button> */}
                </h4>
              </div>

              <div
                className="modal-body p-t-0 p-l-30 p-b-30 p-r-30"
                id="confirm-modal-body"
              >
                Something went wrong. Please login and try
                again.
              </div>

              <span className="input-group-btn" id="view-log-group">
                <button
                  onClick={this.onLoginClick}
                  className="btn confirm-modal-btn"
                >
                  Yes
                </button>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginDetails: state.AboutReducer.about,
  forbiddenError: state.AboutReducer.forbiddenError,
});

export default connect(mapStateToProps, {
  closeForbiddenErrorModal,
  clearLogin,
})(ForbiddenErrorModal);
