import React, { Component } from 'react';

import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official'

import '../charts/SynchChart.css';
import { formatVflow } from '../../utils/helperFunctions';

class HeatMap extends Component {
  render() {

    const { data } = this.props;
    let tempArray = [...data];
    tempArray.reverse();
    let marketData = tempArray;

    let chartData = marketData.map((value, index) => {
      return [value.date, value.vflow]
    })

    let options = {
      chart: {
        type: 'area',
        backgroundColor: '#37394d',
        height: '50px',
        margin: 0,
        borderRadius: 10,
      },

      title: {
        text: "",
      },
      xAxis: {
        labels: {
          enabled: false
        }
      },

      legend: {
        enabled: false,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },

      credits: {
        enabled: false
      },

      yAxis: {
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
        minPadding: 0,
        maxPadding: 0,
        startOnTick: true,
        endOnTick: true,
        tickWidth: 1,
        visible: true,
        gridLineWidth: 0,
        tickPositioner: function () {
          let dataMax = this.dataMax;
          let dataMin = this.dataMin;

          const deltaA = Math.abs(dataMax - dataMin) * 1.35;

          let calibratedMin = Math.min(dataMin - deltaA, 0);
          let calibratedMax = Math.max(dataMax + deltaA, 0);
          if (calibratedMin === 0) {
            calibratedMin = parseInt('-' + (dataMin / 2));
          }
          return [calibratedMin, calibratedMax]
        },
        plotLines: [{
          value: 0,
          color: 'red',
          dashStyle: 'solid',
          width: 1
        }]
      },

      series: [{
        borderWidth: 1,
        tooltip: {
          headerFormat: '',
          pointFormatter: function () {
            const { name, y } = this.options;
            let date = name.split(' ');
            return 'VFLOW: <span style="font-family:NunitoSans-Bold">' + formatVflow(y) +
              '<br/><span style="font-family: NunitoSans-Regular; font-size: 12px; font-weight: normal; color: #333; padding: 10px;">' +
              date[0] + '</span>'
          }
        },
        data: chartData,
        color: '#80cbc4',
        fillColor: '#80cbc438',
        lineWidth: 0.1,
        marker: {
          radius: 1.5
        },
      }]
    };

    const chartView =
      <>
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            options={options}
          />
        </div>
      </>

    return (
      <>
        <div className="p-0 heat-map-container">
          <div id={"synch-chart-container"}>
            {chartView}
          </div>
        </div>
      </>
    )
  }
}

export default HeatMap