import React, { Component } from "react";
import { connect } from "react-redux";
import { watchlistNameCheck } from "../utils/helperFunctions";
import ConfirmModal from "../layout/ConfirmModal";
import axios from 'axios'
import { BASE_URL } from "../utils/const";
import uuid from 'react-uuid';
import { useFlexLayout } from "react-table";

import {LinearProgress, Stack} from '@mui/material'

class SaveOrSubscribeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableButtons: false,
      name: this.props.transactionWatchlist?.watchlistname || '',
      showConfirmModal: false,
      notes: "",
      maxAllocation: '0',
      timingType: 'LONG_SHORT',
      managed: false,
      show: this.props.show,
      checkUnique: true,
      loading: false
    };
  }

  componentDidMount(){
    // console.log(this.props)
    this.setState({disableButtons: true, name: this.props.transactionWatchlist.watchlistname})
    this.checkUnique(this.state.name)
    // let checkUniqueOutput = this.checkUnique(this.state.name)
      // .then(() => {
        // console.log(checkUniqueOutput, typeof(checkUniqueOutput))
        // if(checkUniqueOutput === -1) this.setState({checkUnique: true})
        // if(checkUniqueOutput !== -1) this.setState({checkUnique: false})
        // this.setState({disableButtons: false})
      // })
    
  }

  componentDidUpdate(prevProps) {
    const { transactionWatchlist } = this.props;

    if (this.state.name === '' && transactionWatchlist && this.state.usePrevValue && !this.state.valueUpdated) {
      this.setState({
        name: transactionWatchlist?.watchlistname || '',
        notes: transactionWatchlist?.watchlistnotes || '',
        timingType: transactionWatchlist.timingtype || 'LONG_SHORT',
        maxAllocation: transactionWatchlist.maxallocation || '0'
      })
    }

    if (prevProps.watchlistname === '' && this.props.watchlistname === '' && !this.state.valueUpdated && this.state.name !== '') {
      if (transactionWatchlist === '' || transactionWatchlist === undefined) {
        this.setState({
          name: '',
          notes: '',
          timingType: 'LONG_SHORT',
          maxAllocation: '0',
          usePrevValue: false
        })
      }
    }

    if (transactionWatchlist !== '' && transactionWatchlist !== undefined) {
      if (this.state.name !== transactionWatchlist.watchlistname && !this.state.valueUpdated) {
        this.setState({
          name: transactionWatchlist.watchlistname,
          notes: transactionWatchlist.watchlistnotes || "",
          timingType: transactionWatchlist.timingtype || "LONG_SHORT",
          maxAllocation: transactionWatchlist.maxallocation?transactionWatchlist.maxallocation:'0'
        })
      }
    }
  }

  getUserWatchlists = async () => {
    const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
    const accessToken = localStorage.getItem("accessToken");
    let savedWatchlistNames = []
    let savedWatchlists = []
    let axiosConfig = {
        headers: {
        Authorization: `Bearer ${accessToken}`,
        idtoken: `${localStorage.getItem("idToken")}`,
        ContentType: 'application/json'
      }
    }
    let staticUuid = uuid()
    let data = {
      uuid: staticUuid,
      email: userEmail,
      useremail: userEmail,
    }
    await axios.post(`${BASE_URL}/signal/v1/portfolio/watchlist?uuid=${staticUuid}`, data, axiosConfig).then((res) => {
      // console.log("response", res)
      res.data.portfolios.forEach((watchlist, index) => {
        savedWatchlists.push(watchlist)
        savedWatchlistNames.push(watchlist.watchlistname)
      })
    }).catch((err) => console.log(err))
    this.setState({savedWatchlists: savedWatchlists})
    return savedWatchlistNames
  }

  checkUnique = async (name) => {
    // let savedWatchlists = await this.getUserWatchlists()
    this.setState({disabled: true, loading: true})
    let savedWatchlist = []
    savedWatchlist = await this.getUserWatchlists().then(this.setState({disabled: false}))
    // let { savedWatchlist } = this.props;
    // console.log(savedWatchlist, name)
    // let existWatchlist = this.props.koshaWatchlists.findIndex((watchlistName) => watchlistName === name)
    let existWatchlist = savedWatchlist.findIndex(
      // (watchlist) => watchlist.watchlistname.toLowerCase() === name.toLowerCase()
      (watchlist) => watchlist.toLowerCase() === name.toLowerCase()
    );
    // console.log(existWatchlist)

    if(existWatchlist === -1) this.setState({checkUnique: true})
    if(existWatchlist !== -1) this.setState({checkUnique: false})
    this.setState({disableButtons: false, loading: false})

    return existWatchlist;
  };

  afterWatchlistSaved = () => {
    this.setState({
      name: "",
      notes: "",
      disabled: false,
      timingType: 'LONG_SHORT',
      maxAllocation: '0',
      usePrevValue: true,
      valueUpdated: false
    });
  }

  handleChooseSave = () => {
    // console.log("user chose save")
    this.setState({disableButtons: true})
    this.props.onSave()
    setTimeout(() => {
      this.setState({disableButtons: false})
    },5000)
  }

  handleChooseSubscribe = async() => {
    this.setState({disableButtons: true})
    // let existWatchlist = await this.checkUnique(this.state.name.trim());
    // console.log(existWatchlist)

    // if (existWatchlist !== -1) {
    //   //returns kosha watchlist not user watchlist
    //   let watchlist = this.props.savedWatchlist[existWatchlist]
    //   // let watchlist = this.props.koshaWatchlists[existWatchlist]
    //   console.log("watchlists", watchlist)
    //   let hashkey = watchlist.hashkey;
    //   let managed = watchlist.managed;
    //   this.setState({
    //     showConfirmModal: true,
    //     show: false,
    //     hashkey,
    //     managed,
    //     watchlist,
    //     timingType: watchlist.timingType
    //   });
    //   setTimeout(() => {
    //     this.setState({disableButtons: false})
    //   },3000)
    // }else{
      // console.log("koshawatchlists", this.props.koshaWatchlists)
      let watchlist = this.props.koshaWatchlists.findIndex((watchlist) => watchlist.watchlistname.toLowerCase() === this.state.name.toLowerCase())
      // console.log(watchlist)
      this.props.onSubscribe(
        this.state.name,
        this.state.notes,
        "new",
        watchlist.maxallocation,
        watchlist.timingtype,
        watchlist.hashkey,
        this.state.name,
        false)
        setTimeout(() => {
          this.setState({disableButtons: false})
        },3000)
    // }
  }

  onSubscribeWatchlistAfterConfirm = async() => {
    let existWatchlist = await this.checkUnique(this.state.name.trim())
    let watchlist = this.props.koshaWatchlists[existWatchlist]
    // console.log(watchlist)
    this.props.onSubscribe(
      this.state.name,
      this.state.notes,
      "update",
      watchlist.maxAllocation,
      watchlist.timing,
      watchlist.hashkey,
      this.state.name,
      false, 
      )
      setTimeout(() => {
        this.setState({disableButtons: false})
      },3000)
  }

  render() {
    const {
      show,
      buttonName,
      koshaWatchlist,
      transactionWatchlist,
    } = this.props;

    // console.log(this.props.savedWatchlist)
    const { name, notes, showConfirmModal, usePrevValue, maxAllocation, allocError, timingError, timingType, disabled } = this.state;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";


    return (
      <>
        <div
            className={"modal fade bs-example-modal-sm " + modalClass}
            style={{ display: display }}
          >
            <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                <h4 className="modal-title symbols-modal-title">
                  Save Or Subscribe Watchlist
                  <button
                    disabled={disabled }
                    type="button"
                    onClick={this.props.onClose}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>

              <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
              <div className="password-modal-username">Would you like to Save or Subscribe to this Kosha Watchlist?</div>
                <div className="save-watchlist-form">
                  <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {this.state.loading && 
                        <div style={{width: '70%', color:"#3bc796", marginTop: '3rem'}}><LinearProgress color="inherit"/></div>}
                    {!this.state.loading && 
                      <>
                        <button
                          onClick={this.handleChooseSave}
                          // disabled={
                          //     (name === "") || buttonName.includes("Saving")
                          // }
                          disabled={this.state.disableButtons}
                          className="btn btn-outline waves-effect waves-light m-b-5 m-t-20 confirm-modal-btn"
                          >
                          Save Watchlist
                          {/* {buttonName.includes("Saving") && (
                              <i className="fa fa-spinner fa-spin" style={{marginLeft: "1rem"}}></i>
                          )} */}
                        </button>
                        <div className="tooltipx">
                          <button
                              onClick={this.handleChooseSubscribe}
                              // disabled={
                              //     (name === "") || buttonName.includes("Saving")
                              // }
                              disabled={this.state.disableButtons || !this.state.checkUnique}
                              className="btn btn-outline waves-effect waves-light m-b-5 m-t-20 confirm-modal-btn"
                              >
                              Subscribe to Watchlist
                              {/* {buttonName.includes("Saving") && (
                                  <i className="fa fa-spinner fa-spin" style={{marginLeft: "1rem"}}></i>
                              )} */}
                          </button>
                          {!this.state.checkUnique && <div className="tooltiptext-div">A watchlist with this name already exists. Please delete the existing watchlist to select this option.</div>}
                        </div>
                      </>}
                    {/* <button
                        onClick={this.handleChooseSubscribe}
                        disabled={
                            (name === "") || buttonName.includes("Saving")
                        }
                        className="btn btn-outline waves-effect waves-light m-b-5 m-t-20"
                        >
                        Subscribe to Watchlist 
                        {buttonName.includes("Saving") && (
                            <i className="fa fa-spinner fa-spin" style={{marginLeft: "1rem"}}></i>
                        )}
                    </button> */}
                  </span>
                  <div className="clearfix"></div>
                </div> 
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          style={{ display: display }}
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                <h4 className="modal-title symbols-modal-title">
                  Save Or Subscribe Watchlist
                  <button
                    disabled={disabled }
                    type="button"
                    onClick={this.close}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>

              <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
              <div className="password-modal-username">Save or Subscribe to this Kosha Watchlist?</div>
                <div className="login-form save-watchlist-form">
                    <button
                        onClick={this.handleChooseSave}
                        disabled={
                            (name === "") || buttonName.includes("Saving")
                        }
                        className="btn btn-outline waves-effect waves-light m-b-5 m-t-20"
                        >
                        Save Watchlist
                        {buttonName.includes("Saving") && (
                            <i className="fa fa-spinner fa-spin" style={{marginLeft: "1rem"}}></i>
                        )}
                    </button>
                    <button
                        onClick={this.handleChooseSubscribe}
                        disabled={
                            (name === "") || buttonName.includes("Saving")
                        }
                        className="btn btn-outline waves-effect waves-light m-b-5 m-t-20"
                        >
                        Subscribe to Watchlist 
                        {buttonName.includes("Saving") && (
                            <i className="fa fa-spinner fa-spin" style={{marginLeft: "1rem"}}></i>
                        )}
                    </button>
                  <div className="clearfix"></div>
                </div> 
              </div>
            </div>
          </div>
                        </div>*/}
        <ConfirmModal
          show={showConfirmModal}
          confirm={() => {
            this.setState({ showConfirmModal: false, show: true });
            this.onSubscribeWatchlistAfterConfirm();
          }}
          saveWatchlistCheck={true}
          selectedWatchlistName ={this.state.name}
          cancel={() => {
            this.setState({ showConfirmModal: false, show: true, disableButtons: false });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  koshaWatchlists: state.PortfolioReducer.koshaWatchlists
});

export default connect(mapStateToProps)(SaveOrSubscribeModal);