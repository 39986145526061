import React, { Component } from "react";
import { CHART_TYPE } from "../../utils/const";
import { Link } from "react-router-dom";

import {
  fetchAsset,
  fetchChartData,
  getSupportedSymbols,
  updateValues,
  updateCompareValues,
  fetchCompareAsset,
  fetchTimingData,
  fetchPerformanceData,
  updateAssets,
  updateBenchmarkValues,
} from "../../actions/AssetAction";

import { getAboutData } from "../../actions/AboutAction";

import "./Asset.css";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import ErrorModal from "../../layout/ErrorModal";
import ApiModal from "../../layout/ApiModal";
import AssetList from "./AssetList";
import SynchChart from "../charts/SynchChart";
import { connect } from "react-redux";

import { asciiValueCheck, sortArray } from "../../utils/helperFunctions";
import AutocompleteField from "../AutocompleteField";
import AnalyzedData from "./AnalyzedData";
import RangeSelectorModal from "../../layout/RangeSelectorModal";
import ChartTabs from "../charts/Tabs";
import { ExportToCsv } from "export-to-csv";
import firebaseObj from "../../utils/firebase";
import packageJson from "../../../package.json";
import uuid from "react-uuid";
import { event } from "jquery";
import ForbiddenErrorModal from "../../layout/ForbiddenErrorModal";

const analytics = firebaseObj.analytics();

class Asset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      assetChartData: null,
      familyChartData: null,
      search: "",
      loading: false,
      error: null,
      subasset: null,
      apiModalShow: false,
      apiDetails: [],
      apiResponse: [],
      symbols: [],
      symbolLoading: true,
      compareSymbol: "SPY",
      familyData: [],
      showViewLog: true,
      showRangeModal: false,
      smaRange: 50,
      showChartModal: false,
      showbreadCrumb: true,
      holdings: [],
      prevRoute: "",
    };
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    // console.log("componentDidMount -> ASSET PROPS:", this.props)
    const { loginDetails } = this.props.about;

    let tabCount = localStorage.getItem("tab");
    let autoLogin = localStorage.getItem("autoLogin");
    let reloadCheck = window.sessionStorage.getItem("reload");
    let compareSymbol = localStorage.getItem("compareSymbol");

    if (
      loginDetails === null ||
      Object.keys(loginDetails).length === 0 ||
      ((tabCount !== "many" || autoLogin !== "true") && reloadCheck !== "true")
    ) {
      this.props.history.push("/");
    } else {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version,
      });
      analytics.logEvent("Analyze Asset Screen");

      window.scroll(0, 0);
      this._mounted = true;

      let prevRoute = localStorage.getItem("currentRoute");
      localStorage.setItem("currentRoute", "asset");
      window.sessionStorage.setItem("token", loginDetails.apikey);

      if (!prevRoute) {
        prevRoute = "asset";
      }

      if (this.props.supportedSymbols?.length === 0) {
        this.props.getSupportedSymbols(
          () => {
            const { supportedSymbols } = this.props;

            let sortedSymbols = supportedSymbols.sort(sortArray);

            this.setState(
              {
                symbols: sortedSymbols,
                symbolLoading: false,
                prevRoute,
              },
              () => {
                this.setCurrentData(this.state.prevRoute, compareSymbol);
              }
            );
          },
          (err, request) => {
            this.setErrorState(err, request);
          }
        );
      } else {
        let sortedSymbols = this.props.supportedSymbols?.sort(sortArray);
        this.setState(
          {
            symbols: sortedSymbols,
            symbolLoading: false,
            prevRoute,
          },
          () => {
            this.setCurrentData(this.state.prevRoute, compareSymbol);
          }
        );
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.location.pathname !== prevProps.location.pathname){
      // console.log("component did update called, onItemSelect")
      let newAsset = this.props.location.pathname.replace('/asset/', '')
      this.onItemSelect(newAsset)
    }
  }

  setCurrentData = (prevRoute, compareSymbol) => {
    if (this.props.assetChartData !== null && prevRoute !== "home") {
      const { assetChartData, familyChartData, familyData, data } = this.props;
      let search = data.analyzed.assetdata.symbol;

      if (prevRoute !== "fullView") {
        this.setState(
          {
            prevRoute,
          },
          () => {
            this.props.updateAssets(
              () => {},
              () => {}
            );
          }
        );
      } else {
        this.setState({
          data,
          assetChartData,
          familyChartData,
          familyData,
          search,
          compareSymbol: familyData.symbol,
          prevRoute,
        });
      }
    } else {
      const { familyData, data } = this.props;
      if (data && familyData) {
        this.setState({
          prevRoute,
        });
      }
    }

    if (this.props.location.pathname.includes("watchlist")) {
      localStorage.setItem("currentRoute", "backToWatchlist");
    }

    if (this.props.match.params.subasset && prevRoute !== "fullView") {
      let subasset = this.props.match.params.subasset;
      let assetName = subasset.split("?");

      let parentAsset = this.props.match.params.parentasset;
      // console.log(parentAsset)
      let prevParent = localStorage.getItem("parentAsset");

      if (parentAsset !== prevParent && parentAsset !== undefined) {
        const { symbols } = this.state;
        let index = symbols.filter((asset) => asset.symbol === parentAsset);

        if (index.length !== 0) {
          // console.log("there is a parentAsset", parentAsset)
          localStorage.setItem("parentAsset", parentAsset);
          this.setState(
            {
              subasset: assetName[0],
              search: assetName[0],
              compareSymbol: compareSymbol || "SPY",
            },
            () => {
              window.scroll(0, 0);
              this.analyzeAsset(true);
            }
          );
        } else {
          localStorage.setItem("parentAsset", "");
          // console.log("no parentAsset", parentAsset)
          this.setState({
            error: "This Parent Symbol is not supported!",
            showViewLog: false,
            loading: false,
            data: null,
            assetChartData: null,
            familyChartData: null,
            request: null,
            showbreadCrumb: false,
          });
        }
      } else {
        this.setState(
          {
            subasset: assetName[0],
            search: assetName[0],
            compareSymbol: compareSymbol || "SPY",
          },
          () => {
            window.scroll(0, 0);
            // console.log("calling analyze asset after setCurrentData")
            this.analyzeAsset(true);
          }
        );
      }
    }
  };

  onRangeChange = (smaRange) => {
    this.setState({
      smaRange,
      showRangeModal: false,
    });
  };

  showRangeModal = () => {
    this.setState({
      showRangeModal: true,
    });
  };

  handleChange = (e) => {
    let value = e.target.value.toUpperCase();

    this.setState({
      search: value,
    });
  };

  goToPortfolio = () => {
    this.props.history.push("/watchlist?uuid=" + uuid());
  };

  goToAssetPage = () => {
    //remounting page causes duplicate requests
    let oldLocation = this.props.history.location.pathname.split("/")
    if(oldLocation[2] === this.state.search){
      // console.log("old location matches search -> not remounting?")
      this.analyzeAsset(true);
    } else{
      // console.log("old location does not matche search -> remounting?")
      localStorage.setItem("compareSymbol", this.state.compareSymbol);
      this.props.history.push("/asset/" + this.state.search + "?uuid=" + uuid());

      let location = this.props.history.location.pathname.split("/");
      // console.log("location[2] and this.state.search", location[2], this.state.search)
      if (location[2] === this.state.search) {
        window.scroll(0, 0);
      }
    }
    // console.log("Analyze button clicked?")
    // localStorage.setItem("compareSymbol", this.state.compareSymbol);
    // this.props.history.push("/asset/" + this.state.search + "?uuid=" + uuid());

    // let location = this.props.history.location.pathname.split("/");
    // console.log("location[2] and this.state.search", location[2], this.state.search)
    // if (location[2] === this.state.search) {
    //   window.scroll(0, 0);
    //   // this.analyzeAsset(true);
    // }else{
    //   // this.analyzeAsset(true);
    // }
    // this.analyzeAsset(true);
  };

  analyzeAsset = (check) => {
    // console.log('analyze asset called', check)
    const history = this.props.history;
    const { loginDetails } = this.props.about;
    const { supportedSymbols } = this.props;

    if (!check) {
      // console.log("check failed", history)
      if (history.location.state && history.location.state.category) {
        let state = { ...history.location.state };
        delete state.category;
        history.replace({ ...history.location, state });
      }
    }

    let showbreadCrumb = this.state.showbreadCrumb;
    let subasset = this.props.match.params.subasset;
    let assetName = subasset ? subasset.split("?") : "";

    let prevCompareSymbol = this.state.compareSymbol;
    localStorage.setItem("prevCompare", prevCompareSymbol);

    if (
      this.props.match.params.parentasset &&
      this.state.search !== assetName[0]
    ) {
      showbreadCrumb = false;
    }

    let index = supportedSymbols.findIndex(
      (value) => value && value.symbol === this.state.search
    );

    if (index === -1) {
      this.setState({
        error: "This Symbol is not supported!",
        showViewLog: false,
        loading: false,
        data: null,
        assetChartData: null,
        familyChartData: null,
        request: null,
        showbreadCrumb,
      });
    } else {
      this.setState({
        loading: true,
        data: null,
        assetChartData: null,
        familyChartData: null,
        request: null,
        showbreadCrumb,
      });
      // console.log("state when analyzing: ", this.state)

      this.props.fetchAsset(
        this.state.search
      ).then((result) => {
        // console.log("Promise works? ", result)
        let { data, assetRequest } = this.props;
        // console.log("data", data)
        data = result
        // console.log("fetch asset called, props -> ", this.props)
        // console.log("props data when analyzing", this.props.data.analyzed, this.props.data.analyzed.assetdata, result.analyzed.assetdata)

        analytics.setUserProperties({
          email: loginDetails.email,
          App_Version: packageJson.version,
        });
        analytics.logEvent("Analyze Asset", {
          asset: this.state.search,
          benchmark: this.state.compareSymbol,
        });

        if (data.analyzed && data.analyzed.assetdata.code === 0) {
          if (this._mounted) {
            let apiDetails = [];
            let apiResponse = [];
            apiDetails.push(assetRequest);
            apiResponse.push(data);

            this.setState({
              data,
              apiDetails,
              apiResponse,
            });
          }

          if (data.analyzed && data.analyzed.assetdata.vsip) {
            this.props.fetchCompareAsset(
              this.state.compareSymbol,
              () => {
                let { familyData, familyRequest } = this.props;
                this.setState({
                  familyData,
                });

                this.props.fetchChartData(
                  [{ vsip: data.analyzed.assetdata.vsip }],
                  CHART_TYPE.ASSET_CHART,
                  () => {
                    if (this._mounted) {
                      let apiDetails = this.state.apiDetails;
                      let apiResponse = this.state.apiResponse;
                      const {
                        assetChartRequest,
                        assetChartData,
                        timingData,
                        performanceData,
                      } = this.props;

                      apiDetails.push(familyRequest, assetChartRequest);
                      apiResponse.push(familyData, assetChartData);

                      let holdings = [
                        { symbol: this.state.search, allocation: "100" },
                      ];
                      if (!timingData || !performanceData) {
                        this.props.fetchTimingData(
                          holdings,
                          "asset",
                          () => {
                            if (this._mounted) {
                              const {
                                timingData,
                                timingRequest,
                              } = this.props;
                              let apiDetails = this.state.apiDetails;
                              let apiResponse = this.state.apiResponse;

                              apiDetails.push(timingRequest);
                              apiResponse.push(timingData);

                              this.props.fetchPerformanceData(
                                holdings,
                                "asset",
                                () => {
                                  if (this._mounted) {
                                    const {
                                      performanceData,
                                      performanceRequest,
                                    } = this.props;

                                    apiDetails.push(performanceRequest);
                                    apiResponse.push(performanceData);

                                    this.setState({
                                      holdings,
                                      loading: false,
                                      apiDetails,
                                      apiResponse,
                                    });
                                  }
                                },
                                (err, request) => {
                                  this.setErrorState(err, request);
                                }
                              );
                            }
                          },
                          (err, request) => {
                            this.setErrorState(err, request);
                          }
                        );
                      }

                      this.setState({
                        assetChartData,
                        apiDetails,
                        apiResponse,
                      });
                    }

                    this.props.fetchChartData(
                      [{ vsip: this.props.familyData.vsip }],
                      CHART_TYPE.FAMILY_CHART,
                      () => {
                        if (this._mounted) {
                          let apiDetails = this.state.apiDetails;
                          let apiResponse = this.state.apiResponse;
                          const {
                            familyChartRequest,
                            familyChartData,
                          } = this.props;

                          apiDetails.push(familyChartRequest);
                          apiResponse.push(familyChartData);

                          this.setState(
                            {
                              familyChartData,
                              error: null,
                              apiDetails,
                              apiResponse,
                              loading: false,
                            },
                            () => {
                              if (this.state.prevRoute !== "home") {
                                this.setState({
                                  loading: false,
                                });
                              }
                            }
                          );
                        }
                      },
                      (err, request) => {
                        this.setErrorState(err, request);
                      }
                    );
                  },
                  (err, request) => {
                    this.setErrorState(err, request);
                  }
                );
              },
              (err, request) => {
                this.setErrorState(err, request);
              }
            );
          }
        } else if (data.analyzed) {
          if (this._mounted) {
            let apiDetails = [];
            let apiResponse = [];
            apiDetails.push(this.props.assetRequest);
            apiResponse.push(this.props.data);

            this.setState({
              loading: false,
              error: data.analyzed.assetdata.message,
              data: null,
              assetChartData: null,
              familyChartData: null,
              apiDetails,
              apiResponse,
            });
          }
        }
      })
      .catch((err, req) => {
        console.log(err.message)
        this.setErrorState(err, req);
      });
    }
  };

  setErrorState = (err, request) => {
    let apiDetails = [];
    let apiResponse = [];
    let error = err.message ? err.message : "Error Occured !!";
    apiDetails.push(request);
    apiResponse.push({ error });

    if (this._mounted) {
      this.setState({
        loading: false,
        error,
        data: null,
        assetChartData: null,
        familyChartData: null,
        apiDetails,
        apiResponse,
      });
    }
  };

  clearField = () => {
    this.setState({
      search: "",
    });
  };

  closeAlert = () => {
    this.setState({
      error: null,
    });
  };

  handleKeyPress = (type, e) => {
    let entered = e.target.value.toUpperCase();
    if (e.target.value && e.key === "Enter") {
      let symbols = this.state.symbols;
      let index = symbols.findIndex(
        (value) => value && value.symbol === entered
      );

      if (index === -1) {
        this.setState({
          error: "This Symbol is not supported!",
          showViewLog: false,
          [type]: type === "compareSymbol" ? "SPY" : "",
        });
      } else {
        window.scroll(0, 0);
      }
    }
    if (e.target.value && e.key === "Backspace") {
      this.setState({
        [type]: entered,
      });
    }
  };

  onBlur = (e) => {
    if (e.target.value) {
      let symbols = this.state.symbols;
      let entered = e.target.value.toUpperCase();
      let index = symbols.findIndex(
        (value) => value && value.symbol === entered
      );
      let type = e.target.id;

      if (index === -1) {
        this.setState({
          error: "This Symbol is not supported!",
          showViewLog: false,
          [type]: type === "compareSymbol" ? "SPY" : "",
        });
      }
    }
  };

  getApiInfo = () => {
    this.setState({
      apiModalShow: true,
    });
  };

  onApiModalClose = () => {
    this.setState({
      apiModalShow: false,
    });
  };

  onErrorClose = () => {
    this.setState({
      error: false,
      showViewLog: true,
    });
  };

  viewLog = () => {
    this.setState({
      apiModalShow: true,
      error: false,
    });
  };

  onBackClick = () => {
    this.setState({
      loading: true,
    });

    let prevState = this.props.history.location.state;

    if (prevState) {
      if (prevState.assetData)
        this.props.history.push({
          pathname: "/subsubcategoryAsset",
          search: "?subsubCategoryAsset=" + prevState.assetData.symbol,
          state: prevState,
        });
      else if (prevState.subsubcategory)
        this.props.history.push({
          pathname: "/subsubcategory",
          search: "?subsubcategory=" + prevState.subsubcategory.symbol,
          state: prevState,
        });
      else if (prevState.subcategory)
        this.props.history.push({
          pathname: "/subcategory",
          search: "?subcategory=" + prevState.subcategory.symbol,
          state: prevState,
        });
      else if (prevState.category)
        this.props.history.push({
          pathname: "/category",
          search: "?symbol=" + prevState.category.symbol,
          state: prevState,
        });
    }
  };

  getParentName = () => {
    let prevState = this.props.history.location.state;

    if (prevState) {
      if (prevState.assetData) return prevState.assetData.symbol;
      else if (prevState.subsubcategory) return prevState.subsubcategory.symbol;
      else if (prevState.subcategory) return prevState.subcategory.symbol;
      else if (prevState.category) return prevState.category.symbol;
    }
  };

  onSearchChange = (e) => {
    this.setState({
      data: null,
      assetChartData: null,
      familyChartData: null,
      apiModalShow: false,
      apiResponse: [],
      familyData: [],
      showViewLog: true,
      showRangeModal: false,
      smaRange: 50,
    })
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let errorDiv = document.getElementById("error-div");
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }
    if (!valueCheck) {
      if (errorDiv) errorDiv.style.visibility = "hidden";
      if (!numberCheck && (asciiCheck || value === "")) {
        this.setState({
          search: value,
          prevRoute: "asset",
        });
      }
    } else {
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    }
  };

  onItemSelect = (search) => {
    // console.log("onItemSelect ", search, this.state.compareSymbol)
    this.setState(
      {
        search,
      },
      () => {
        if (this.state.compareSymbol !== "") this.goToAssetPage();
      }
    );
  };

  onCompareSearchChange = (e) => {
    this.setState({
      data: null,
      assetChartData: null,
      familyChartData: null,
      apiModalShow: false,
      apiResponse: [],
      familyData: [],
      showViewLog: true,
      showRangeModal: false,
      smaRange: 50,
    })
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let errorDiv = document.getElementById("error-benchmark-div");
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }
    if (!valueCheck) {
      if (errorDiv) errorDiv.style.visibility = "hidden";
      if (!numberCheck && (asciiCheck || value === "")) {
      this.setState({
        compareSymbol: value,
      });
    }
    } else {
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    }
  };

  onCompareItemSelect = (compareSymbol) => {
    // console.log("onCompareItemSelect ", compareSymbol)
    this.setState(
      {
        compareSymbol,
      },
      () => {
        if (this.state.search !== "") {
          this.goToAssetPage();
        }
      }
    );
  };

  showChartInModal = () => {
    this.props.history.push({
      pathname: "/chartView",
      state: {
        title: this.state.data.analyzed.assetdata.symbol,
        assetChartData: this.state.assetChartData,
        smaRange: this.state.smaRange,
        familyChartData: this.state.familyChartData,
      },
      search: "uuid=" + uuid(),
    });
  };

  showFamilyChart = () => {
    this.props.history.push({
      pathname: "/chartView",
      state: {
        title: this.state.familyData.symbol,
        assetChartData: this.state.familyChartData,
        smaRange: this.state.smaRange,
        familyChartData: this.state.assetChartData,
      },
      search: "uuid=" + uuid(),
    });
  };

  showTimingChart = () => {
    this.props.history.push({
      pathname: "/chartView",
      state: {
        title: "Timing Model Performance",
        assetChartData: this.state.data.analyzed.assetdata.timings,
        chartName: "timingModel",
      },
      search: "uuid=" + uuid(),
    });
  };

  onClose = () => {
    this.setState({
      showChartModal: false,
      showRangeModal: false,
    });
  };

  exportCsv = () => {
    const exportOptions = {
      fieldSeparator: ",",
      quoteStrings: "",
      decimalSeparator: ".",
      filename: this.state.search,
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        "Asset",
        "Weight",
        "Timing",
        // "Risk",
        // "Risk Profile",
        "VVOL",
        "VFLOW",
        "Price Movement Up",
        "Price Movement Down",
        "Std Deviation Up",
        "Std. Deviation Down",
      ],
    };

    let assets = this.state.data.analyzed.listofasset;
    let data = [];

    assets.forEach((asset) => {
      data.push({
        asset: asset.data.symbol,
        allocation: asset.allocation,
        timing: asset.data.timing || "N/A",
        // risk: asset.data.riskprofile || "N/A",
        // riskProfile: asset.data.riskprofilenumber || "N/A",
        vvol: asset.data.vvol || "N/A",
        vflow: asset.data.vflow || "N/A",
        pricemovementup: asset.data.pricemovementup || "N/A",
        pricemovementdown: asset.data.pricemovementdown || "N/A",
        stddevup: asset.data.stddevup || "N/A",
        stddevdown: asset.data.stddevdown || "N/A",
      });
    });

    const csvExporter = new ExportToCsv(exportOptions);
    csvExporter.generateCsv(data);
  };

  updateValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.performanceData.return;

    this.props.updateValues(
      newReturn,
      newDrawdown,
      newRecovery,
      newSharpe,
      "asset",
      () => {
        if (prevReturn !== newReturn) this.forceUpdate();
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  updateCompareValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.timingData.return;
    this.props.updateCompareValues(
      newReturn,
      newDrawdown,
      newRecovery,
      newSharpe,
      "asset",
      () => {
        if (prevReturn !== newReturn) this.forceUpdate();
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  updateBenchmarkValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.familyChartData.performance.return;
    this.props.updateBenchmarkValues(
      newReturn,
      newDrawdown,
      newRecovery,
      newSharpe,
      "asset",
      () => {
        if (prevReturn !== newReturn) this.forceUpdate();
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  onDateChange = () => {
    // console.log("date change");
  };

  render() {
    const {
      loading,
      data,
      assetChartData,
      familyChartData,
      search,
      error,
      symbolLoading,
      showViewLog,
      showbreadCrumb,
      subasset,
      apiDetails,
      apiModalShow,
      apiResponse,
      symbols,
      compareSymbol,
      familyData,
      showRangeModal,
      smaRange,
      holdings,
    } = this.state;

    const { timingData, performanceData } = this.props;
    console.log("render function called")

    const price =
      assetChartData &&
      assetChartData.closingprice[assetChartData.closingprice.length - 1];
    const familyPrice =
      familyChartData &&
      familyChartData.closingprice[familyChartData.closingprice?.length - 1];
    let poiData = assetChartData && assetChartData.poi;
    let familyPoiData = familyChartData && familyChartData.poi;

    if (
      poiData &&
      poiData.length !== 0 &&
      assetChartData.closingprice[0].date !== poiData[0].date
    )
      poiData.reverse();
    if (
      familyPoiData &&
      familyPoiData.length !== 0 &&
      familyChartData.closingprice[0].date !== familyPoiData[0].date
    )
      familyPoiData.reverse();

    const path = this.props.location.pathname.includes("watchlist")
      ? "watchlist"
      : "asset";

    let dataBoxView =
      data &&
      data.analyzed &&
      data.analyzed.assetdata.code === 0 &&
      familyData &&
      familyData.symbol ? (
        <>
          <AnalyzedData
            assetdata={data.analyzed.assetdata}
            price={price}
            familyPrice={familyPrice}
            familydata={familyData}
            currentRoute={"asset"}
            displayChartData={true}
          />
        </>
      ) : loading ? (
        <div className="card-loader card-loader--tabs h-125 m-b-30"></div>
      ) : (
        ""
      );

    let assetChartView =
      assetChartData && data && familyChartData ? (
        <SynchChart
          key="asset"
          id={"assetChartId"}
          title={data.analyzed.assetdata.symbol}
          data={assetChartData}
          smaRange={smaRange}
          showRangeModal={this.showRangeModal}
          showChartInModal={this.showChartInModal}
          showViewButton={true}
          familyChartData={familyChartData}
          onDateChange={this.onDateChange.bind(this)}
          poiData={poiData}
        />
      ) : loading ? (
        <div className="card-loader card-loader--tabs m-b-30 h-565"></div>
      ) : (
        ""
      );

    let familyChartView =
      familyChartData && familyData ? (
        <SynchChart
          key="family"
          id={"familyChartId"}
          title={familyData.symbol}
          data={familyChartData}
          smaRange={smaRange}
          showRangeModal={this.showRangeModal}
          showChartInModal={this.showFamilyChart}
          showViewButton={true}
          onDateChange={this.onDateChange.bind(this)}
          poiData={familyPoiData}
        />
      ) : loading ? (
        <div className="card-loader card-loader--tabs m-b-30 h-565"></div>
      ) : (
        ""
      );

    let listView =
      data &&
      data.analyzed &&
      data.analyzed.listofasset &&
      data.analyzed.listofasset.length > 0 ? (
        <AssetList
          path={path}
          parentAsset={data.analyzed.assetdata.symbol}
          data={data.analyzed.listofasset.map((itm) => ({
            ...itm.data,
            allocation: itm.allocation,
          }))}
          cols={data.analyzed.listassetvisiblecols}
          exportCsv={this.exportCsv}
        />
      ) : loading ? (
        <div className="card-loader card-loader--tabs m-b-10"></div>
      ) : (
        ""
      );

    let dataView = (
      <>
        {dataBoxView}
        <div className="row">
          <div className="col-md-6 col-sm-12">{assetChartView}</div>
          <div className="col-md-6 col-sm-12">{familyChartView}</div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {assetChartData && timingData && performanceData && !loading ? (
              timingData.timingsdata.length !== 0 &&
              performanceData.percentagegainsdata.length !== 0 &&
              performanceData.percentagegainsdata.length === 2 && (

                <ChartTabs
                  //timing, performance, chartdata
                  performanceData={performanceData}
                  assetSymbol={data.analyzed.assetdata.symbol}
                  holdings={holdings}
                  timingData={timingData}
                  showViewButton={true}
                  showMultiSelect={false}
                  singleAssetPerformance={null}
                  singleAssetTiming={null}
                  singleLoading={false}
                  currentRoute="asset"
                  // updateValues={this.updateValues.bind(this)}
                  // updateCompareValues={this.updateCompareValues.bind(this)}
                  // updateBenchmarkValues={this.updateBenchmarkValues.bind(this)}
                  familyChartData={familyChartData}
                  benchmark={familyData.symbol}
                  selectedAssets={search}
                />
              )
            ) : loading ? (
              <div className="card-loader card-loader--tabs m-b-10"></div>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 asset-holdings-table">
            {listView}
          </div>
        </div>
      </>
    );

    let titleView = !(
      this.props.match.params.parentasset && this.props.match.params.subasset
    ) ? (
      data ? (
        this.props.history.location.state &&
        this.props.history.location.state.category ? (
          <>
            <ol className="breadcrumb p-0 m-b-0">
              <li className="page-title">
                <div
                  className="category-click"
                  onClick={this.onBackClick.bind(this)}
                >
                  {this.getParentName()}
                </div>
              </li>
              <li className="active page-title text-white">
                {data.analyzed.assetdata.symbol}
              </li>
            </ol>
          </>
        ) : null
      ) : null
    ) : (
      <>
        {showbreadCrumb && (
          <ol className="breadcrumb p-0 m-b-0">
            <li className="page-title">
              <Link to={`/${path}/` + this.props.match.params.parentasset}>
                {this.props.match.params.parentasset}
              </Link>
            </li>
            <li className="active page-title text-white">{subasset}</li>
          </ol>
        )}
      </>
    );

    let errorView =
      !loading && error ? (
        <>
          <ErrorModal
            show={true}
            message={error}
            viewLog={this.viewLog}
            showViewLog={showViewLog}
            onClose={this.onErrorClose}
          />
        </>
      ) : (
        ""
      );

    return (
      <>
        <Header history={this.props.history} />
        <div id="page-wrapper" className="m-l-0">
          <div className="container">
            <div className="row p-t-10 p-b-10 b-0">
              {(data || loading) && (
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  {data && <>{titleView}</>}
                  {this.props.location.pathname.includes("watchlist") ? (
                    <div className="analyze-asset-container-start m-t-10">
                      <button
                        onClick={() => {
                          this.props.history.push("/watchlist?uuid=" + uuid());
                        }}
                        className="btn"
                      >
                        Back to Watchlist
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </div>

            <div className="white-box analyze-asset-white-box">
              {!symbolLoading ? (
                <div className="row portfolio-container">
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <h4 className="page-title">Analyze Asset</h4>
                    <h3 className="box-subtitle m-b-5">Example: AAPL or XLF</h3>
                  </div>
                  <div
                    className="col-md-3 col-sm-4 col-xs-4"
                    id="autocomplete-input"
                  >
                    <h5 className="section-list-item">Asset Symbol</h5>
                    <AutocompleteField
                      wrapperStyle={{ marginBottom: "10px", marginTop: "10px" }}
                      placeholder="Enter Asset Symbol"
                      onSearchChange={this.onSearchChange}
                      onItemSelect={this.onItemSelect}
                      symbols={symbols}
                      search={search}
                      onKeyUp={this.handleKeyPress.bind(this, "search")}
                      onBlur={this.onBlur}
                      id="search"
                      onPaste={(event) => {
                        event.preventDefault();
                      }}
                      onDrop={(event) => {
                        event.preventDefault();
                      }}
                      disabled={loading}
                    />
                    {price && familyPrice && (
                      <h5 className="cp-heading">
                        Closing Price:
                        <span className="cp-text"> {price.close}</span>
                      </h5>
                    )}

                    <div className="symbol-data-div">
                      <span className="tooltiptext" id="error-div">
                        Maximum length is 6 characters.
                      </span>
                    </div>
                  </div>

                  <div
                    className="col-md-3 col-sm-4 col-xs-4"
                    id="autocomplete-input"
                  >
                    <h5 className="section-list-item">Benchmark</h5>
                    <AutocompleteField
                      wrapperStyle={{ marginBottom: "10px", marginTop: "10px" }}
                      placeholder="Enter Benchmark Symbol"
                      onSearchChange={this.onCompareSearchChange}
                      onItemSelect={this.onCompareItemSelect}
                      symbols={symbols}
                      search={compareSymbol}
                      onKeyUp={this.handleKeyPress.bind(this, "compareSymbol")}
                      onBlur={this.onBlur}
                      id="compareSymbol"
                      onPaste={(event) => {
                        event.preventDefault();
                      }}
                      onDrop={(event) => {
                        event.preventDefault();
                      }}
                      disabled={loading}
                    />
                    {price && familyPrice && (
                      <h5 className="cp-heading">
                        Closing Price:
                        <span className="cp-text"> {familyPrice.close}</span>
                      </h5>
                    )}
                    <div className="symbol-data-div">
                      <span className="tooltiptext" id="error-benchmark-div">
                      Maximum length is 6 characters.
                      </span>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-4 col-xs-4">
                    <h5 className="section-list-item empty-item"> </h5>
                    <button
                      onClick={() => this.goToAssetPage()}
                      disabled={search === "" || loading}
                      className="btn btn-outline waves-effect waves-light m-b-5 m-t-10 analyze-portfolio-btn"
                    >
                      Analyze{" "}
                      {loading && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="card-loader card-loader--tabs h-125 m-b-30"></div>
              )}
            </div>

            {errorView}

            {dataView}
          </div>
          <Footer onClick={this.getApiInfo} />

          <ApiModal
            show={apiModalShow}
            apiDetails={apiDetails}
            onClose={this.onApiModalClose}
            apiResponse={apiResponse}
            history={this.props.history}
            screen="asset"
          />
          <ForbiddenErrorModal
          history={this.props.history}/>

          <RangeSelectorModal
            show={showRangeModal}
            period={smaRange}
            onSave={this.onRangeChange}
            onClose={this.onClose}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.AssetReducer.asset,
  assetRequest: state.AssetReducer.assetRequest,
  assetChartData: state.AssetReducer.assetChartData,
  assetChartRequest: state.AssetReducer.assetChartRequest,
  familyChartRequest: state.AssetReducer.familyChartRequest,
  familyChartData: state.AssetReducer.familyChartData,
  supportedSymbols: state.AssetReducer.supportedSymbols,
  supportedSymbolsRequest: state.AssetReducer.supportedSymbolsRequest,
  familyData: state.AssetReducer.familyData,
  familyRequest: state.AssetReducer.familyRequest,
  about: state.AboutReducer,
  timingData: state.AssetReducer.timingData,
  performanceData: state.AssetReducer.performanceData,
  timingRequest: state.AssetReducer.timingRequest,
  performanceRequest: state.AssetReducer.performanceRequest,
});

export default connect(mapStateToProps, {
  fetchAsset,
  fetchChartData,
  getSupportedSymbols,
  fetchCompareAsset,
  fetchPerformanceData,
  fetchTimingData,
  updateAssets,
  getAboutData,
  updateValues,
  updateCompareValues,
  updateBenchmarkValues,
})(Asset);
