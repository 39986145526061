import React, { Component } from "react";
import { connect } from "react-redux";
import {
    RadioGroup,
    ReversedRadioButton,
} from "react-radio-buttons";

import {
    getTransactionSimulation,
    getTransactionSimulationSummary,
} from "../actions/WatchlistTimingsAction";
import Form from "react-bootstrap/Form";

class TransactionsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            loading: false,
            timingType: "LONG_SHORT",
            maxAllocation: "0",
            allocError: "",
            timingError: "",
            valueUpdated: false
        };
    }

    componentDidUpdate(prevProps) {
        const { transactionWatchlist } = this.props;

        if (transactionWatchlist && !this.state.valueUpdated && this.state.maxAllocation !== transactionWatchlist.maxallocation) {
            this.setState({
                maxAllocation: transactionWatchlist.maxallocation,
                timingType: transactionWatchlist.timingtype
            })
        }
    }

    close = () => {
        if (this.props.cancel) {
            this.props.cancel();
        }
        this.setState({
            show: false,
            valueUpdated: false
        });
    };

    handleChange = (e) => {
        this.setState({
            maxAllocation: e.target.value,
            allocError: "",
            valueUpdated: true
        });
    };

    onValueSubmit = () => {
        const { maxAllocation, timingType } = this.state;
        const {
            holdings,
            benchmark,
            transactionWatchlist,
            bucketLoading,
        } = this.props;

        if (maxAllocation === "" || maxAllocation < 0 || maxAllocation > 100) {
            this.setState({
                allocError:
                    "Please enter a Max Allocation amount as a percentage between 0 and 100.",
            });
            return false;
        }

        if (timingType === "") {
            this.setState({
                timingError: "Please select a Timing Type.",
            });
            return false;
        }

        if (!bucketLoading) {
            this.setState({
                loading: true,
            });

            holdings.map((asset) => {
                asset.symbolaction = "A";
            });

            this.props.getTransactionSimulation(
                maxAllocation,
                timingType,
                holdings,
                benchmark,
                transactionWatchlist,
                (result) => {
                    this.props.getTransactionSimulationSummary(
                        maxAllocation,
                        timingType,
                        holdings,
                        benchmark,
                        transactionWatchlist,
                        (result) => {
                            this.setState(
                                {
                                    loading: false,
                                    maxAllocation: "",
                                    allocError: "",
                                    valueUpdated: false
                                },
                                () => {
                                    this.props.cancel();
                                }
                            );
                        },
                        () => {
                            console.log("err");
                        }
                    );
                },
                () => {
                    console.log("err");
                }
            );
        } else {
            this.props.onSubmitForBucket(maxAllocation, timingType);
        }
    };

    timingSelect = (timing) => {
        this.setState({
            timingType: timing,
            timingError: "",
            valueUpdated: true
        });
    };

    render() {
        const { show, bucketLoading, transactionWatchlist } = this.props;
        const {
            loading,
            maxAllocation,
            allocError,
            timingError,
            timingType,
        } = this.state;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in show" : "";

        return (
            <>
                <div
                    className={"modal fade bs-example-modal-sm " + modalClass}
                    style={{ display: display }}
                >
                    <div
                        className="modal-dialog modal-sm password-modal-dialog"
                        style={{ marginTop: "100px" }}
                    >
                        <div className="modal-content">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title symbols-modal-title">
                                    {bucketLoading
                                        ? "Enter details for Bucket Trading"
                                        : "Transactions"}
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close"
                                    >
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                                <>
                                    <div className="login-form" id="password-modal-form">
                                        <div className="password-modal-username">
                                        Max Free Money Redistribution %
                                        </div>
                                        <Form.Check
                                            className={allocError ? "error-input" : ""}
                                            value={maxAllocation}
                                            id="allocation"
                                            type="number"
                                            min="0"
                                            placeholder="Max Allocation"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <span className={allocError ? "show-error" : "hide-error"}>
                                            {allocError}
                                        </span>
                                    </div>
                                    <div
                                        className="password-modal-username"
                                        style={{ margin: "20px 0 10px" }}
                                    >
                                        Timing Type
                                    </div>
                                    <div>
                                        <RadioGroup onChange={this.timingSelect} value={timingType || 'LONG_SHORT'}>
                                            <ReversedRadioButton
                                                value="LONG_ONLY"
                                                iconSize={20}
                                                iconInnerSize={10}
                                                pointColor={"#3BC796"}
                                            >
                                                LONG_ONLY
                                            </ReversedRadioButton>
                                            <ReversedRadioButton
                                                value="SHORT_ONLY"
                                                iconSize={20}
                                                iconInnerSize={10}
                                                pointColor={"#3BC796"}
                                            >
                                                SHORT_ONLY
                                            </ReversedRadioButton>
                                            <ReversedRadioButton
                                                value="LONG_SHORT"
                                                iconSize={20}
                                                iconInnerSize={10}
                                                pointColor={"#3BC796"}
                                            >
                                                LONG_SHORT
                                            </ReversedRadioButton>
                                        </RadioGroup>
                                        <span className={timingError ? "show-error" : "hide-error"}>
                                            {timingError}
                                        </span>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <button
                                            disabled={loading}
                                            onClick={this.onValueSubmit}
                                            className="btn login-btn"
                                        >
                                            Download CSV{" "}
                                            {loading && <i className="fa fa-spinner fa-spin"></i>}
                                        </button>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    getTransactionSimulation,
    getTransactionSimulationSummary,
})(TransactionsModal);
