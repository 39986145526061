import React, { Component } from "react";

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      showLoading: false,
    };
  }

  close = () => {
    if (this.props.cancel) {
      this.props.cancel();
    }
    this.setState({
      show: false,
    });
  };

  clearTable = () => {
    if (this.props.showDeleteModel) {
      this.setState({
        showLoading: true,
      });
    }

    this.props.confirm();
    setTimeout(() => {
      this.setState({
        showLoading: false,
      });
      this.props.cancel();
    }, 5000);
  };

  render() {
    const {
      show,
      logoutModal,
      timeoutModal,
      reloadModal,
      showDeleteModel,
      deleteWatchlist,
      benchmarkChange,
      saveWatchlistCheck,
      switchModal,
      switchType,
      fileUploadError,
      downloadTemplate,
      headerMismatch,
      manageWatchlistCheck,
      managed,
      managedWatchlistAlert,
      unmanagedWatchlistName,
      managedWatchlistName,
      unsupportedAssets,
      selectedWatchlistName
    } = this.props;
    const { showLoading } = this.state;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";

    return (
      <>
        <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          style={{ display: display }}
        >
          <div className="modal-dialog modal-m">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">
                <h4 className="modal-title">
                  {logoutModal
                    ? "Confirm Logout"
                    : timeoutModal
                    ? "Session Timed Out"
                    : reloadModal
                    ? "New Data Available"
                    : showDeleteModel
                    ? `Delete Watchlist`
                    : saveWatchlistCheck
                    ? "Existing watchlist"
                    : switchModal
                    ? `Switch ${
                        switchType === "quantity" ? "Weight" : "Quantity"
                      } to ${switchType === "quantity" ? "Quantity" : "Weight"}`
                    : fileUploadError || headerMismatch
                    ? "File upload error"
                    : manageWatchlistCheck
                    ? "Select Watchlist "
                    : managedWatchlistAlert
                    ? "Alert!"
                    : unsupportedAssets
                    ? "Alert!"
                    : benchmarkChange
                    ? "New Benchmark"
                    : "Clear All Entries"}
                  <button
                    type="button"
                    onClick={this.close}
                    disabled={showLoading}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>

              <div
                className="modal-body p-t-0 p-l-30 p-b-30 p-r-30"
                id="confirm-modal-body"
              >
                {logoutModal ? (
                  "Are you sure you want to logout?"
                ) : timeoutModal ? (
                  "Your session will expire now due to inactivity. Do you want to continue your session?"
                ) : reloadModal ? (
                  "The page will reload now."
                ) : showDeleteModel ? (
                  <span>
                    Are you sure you want to delete{" "}
                    <b style={{ wordBreak: "break-word" }}>{deleteWatchlist}</b>{" "}
                    watchlist?
                  </span>
                ) : benchmarkChange ? (
                  "Selecting a new benchmark will re-analyze the Watchlist. Do you want to proceed?"
                ) : saveWatchlistCheck ? (
                  <span>
                    By saving to{" "}
                    <b style={{ wordBreak: "break-word" }}>
                      {selectedWatchlistName}
                    </b>{" "}
                    , you will be overwriting the existing assets in this
                    watchlist. Are you sure you want to update{" "}
                    <b style={{ wordBreak: "break-word" }}>
                      {selectedWatchlistName}
                    </b>{" "}?
                  </span>
                ) : switchModal ? (
                  `Switching from ${
                    switchType === "quantity" ? "Weight" : "Quantity"
                  } to ${
                    switchType === "quantity" ? "Quantity" : "Weight"
                  } will clear the currently displayed data. Do you want to proceed?`
                ) : headerMismatch ? (
                  "This CSV format is not supported. Please click Download Template to see the supported format."
                ) : fileUploadError ? (
                  "There are some unwanted rows in this file, which will not be added. Click Ok to continue or Cancel to upload the file again"
                ) : manageWatchlistCheck ? (
                  <span>
                    {managed ? (
                      <span>
                        Are you sure you want to un-select Watchlist{" "}
                        <b style={{ wordBreak: "break-word" }}>
                          {unmanagedWatchlistName}
                        </b>{" "}
                      </span>
                    ) : managedWatchlistName ? (
                      <span>
                        Only one Watchlist can be “selected” at a time. Click
                        "Yes" to un-select Watchlist (
                        <b style={{ wordBreak: "break-word" }}>
                          {managedWatchlistName}
                        </b>
                        ) and move the “selected” flag to Watchlist (
                        <b style={{ wordBreak: "break-word" }}>
                          {unmanagedWatchlistName}
                        </b>
                        ) instead. Click “No” to cancel.
                      </span>
                    ) : (
                      <span>
                        Only one Watchlist can be “selected” at a time. Click
                        "Yes" to select Watchlist (
                        <b style={{ wordBreak: "break-word" }}>
                          {unmanagedWatchlistName}
                        </b>
                        )
                      </span>
                    )}
                  </span>
                ) : managedWatchlistAlert ? (
                  "Vistalytics Inc. will perform the necessary transactions in the next business day to reflect your changes in the Selected Watchlist."
                ) : unsupportedAssets ? (
                  <span>
                    <b>Sorry! </b>
                    Asset(s) <b>({unsupportedAssets.toString()})</b> is/are
                    invalid. Hence these assets were not added to the watchlist.
                    Please check the assets and re-upload.
                  </span>
                ) : (
                  "Are you sure you want to clear all entries?"
                )}
                {(fileUploadError || headerMismatch) && (
                  <div className="download-template">
                    <i onClick={downloadTemplate} className="fa fa-info-circle">
                      <span className="template-text-modal">
                        Download Template
                      </span>
                    </i>
                  </div>
                )}
              </div>

              {!reloadModal && !showLoading && (
                <span className="input-group-btn" id="view-log-group">
                  {!timeoutModal && (
                    <button
                      onClick={this.clearTable}
                      className="btn confirm-modal-btn"
                    >
                      {fileUploadError ||
                      headerMismatch ||
                      managedWatchlistAlert ||
                      unsupportedAssets
                        ? "OK"
                        : "Yes"}
                    </button>
                  )}
                  {!headerMismatch &&
                    !managedWatchlistAlert &&
                    !unsupportedAssets && (
                      <button
                        onClick={this.close}
                        className="btn confirm-modal-btn"
                      >
                        {timeoutModal
                          ? "Continue"
                          : fileUploadError
                          ? "Cancel"
                          : "No"}
                      </button>
                    )}
                </span>
              )}

              {showLoading && (
                <div style={{ textAlign: "center" }}>
                  <button
                    disabled={true}
                    onClick={showLoading && this.close}
                    className="btn confirm-modal-btn"
                  >
                    Deleting
                    {<i className="fa fa-spinner fa-spin" style={{marginLeft:'1rem'}}></i>}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConfirmModal;
