import React, { Component } from 'react';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

import '../home/Home.css';
import { connect } from 'react-redux';
import ErrorModal from '../../layout/ErrorModal';
import ApiModal from '../../layout/ApiModal';
import ForbiddenErrorModal from '../../layout/ForbiddenErrorModal'
import { fetchTimingData, fetchAsset, fetchChartDataWithDate } from '../../actions/AssetAction';
import { getMarketData } from '../../actions/MarketActions';
import firebaseObj from '../../utils/firebase';
import packageJson from '../../../package.json';
import uuid from 'react-uuid';
import AssetDataCard from './AssetDataCard';
import { calculateSentimentValue } from '../../utils/helperFunctions';
import { symbols } from './symbols';

const analytics = firebaseObj.analytics();

class Market extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            apiModalShow: false,
            apiDetails: [],
            apiResponse: [],
            loading: true,
            currentData: {}
        }
    }

    componentDidMount() {
        const { loginDetails } = this.props.about;

        let tabCount = localStorage.getItem('tab');
        let autoLogin = localStorage.getItem('autoLogin');
        let reloadCheck = window.sessionStorage.getItem('reload');

        if ((loginDetails === null || Object.keys(loginDetails).length === 0) ||
            ((tabCount !== 'many' || autoLogin !== 'true') && reloadCheck !== 'true')) {
            this.props.history.push('/');
        }
        else {
            analytics.setUserProperties({
                email: loginDetails.email,
                App_Version: packageJson.version
            });
            analytics.logEvent('Market Overview');

            localStorage.setItem('currentRoute', 'home');
            window.sessionStorage.setItem('token', loginDetails.apikey);

            this.setState({
                loading: true
            })

            this.retrieveMarketData();
        }
    }

    retrieveMarketData = () => {
        this.props.getMarketData(symbols,
            () => {
                let currentData = this.props.marketData;

                this.setState({
                    currentData
                })
            },
            (err, request) => {
                this.setErrorState(err, request);
            }
        );
    }

    setErrorState = (err, request) => {
        let apiDetails = [];
        let apiResponse = [];
        let error = err.message ? err.message : "Error Occured !!";
        let errorJson = err;
        apiDetails.push(request);
        apiResponse.push({ error });

        this.setState({
            loading: false,
            error,
            apiDetails,
            apiResponse,
            errorJson: errorJson
        });
    }

    getApiInfo = () => {
        this.setState({
            apiModalShow: true
        })
    }

    onApiModalClose = () => {
        this.setState({
            apiModalShow: false
        })
    }

    viewLog = () => {
        this.setState({
            apiModalShow: true,
            error: false
        })
    }

    goToAsset = (asset) => {
        this.props.history.push('/asset/' + asset + '?uuid=' + uuid());
    }

    getData = () => {
        let currentData = this.props.marketData;

        return symbols.map((asset, index) => {
            if (currentData && currentData.length > 0) {
                let currentDataArray = currentData.filter((data) => {
                    return data.symbol === asset
                })

                if (currentDataArray && currentDataArray[0]['vflows']) {
                    let graphData = currentDataArray[0]['vflows'];
                    let listofasset = currentDataArray[0]["listofasset"];
                    let sentimentValue = this.getSentimentValue(listofasset);

                    return (
                        <AssetDataCard
                            key={index}
                            title={asset}
                            sentiment={sentimentValue.id}
                            sentimentValue={sentimentValue.value}
                            chartData={graphData}
                            assetClick={this.goToAsset.bind(this)}
                        />
                    )
                }
            }
            else {
                return (
                    <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-xs-12" id="market-div-body">
                        <div className="card-loader card-loader--tabs"></div>
                    </div>
                )
            }
        })
    }

    getSentimentValue(data) {
        let longAssets = data.filter((asset) => {
            return asset.data.timing === 'Long'
        })

        let shortAssets = data.filter((asset) => {
            return asset.data.timing === 'Short'
        })

        let aggregate = 0;
        data.forEach((asset) => {
            aggregate += asset.allocation
        })

        let sentiment = calculateSentimentValue(longAssets, shortAssets, aggregate);
        return sentiment
    }

    render() {
        const { history } = this.props;
        const { apiModalShow, apiResponse, apiDetails, loading, error, currentData } = this.state;

        let errorView = !loading && error ? (
            <>
                <ErrorModal
                    show={true}
                    message={error}
                    viewLog={this.viewLog}
                    showViewLog={true}
                />
            </>
        ) : "";

        return (
            <>
                <Header history={history} />
                <div id="page-wrapper" className="m-l-0">
                    <div className="container">
                        <div className="row" id="market-data-row">
                            {
                                currentData && this.getData()
                            }
                        </div>
                    </div>

                    <Footer location="home" onClick={this.getApiInfo} />

                    {errorView}

                    <ApiModal
                        show={apiModalShow}
                        apiDetails={apiDetails}
                        onClose={this.onApiModalClose}
                        apiResponse={apiResponse}
                        history={this.props.history}
                        errorJson={this.state.errorJson}
                        screen='home'
                    />
                    <ForbiddenErrorModal
                        history={history}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    marketData: state.MarketReducer.marketData,
    timingRequest: state.AssetReducer.timingRequest,
    about: state.AboutReducer,
});

export default connect(mapStateToProps, { fetchTimingData, fetchChartDataWithDate, fetchAsset, getMarketData })(Market)
