import React, { Component } from "react";
import packageJson from "../../package.json";

class ReleaseNotesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setState({
      show: this.props.show,
    });
  }

  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      show: false,
    });
  };

  render() {
    const { show } = this.props;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";

    return (
      <>
        <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          id="release-notes-modal"
          style={{ display: display }}
        >
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">
                <h4 className="modal-title">
                  Release Notes
                  <button
                    type="button"
                    onClick={this.close}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>
              <div
                className="modal-body p-t-0 p-l-30 p-b-30 p-r-30"
                id="release-notes-modal-body"
              >
                 <>
                <div className="version-number-date">
                    v{packageJson.version} - 28th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-556 Remove "unsaved changes" alert from browser.
                      </li>
                      <li className="section-list-item">
                        #VUI-551 Tooltips for Kosha.
                      </li>    
                    </ul>
                    <div className="section-header-text">Bug fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-558 Changing Selected Watchlist shows error.
                      </li>
                               
                    </ul>
                  </div>
                </>

                <hr className="request-delimiter" />
                 <>
                <div className="version-number-date">
                    v3.1.3+98 - 26th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-548 Watchlist Timing Chart Export to CSV showing unexpected values.
                      </li>
                      <li className="section-list-item">
                        #VUI-550 Timing Chart Y-Axis: Multiply by 100 at the end, not at the beginning.
                      </li>              
                    </ul>
                  </div>
                </>

                <hr className="request-delimiter" />
                <>
                <div className="version-number-date">
                    v3.1.3+97 - 20th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Adding ID Token to all API Calls.
                      </li>                     
                    </ul>
                  </div>
                </>

                <hr className="request-delimiter" />

                 <>
                  <div className="version-number-date">
                    v3.1.3+96 - 19th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                      Timing Chart fixed for Save Watchlist.
                      </li>
                     
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+95 - 18th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Percentage symbol removed from Y Axis
                      </li>
                      <li className="section-list-item">
                        Chart lines overflowing on zoom fixed
                      </li>
                      <li className="section-list-item">
                        Missing assets in timing chart fixed
                      </li>
                      <li className="section-list-item">
                        Zoomed-in calculations fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                 <>
                  <div className="version-number-date">
                    v3.1.3+94 - 11th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                     #VUI-540 Update the Y-Axis for Timing Charts (Watchlists page and Analyze Assets page).
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+93 - 8th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-537 Re-arrange the Cards on Analyze Asset Page.
                      </li>
                      <li className="section-list-item">
                        #VUI-538 Tooltips for Market Risk and VDIFF on Analyze Asset Page.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+92 - 7th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-535 VDIFF card added in Analyze asset page.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+91 - 6th October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-535 Update to Timing Model Charts.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+90 - 1st October 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-273 On Analyze Asset page, clicking Analyze for a
                        second time only changes the uuid but the Analyze
                        function does not run.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+89 - 30th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-530 When Optimize finishes running, there should be
                        an asterisk next to the Watchlist name.
                      </li>
                      <li className="section-list-item">
                        #VUI-531 Please revert the color change for Watchlist
                        Timing Model Return line
                      </li>
                      <li className="section-list-item">
                        #VUI-532 Update to Timing chart zoom calculations.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-534 Dev - NaN values in Analyze Asset.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+88 - 29th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-503 Distinguish column headers in Kosha Watchlists
                        and My Watchlists modals.
                      </li>
                      <li className="section-list-item">
                        #VUI-529 Add % sign to the label of Drawdown and Return
                        on Watchlist timing charts.
                      </li>
                      <li className="section-list-item">
                        #VUI-514 Watchlist Timing Model Return line should
                        always be green.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+87 - 28th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-525 Alphabetic slider for Assets modal.
                      </li>
                      <li className="section-list-item">
                        #VUI-526 Enable optimize button in Beta.
                      </li>
                      <li className="section-list-item">
                        #VUI-528 Update to number of decimal places for
                        Drawdown, Return, and Sharpe Ratio.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-517 Individual dates do not appear on Timing
                        charts.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+86 - 27th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-523 Please add "%" after Avg move up and Avg move
                        down in Analyze Asset page.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-518 Sort in My Watchlists is not working as
                        expected.
                      </li>
                      <li className="section-list-item">
                        #VUI-521 Export to Csv in Alberto without asset Position
                        creates csv files with "undefined" column values.
                      </li>
                      <li className="section-list-item">
                        #VUI-522 Alberto: Add Symbol and Position in Alberto
                        does not do anything when Position is not entered for an
                        asset.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+85 - 24th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-519 Clicking Re-Analyze after changing a Watchlist
                        asset and then Saving makes the asset disappear.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+84 - 23th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-514 Watchlist Timing Model Return line should
                        always be green.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-511 Uploading a csv with all unsupported assets
                        causes page to become blank.
                      </li>
                      <li className="section-list-item">
                        #VUI-512 Scrolling Max Free Money Redistribution % value
                        scrolls the page too.
                      </li>
                      <li className="section-list-item">
                        #VUI-513 Change the ETF Holdings table column header
                        from Allocation % to Weight.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+83 - 22th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-504 Portfolio validation to be done before click on
                        Save and Optimize buttons.
                      </li>
                      <li className="section-list-item">
                        #VUI-505 Update the alert message that appears when
                        making changes to Selected Watchlist.
                      </li>
                      <li className="section-list-item">
                        #VUI-506 Saved watchlist related fields (like name, max
                        redistribution, timing type, etc) should be retained
                        after successful Optimize. Both Sharpe forward and
                        backward.
                      </li>
                      <li className="section-list-item">
                        #VUI-507 hide the Allocation % column values when a
                        saved watchlist is getting loaded
                      </li>
                      <li className="section-list-item">
                        #VUI-508 Change the text of alert message when trying to
                        Save Watchlist with the same name as the existing
                        Selected Watchlist.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+82 - 21th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Trimming Whitespaces in Watchlist Name.
                      </li>
                      <li className="section-list-item">
                        Fixing Managed state while saving Watchlist.
                      </li>
                      <li className="section-list-item">
                        Handling Version Upgrade during API Calls.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+81 - 20th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-502 Deleting multiple Watchlists one by one "looks"
                        unsuccessful.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+80 - 16th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-498 Video Tutorial video player size when loading
                        should be same.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+79 - 15th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-500 Do not allow "e" in the Max Free Money
                        Redistribution % of Save Watchlist modal.
                      </li>
                      <li className="section-list-item">
                        #VUI-501 The change benchmark modal should have a
                        different title.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-482 Save without weight not showing error message.
                      </li>
                      <li className="section-list-item">
                        #VUI-497 Video tutorial arrow navigation.
                      </li>
                      <li className="section-list-item">
                        #VUI-499 Error message persists in save watchlist modal.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+78 - 13th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-492 Switch the order of the Watchlist tab and the
                        Market Overview tab.
                      </li>
                      <li className="section-list-item">
                        #VUI-493 Add another condition for "^" symbols to the
                        "Selected" button click.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-477 Alberto does not work for the first asset.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+77 - 9th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-488 Kosha Watchlist/My Watchlist Columns.
                      </li>
                      <li className="section-list-item">
                        #VUI-489 Allow capital letters, spaces, and dashes "-"
                        in the Watchlist name.
                      </li>
                      <li className="section-list-item">
                        #VUI-490 If a Watchlist has an asset that starts with
                        "$", show an error message when the user clicks the "Not
                        Selected" button.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+76 - 8th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-486 Only pick up weight from the column that has
                        header of "weight" when CSV file upload.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-487 Changing the benchmark can lead to label bug.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+75 - 7th September 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-483 Hover over Watchlist name in My Watchlist to
                        see Notes/Description.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-482 Save watchlist without weight.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+74 - 24th August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-476 Replace "Managed"/"Not Managed" wording with
                        "Selected"/"Not Selected".
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+73 - 20th August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-474 Optimize can lead to broken page.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+72 - 18th August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-473 User gets logged out when trying to Analyze
                        invalid Watchlist.
                      </li>
                    </ul>
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-472 System response when user uploads watchlist of
                        unsupported symbols.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+71 - 9th August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-470 Showing message when user session expired on
                        login page.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+70 - 5th August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-468 Updates to Asset Holdings Table and CSV.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+69 - 4th August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-467 Remove About api refresh.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+68 - 2nd August 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-464 Change "Add Symbol" to "Add Row" in watchlist.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-465 Editing a Watchlist causes some users to get
                        logged out.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+67 - 28th July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-461 confirm modals text-wrap CSS property updated.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+66 - 27th July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-460 Alerting user of Which Watchlist will become
                        Managed/Unmanaged.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+65 - 23rd July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-459 Managed Watchlist adding/deleting row Alert.
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-458 Managed watchlist saving un manages the
                        Watchlist.
                      </li>
                      <li className="section-list-item">
                        #VUI-450 Saving watchlist crash fix.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+64 - 15th July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Version number check in about API
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+63 - 12th July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-424 Limit the weight to 2 decimal places in The
                        Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+62 - 5th July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-306 Chart legends issue when chart zoom fix
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+61 - 2nd July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-314 Chart zoom issue fix
                      </li>
                      <li className="section-list-item">
                        #VUI-452 Zoom + Reset Zoom several times and the chart
                        goes flat bug fix
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-453 Tooltip added on chart cards
                      </li>
                      <li className="section-list-item">
                        #VUI-454 Un-hide the Optimize button
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+60 - 1st July 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-320 Watchlist charts zoom issue fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+59 - 30th June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-428 Date conversion in charts fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+58 - 29th June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-450 Save watchlist box shows old data bug fix{" "}
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+57 - 28th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-443 Allocation%, Timing Type and Max Free Money
                        Redistribution update
                      </li>
                      <li className="section-list-item">
                        #VUI-445 Allocation %, Timing Type and Max Free Money
                        header added in Bucket Trading CSV
                      </li>
                      <li className="section-list-item">
                        #VUI-447 Watchlist weight check added 100 to -100
                      </li>
                      <li className="section-list-item">
                        #VUI-448 Watchlist data clear after delete
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-446 Save watchlist request body update
                      </li>
                      <li className="section-list-item">
                        #VUI-449 Delete final row Watchlist bug fix
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+56 - 25th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-431 Adding new symbol to watchlist fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-432 Watchlist crash fix on Optimize
                      </li>
                      <li className="section-list-item">
                        #VUI-434 Allocation% cleared on table clear
                      </li>
                      <li className="section-list-item">
                        #VUI-437 Previous Max Allocation value cleared when new
                        watchlist is loaded
                      </li>
                      <li className="section-list-item">
                        Asterisk added on delete row
                      </li>
                      <li className="section-list-item">
                        Reloading page with correct watchlist data after marking
                        as Managed
                      </li>
                      <li className="section-list-item">
                        Duplicate asset check on saving/updating watchlist
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-433 Max Allocation label changed to Max Free Money
                        Redistribution
                      </li>
                      <li className="section-list-item">
                        #VUI-435 Watchlist name populated after saving
                      </li>
                      <li className="section-list-item">
                        #VUI-436 Asterisk mark to indicate unsaved changes in
                        the watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-438 Allocation %, Max Free Money Redistribution and
                        Timing Type added to exported CSV file
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+55 - 24th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-429 Charts and Sharpe/Drawdown/Days to
                        Recover/Return must get updated when user save new
                        watchlist parameters
                      </li>
                      <li className="section-list-item">
                        {" "}
                        #VUI-430 new watchlist data added
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+54 - 22nd June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Show Transactions modal with Max Allocation and Timing
                        Type values, if already available
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Save Watchlist fixes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+53 - 21st June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Download transaction summary csv if Max Allocation and
                        Timing Type are present
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+52 - 15th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-421 Save Watchlist parameters clearing bug fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+51 - 14th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-425 Unsupported benchmark should not Analyze
                      </li>
                      <li className="section-list-item">
                        #VUI-426 Video tutorial navigation buttons fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-427 On CSV upload, duplicate symbols error message
                        fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+50 - 11th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-420 Extra Assets pop up header changed to 'Weight'
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-417 Version Release Notes and API Details visible
                        only in dev
                      </li>
                      <li className="section-list-item">
                        #VUI-423 Transactions Modal button text changed to
                        Download CSV
                      </li>
                      <li className="section-list-item">
                        #VUI-422 Watchlist name character limit increased to 100
                      </li>
                      <li className="section-list-item">
                        #VUI-418 404 page for incorrect URLs
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+49 - 10th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-415 Removed text under Watchlist title
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-414 Save watchlist with all weights 0 fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+48 - 9th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-411 Update watchlist fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+47 - 8th June 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-374 Error message for Invalid CSV format changed in
                        Watchlist and Alberto
                      </li>
                      <li className="section-list-item">
                        #VUI-406 Message for Manage Watchlist updated
                      </li>
                      <li className="section-list-item">
                        #VUI-408 Max Allocation changed to Max Allocation (%)
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-407 Populate correct watchlist name after loading
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+46 - 7th June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Radio button UI improved
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-402 Cannot save watchlist with 0 weights for all
                        assets
                      </li>
                      <li className="section-list-item">
                        #VUI-403 Crash on watchlist save fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-404 Upload incorrect CSV crash fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-393 Radio button click fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+45 - 4th June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-390 Added POIs on Closing Price chart of Benchamrk
                        in Watchlist
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-401 Default Timing Type changed in Transactions
                        Modal
                      </li>
                      <li className="section-list-item">
                        #VUI-400 Copy text not allowed in Watchlist name field
                      </li>
                      <li className="section-list-item">
                        #VUI-395 Watchlist crash after Optimize fixed.
                      </li>
                      <li className="section-list-item">
                        #VUI-397 Delete watchlist fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-396 Restrict weight field for e,E and + values
                      </li>
                      <li className="section-list-item">
                        #VUI-399 Typing 0 for weight fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-398 Select Symbol fixed for Timing Chart
                      </li>
                      <li className="section-list-item">
                        #VUI-391 Default Timing Type picked up in Bucket Trading
                      </li>
                      <li className="section-list-item">
                        #VUI-392 Spinner for Bucket Trading cleared after pop up
                        is closed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+44 - 3rd June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Max Allocation and Timing Type values added to Bucket
                        Trading API from Watchlist Response
                      </li>
                      <li className="section-list-item">
                        Pop up to accept Max Allocation and Timing Type values
                        for Bucket Trading API
                      </li>
                      <li className="section-list-item">
                        Managed and Not Managed options added to Watchlist Modal
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Handled empty portfolio response for Save Watchlist
                      </li>
                      <li className="section-list-item">
                        Benchmark symbol sent in Watchlist API to get Daily data
                        and chart data
                      </li>
                      <li className="section-list-item">
                        Textfield not accepting first character - fixed
                      </li>
                      <li className="section-list-item">
                        Max Allocation and Timing Type values populated from
                        Watchlist Response
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+43 - 2nd June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-387 The mouse wheel scroll should only scroll the
                        watchlist table, not change the values
                      </li>
                      <li className="section-list-item">
                        #VUI-388 Hide "By Quantity" Watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-389 Hide the "Handover to Alberto" and "Handover to
                        Watchlist" button on Alberto page
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+42 - 1st June 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-384 Optimize watchlist only if more than 1 asset is
                        present
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-379 Quantity change on row deletion fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-386 Benchmark placeholder fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-334 Timing Chart fix
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-385 Alberto table Position dropdown UI
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+41 - 26th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="release-notes-body">
                      <div className="section-header-text">What's New</div>
                      <ul className="section-list">
                        <li className="section-list-item">
                          Added Max Allocation and Timing Type to Save Watchlist
                        </li>
                        <li className="section-list-item">
                          Added watchlist details to transaction simluation APIs
                        </li>
                      </ul>
                    </div>

                    <div className="section-header-text">Bug fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-381 Error message when benchmark is cleared
                      </li>
                      <li className="section-list-item">
                        #VUI-382 Watchlist with unsupported assets will not be
                        analysed
                      </li>
                      <li className="section-list-item">
                        #VUI-378 Assets with 0 weight error fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-376 Ignore blank lines in uploaded csv file
                      </li>
                      <li className="section-list-item">
                        #VUI-377 Save Watchlist fixes
                      </li>
                      <li className="section-list-item">
                        #VUI-380 Default weight and quantity is blank
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+40 - 25th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Kosha Watchlist name is not editable
                      </li>
                      <li className="section-list-item">
                        Assets with no weight from csv file bug fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+39 - 24th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Added hashkey value for Save Watchlist API
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-373 - Assets Modal page number fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+38 - 21st May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Transactions Simulation Summary API integrated
                      </li>
                      <li className="section-list-item">
                        Added benchmark data to Transaction Simulation API
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+37 - 20th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-372 - Watchlist Transaction box error fixed
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-356 - Alberto functionality - added meaningful
                        error messages for different errors
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+36 - 19th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-371 - Market Risk Asset Symbol will always be SPY
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-356 - Alberto functionality - added error message
                        and download template option in upload modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+35 - 18th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-347 - Allow zero to be entered as weight
                      </li>
                      <li className="section-list-item">
                        #VUI-369 - Handling of zero weight - atleast one should
                        be non-zero
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+34 - 17th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-367 - Cannot click twice on save watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-362 - Alert message on switching from Weight to
                        Allocation
                      </li>
                      <li className="section-list-item">
                        #VUI-364 - Unique name check for Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+34 - 13th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-366 - Saving blank watchlist crash fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-363 - Name is mandatory to save watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-365 - Save button is disabled on Save Watchlist
                        modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+33 - 10th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-361 - Show error modal if there are unsupported
                        assets and move them to top of the table.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+32 - 7th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-332 (update) - Allow only 1 minus(-) sign in Weight
                        field in Watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-334 - Timing Chart bug fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+31 - 5th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-360 - Delete button handling in Delete Watchlist
                        Modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+30 - 4th May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-345 (update) - Erroneous row highlighted
                      </li>
                      <li className="section-list-item">
                        #VUI-337 (update) - Download template header changed to
                        Weight
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-359 - Transaction Simulation validations for empty
                        fields
                      </li>
                      <li className="section-list-item">
                        Validation check for assets before Optimize
                      </li>
                      <li className="section-list-item">
                        Export to CSV button text removed and added icon in
                        Watchlist vs. Timing Chart
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+29 - 3rd May 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-352 (Update) - Search button hidden till Analyze is
                        completed
                      </li>
                      <li className="section-list-item">
                        Transaction Simulation Max Allocation accepts only
                        numbers
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+28 - 30th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Transaction Simulation implemented in Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+27 - 29th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-358 - Access saved watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-339 - Period button is clickable when moved into
                        border
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+26 - 27th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-357 - Crash fixed when Optimize modal is closed
                      </li>
                      <li className="section-list-item">
                        #VUI-349 - Old Benchmark symbol restored when invalid
                        asset is entered for Benchmark field
                      </li>
                      <li className="section-list-item">
                        #VUI-355(3,4) - Albertize button enable fix when valid
                        assets are entered
                      </li>
                      <li className="section-list-item">
                        #VUI-355 (2) - Add new row only if last row is not empty
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-351 Toggle button added for Weight/Quantity
                        selection
                      </li>
                      <li className="section-list-item">
                        #VUI-353 Alert message added if user tries to leave
                        Watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-348 Alert message added if user tries to leave
                        Watchlist during Analyze
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+25 - 26th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-348 - Alert message on benchmark symbol change
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-331 - Albertize button grayed out when position is
                        empty on CSV Upload
                      </li>
                      <li className="section-list-item">
                        #VUI-332 - Accept weight values between 0 and 1.
                      </li>
                      <li className="section-list-item">
                        #VUI-335 - Validation on weight values in Watchlist
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Multiply allocation by 10, if Optimizer returns all
                        allocations less than 1
                      </li>
                      <li className="section-list-item">
                        Save Kosha Watchlist as My Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+24 - 23rd Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-340 - Removed Save Watchlist and Optimize options
                        for Watchlist by Quantity
                      </li>
                      <li className="section-list-item">
                        #VUI-344 - Saved watchlist results are accessible
                      </li>
                      <li className="section-list-item">
                        #VUI-345 - Watchlist by Quantity validation for quantity
                        field fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-350 - Check for empty weight/quantity fields on csv
                        upload
                      </li>
                      <li className="section-list-item">
                        #VUI-352 - Disable all buttons when loading
                      </li>
                      <li className="section-list-item">
                        #VUI-343 - Alberto page dropdowns UI fixed
                      </li>
                      <li className="section-list-item">
                        Weight values after csv upload fixed
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-346 - Removed extra row addition on enter key press
                      </li>
                      <li className="section-list-item">
                        #VUI-337 - Header Allocation changed to Weight in
                        Analyze Asset
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+23 - 22nd Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Optimize for Watchlist implemented. Includes Sharpe
                        Backward and Forward.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+22 - 21st Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Update and Delete Watchlist feature
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+21 - 20th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-327 Duplicate asset symbols not allowed in
                        Watchlist
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-329 Time range for Watchlist charts
                      </li>
                      <li className="section-list-item">
                        #VUI-330 Cursor placement in Analyze Asset charts
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+20 - 16th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-324 Watchlist Analyze fix
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+19 - 15th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-322 Watchlist fix for empty assets
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+18 - 14th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-319 Export to csv fix in Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+17 - 12th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-302 Validation for Weight field
                      </li>
                      <li className="section-list-item">
                        #VUI-305 Alberto Page UI fixes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+16 - 9th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-317 URL manipulation in Analyze Asset fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+15 - 8th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-295 Chart zoom fix in Analyze Asset
                      </li>
                      <li className="section-list-item">
                        #VUI-306 Legend is reset to show all values
                      </li>
                      <li className="section-list-item">
                        #VUI-312 Export to CSV columns fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-313 Chart zoom fix on adding single asset
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+14 - 7th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Watchlist Upload csv fix
                      </li>
                      <li className="section-list-item">
                        #VUI-302 '.' and 'e' not allowed
                      </li>
                      <li className="section-list-item">
                        $ symbol name fixed in charts
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-300 Watchlist weight should be between -100 and 100
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+13 - 6th Apr 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-296 - Watchlist crash fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-298 - Watchlist crash in Quantity tab fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-299 - Watchlist Export to CSV Quantity value fixed
                      </li>
                      <li className="section-list-item">
                        Analyze Asset - validation for numbers in asset field
                        and error modal on tab change fixed
                      </li>
                      <li className="section-list-item">
                        Watchlist by Quantity - accept only integers for
                        Quantity
                      </li>
                      <li className="section-list-item">
                        Watchlist - Clear old data before showing selected Kosha
                        watchlist data
                      </li>
                      <li className="section-list-item">
                        Alberto - Accept CSV with timing available
                      </li>
                      <li className="section-list-item">
                        #VUI-293 - Closing Price chart zoom fix
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+12 - 31st Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Crash after returning to Watchlist page fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+11 - 30th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-291 Watchlist Analyze button disabled on load
                      </li>
                      <li className="section-list-item">
                        #VUI-289 Timing chart bug fixed on Incognito mode
                      </li>
                      <li className="section-list-item">
                        #VUI-290 Timing chart data change fixed on Incognito
                        mode
                      </li>
                      <li className="section-list-item">
                        Export to CSV on zoom fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+10 - 28th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-288 Chart fixed in Analyze Asset
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v3.1.3+9 - 25th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-285 Screen data check on resizing window
                      </li>
                      <li className="section-list-item">
                        #VUI-286 Analyze Asset crash fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-287 Clear single selected symbol in dropdown fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.8 - 17th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-281 Invalid symbols handled in Watchlists
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.7 - 15th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-264 Technical Analysis zoom fix
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.6 - 12th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-266 POI data points for Benchmark chart
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-268 Prevent drop/paste in Allocation field
                      </li>
                      <li className="section-list-item">
                        #VUI-269 Over scroll in Watchlist and Alberto fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-270 Calendar crash fix
                      </li>
                      <li className="section-list-item">
                        #VUI-277 Blank allocation check fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-278 Cursor pointer only for title in Market
                        Overview
                      </li>
                      <li className="section-list-item">
                        #VUI-275 Show error message on wrong csv format in
                        Alberto
                      </li>
                      <li className="section-list-item">
                        #VUI-279 Analyze Asset suggested Symbol dropdown
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.5 - 10th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-261 Download single file with different columns for
                        each chart line
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Market Overview tab click issue fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.4 - 5th Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's new</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-261 'Get Chart Data' option added
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-260 Removed 'Return' from Benchmark name
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-262 Display 'No Watchlists' message if user has no
                        watchlists
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.3 - 5th Mar 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-259 Max Expected values removed from exported csv
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.2 - 2nd Mar 2021
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-257 Benchmark return value and graph added to chart
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-258 Crash in Technical Analysis fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.1 - 1st Mar 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-255 No special characters/space allowed for
                        Watchlist Name
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.3.0 - 26th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-255 Added character limit and constraints for
                        Watchlist Name
                      </li>
                      <li className="section-list-item">
                        #VUI-256 Selected watchlist name populated in table
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.9 - 25th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Watchlist API changes integrated
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-255 Added ellipses to prevent overflow of Watchlist
                        Name and Notes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.8 - 23rd Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-254 Modal for Kosha Watchlists
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.7 - 17th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        API integration to get details of saved/system
                        watchlists
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.6 - 12th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-250 POI data points hover fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-252 Incorrect dates on scroll fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v{packageJson.version} - 11th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-251 Kosha Watchlists
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.4 - 9th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-250 Position of POI data points fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.3 - 5th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">Save Watchlists</li>
                      <li className="section-list-item">
                        Get Saved Watchlists
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.2 - 4th Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Editable date for charts
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Charts changes in Analyze and Watchlist pages
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.1 - 1st Feb 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        API for POI data integrated
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Charts changes in Analyze and Watchlist pages
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.2.0 - 28th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-244 Added Watchlist limit message to pop-up
                      </li>
                      <li className="section-list-item">
                        #VUI-245 Helper text for symbol text fields
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.9 - 27th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-239 New color for VVOL bubbles in Closing Price
                        chart
                      </li>
                      <li className="section-list-item">
                        #VUI-242 Widen columns of Timing Difference Modal
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-243 Timing Chart values fixed
                      </li>
                      <li className="section-list-item">
                        Analyze Asset on Benchamrk change fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.8 - 25th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-237 Technical Analysis crash on Relative Price
                        fixed
                      </li>
                      <li className="section-list-item">
                        Analyze Button in Watchlist page fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.7 - 22nd Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Market Overview VFLOW values fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.6 - 19th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-234 Asset Holdings table column widths
                      </li>
                      <li className="section-list-item">
                        #VUI-235 Changes to Market Overview
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.5 - 15th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-228 Disabled benchmark field when loading
                      </li>
                      <li className="section-list-item">
                        #VUI-209 Changed market overview symbols to $ symbols
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.4 - 12th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-209 Market Overview Revamp
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-230 Removed bubbles from Price chart
                      </li>
                      <li className="section-list-item">
                        #VUI-231 Reduce row heights of Timing Difference Modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.3 - 11th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Asset Return and Timing Model Graph value reset on zoom
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-228 Disabled Asset and Benchmark fields while
                        analyzing
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.2 - 6th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Bucket Trading Details available as CSV in Timing
                        Difference Modal
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Allocation value of Holdings fixed
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-225 Adding new rows to Watchlist - blank row always
                        present
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.1 - 5th Jan 2021
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-223 Allowed negative allocation for $ assets
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Show Allocation value of Holdings in Timing Difference
                        Modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.1.0 - 29th Dec 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        VVOL peaks displayed in closingPrice
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-222 Removed SMA graph from VVOL
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.9 - 28th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Fixed watchlist crash issue, app was crashing if the
                        total allocation of a stock is 0
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />

                <>
                  <div className="version-number-date">
                    v1.0.8 - 24th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Added export Timing Diff to csv
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.7 - 18th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Enhancement to Timing Diff modal
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analyze Asset bug fixed - Asset name in Timing Graph
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.6 - 17th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-215 Disable buttons when page is loading, make
                        disabled buttons more visible.
                      </li>
                      <li className="section-list-item">
                        #VUI-216 Alberto CSV upload changes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.5 - 14th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-211 Analyze Asset bug fixed when changing asset
                      </li>
                      <li className="section-list-item">
                        Volatility value for Asset and Benchmark fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.4 - 11th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Negative allocation values not allowed for $ symbols in
                        Watchlist table
                      </li>
                      <li className="section-list-item">
                        Watchlist table UI changes
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Valid asset symbol check in Alberto
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.3 - 10th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        API integrated to get Timing Difference in Watchlist
                      </li>
                      <li className="section-list-item">
                        Timing Difference table has expanded rows for ETFs
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.2 - 8th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analyze Asset - Moved Closing Price to header,
                        Volatility added
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Reduced margins and spacing in Watchlist table
                      </li>
                      <li className="section-list-item">
                        Added Sentiment label above Sentiment bar
                      </li>
                      <li className="section-list-item">
                        Position value added in Alberto table from
                        uploaded/handover watchlist csv
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.1 - 4th Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-204 Asset search function improved
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-200 $ and $$ Asset in Analyze Asset fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-206 Sentiment bug fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v1.0.0 - 1st Dec 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-201 Sentiment v2 implemented
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-203 Upload CSV data presentation in Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.9 - 30th Nov 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-201 Sentiment Feature implemented
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-202 UI for $ and $$ Assets in modal fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.8 - 19th Nov 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-200 Selecting $ assets in Analyze Asset fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.7 - 23rd Nov 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analytics for Alberto feature added
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        New logic for Alberto implemented
                      </li>
                      <li className="section-list-item">
                        #VUI-199 Download file name changes implemented
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Auto login on new tab URL change fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.6 - 19th Nov 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-198 Alberto Feature Implemented
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.5 - 17th Nov 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analytics for System Watchlist added
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-193 Fixes for scenario 1 Session management
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.4 - 11th Nov 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Show modal and reload when new data is available
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.3 - 10th Nov 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-193 Session Management among different tabs and
                        time out
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-195 Redirection from Supported Symbols fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-193 Fixes to Session Management among different
                        tabs and time out
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.2 - 9th Nov 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-195 Redirection from Supported Symbols fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.1 - 6th Nov 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-193 Automatic login disabled
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.9.0 - 4th Nov 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-194 Allowed more special characters in Asset symbol
                        search
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.9 - 3rd Nov 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        uuid added to API calls
                      </li>
                      <li className="section-list-item">
                        uuid added to all path URLs
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Check for empty Timing and Performance data for Market
                        symbols
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.8 - 2nd Nov 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-190 Special Characters and Numbers not allowed in
                        Asset and Benchmark search
                      </li>
                      <li className="section-list-item">
                        #VUI-191 Search in Supported Symbols modal fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-192 Analyze button is disabled when processing
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.7 - 30th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-189 Uploaded CSV file name available after
                        redirecting back to Watchlist page
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.6 - 29th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        System Watchlist Timing flow handled
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-186 Uploaded CSV file name in watchlist table
                      </li>
                      <li className="section-list-item">
                        #VUI-187 Previous and Current Dates added in Timing Diff
                        modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.5 - 28th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-183 User is redirected from login screen when valid
                        token is present
                      </li>
                      <li className="section-list-item">
                        #VUI-181, #VUI-182 Raise a bug button fixes
                      </li>
                      <li className="section-list-item">
                        #VUI-178 Route check for tab names in multiple windows
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.4 - 27th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-175 Select Symbols dropdown is empty, by default
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-176 Close button functionality for Select Symbols
                        dropdown fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-177 Forgot Password flow fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.3 - 23rd Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-171 Download CSV of Supported Symbols
                      </li>
                      <li className="section-list-item">
                        #VUI-170 Limit watchlist to 50 symbols
                      </li>
                      <li className="section-list-item">
                        #VUI-172 Move unsupported symbols to top of watchlist
                        table list
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analyse Asset redirection fixes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.2 - 22nd Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-169 Supported Symbols List available in a modal
                        under Assets tab
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-168 Values for EMA and BB Indicators are now
                        showing up for all dates
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.1 - 21st Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        UUID sent in all API calls
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-166 Y-axis values for Relative Price fixed
                      </li>
                      <li className="section-list-item">
                        Login flow fixes in CreateApiKey and ValidateApiKey when
                        user status is INVALID
                      </li>
                      <li className="section-list-item">
                        #KOSHA-15 Password validation error message in Reset
                        Password
                      </li>
                      <li className="section-list-item">
                        #VUI-168 Values for Indicators are now showing up for
                        all dates
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.8.0 - 20th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Login flow changes in CreateApiKey and ValidateApiKey
                      </li>
                      <li className="section-list-item">
                        Sticky Header in Technical Analysis
                      </li>
                      <li className="section-list-item">
                        Assets in Watchlist added to Analytics event
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Right buttons shown in Technical Analysis
                      </li>
                      <li className="section-list-item">
                        Right buttons hidden in Reset Password
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.9 - 19th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Tooltip for RSI, Relative Price and Correlation moved
                        above respective charts
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Hide Provide Feedback button on logout
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.8 - 16th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-163 Drop-down menu for Video titles in Video
                        Tutorial Modal
                      </li>
                      <li className="section-list-item">
                        #VUI-164 Raise a Bug, Feedback and Video Tutorial
                        buttons available only after login
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.7 - 15th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-157 Raise a bug button position aligned to other
                        buttons
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-160 Tooltip position of chart fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-162 Zero line for Daily Vflow chart
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.6 - 14th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Video Tutorial Button and Modal added with API
                        integration
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-157 Raise a bug button location changed to increase
                        visibility
                      </li>
                      <li className="section-list-item">
                        #VUI-158 Tab label changes in Watchlist
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-156 App crash fix in Analyze Asset
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.5 - 12th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-155 SMA added for VVOL and VFLOW charts
                      </li>
                    </ul>
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Removed symbols with '$' from Watchlist table dropdown
                        list
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.4 - 7th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Google Analytics setup added with events tracking
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.3 - 5th Oct 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-153 Tooltip table column width fixed to avoid
                        flicker
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-153 Timing Model Return data now available in
                        Timing tab
                      </li>
                      <li className="section-list-item">
                        UI fixes in Watchlist screen
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.2 - 29th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-153 Tooltip Position and UI changes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.1 - 28th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Base URL for backend server changed
                      </li>
                    </ul>
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-153 Performance vs Timing chart fixes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.7.0 - 25th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Confirm Logout Modal added
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.9 - 23rd Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Performance vs Timing graphs y axis points fixed
                      </li>
                      <li className="section-list-item">
                        Fixed benchmark value in Analyze Asset getting changed
                        when Watchlist is analyzed with a different asset symbol
                      </li>
                      <li className="section-list-item">
                        Fixed asset symbols graph missing when navigating back
                        to Analyze Asset from a different screen
                      </li>
                      <li className="section-list-item">
                        Added Logout and Report bug options in mobile view
                        overflow menu
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.8 - 22nd Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Fonts fixed in Firefox
                      </li>
                      <li className="section-list-item">
                        As of Date update fix
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.7 - 21st Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Download Template in Safari issue fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.6 - 18th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Timing data for Performance vs Timing chart for single
                        asset mapped to Portolio data
                      </li>
                      <li className="section-list-item">
                        Date added to Performance vs Timing chart tooltip
                      </li>
                      <li className="section-list-item">
                        Scroll locked first two columns in Asset Holdings table
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.5 - 17th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        JIRA Issue Collector script and button added in header
                        and linked to VistaKosha Jira project{" "}
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Login flow updated to validate key if already present in
                        Cognito
                      </li>
                      <li className="section-list-item">
                        Changed "Compare with" to "Benchmark" in Analyze Asset
                        and Watchlists page
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Fixed crash in performance vs. timing chart in watchlist
                        when user removes all the symbols from select symbol
                        search box
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.4 - 16th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Horizontal legend in Performance and Timing chart
                      </li>
                      <li className="section-list-item">
                        Get Asset data with 100 allocation when there is only
                        one symbol present in dropdown in Performance, Timing
                        chart
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        All symbol values are now present in Performance vs
                        Timing chart legend when there are less than 4 assets
                        selected
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.3 - 15th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        JIRA Issue Collector script and button added in header
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Save token to Cognito after login
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.2 - 11th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        JIRA Feedback script added
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Performance, Timing chart Tooltip moved to a fixed
                        position
                      </li>
                      <li className="section-list-item">
                        Dropdown select in Performance, Timing chart and
                        Technical Indicator changed
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Login / Logout flow fixes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.1 - 10th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Logout button added in header
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Login / Logout flow handled
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Cleared File Reader value to allow upload of same file
                        again
                      </li>
                      <li className="section-list-item">
                        Watchlist table field populated appropriately after
                        uploading and exported CSV
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.6.0 - 9th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Forgot Password and Reset Password flow
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-141 Legend names in Performance, Timing Tabs
                      </li>
                      <li className="section-list-item">
                        #VUI-132 Tab name is Performance when all Timing data is
                        0
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-145 Clicking home icon in breadcrumb redirects to
                        main Market overview.
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.9 - 4th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        AWS Cognito integrated for Login
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.8 - 4th Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Timing Difference Modal in Watchlist
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.7 - 1st Sept 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Correlation Chart for Analyze Asset and Watchlist
                      </li>
                      <li className="section-list-item">
                        #VUI-140 'Clear All Entries' button in Watchlist with
                        confirm modal
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-137 Export CSV format in Watchlist fixed to match
                        format in Upload CSV
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.6 - 28th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Relative Price Chart for Analyze Asset and Watchlist
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Added validation for symbols starting with $$ in
                        Watchlist table
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.5 - 27th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Period dropdown and Reset Zoom moved to chart header
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.4 - 26th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Added dummy authentication to check if user is logged in
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.3 - 25th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Show only Performance Chart when Timings data is empty
                        in Analyze Asset
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Aggregate values in Watchlist table rounded off to 3
                        decimal places
                      </li>
                      <li className="section-list-item">
                        UI fixes to table and tabs
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.2 - 24th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-128 Asset Details table removed from Analyze Asset
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-131 N/A for missing values in Performance Chart and
                        Analyze Asset
                      </li>
                      <li className="section-list-item">
                        #VUI-130 "Timing" is made the third column on Watchlist
                        page
                      </li>
                      <li className="section-list-item">
                        #VUI-129 Removed "Max Expected Move Up" and "Max
                        Expected Move Down" from Watchlist page
                      </li>
                      <li className="section-list-item">
                        #VUI-124 Updated portfolio URL to 'watchlist'
                      </li>
                      <li className="section-list-item">
                        #VUI-127 Removed individual symbols on Performace vs
                        Timing chart in Analyze Asset
                      </li>
                      <li className="section-list-item">
                        #VUI-126 Moved Reset Zoom button to the center of chart
                      </li>
                      <li className="section-list-item">
                        #VUI-125 Analyze button styling changed in Analyze Asset
                        page to match theme
                      </li>
                      <li className="section-list-item">
                        #VUI-133 part 5 Mapped Asset holdings table headers
                      </li>
                      <li className="section-list-item">
                        'View Template' changed to 'Download Template
                      </li>
                      <li className="section-list-item">
                        Sort added for Timing column in watchlist table
                      </li>
                      <li className="section-list-item">
                        #VUI-134 Changes to Asset Holdings table download
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analyze button text changed to Re-analyze only after
                        Performance and Timing data is received
                      </li>
                      <li className="section-list-item">
                        Removed extra margin above Watchlist header
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.1 - 21st Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-123 Export to CSV button added for Asset Holdings
                        table on Analyze Asset page
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        MD5 hashing for EmailID, Password, IP Address for Login
                        API
                      </li>
                      <li className="section-list-item">
                        Retain only Performance vs Timing Chart in Analyze Asset
                      </li>
                      <li className="section-list-item">
                        Rounded off values to 3 decimal places in Watchlist
                      </li>
                      <li className="section-list-item">
                        Import CSV template modified
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-122 'As of Date' updated on refresh
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.5.0 - 20th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">Login Screen UI</li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Sort for Asset column added in Watchlist
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Fixed symbol removal in Performance/timing chart
                        dropdown list
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.9 - 19th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-120 Disclaimer Text added
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-119 Upload CSV button UI changed to match theme
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        State management in Analyze Asset
                      </li>
                      <li className="section-list-item">
                        #VUI-121 App crash due to empty timing data fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.8 - 18th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Clear performance and timing data when switching between
                        Analyze Asset and Watchlist
                      </li>
                      <li className="section-list-item">
                        Removed quotes from asset symbol string in CSV template
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.7 - 18th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-112 CSV Template for Watchlist Table
                      </li>
                      <li className="section-list-item">
                        #VUI-113 Export CSV from Watchlist Table
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-110 Re-named Portfolio to Watchlist in Closing
                        Price and Full View Chart
                      </li>
                      <li className="section-list-item">
                        #VUI-114 Fixed header for Watchlist Table
                      </li>
                      <li className="section-list-item">
                        #VUI-115 Request/Response Modal fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-116 Upload CSV - capitalize lowercase symbols
                      </li>
                      <li className="section-list-item">
                        #VUI-117 Format text for values in Performance and
                        Timing Tabs
                      </li>
                      <li className="section-list-item">
                        #VUI-118 Negative VFLOW values visible now on hover
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.6 - 17th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Performance, Timing, Performance vs. Timing graphs added
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.5 - 14th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-110 Renamed Home to Market Overview and Analyze
                        Portfolio to Watchlists
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-109 Analyze button is active when table has valid
                        values
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.4 - 13th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        CSV Upload Assets List for Analyze Portfolio
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-108 App crash in Analyze Portfolio fixed
                      </li>
                      <li className="section-list-item">
                        Maintaining app state after chart full view
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.3 - 12th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-108 App crash in Analyze Asset fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.2 - 11th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-107 Displayed date in crosshair for Timing Modal
                        Chart
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        App crash in Analyze Assets for few asset symbols fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.1 - 10th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-84 MACD plotting fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.4.0 - 7th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-105 Horizontal Lines for RSI
                      </li>
                      <li className="section-list-item">
                        #VUI-103 Analyze Asset Values added for Comparison
                      </li>
                      <li className="section-list-item">
                        Timing Model Performance Chart added in Analyze Asset
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Range Selector Modal for RSI
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-106 SMA window close option fixed
                      </li>
                      <li className="section-list-item">
                        #VUI-100 Increased padding for graph values
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.9 - 6th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-84 RSI indicator plotted in Closing Price graph
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        About API changes integrated
                      </li>
                      <li className="section-list-item">
                        VFLOW graph y-axis values are not truncated
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.8 - 5th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-101 Added commas to VFLOW values
                      </li>
                      <li className="section-list-item">
                        #VUI-102 Displaying crosshair values for Percentage
                        Gains Chart
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.7 - 4th Aug 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-100 Expanded chart values UI fix
                      </li>
                      <li className="section-list-item">
                        Crash in Analyze Portfolio for few symbols when
                        allocation was 1 fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.6 - 31st July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        'As of' date added to Header
                      </li>
                      <li className="section-list-item">
                        #VUI-99 VVOL chart type changed
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-97 Crosshair synchronizing for all charts
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.5 - 30th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-97 Crosshair synchronizing for all charts - In
                        progress
                      </li>
                      <li className="section-list-item">
                        #VUI-84 MACD graph in Closing Price - In progress
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-95 Range Selection label made same for both charts
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.4 - 29th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        About API integrated for Product Team Release Notes
                      </li>
                      <li className="section-list-item">
                        #VUI-96 Pop-out chart added for Percentage Gains
                      </li>
                      <li className="section-list-item">
                        #VUI-84 Technical Analysis button added to view full
                        chart with options to add indicators
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-95 Range Selection label made same for both charts
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.3- 27th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-94 Analyze Asset UI changes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.2 - 24th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-84 EMA and Bollinger Bands graph added to Closing
                        Price chart
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-90 Y axis adjusts on Zoom
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.1 - 23rd July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-92 Version check added to get latest deployed code
                      </li>
                    </ul>
                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-90 Y axis adjusts on Zoom
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.3.0 - 22nd July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-90 Y axis adjusts on Zoom
                      </li>
                      <li className="section-list-item">
                        0 line color changed in Percentage Gains chart
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-89 Refresh on Chart View screen fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.9 - 20th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-83 Dropdown for Time Range Selection in Closing
                        Price Chart
                      </li>
                      <li className="section-list-item">
                        #VUI-87 About button added in footer for Product Team
                        Release Notes
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-81 Add Symbol button location re-organized
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-86 Formula for calculating date range added
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.8 - 17th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-82 Enlarge Charts - Asset Level
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        0 Line in Percentage Gain shown
                      </li>
                      <li className="section-list-item">
                        SMA Range is now configurable
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Closing Price graph bugs fixed
                      </li>
                      <li className="section-list-item">
                        Removed time range selection
                      </li>
                      <li className="section-list-item">
                        Removed Rhombus crosshair of SMA
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.7 - 16th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-79 SMA in Closing Price graph
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">#VUI-80 fixed</li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.6 - 15th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Multi-select dropdown options for Percentage Gains Graph
                        with assets sorted by allocation
                      </li>
                      <li className="section-list-item">
                        Zoom option for graphs
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.5 - 14th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Percentage Gains Graph in Analyze Portfolio
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Unsupported Symbol Error Handling
                      </li>
                      <li className="section-list-item">#VUI-71 fixed</li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.4 - 13th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Analyze Asset and Analyze Portfolio UI Changes
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.3 - 10th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-21 Standard Deviation Added
                      </li>
                      <li className="section-list-item">
                        #VUI-60 '%' Symbol next to Avg Move Up and Avg Mode Down
                        numbers
                      </li>
                      <li className="section-list-item">
                        #VUI-72 Firstcut of Comparison Field editable in Analyze
                        Asset
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-71 Fast Type Dropdown List is sorted
                      </li>
                      <li className="section-list-item">
                        #VUI-71 Analyze function runs when symbol is selected or
                        enter key pressed on dropdown suggestion list
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.2 - 9th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Fast Type Dropdown Suggestion List for assets - #VUI-71
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Comparison Asset Option in Analyze Portfolio
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Portfolio Table edit options fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.1 - 8th July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Asset Holdings Columns are now dynamic
                      </li>
                      <li className="section-list-item">
                        Company name tooltip on asset name hover in the Asset
                        Holdings table
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.2.0 - 3rd July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Portfolio Chart cleared on edit/delete action in
                        Portfolio Table
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Issues #VUI-57, #VUI-70 fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.1.9 - 2nd July 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Dropdowns in Market Card
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Issues #VUI-52, #VUI-63, #VUI-64, #VUI-65 fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.1.8 - 30th June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Description Tooltip in Market Card
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        VFlow amount formatted in Market Card
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Parent asset name in Analyze Asset page fixed
                      </li>
                      <li className="section-list-item">
                        Redirect to home page from Analyze Asset fixed
                      </li>
                      <li className="section-list-item">
                        Blank page on market card click fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.1.7 - 29th June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Dropdowns in Market Card
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Landing page delay fixed
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Aggregate Value calculation
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.1.6 - 26th June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        New Landing Page with Market API
                      </li>
                      <li className="section-list-item">
                        API Changes Integrated
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        VFlow graph tooltip format
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Closing Price value in Analyze Asset fixed
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />
                <>
                  <div className="version-number-date">
                    v0.1.5 - 23rd June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Release Notes and Version Number added to footer
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />

                <>
                  <div className="version-number-date">
                    v0.1.4 - 22nd June 2020
                  </div>

                  <div className="release-notes-body">
                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        JSON formatter added to API Details Modal
                      </li>
                      <li className="section-list-item">
                        Portfolio Table changes to Average row
                      </li>
                      <li className="section-list-item">
                        Asset Holdings table header
                      </li>
                      <li className="section-list-item">
                        UI fixes to API Details Modal
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />

                <>
                  <div className="version-number-date">
                    v0.1.3 - 19th June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        New Portfolio API integrated
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />

                <>
                  <div className="version-number-date">
                    v0.1.2 - 18th June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Open API Details in New Tab
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Portfolio Graph Fixes
                      </li>
                    </ul>

                    <div className="section-header-text">Improvements</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        Search text changed to CAPS
                      </li>
                    </ul>
                  </div>
                </>
                <hr className="request-delimiter" />

                <>
                  <div className="version-number-date">
                    v0.1.1 - 17th June 2020
                  </div>
                  <div className="release-notes-body">
                    <div className="section-header-text">What's New</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        API Details Modal Added
                      </li>
                    </ul>

                    <div className="section-header-text">Bug Fixes</div>
                    <ul className="section-list">
                      <li className="section-list-item">
                        #VUI-34, #VUI-35, #VUI-36 fixed
                      </li>
                    </ul>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReleaseNotesModal;
