import React, { Component } from 'react'

class ErrorModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      show: false
    }
  }

  componentDidMount() {
    this.setState({
      show: this.props.show
    });
  }

  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      show: false
    });
  }

  viewLog = () => {
    this.props.viewLog()
  }

  render() {

    const { show } = this.state;
    const { message, showViewLog, showTemplate, downloadTemplate } = this.props;

    const display = show ? "flex" : "none";

    const modalClass = show ? "in" : ""

    return (
      <>
        <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">
                <h4 className="modal-title">Error!
                  <button
                    type="button"
                    onClick={this.close}
                    className="close api-details-modal-close">
                    <i className="fa fa-times"></i>
                  </button>
                </h4>
              </div>

              <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">{message}

                {
                  showTemplate &&
                  <div className="download-template">
                    <i onClick={downloadTemplate} className="fa fa-info-circle">
                      <span className="template-text-modal">Download Template</span>
                    </i>
                  </div>
                }
              </div>
              {
                showViewLog &&
                <span className="input-group-btn" id="view-log-group">
                  <button onClick={this.viewLog} className="btn" id="view-log-btn">View Log</button>
                </span>
              }

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ErrorModal
