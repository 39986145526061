import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import {OverlayTrigger,Tooltip} from 'react-bootstrap'
import '../home/Home.css'
import ErrorModal from '../../layout/ErrorModal';
import ApiModal from '../../layout/ApiModal';
import { validateEmail, validatePassword, today,getTooltipText } from '../../utils/helperFunctions';

import { login, clearLogin, validateKey, createKey, updateApiKey } from '../../actions/AboutAction';
import {
    CognitoUser,
    CognitoUserAttribute,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';
import * as  AmazonCognitoIdentity from "amazon-cognito-identity-js";
import poolData from '../login/UserPool';
import firebaseObj from '../../utils/firebase';
import packageJson from '../../../package.json';
import ForbiddenErrorModal from '../../layout/ForbiddenErrorModal';

const analytics = firebaseObj.analytics();

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            apiModalShow: false,
            apiDetails: [],
            apiResponse: [],
            loading: false,
            username: '',
            password: '',
            emailError: null,
            passwordError: null,
            resetStatus: 1,
            confirmPassword: '',
            confirmError: null,
            oldPassword: '',
            code: '',
            codeError: null,
        }
    }

    componentDidMount() {
        localStorage.setItem('currentRoute', 'resetPassword');
    }

    /**
     * On Show error Modal handle
     * @param {*} err 
     * @param {*} request 
     */
    setErrorState = (err, request) => {
        let apiDetails = [];
        let apiResponse = [];
        let error = err.message ? err.message : "Error Occured !!";
        let errorJson = err;
        apiDetails.push(request);
        apiResponse.push({ error });

        this.setState({
            loading: false,
            error,
            apiDetails,
            apiResponse,
            errorJson: errorJson
        });
    }

    /**
     * On API Modal open handle
     */
    getApiInfo = () => {
        this.setState({
            apiModalShow: true
        })
    }

    /**
     * On API Modal Close handle
     */
    onApiModalClose = () => {
        this.setState({
            apiModalShow: false
        })
    }

    /**
     * On View Log handle
     */
    viewLog = () => {
        this.setState({
            apiModalShow: true,
            error: false
        })
    }

    /**
     * On input field change handle
     * @param {*} type 
     * @param {*} e 
     */
    handleChange = (type, e) => {
        let value = e.target.value;

        this.setState({
            [type]: value,
            passwordError: null,
            codeError: null,
            confirmError: null
        })
    }

    /**
     * On Email submit to get verification code
     */
    onGetCode = () => {
        const { username } = this.state;
        let emailCheck = false;

        this.setState({
            loading: true
        })

        if (username === '') {
            this.setState({
                emailError: 'Please enter username',
                loading: false
            })
        }
        else {
            emailCheck = validateEmail(username);
        }

        if (emailCheck) {
            this.setState({
                emailError: 'Please enter a valid email address',
                loading: false
            })
            return false
        }

        if (!emailCheck) {
            let thisVal = this;

            let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

            const user = new CognitoUser({
                Username: username,
                Pool: userPool,
            });

            user.forgotPassword({
                onFailure: err => {
                    thisVal.onErrorFunc(err);
                },
                inputVerificationCode: data => {
                    if (data) {
                        console.log('data', data);
                    }
                    thisVal.setState({
                        resetStatus: 2,
                        loading: false
                    })
                },
            });
        }
    }

    /**
   * On Create API Key
   */
    onCreateAPIKey = (email) => {
        if (email !== '') {
            this.props.createKey(email,
                () => {
                    const { loginDetails } = this.props.about;
                    this.onCreateAPIKeySuccess(loginDetails);
                },
                (err) => {
                    this.onErrorFunc(err);
                })
        }
        else {
            let err = { 'message': 'Something went wrong! Please login again' };
            this.onErrorFunc(err)
        }
    };

    /**
   * On Create API Key Success
   */
    onCreateAPIKeySuccess = (data) => {
        // update API Key
        this.onUpdateToAWSAPIkey(data.apikey);
    };

    /**
   * API Key Update to Aws Cogito
   */
    onUpdateToAWSAPIkey = (apiKey) => {
        let thisVal = this;
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        const { loginDetails } = this.props.about;

        const attributes = [
            new CognitoUserAttribute({
                Name: 'custom:apikey',
                Value: apiKey,
            }),
        ];

        const cognitoUser = userPool.getCurrentUser();

        cognitoUser.getSession(function (err, session) {
            if (err) {
                console.log('err', err);
            }
            else {
                cognitoUser.updateAttributes(attributes, (err, result) => {
                    if (err) console.error('err', err);
                    if (result) {
                        thisVal.props.updateApiKey(apiKey,
                            () => {
                                analytics.setUserProperties({
                                    email: loginDetails.email,
                                    App_Version: packageJson.version
                                });
                                analytics.logEvent('Login Success');

                                thisVal.props.history.push('/market?uuid=' + today())
                            },
                            (err) => {
                                console.log('err', err)
                            })
                    }
                });
            }
        })
    };

    /**
     * Error Function Handle
     */
    onErrorFunc = err => {
        if (err.message) {
            if (err.code === 'CodeMismatchException') {
                this.setState({
                    codeError: err.message,
                    loading: false
                })
            }
            else {
                this.setState({
                    passwordError: err.message,
                    loading: false
                })
            }
            return;
        }
        this.setState({
            passwordError: 'Something went wrong! Please try again later.',
            loading: false
        })
    };

    /**
     * on REset Password handle
     */
    onResetPassword = () => {
        const { password, confirmPassword, code } = this.state;
        let passwordCheck = false;

        this.setState({
            loading: true
        })

        if (code.length === 0) {
            this.setState({
                codeError: 'Please enter code sent to your email address',
                loading: false
            })
            return false;
        }

        if (password.length === 0) {
            this.setState({
                passwordError: 'Please enter password',
                loading: false
            })
            return false;
        }
        else {
            passwordCheck = validatePassword(password);
        }

        if (passwordCheck) {
            this.setState({
                passwordError: 'Please enter a password with 8 or more characters',
                loading: false
            })
            return false
        }

        if (password !== confirmPassword) {
            this.setState({
                confirmError: 'Passwords do not match!',
                loading: false
            })
            return false;
        }

        if (!passwordCheck) {
            this.setState({
                loading: true
            })
            this.onNewPasswordSubmit(code, password);
        }
    }

    /**
   * On Password update submit
   */
    onNewPasswordSubmit = (code, password) => {
        let thisVal = this;
        let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

        let { username } = this.state;

        const user = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        user.confirmPassword(code, password, {
            onSuccess: () => {
                const authDetails = new AuthenticationDetails({
                    Username: username,
                    Password: password,
                });

                user.authenticateUser(authDetails, {
                    onSuccess: result => {
                        if (result) {
                          localStorage.setItem('accessToken', result?.accessToken?.jwtToken);
                          localStorage.setItem("idToken", result?.idToken.jwtToken);
                            thisVal.onCreateAPIKey(username)
                        }
                    },

                    onFailure: err => {
                        thisVal.setState({
                            loading: false
                        })
                        thisVal.onErrorFunc(err)
                    },
                });
            },

            onFailure: err => {
                thisVal.setState({
                    loading: false
                })
                thisVal.onErrorFunc(err)
            },
        });
    };

    render() {
        const { history } = this.props;
        const { apiModalShow, apiResponse, apiDetails, loading, error, errorJson, confirmError, codeError, code,
            username, password, emailError, passwordError, resetStatus, confirmPassword } = this.state;

        let errorView = !loading && error ? (
            <>
                <ErrorModal
                    show={true}
                    message={error}
                    viewLog={this.viewLog}
                    showViewLog={true}
                />
            </>
        ) : null;

        return (
            <>
                <Header history={history} />
                <div id="page-wrapper" className="m-l-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-12">
                                <div className="white-box login-white-box">
                                    {
                                        resetStatus === 1 ?
                                            <div className="login-form">
                                                <input
                                                    className={emailError ? 'error-input' : ''}
                                                    value={username}
                                                    autocomplete="off"
                                                    id="username"
                                                    type="search"
                                                    placeholder="Enter Email Address"
                                                    onChange={this.handleChange.bind(this, 'username')}
                                                />
                                                <span className={emailError ? 'show-error' : 'hide-error'}>{emailError}</span>
                                                <div className="tooltipx">
                                                <button
                                                    onClick={this.onGetCode}
                                                    className="btn login-btn">
                                                    Submit {loading && <i className="fa fa-spinner fa-spin"></i>}
                                                </button>
                                                <div className="tooltiptext-div">{"Enter your Email Address and click Submit to reset your password."}</div>
                                                </div>
                                            </div>
                                            :
                                            <div className="login-form">
                                                <input
                                                    className={codeError ? 'error-input' : ''}
                                                    value={code}
                                                    autocomplete="off"
                                                    id="code"
                                                    type="text"
                                                    placeholder="Enter Code"
                                                    onChange={this.handleChange.bind(this, 'code')}
                                                />
                                                <span className={codeError ? 'show-error' : 'hide-error'}>{codeError}</span>

                                                <input
                                                    className={passwordError ? 'error-input' : ''}
                                                    value={password}
                                                    autocomplete="new-password"
                                                    id="password"
                                                    type="password"
                                                    placeholder="Enter new password"
                                                    onChange={this.handleChange.bind(this, 'password')}
                                                />
                                                <span className={passwordError ? 'show-error' : 'hide-error'}>{passwordError}</span>

                                                <input
                                                    className={passwordError ? 'error-input' : ''}
                                                    value={confirmPassword}
                                                    autocomplete="new-password"
                                                    id="password"
                                                    type="password"
                                                    placeholder="Confirm password"
                                                    onChange={this.handleChange.bind(this, 'confirmPassword')}
                                                />
                                                <span className={confirmError ? 'show-error' : 'hide-error'}>{confirmError}</span>

                                                <button
                                                    onClick={this.onResetPassword}
                                                    className="btn login-btn">
                                                    Submit {loading && <i className="fa fa-spinner fa-spin"></i>}
                                                </button>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer location="resetPassword" onClick={this.getApiInfo} />
                    {errorView}
                    <ApiModal
                        show={apiModalShow}
                        apiDetails={apiDetails}
                        onClose={this.onApiModalClose}
                        apiResponse={apiResponse}
                        history={this.props.history}
                        errorJson={errorJson}
                        screen='home'
                    />
                    <ForbiddenErrorModal
                    history={history}/>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    about: state.AboutReducer
});

export default connect(mapStateToProps, { login, clearLogin, createKey, validateKey, updateApiKey })(ResetPassword)