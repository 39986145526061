export const ACTIONS = Object.freeze({
  FETCH_ASSET: "FETCH_ASSET",
  FETCH_CHART_DATA: "FETCH_CHART_DATA",
  FETCH_PORTFOLIO: "FETCH_PORTFOLIO",
  CLEAR_PORTFOLIO: 'CLEAR_PORTFOLIO',
  FETCH_MULTIPLE_CHART_DATA: "FETCH_MULTIPLE_CHART_DATA",
  UPDATE_ASSETS: "UPDATE_ASSETS",
  RESET_PORTFOLIO_CHART: "RESET_PORTFOLIO_CHART",
  FETCH_PORTFOLIO_NEW: 'FETCH_PORTFOLIO_NEW',
  FETCH_FAMILY_CHART_DATA: 'FETCH_FAMILY_CHART_DATA',
  FETCH_FAMILY_DATA: 'FETCH_FAMILY_DATA',
  FETCH_ASSET_FAMILY_DATA: 'FETCH_ASSET_FAMILY_DATA',

  FETCH_TIMING_DATA: 'FETCH_TIMING_DATA',

  FETCH_MARKET_DATA: 'FETCH_MARKET_DATA',
  UPDATE_PORTFOLIO_PROPS: 'UPDATE_PORTFOLIO_PROPS',
  SUPPORTED_SYMBOLS: 'SUPPORTED_SYMBOLS',

  ABOUT: 'ABOUT',
  GET_LOGS: 'GET_LOGS',

  ASSET_TIMING_DATA: 'ASSET_TIMING_DATA',
  ASSET_PERFORMANCE_DATA: 'ASSET_PERFORMANCE_DATA',
  SINGLE_ASSET_TIMING: 'SINGLE_ASSET_TIMING',
  SINGLE_ASSET_PERFORMANCE: 'SINGLE_ASSET_PERFORMANCE',
  CLEAR_SINGLE_DATA: 'CLEAR_SINGLE_DATA',

  UPDATE_CHART_ZOOM_VALUES: 'UPDATE_CHART_ZOOM_VALUES',

  WATCHLIST_TIMING_DATA: 'WATCHLIST_TIMING_DATA',
  WATCHLIST_PERFORMANCE_DATA: 'WATCHLIST_PERFORMANCE_DATA',

  CLEAR_ASSET_DATA: 'CLEAR_ASSET_DATA',
  LOGIN: 'LOGIN',
  CLEAR_LOGIN: 'CLEAR_LOGIN',
  SET_USER_GROUP: 'SET_USER_GROUP',

  CREATE_KEY: 'CREATE_KEY',
  VALIDATE_KEY: 'VALIDATE_KEY',

  GET_TRAINING_VIDEO: 'GET_TRAINING_VIDEO',

  GET_WATCHLIST_TIMINGS: 'GET_WATCHLIST_TIMINGS',
  ALBERTO_TIMING_DATA: 'ALBERTO_TIMING_DATA',

  COGNITO_CHECK: 'COGNITO_CHECK',

  GET_BUCKET_DETAILS: 'GET_BUCKET_DETAILS',
  UPDATE_VALUES: 'UPDATE_VALUES',
  UPDATE_COMPARE_VALUES: 'UPDATE_COMPARE_VALUES',
  UPDATE_BENCHMARK_VALUES: 'UPDATE_BENCHMARK_VALUES',

  UPDATE_WATCHLIST_VALUES: 'UPDATE_WATCHLIST_VALUES',
  UPDATE_WATCHLIST_COMPARE_VALUES: 'UPDATE_WATCHLIST_COMPARE_VALUES',
  UPDATE_WATCHLIST_BENCHMARK_VALUES: 'UPDATE_WATCHLIST_BENCHMARK_VALUES',
  UPDATE_WATCHLIST_PERF_VALUES: 'UPDATE_WATCHLIST_PERF_VALUES',
  UPDATE_WATCHLIST_TIMING_VALUES: 'UPDATE_WATCHLIST_TIMING_VALUES',

  SAVE_WATCHLIST: 'SAVE_WATCHLIST',
  GET_WATCHLIST: 'GET_WATCHLIST',

  GET_KOSHA_WATCHLIST: 'GET_KOSHA_WATCHLIST',
  GET_WATCHLIST_DATA: 'GET_WATCHLIST_DATA',
  GET_KOSHA_WATCHLISTS: 'GET_KOSHA_WATCHLISTS',
  GET_USER_WATCHLISTS: 'GET_USER_WATCHLISTS',

  FETCH_SAVED_WATCHLIST_DETAILS: 'FETCH_SAVED_WATCHLIST_DETAILS',
  FORBIDDEN_ERROR : 'FORBIDDEN_ERROR',
  FIREBASE_TOKEN_SEND:'FIREBASE_TOKEN_SEND',
  VERSION_UPDATE: 'VERSION_UPDATE',
  TOOLTIPS_JSON:'TOOLTIPS_JSON',
});