import packageJson from "../../package.json";

const versionUpdate = (currentBuildVersions) => {
  if(!currentBuildVersions){
    return false;
  }
  let host = window.location.host;
  let hostName = "beta";
  let currentVersion = packageJson.version;

  if (
    // host === "http://beta.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com/" || 
    // host === "https://beta.kosha.vistalytics.com"||
    host === "beta.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com" ||
    host === "beta.kosha.vistalytics.com"){
      hostName = "beta";
  } else if(
    host === "https://kosha.vistalytics.com" ||
    host === "kosha.vistalytics.com"){
      hostName = "prod"
  }

  let newVersions = currentBuildVersions.split("|");
  let version = "";
  if (hostName === "dev") version = newVersions[0].split("dev:")[1];
  if (hostName === "beta") version = newVersions[1].split("beta:")[1];
  if (hostName === "prod") version = newVersions[2].split("prod:")[1];

  // console.log(host, hostName, version)

  let first = currentVersion.split('.')[2];
  let second = version.split('.')[2];
  let shouldUpdate = false;
  if(first.split('+')[0]===second.split('+')[0] ){
    shouldUpdate = first.split('+')[1]-second.split('+')[1]<0?true:false;
  }
  else{
    shouldUpdate =first.split('+')[0]<second.split('+')[0];
  }

  // console.log("ShouldUpdate -> ", shouldUpdate)
  //return shouldUpdate
  return shouldUpdate
};

export default versionUpdate;
