import React from "react";
import { alphabetArray } from "../utils/helperFunctions";
const AlphabeticSlider = ({ onAlphaChange, activeAlpha }) => {
  return (
    <>
      <div className="alphabetic-slider">
        <div className="alphabet-slider-div">
          {alphabetArray().map((alphabet) => {
            return (
              <div
                key={alphabet}
                className={
                  activeAlpha === alphabet
                    ? "app-element-alpha active-alphabet"
                    : "app-element-alpha"
                }
                onClick={() => onAlphaChange(alphabet)}
              >
                {alphabet}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AlphabeticSlider;
