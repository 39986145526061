// dev
const poolData = {
    UserPoolId: 'us-east-2_dZxxF8lWb',
    ClientId: '3caljelv6qt1sevao0dcpcqa8k',
};

// beta/prod
// const poolData = {
//     UserPoolId: 'us-east-1_racOYWXkj',
//     ClientId: 'qg3ojaqf6gakto4eeg1p5t1mn',
// };

export default poolData;