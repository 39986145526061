import React, { Component } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currBranch } from '../../../utils/const';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default class CopyTimingsModal extends Component{
	constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
			newBranch: currBranch !== 'Dev'? 'Dev' : 'Prod',
			allOrSelected: 'all',
			symbolList: [],
			symbolListString: '',
			symbolListError: '',
			tables: {
				indicators: true, 
				timings: true
			},
			checkboxError: false
    };
	}

	onConfirm = () => {
		let tables = []
		let tablesToUpdate = ''
		Object.keys(this.state.tables).forEach((key) => {
			if(this.state.tables[key]){
				tablesToUpdate += `${key}, `
				tables.push(key)
			}
		})
		this.props.setTables(tables)
		this.props.setfromBranch(this.state.newBranch)
		this.props.setConfirmAllOrSelected(this.state.allOrSelected)
		if(this.state.allOrSelected === "selected") {
			this.props.setSupportedSymbols(this.state.symbolList)
		}
		this.props.setConfirmationMessage(`
			Copy timings from ${this.state.newBranch} to ${currBranch}. 
			Symbols to Update: ${(this.state.allOrSelected ==="all") ? this.state.allOrSelected : `${this.state.allOrSelected}: ${this.state.symbolListString}`}.
			Tables to Update: ${tablesToUpdate}
		`)
		this.props.setConfirmType('orange')
		this.props.onClose()
		this.props.showConfirmSelectionModal()
	}

	onCloseConfirmModal = () => {
		this.setState({showConfirmationModal: !this.state.showConfirmationModal})
	}

	onChangeSymbolListString = (event) => {
		this.setState({symbolListString: event.currentTarget.value.toUpperCase()})
		const symbolList = event.currentTarget.value.toUpperCase().replaceAll(' ', '').split(",")
		let unsupportedSymbols = []
		let supportedSymbols = []
		symbolList.forEach((symbol) => {
			let isSymbolSupported = false
			this.props.supportedSymbols.forEach((supportedSymbol) => {
				if(supportedSymbol.symbol.includes(symbol)){
					isSymbolSupported = true
					let newSymbolList = this.state.symbolList
					newSymbolList.push('symbol')
					this.setState({symbolList: newSymbolList})
				}
			})
			if(isSymbolSupported) {
				supportedSymbols.push(symbol)
				// this.setState((state) => ({symbolListError: `${state.symbolListError} Symbol is not a supported Symbol: ${symbol}.`}))
			}else{
				unsupportedSymbols.push(symbol)
			}
		})
		let symbolListErrorString = ''
		unsupportedSymbols.map((symbol) => {
			symbolListErrorString += `'${symbol}' is not a supported Symbol. `
		})
		this.setState({
			symbolListError: symbolListErrorString,
			symbolList: supportedSymbols
		})
	}

	handleChangeCheckbox = (event) => {
    this.setState({
      ...this.state,
			tables: {
				...this.state.tables,
				[event.target.name]: event.target.checked,
			}
    }, () =>{
			if(Object.values(this.state.tables).filter((v) => v).length === 0){
				this.setState({checkboxError: true})
			}else{
				this.setState({checkboxError: false})
			}
		});
  };

	render(){
		return(
			<Modal
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={this.props.style}>
                    <Box>
                        <Typography sx={{fontSize: '1.6em'}}>The Data Below Will be Copied into {currBranch}</Typography>

                        <FormControl onSubmit={this.onConfirm} required>
                            <FormLabel sx={{color: "white", fontSize: '1em', display: 'flex'}}><Typography sx={{fontSize: '1em !important'}}>Select Branch to Copy Timings From:</Typography></FormLabel>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label-branches"
                                defaultValue= {currBranch !== 'Dev' ? 'Dev': 'Prod'}
                                name="radio-buttons-group-branches"
                                value={this.state.newBranch}
                                onChange={(event) => this.setState({newBranch: event.currentTarget.value})}
                            >
                                {currBranch !== 'Dev' && <FormControlLabel value="Dev" control={<Radio />} label="Dev" sx={{fontSize: '1em !important'}}/>}
                                {currBranch !== 'Beta' && <FormControlLabel value="Beta" control={<Radio />} label="Beta" />}
                                {currBranch !== 'Prod' && <FormControlLabel value="Prod" control={<Radio />} label="Prod" />}
                            </RadioGroup>

                            <FormLabel sx={{color: "white", fontSize: '1em', display: 'flex'}}><Typography sx={{fontSize: '1em !important'}}>Select Which Symbols to Copy:</Typography></FormLabel>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label-allorselected"
                                defaultValue= 'all'
                                name="radio-buttons-group-allorselected"
                                value={this.state.allOrSelected}
                                onChange={(event) => this.setState({allOrSelected: event.currentTarget.value})}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="All"/>
                                <FormControlLabel value="selected" control={<Radio />} label="Selected (separate symbols by commas)" />
                            </RadioGroup>

                            <TextareaAutosize
                                aria-label="empty textarea"
                                disabled={this.state.allOrSelected === "all"}
                                placeholder="Empty"
                                maxRows={4}
                                style={{ width: 200, marginLeft: '22px' }}
                                value={this.state.symbolListString}
                                onChange={this.onChangeSymbolListString}
                            />
                            {this.state.symbolListError && <Typography sx={{color: 'red', marginTop:'0.5rem'}}>{this.state.symbolListError}</Typography>}

                            <FormLabel sx={{color: "white", fontSize: '1em', display: 'flex', marginTop:'1rem'}}><Typography sx={{fontSize: '1em !important'}}>Select Tables to Copy:</Typography></FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.tables.indicators} onChange={this.handleChangeCheckbox} name="indicators" />
                                    }
                                    label="Indicators"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.tables.timings} onChange={this.handleChangeCheckbox} name="timings" />
                                    }
                                    label="Timings"
                                />
                            </FormGroup>
                            {this.state.checkboxError && <Typography sx={{color: 'red'}}>Please select at least one table to copy</Typography>}
                            {/* <FormHelperText>You can display an error</FormHelperText> */}
                        </FormControl>
                    </Box>


                    <Box sx={{display: 'flex', justifyContent: 'space-between', margin: 'auto'}}>
                        <button className="btn waves-effect waves-light m-b-5 m-t-10" 
                            disabled={
                                this.state.symbolListError || 
                                this.state.checkboxError || 
                                (this.state.allOrSelected==="selected" && this.state.symbolList.length ===0)} 
                            onClick={this.onConfirm}
                            >Confirm</button>
                        <button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.props.onClose}>Cancel</button>
                    </Box>
                </Box>
            </Modal>
		)
	}
}