import React, { Component } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currBranch } from '../../../utils/const';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default class ConfirmSelectionModal extends Component {
	onConfirm = () => {
		// Need to hook up to apis once I get them
		this.props.onClose()
		this.props.onConfirmClick()
	}

	render(){
		return(
			<Modal
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
				<Box sx={this.props.style}>
					<Box sx={{marginBottom: '10px'}}>
						<Typography sx={{fontSize: '1.6em !important'}}>This action is Irreversible. Please confirm your selection:</Typography>
						<Typography sx={{fontSize: '1em !important', marginTop: '10px', color: "white"}}>{this.props.message}</Typography>
					</Box>

					<Box sx={{display: 'flex', justifyContent: 'space-between', margin: 'auto'}}>
						<button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.onConfirm}>Confirm</button>
						<button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.props.onClose}>Cancel</button>
					</Box>
				</Box>
			</Modal>
		)
	}
}