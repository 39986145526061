import React, { Component } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currBranch } from '../../../utils/const';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default class SendWatchlistMessageModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            isMessageIncluded: false,
            message: ''
        }
    }

    onConfirm = () => {
        let message = (!this.state.isMessageIncluded) ? "": this.state.message
        this.props.setMessage(message)
        this.props.setConfirmationMessage(`Send a Watchlist Email to All Users. ${(message !== '') ? `Message: "${message}"` : 'No Message Included.'}`)
        this.props.onClose()
        this.props.showConfirmSelectionModal()
    }

    render() {
        return(
            <Modal
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={this.props.style}>
                    <Box>
                        <Typography sx={{fontSize: '1.6em'}}>Send A Watchlist Email to Users on {currBranch}</Typography>

                        <FormControl onSubmit={this.onConfirm} required>
                            <FormLabel sx={{color: "white", fontSize: '1em', display: 'flex'}}><Typography sx={{fontSize: '1em !important'}}>Select Message to Append to the Email</Typography></FormLabel>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label-branches"
                                defaultValue= {false}
                                name="radio-buttons-group-branches"
                                value={this.state.isMessageIncluded}
                                onChange={(event) => this.setState({isMessageIncluded: event.currentTarget.value})}
                            >
                                <FormControlLabel value={false} control={<Radio />} label="No Message" sx={{fontSize: '1em !important'}}/>
                                <FormControlLabel value={true} control={<Radio />} label="Message" />
                                
                            </RadioGroup>

                            {/* <Box sx={{marginLeft:'22px', marginTop:'2rem'}}> */}
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    disabled={!this.state.isMessageIncluded}
                                    placeholder="Empty"
                                    maxRows={4}
                                    style={{ width: 200, marginLeft: '22px', marginBottom: '2rem' }}
                                    value={this.state.message}
                                    onChange={(event) => this.setState({message: event.currentTarget.value})}
                                />
                            {/* </Box> */}
                        </FormControl>
                    </Box>


                    <Box sx={{display: 'flex', justifyContent: 'space-between', margin: 'auto'}}>
                        <button className="btn waves-effect waves-light m-b-5 m-t-10" 
                            
                            onClick={this.onConfirm}
                            >Confirm</button>
                        <button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.props.onClose}>Cancel</button>
                    </Box>
                </Box>
            </Modal>
        )
    }
}
