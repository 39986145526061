import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Dropdown, { MenuItem } from "@trendmicro/react-dropdown";

import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import logo from "../assets/img/vistalytics-logo.png";

import { clearLogin, getAboutData, getTrainingVideo } from "../actions/AboutAction";
import { getSupportedSymbols } from "../actions/AssetAction";
import ConfirmModal from "../layout/ConfirmModal";
import jQuery from "jquery";
import firebaseObj from "../utils/firebase";
import packageJson from "../../package.json";
import TutorialModal from "./TutorialModal";
import SymbolsModal from "./SymbolsModal";
import { sortArray } from "../utils/helperFunctions";
import uuid from "react-uuid";
import IdleTimer from "react-idle-timer";
import { BUG_SCRIPT_URL, FEEDBACK_SCRIPT_URL } from "../utils/const";
import { today } from "../utils/helperFunctions";
import { getTooltipText } from "../utils/helperFunctions";
import accessTokenUpdate from "../actions/accessTokenUpdate"
import Banner from './Banner'
import CustomTooltip from '../components/common/CustomTooltip'

import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

const analytics = firebaseObj.analytics();

// window.ATL_JQ_PAGE_PROPS = jQuery.extend(window.ATL_JQ_PAGE_PROPS, {
//   // ==== custom trigger function ====
//   triggerFunction: function (showCollectorDialog) {
//     console.log("triggerFunction defined")
//     jQuery("#feedback-button").on("click", function (e) {
//       console.log("jquery trigger function called")
//       e.preventDefault();
//       showCollectorDialog();
//     });
//     // add any other custom triggers for the issue collector here
//   },
// });

const PATHS = Object.freeze({
  ASSET: "asset",
  PORTFOLIO: "watchlist",
  MARKET: "market",
  ALBERTO: "alberto",
});

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      confirmLogoutModal: false,
      showTutorial: false,
      videoReady: false,
      showSymbols: false,
      symbolsReady: false,
      showTimeoutModal: false,
      isTimedOut: false,
      timeout: 7200000,
      // timeout: 20
    };

    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  toggleDropdown = () => {
    console.log(this.state.toggled)
    this.setState({
      toggled: !this.state.toggled,
    }, () => console.log(this.state.toggled));
  };

  refreshData(type) {
    if (this.props.refreshData) this.props.refreshData(type);
  }

  onLogout = () => {
    this.setState({
      confirmLogoutModal: true,
    });
  };

  cancel = () => {
    this.message_broadcast(this.idleTimer?.getLastActiveTime());
    this.resetTimer();

    this.setState({
      confirmLogoutModal: false,
      showTutorial: false,
      videoReady: false,
      symbolsReady: false,
      showSymbols: false,
      showTimeoutModal: false,
      timeout: 7200000,
    });
  };

  confirmLogout = () => {
    const { loginDetails } = this.props.about;

    clearInterval(this.callUpdateAccessToken)

    this.resetTimer();
    this.setState({
      confirmLogoutModal: false,
      showTimeoutModal: false,
      isTimedOut: false,
      timeout: 7200000,
    });

    analytics.setUserProperties({
      email: loginDetails?.email,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Logout");

    this.props.clearLogin(
      () => {
        window.sessionStorage.setItem("token", "");
        window.sessionStorage.setItem("reload", "false");
        localStorage.clear();
        this.message_broadcast("logout");
        this.props.history.push("/");
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  componentDidMount() {
    // window.ATL_JQ_PAGE_PROPS = jQuery.extend(window.ATL_JQ_PAGE_PROPS, {
    //   // ==== custom trigger function ====
    //   triggerFunction: function (showCollectorDialog) {
    //     console.log("triggerFunction defined")
    //     jQuery("#feedback-button").on("click", function (e) {
    //       console.log("jquery trigger function called")
    //       e.preventDefault();
    //       showCollectorDialog();
    //     });
    //     // add any other custom triggers for the issue collector here
    //   },
    // });

    // TODO: look at syntax and figure out how to get rid of empty callbacks
    // this.props.getAboutData(this.props.about.id,
    //   () => {},
    //   () => {});
    this.props.getAboutData('idk why we need an id', 
        () => {}, 
        () => {})

    this.callAboutApi = setInterval(() => {
      // dispatch call to about api to check if ETL is running
      this.props.getAboutData('idk why we need an id', 
        () => {}, 
        () => {})
    }, 1000*60)

    setTimeout(() => {
      if (
        localStorage.getItem("loginDetails") &&
        localStorage.getItem("accessToken")
      ) {
        analytics.logEvent("Login");
        this.message_broadcast("login");
      }
    }, 1000);
    const { loginDetails } = this.props.about;

    // script 2 causes bugs for autologout
    const script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = FEEDBACK_SCRIPT_URL;
    // script2.src = "www.google.com"
    script2.async = true;

    let autoLogin = localStorage.getItem("autoLogin");
    let reloadCheck = window.sessionStorage.getItem("reload");

    if (autoLogin === "true" || reloadCheck === "true") {
      this.message_broadcast("new_tab");
    }

    // if (loginDetails !== null && loginDetails.apikey !== '') {
    if (loginDetails !== null) {
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.src = BUG_SCRIPT_URL;
      script1.async = true;
      document.head.append(script1);
      // document.head.append(script2);

      window.addEventListener("storage", this.message_receive);
      this.callUpdateAccessToken = setInterval(() => {
        // console.log("setInterval called")
        accessTokenUpdate()
      }, 1000*60*60)
    // } else {
      // let feedbackBtn = document.getElementById("atlwdg-trigger");

      // if (feedbackBtn) feedbackBtn.style.display = "none !important";
    }

    window.addEventListener("beforeunload", this.beforeUnload);
    window.addEventListener("load", this.onPageLoad);
  }

  componentWillUnmount(){
    clearInterval(this.callUpdateAccessToken)
  }

  beforeUnload = (event) => {
    this.message_broadcast("tab_close");
    window.sessionStorage.setItem("reload", "true");
  };

  onPageLoad = () => {};

  message_receive = (event) => {
    if (event.key === "timeout") {
      if (event.newValue === "logout") {
        localStorage.clear();
        this.props.clearLogin(
          () => {
            window.sessionStorage.setItem("token", "");
            this.props.history.push("/");
          },
          (err) => {
            console.log("err", err);
          }
        );
      } else if (event.newValue === "new_tab") {
        localStorage.setItem("tab", "many");
        localStorage.setItem("autoLogin", "true");
      } else if (event.newValue === "tab_close") {
        localStorage.setItem("tab", "many");
        localStorage.setItem("autoLogin", "true");
      } else if (event.newValue === "reload") {
        window.location.reload();
      } else {
        var timeout = JSON.parse(event.newValue);

        if (timeout) {
          localStorage.setItem("tab", "many");
          localStorage.setItem("autoLogin", "true");

          this.setState({
            showTimeoutModal: false,
            isTimedOut: false,
          });

          if (this.idleTimer) this.idleTimer.reset();
        }
      }
    } else if (event.key === "multiLogin") {
      if (event.newValue === "login") {
        window.location.reload();
      }
    } else return;
  };

  message_broadcast = (lastActiveTime) => { 
    if (lastActiveTime === "logout") {
      localStorage.setItem("timeout", "logout");
      localStorage.setItem("autoLogin", "false");
    } else if (lastActiveTime === "login") {
      localStorage.setItem("multiLogin", "login");
    } else if (lastActiveTime === "new_tab") {
      localStorage.setItem("timeout", "new_tab");
      localStorage.setItem("tab", "many");
    } else if (lastActiveTime === "tab_close") {
      localStorage.setItem("timeout", "tab_close");
      localStorage.setItem("tab", "one");
      localStorage.setItem("autoLogin", "false");
    } else {
      localStorage.setItem("timeout", lastActiveTime);
      localStorage.setItem("tab", "one");
    }
  };

  getTutorial = () => {
    this.setState({
      showTutorial: true,
    });

    this.props.getTrainingVideo(
      () => {
        this.setState({
          videoReady: true,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  getAssetsModal = () => {
    this.setState({
      showSymbols: true,
    });

    if (this.props.supportedSymbols.length === 0) {
      this.props.getSupportedSymbols(
        () => {
          const { supportedSymbols } = this.props;

          let sortedSymbols = supportedSymbols.sort(sortArray);

          this.setState({
            symbols: sortedSymbols,
            symbolsReady: true,
          });
        },
        (err, request) => {
          // this.setErrorState(err, request);
        }
      );
    } else {
      this.setState({
        symbolsReady: true,
      });
    }
  };

  onAssetClick = (asset) => {
    this.setState({
      showSymbols: false,
      symbolsReady: false,
    });

    this.props.history.push("/asset/" + asset.symbol + "?uuid=" + uuid());
  };

  resetTimer = () => {
    this.setState({
      showTimeoutModal: false,
      timeout: 7200000,
    });
  };

  handleOnAction(event) {
    let lastActiveTime = new Date().getTime();
    if (this.idleTimer) lastActiveTime = this.idleTimer.getLastActiveTime();

    this.message_broadcast(lastActiveTime);
    this.resetTimer();
  }

  handleOnActive(event) {
    this.resetTimer();
    console.log("browser is active")
    this.callAboutApi = setInterval(() => {
      // dispatch call to about api to check if ETL is running
      this.props.getAboutData('idk why we need an id', 
        () => {}, 
        () => {})
    }, 1000*60)
    // clearTimeout(this.logoutTimer)
  }

  handleOnIdle(event) {
    // need to rewrite, user not getting logged out on idle?
    console.log("browser is idle")
    // this.logoutTimer = setTimeout(() => {
    //   this.confirmLogout()
    // }, 2000)
    if (this.props.about.loginDetails !== null){
      this.confirmLogout()
      window.sessionStorage.setItem("forbidden", true)
    }
    this.setState({
      isTimedOut: true
    })
    clearInterval(this.callAboutApi)
    // const { isTimedOut } = this.state;

    // if (isTimedOut) {
    //   this.confirmLogout();
    // } else {
    //   this.setState(
    //     {
    //       showTimeoutModal: true,
    //       timeout: 5000,
    //     },
    //     () => {
    //       this.idleTimer.reset();
    //     }
    //   );

    //   this.setState({
    //     isTimedOut: true,
    //   });
    // }
  }

  render() {
    const {
      toggled,
      confirmLogoutModal,
      showTutorial,
      symbolsReady,
      showSymbols,
      videoReady,
      showTimeoutModal,
      timeout,
    } = this.state;
    const toggleClass = toggled ? "open" : "";
    const { pathname } = this.props.history.location;
    const { about, loginDetails, training } = this.props.about;
    const { supportedSymbols, location } = this.props;
    const sessionToken = window.sessionStorage.getItem("token");

    const tabClass =
      pathname.includes(PATHS.PORTFOLIO) ||
      pathname.includes(PATHS.ASSET) ||
      pathname.includes(PATHS.MARKET)
        ? ""
        : "hidden";

    const showData =
      pathname !== "/" &&
      pathname !== "/resetPassword" &&
      pathname !== "/systemWatchlistTiming"
        ? true
        : false;

    return (
      <>
        {showData && loginDetails !== null && sessionToken !== null && (
          <>
            {/* <div className="atlwdg-trigger" id="feedback-button"> */}
              {/* <div className="tooltipx"> */}
                {/* Report a bug  */}
                {/* <div className="tooltiptext-div">
                  {getTooltipText("raiseBug")}
                </div>
              </div> */}
            {/* </div> */}
            <div
              className="video-tutorial-btn atlwdg-trigger"
              onClick={this.getTutorial}
            >
              {/* <div className="tooltipx "> */}
                Video Tutorial
                {/* <div className="tooltiptext-div">
                  {getTooltipText("videoTutorial")}
                </div>
              </div> */}
            </div>
              <div
                className="assets-list-btn atlwdg-trigger"
                onClick={this.getAssetsModal}
              >
            {/* <div className="tooltipx"> */}
                Assets
              {/* <div className="tooltiptext-div">
                {getTooltipText("allAssets")}
              </div>
              </div> */}
            </div>
          </>
        )}
        {pathname !== "/chartView" && (
          <nav className="navbar navbar-default navbar-static-top m-b-0 p-0">
            <div className="navbar-header">
              <div className="container header-container">
                <div className="logo-div">
                  {pathname === "/systemWatchlistTiming" ? (
                    <CustomTooltip title={getTooltipText("vistalyticsLogo")}>
                      <div className="logo">
                        <img
                          src={logo}
                          alt="home"
                          height="50"
                          className="dark-logo"
                        />
                      </div>
                      {/* <div className="tooltiptext-div"> */}
                        {/* {getTooltipText("vistalyticsLogo")} */}
                      {/* </div> */}
                    </CustomTooltip>
                    // <div className="tooltipx">
                    //   <div className="logo">
                    //     <img
                    //       src={logo}
                    //       alt="home"
                    //       height="50"
                    //       className="dark-logo"
                    //     />
                    //   </div>
                    //   <div className="tooltiptext-div">
                    //     {getTooltipText("vistalyticsLogo")}
                    //   </div>
                    // </div>
                  ) : (
                    <Link
                      className="logo"
                      to={{
                        pathname:
                          pathname === "/"
                            ? "/"
                            : loginDetails === null ||
                              loginDetails?.apikey === ""
                            ? "/"
                            : "/watchlist",
                        state: undefined,
                        search:
                          pathname === "/"
                            ? undefined
                            : loginDetails === null ||
                              loginDetails?.apikey === ""
                            ? undefined
                            : "uuid=" + uuid(),
                      }}
                      // onClick={this.refreshData.bind(this, "main")}
                    >
                      <CustomTooltip title={getTooltipText("vistalyticsLogo")}>
                        <div className="logo">
                          <img
                            src={logo}
                            alt="home"
                            height="50"
                            className="dark-logo"
                          />
                        </div>
                      </CustomTooltip>
                    </Link>
                  )}
                  {showData && location !== "notfound" && (
                    <CustomTooltip title={getTooltipText("asOfDate")}>
                      <div className="as-of-date">
                        Data As Of: {about && about.asofdate}
                      </div>
                    </CustomTooltip>
                  )}
                </div>

                {showData && location !== "notfound" ? (
                  <>
                    <ul
                      className={
                        "nav customtab nav-tabs pull-right hidden-xs header-container"
                      }
                      role="tablist"
                    >
                      <li
                        role="presentation"
                        className={
                          pathname.includes(PATHS.PORTFOLIO) ? "active" : ""
                        }
                        id="home-item"
                      >
                        <CustomTooltip title={getTooltipText("watchlist")}>
                          <Link
                            to={{
                              pathname:
                                pathname === "/"
                                  ? "/"
                                  : loginDetails === null ||
                                    loginDetails?.apikey === ""
                                  ? "/"
                                  : "/watchlist",
                              state: undefined,
                              search:
                                pathname === "/"
                                  ? undefined
                                  : loginDetails === null ||
                                    loginDetails?.apikey === ""
                                  ? undefined
                                  : "uuid=" + uuid(),
                            }}
                            >
                            <span className="hidden-xs">Watchlists</span>
                          </Link>
                        </CustomTooltip>
                      </li>

                      <li
                        role="presentation"
                        className={
                          pathname.includes(PATHS.ASSET) ? "active" : ""
                        }
                      >
                        <CustomTooltip title={getTooltipText("analyzeAsset")}>
                          <Link to={"/asset?uuid=" + uuid()}>
                            <span className="hidden-xs">Analyze Asset</span>
                          </Link>
                        </CustomTooltip>
                      </li>

                      {/* <li
                        role="presentation"
                        className={
                          pathname.includes(PATHS.MARKET) ? "active" : ""
                        }
                      >
                        <div className="tooltipx">
                          <Link
                            to={"/market?uuid=" + today()}
                            onClick={this.refreshData.bind(this, "main")}
                          >
                            <span className="hidden-xs">Market Overview</span>
                          </Link>
                          <div className="tooltiptext-div">
                            {getTooltipText("marketOverview")}
                          </div>
                        </div>
                      </li> */}

                      <li
                        role="presentation"
                        className={
                          pathname.includes(PATHS.ALBERTO) ? "active" : ""
                        }
                      >
                        <CustomTooltip title={getTooltipText("alberto")}>
                        <Link to={"/alberto?uuid=" + uuid()}>
                            <span className="hidden-xs">Alberto</span>
                          </Link>
                        </CustomTooltip>
                      </li>

                      <li role="presentation">
                        <Dropdown autoOpen={true}>
                          <Dropdown.Toggle title="" noCaret={true}>
                            <i className="fa fa-user" style={{color:"#96a2b4", marginRight: '0px'}}></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="drop-down-menu">
                            {this.props.isOperator && 
                              <MenuItem
                              className="dropdown-menu-right"
                              onClick={() => this.props.history.push("/operator")}
                            >
                              Operator
                            </MenuItem>}
                            <MenuItem
                              className="dropdown-menu-right"
                              onClick={this.onLogout}
                            >
                              Logout
                            </MenuItem>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                      {/* <li>
                      <Button
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                      >
                        Dashboard
                      </Button>
                      </li> */}
                    </ul>
                  </>
                ) : null}

                {!tabClass && showData ? (
                  <ul className="nav navbar-top-links navbar-right pull-right visible-xs">
                    <li className={"dropdown " + toggleClass}>
                      <div
                        className="dropdown-toggle profile-pic"
                        data-toggle="dropdown"
                        onClick={this.toggleDropdown}
                      >
                        <i className="icon-options-vertical"></i>
                      </div>
                      <ul className="dropdown-menu dropdown-user animated flipInY">
                        <li>
                          <Link
                            to={{
                              pathname:
                                pathname === "/"
                                  ? "/"
                                  : loginDetails === null ||
                                    loginDetails?.apikey === ""
                                  ? "/"
                                  : "/watchlist",
                              state: undefined,
                              search:
                                pathname === "/"
                                  ? undefined
                                  : loginDetails === null ||
                                    loginDetails?.apikey === ""
                                  ? undefined
                                  : "uuid=" + uuid(),
                            }}
                          >
                            Watchlists
                          </Link>
                        </li>

                        <li>
                          <Link to={"/asset?uuid=" + uuid()}>
                            Analyze Asset
                          </Link>
                        </li>

                        {/* <li>
                          <Link
                            to={"/market?uuid=" + today()}
                            onClick={this.refreshData.bind(this, "main")}
                          >
                            Market Overview
                          </Link>
                        </li> */}

                        <li>
                          <Link to={"/alberto?uuid=" + uuid()}>Alberto</Link>
                        </li>

                        <li
                          role="presentation"
                          id="feedback-button"
                          style={{ marginTop: "8px" }}
                          onClick={this.onLogout}
                        >
                          Logout
                        </li>
                      </ul>
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </nav>
        )}

        <ConfirmModal
          logoutModal={true}
          show={confirmLogoutModal}
          confirm={this.confirmLogout}
          cancel={this.cancel}
        />

        <ConfirmModal
          logoutModal={false}
          timeoutModal={true}
          show={showTimeoutModal}
          confirm={this.confirmLogout}
          cancel={this.cancel}
        />

        <TutorialModal
          show={showTutorial}
          cancel={this.cancel}
          videos={training}
          videoReady={videoReady}
        />

        <SymbolsModal
          show={showSymbols}
          cancel={this.cancel}
          symbols={supportedSymbols}
          symbolsReady={symbolsReady}
          onAssetClick={this.onAssetClick}
        />

        {showData && (
          //autologout function
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            timeout={1000*60*31}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  about: state.AboutReducer,
  supportedSymbols: state.AssetReducer.supportedSymbols,
  isOperator: state.AboutReducer.isOperator
});

export default connect(mapStateToProps, {
  clearLogin,
  getAboutData,
  getTrainingVideo,
  getSupportedSymbols,
})(Header);
