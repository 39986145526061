import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import { persistStore, persistReducer } from "redux-persist";
// import logger from 'redux-logger';
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['AboutReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  let store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)));
  let persistor = persistStore(store);
  return { store, persistor };
}