import firebase from 'firebase';
import 'firebase/analytics';
import 'firebase/performance';

let hostName = window.location.host;

var firebaseConfig = {
    apiKey: "AIzaSyDzUpGJYAnePG3BQvy8Va9LOiMUzr5xCTs",
    authDomain: "kosha-dev.firebaseapp.com",
    projectId: "kosha-dev",
    storageBucket: "kosha-dev.appspot.com",
    messagingSenderId: "40765638647",
    appId: "1:40765638647:web:dd2c2fe61ae6acb20710f2",
    measurementId: "G-JMGZ4PSQY2"
};

// var firebaseConfig = {
//     apiKey: "AIzaSyBmqvxwpJz2BICI2yXuGne9frzhI_eFVxI",
//     authDomain: "vistalytics.firebaseapp.com",
//     databaseURL: "https://vistalytics.firebaseio.com",
//     projectId: "vistalytics",
//     storageBucket: "vistalytics.appspot.com",
//     messagingSenderId: "341206457545",
//     appId: "1:341206457545:web:ca211ca0b179dcbc9f4118",
//     measurementId: "G-6SBM10FXCJ"
// };

// if (hostName === 'dev.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com') {
//     firebaseConfig = {
//         apiKey: "AIzaSyBVSEcjzuigB6H1UkWiHBaUtpZrDR9cIEE",
//         authDomain: "vista-kosha-dev.firebaseapp.com",
//         databaseURL: "https://vista-kosha-dev.firebaseio.com",
//         projectId: "vista-kosha-dev",
//         storageBucket: "vista-kosha-dev.appspot.com",
//         messagingSenderId: "626842969715",
//         appId: "1:626842969715:web:3d10b527c606a1bd5dec06",
//         measurementId: "G-JQQEX6VYM2"
//     }
// }

// Initialize Firebase
const firebaseObj = firebase.initializeApp(firebaseConfig);
const firebasePerformance = firebase.performance();

export default firebaseObj;