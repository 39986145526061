import React, { Component } from 'react'

import '../asset/Asset.css';
import HeatMap from './HeatMap';

import Dropdown, {
    MenuItem,
} from '@trendmicro/react-dropdown';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import { formatDate } from '../../utils/helperFunctions';

class AssetData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            dropdownItems: [],
            menuOpen: false,
            stayOpen: false,
            parentDropdown: []
        }
    }

    getDropdownMenu = (parent) => {
        if (parent.children) {
            return parent.children.map((data, index) => {
                return (
                    <MenuItem
                        className="dropdown-menu-right"
                        eventKey={data}
                        key={index}
                        onClick={(event) => {
                            this.onDropdownSelect(data, parent, event)
                        }}
                    >
                        {data.symbol}
                        {
                            data.children &&
                            <span className="right-arrow-span" onClick={this.onArrowClick.bind(this, data, parent)}>
                                <i className="fa fa-angle-right" aria-hidden="true"></i>
                            </span>
                        }
                    </MenuItem>
                )
            })
        }
    }

    onArrowClick = (data, parent, e) => {
        e.stopPropagation();

        if (data.children) {
            let parentDropdown = this.state.parentDropdown;
            let addParent = true;

            parentDropdown.forEach((data) => {
                if (data.symbol === parent.symbol) {
                    addParent = false
                }
            })

            if (addParent)
                parentDropdown.push(parent);

            this.setState({
                dropdownItems: data,
                stayOpen: true,
                parentDropdown
            })
        }
    }

    onDropdownSelect = (data, parent, e) => {
        e.preventDefault();
        e.stopPropagation();
        let parentDropdown = [];

        this.state.parentDropdown.forEach((data) => {
            parentDropdown.push(data);
        })
        parentDropdown.push(parent);

        this.props.goToCategory(data, parentDropdown, 'menu');
        this.setState({
            dropdownItems: this.props.data,
            parentDropdown: []
        })
    }

    onCardClick = (data, from) => {
        this.props.onCardClick(data, from)
    }

    componentDidMount() {
        if (this.state.dropdownItems.length === 0) {
            this.setState({
                dropdownItems: this.props.data
            })
        }
    }

    componentDidUpdate(prevProps, nextState) {
        if (this.props.data.symbol !== prevProps.data.symbol) {
            this.setState({
                dropdownItems: this.props.data
            })
        }
        if (this.state.stayOpen && !nextState.stayOpen) {
            this.setState({
                menuOpen: true,
                stayOpen: false
            })
        }
    }

    onBackClick = (e) => {
        e.stopPropagation();

        const { parentDropdown } = this.state;
        let currentItems = parentDropdown[parentDropdown.length - 1]
        parentDropdown.pop();

        this.setState({
            dropdownItems: currentItems,
            stayOpen: true,
            parentDropdown
        })
    }

    render() {
        const { symbol, timing, risk, vflow, vvol, children, description } = this.props.data;
        const { dropdownItems, menuOpen, stayOpen, parentDropdown } = this.state;

        if (vflow) {
            vflow.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });
        }

        return (
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" id="market-div-body">
                <div className="market-div"
                    onClick={this.onCardClick.bind(this, this.props.data, 'card')}>
                    <div className="py-5">
                        <div className="row">
                            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                <h3 className="box-data-title home-page-card-title">
                                    <span className="card-title-text">{symbol}
                                        {
                                            description &&
                                            <span class="tooltiptext">{description}</span>
                                        }
                                    </span>
                                </h3>
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                {
                                    children &&
                                    <Dropdown
                                        autoOpen={true}
                                        open={this.state.menuOpen}
                                        pullRight={true}
                                        onToggle={(open) => {
                                            if (open && !menuOpen) {
                                                this.setState({ menuOpen: true })
                                            }
                                            else if (!open && menuOpen) {
                                                this.setState({ menuOpen: false })
                                            }
                                            else if (stayOpen) {
                                                this.setState({ menuOpen: true })
                                            }
                                        }}
                                    >
                                        <Dropdown.Toggle
                                            title=""
                                            noCaret={false}
                                        />
                                        <Dropdown.MenuWrapper>
                                            <Dropdown.Menu className="drop-down-menu">
                                                {
                                                    parentDropdown.length !== 0 &&
                                                    <>
                                                        <MenuItem
                                                            className="dropdown-menu-header-item">
                                                            <h5 className="dropdown-menu-header" onClick={this.onBackClick.bind(this)}>
                                                                <span>
                                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                                                </span>
                                                                {dropdownItems.symbol}
                                                            </h5>
                                                        </MenuItem>

                                                        <MenuItem
                                                            divider={true}
                                                            className="dropdown-menu-right">
                                                        </MenuItem>
                                                    </>
                                                }
                                                {this.getDropdownMenu(dropdownItems)}
                                            </Dropdown.Menu>
                                        </Dropdown.MenuWrapper>
                                    </Dropdown>
                                }
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>

                        <div className="row">
                            {
                                risk ?
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <h3 className="box-data-title home-page-box-title">RISK</h3>
                                        <h3 className="box-data-text home-page-box-text">{risk.toFixed(3)}</h3>
                                    </div>
                                    :
                                    risk === 0 ?
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <h3 className="box-data-title home-page-box-title">RISK</h3>
                                            <h3 className="box-data-text home-page-box-text">{'0'}</h3>
                                        </div>
                                        : null
                            }

                            {
                                vvol ?
                                    <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4">
                                        <h3 className="box-data-title home-page-box-title">VVOL</h3>
                                        <h3 className="box-data-text home-page-box-text">{vvol}</h3>
                                    </div>
                                    :
                                    vvol === 0 ?
                                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4">
                                            <h3 className="box-data-title home-page-box-title">VVOL</h3>
                                            <h3 className="box-data-text home-page-box-text">{'0'}</h3>
                                        </div>
                                        : null
                            }

                            {
                                timing &&
                                <div className="col-lg-5 col-md-5 col-sm-4 col-xs-4">
                                    <h3 className="box-data-title home-page-box-title">Timing</h3>
                                    <h3 className="box-data-text home-page-box-text">{timing}</h3>
                                </div>
                            }
                        </div>
                    </div>

                    {
                        vflow &&
                        <div className="heat-map-div">
                            <div className="heat-map-header">
                                <h3 className="box-data-title heat-map-date-title">{formatDate(vflow[0].date)}</h3>
                                <h3 className="box-data-title heat-map-title">VFLOW</h3>
                                <h3 className="box-data-title heat-map-date-title">{formatDate(vflow[vflow.length - 1].date)}</h3>
                            </div>
                            <HeatMap data={vflow} />
                        </div>
                    }

                </div>
            </div >
        )
    }
}

export default (AssetData)