import React, { Component } from 'react';
import { connect } from 'react-redux';
import { validatePassword } from '../utils/helperFunctions';
import {
    CognitoUser,
    AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import * as  AmazonCognitoIdentity from "amazon-cognito-identity-js";
import poolData from '../components/login/UserPool';

import { getWatchlistTimings } from '../actions/WatchlistTimingsAction';
import firebaseObj from '../utils/firebase';
import packageJson from '../../package.json';
const analytics = firebaseObj.analytics();

class PasswordModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            password: '',
            loading: false,
            passwordError: null
        }
    }

    close = (message) => {
        if (this.props.cancel) {
            this.props.cancel(message);
        }
        this.setState({
            show: false
        });
    }

    handleChange = (e) => {
        this.setState({
            password: e.target.value,
            passwordError: null
        })
    }

    onPasswordSubmit = () => {
        const { password } = this.state;
        const { username, type, timing } = this.props;
        let passwordCheck = false;

        this.setState({
            loading: true
        });

        if (password.length === 0) {
            this.setState({
                passwordError: 'Please enter password',
                loading: false
            })
            return false;
        }
        else {
            passwordCheck = validatePassword(password);
        }

        if (passwordCheck) {
            this.setState({
                passwordError: 'Please enter a password with 8 or more characters',
                loading: false
            })
            return false
        }

        if (!passwordCheck) {
            let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

            const user = new CognitoUser({
                Username: username,
                Pool: userPool,
            });

            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: password
            });

            user.authenticateUser(authDetails, {
                onSuccess: result => {
                    if (result) {
                        this.props.getWatchlistTimings(type, timing,
                            () => {
                                analytics.setUserProperties({
                                    email: username,
                                    App_Version: packageJson.version
                                });
                                analytics.logEvent('System Watchlist', {
                                    type: type,
                                    timing: timing
                                });

                                this.close('CSV downloaded successfully');
                            },
                            (err) => {
                                console.log('err', err);
                                this.close(err);
                            })
                    }
                },

                onFailure: err => {
                    const { message } = err;

                    this.setState({
                        passwordError: message,
                        loading: false
                    })
                }
            });
        }
    }

    render() {
        const { show, username } = this.props;
        const { password, loading, passwordError } = this.state;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : "";

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm password-modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title symbols-modal-title">Enter password</h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                                <>
                                    <div className="login-form" id="password-modal-form">
                                        <div className="password-modal-username">{username}</div>
                                        <input
                                            className={passwordError ? 'error-input' : ''}
                                            value={password}
                                            autoComplete="new-password"
                                            id="password"
                                            type="password"
                                            placeholder="Enter password"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                        <span className={passwordError ? 'show-error' : 'hide-error'}>{passwordError}</span>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <button
                                            onClick={this.onPasswordSubmit}
                                            className="btn login-btn">
                                            Submit {loading && <i className="fa fa-spinner fa-spin"></i>}
                                        </button>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, { getWatchlistTimings })(PasswordModal)
