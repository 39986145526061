import React, { Component } from "react";
import { connect } from "react-redux";

import info from "../assets/img/info_small.png";
import ReleaseNotesModal from "../layout/ReleaseNotesModal";
import AboutModal from "./AboutModal";
import packageJson from "../../package.json";
import VersionUpdateModal from './VersionUpdateModal'
import { getAboutData,forbiddenErrorModal} from "../actions/AboutAction";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showAbout: false,
    };
  }
  componentDidUpdate(){

  }
  async componentDidMount() {
    try {
      setInterval(() => {
        if(Math.floor((Date.now() - window.sessionStorage.getItem("SESSION_TIME"))/(60*1000))>=60){
        this.props.forbiddenErrorModal();
        }
      },7200000);
    } catch (error) {
      console.log(error);
    }
    // try {
    //   setInterval(async () => {
    //     let host = window.location.host;
    //     let hostName = 'dev';
    //     let currentVersion = packageJson.version;

    //     if (host !== 'dev.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com' && host !== 'localhost:3000')
    //       hostName = 'beta'

    //     let prevAbout = this.props.about;

    //     this.props.getAboutData(prevAbout?.id,
    //       (result) => {
    //         let newVersions = result.currentBuildVersions.split('|');
    //         let version = '';
    //         if (hostName === 'dev') {
    //           version = newVersions[0].split('dev:')[1];
    //         }
    //         else
    //           version = newVersions[1].split('beta:')[1];

    //         if (currentVersion < version)
    //           window.location.reload();
    //       },
    //       (err) => {
    //       }
    //     );
    //   }, 7200000)
    // }
    // catch (e) {
    // }
  }

  onInfoClick = () => {
    this.props.onClick();
  };

  onReleaseNotesClick = () => {
    let hostName = window.location.host;

    if (
      hostName ===
        "dev.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com" ||
      hostName === "localhost:3000"
    ) {
      this.setState({
        show: true,
      });
    }
  };

  onAboutClick = () => {
    this.setState({
      showAbout: true,
    });
  };

  onClose = () => {
    this.setState({
      show: false,
      showAbout: false,
    });
  };

  render() {
    const year = new Date().getFullYear();
    const { show, showAbout } = this.state;
    const { location } = this.props;

    let hostName = window.location.host;
    let showData = location !== "systemWatchlistTiming" ? true : false;
    let infoButtonVisible = false;

    if (
      hostName ===
        "dev.kosha.vistalytics.com.s3-website-us-east-1.amazonaws.com" ||
      hostName === "localhost:3000"
    ) {
      infoButtonVisible = true;
    }

    return (
      <>
        <div className="disclaimer">
          DISCLAIMER: Please remember that past performance may not be
          indicative of future results. Different types of investments involve
          varying degrees of risk, and there can be no assurance that the future
          performance of any specific investment, investment strategy, or
          product made reference to directly or indirectly in this website will
          be profitable or equal any corresponding indicated historical
          performance level(s).
        </div>

        <footer className="footer text-center">
          <div className="col-md-4 col-sm-4 col-xs-4 no-padding">
            {showData && (
              <>
                <span
                  className="version-text"
                  onClick={this.onReleaseNotesClick}
                >
                  Version {packageJson.version}
                </span>
                <span className="version-text" onClick={this.onAboutClick}>
                  Product Release Notes
                </span>
              </>
            )}
          </div>

          <span className="col-md-4 col-sm-4 col-xs-4 no-padding">
            Copyright © {year} Vistalytics Inc
          </span>

          <span className="col-md-4 col-sm-4 col-xs-4 no-padding">
            {showData && infoButtonVisible && (
              <img
                src={info}
                alt="home"
                className="info-image"
                onClick={this.onInfoClick}
              />
            )}
          </span>
        </footer>

        <ReleaseNotesModal show={show} onClose={this.onClose} />

        <AboutModal
          show={showAbout}
          onClose={this.onClose}
          data={this.props.about}
        />
        {this.props.versionUpdate &&(
        <VersionUpdateModal/>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  about: state.AboutReducer.about,
  versionUpdate:state.AboutReducer.versionUpdate
});

export default connect(mapStateToProps, { getAboutData,forbiddenErrorModal })(Footer);
