import React, { Component } from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import PasswordModal from '../../layout/PasswordModal';

class SystemWatchlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPasswordModal: false,
            username: '',
            type: '',
            timing: '',
            message: ''
        }
    }

    componentDidMount() {
        const { search } = this.props.location;

        if (search) {
            let searchParams = search.split('?');
            let singleParams = searchParams[1].split('&');
            let type = singleParams[0].split('type=');
            let username = singleParams[1].split('username=');
            let timing = "false"
            if (singleParams[2] !== undefined) {
                let timingDetails = singleParams[2].split('timing=');
                timing = timingDetails[1]
            }

            if (type !== '' && username !== '') {
                this.setState({
                    showPasswordModal: true,
                    username: username[1],
                    timing: timing,
                    type: type[1]
                })
            }
        }
    }

    cancel = (message) => {
        this.setState({
            showPasswordModal: false,
            message: message
        })
    }

    render() {
        const { history } = this.props;
        const { showPasswordModal, username, type, timing, message } = this.state;
        return (
            <>
                <Header history={history} />

                <div id="page-wrapper" className="m-l-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 system-watchlist-row">
                                <div className="system-watchlist-header">System Watchlist Timings</div>
                                <div className="system-watchlist-message">{message}</div>
                            </div>
                        </div>
                    </div>
                    <Footer location="systemWatchlistTiming" />
                </div>

                <PasswordModal
                    show={showPasswordModal}
                    cancel={this.cancel}
                    username={username}
                    timing={timing}
                    type={type}
                />
            </>
        )
    }
}

export default SystemWatchlist
