import React, { Component } from 'react'
import SynchChart from './SynchChart'
import RangeSelectorModal from '../../layout/RangeSelectorModal'
import PercentageGainsChart from './PercentageGainsChart';
import { rsiData, getRspData, getCorrelationValues } from '../../utils/helperFunctions';
import RsiRangeSelectorModal from '../../layout/RsiRangeSelectorModal';
import TimingsModelChart from './TimingsModelChart';
import Header from '../../layout/Header';

class ChartFullView extends Component {

    constructor(props) {
        super(props)

        let params = this.props.history.location.state;

        this.state = {
            showRangeModal: false,
            smaRange: 50,
            title: params ? params.title : null,
            assetChartData: params ? params.assetChartData : '',
            period: params ? params.smaRange : '',
            chartName: params ? params.chartName : '',
            rsiRange: 14,
            showRsiModal: false,
            familyChartData: params ? params.familyChartData : '',
            correlationRange: 14
        }
    }

    componentDidMount() {
        localStorage.setItem('currentRoute', 'fullView');
    }

    onRangeChange = (period) => {
        this.setState({
            period,
            showRangeModal: false,
        })
    }

    onRsiRangeChange = (rsiRange) => {
        this.setState({
            rsiRange,
            showRsiModal: false,
        })
    }

    showRangeModal = () => {
        this.setState({
            showRangeModal: true
        })
    }

    showRsiModal = () => {
        this.setState({
            showRsiModal: true
        })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    UNSAFE_componentWillMount() {
        if (!this.props.history.location.state) {
            this.props.history.goBack();
            return false;
        }
    }

    getRsiValues = () => {
        let closingPriceData = [];
        let rsiWithDate = [];
        let closingprices = this.state.assetChartData.closingprice;

        closingprices.forEach((value) => {
            closingPriceData.push(value.close)
        })

        let rsiValues = rsiData(closingPriceData, this.state.rsiRange);

        let rsiMax = rsiValues.reduce((a, b) => {
            return Math.max(a, b);
        })

        let rsiMin = rsiValues.reduce((a, b) => {
            return Math.min(a, b);
        })

        closingprices.forEach((value, index) => {
            if (index < (this.state.rsiRange - 1))
                rsiWithDate.push({
                    date: new Date(value.date).getTime(),
                    value: null
                })
            else {
                rsiWithDate.push({
                    date: new Date(value.date).getTime(),
                    value: rsiValues[index]
                })
            }
        })

        return [rsiWithDate, rsiMax, rsiMin];
    }

    getRspValues = () => {
        if (this.state.familyChartData) {
            let closingPriceData = [];
            let familyClosingPriceData = [];
            let finalRspData = [];

            let closingprices = this.state.assetChartData.closingprice;
            let familyClosingPrices = this.state.familyChartData.closingprice;

            closingprices.forEach((value) => {
                closingPriceData.push(value.close)
            })

            familyClosingPrices.forEach((value) => {
                familyClosingPriceData.push(value.close)
            })

            let rspData = getRspData(closingPriceData, familyClosingPriceData);

            let rsiMax = rspData.reduce((a, b) => {
                return Math.max(a, b);
            })

            let rsiMin = rspData.reduce((a, b) => {
                return Math.min(a, b);
            })

            closingprices.forEach((value, index) => {
                finalRspData.push({
                    date: new Date(value.date).getTime(),
                    value: rspData[index]
                })
            })

            return [finalRspData, rsiMax, rsiMin];
        }
        else
            return []
    }

    correlationValues = () => {
        if (this.state.familyChartData) {
            let closingPriceData = [];
            let familyClosingPriceData = [];
            let finalCorrelationData = [];
            let range = this.state.correlationRange;

            let closingprices = this.state.assetChartData.closingprice;
            let familyClosingPrices = this.state.familyChartData.closingprice;

            closingprices.forEach((value) => {
                closingPriceData.push(value.close)
            })

            familyClosingPrices.forEach((value) => {
                familyClosingPriceData.push(value.close)
            })

            let correlationValues = getCorrelationValues(closingPriceData, familyClosingPriceData, range);

            closingprices.forEach((value, index) => {
                if (index < range) {
                    finalCorrelationData.push({
                        date: new Date(value.date).getTime(),
                        value: null
                    })
                }
                else {
                    let cIndex = Math.abs(range - index);

                    finalCorrelationData.push({
                        date: new Date(value.date).getTime(),
                        value: parseFloat(correlationValues[cIndex].toFixed(3))
                    })
                }
            })

            return finalCorrelationData;
        }
        else
            return []
    }

    closeModal = () => {
        this.setState({
            showRangeModal: false,
            showRsiModal: false
        })
    }

    render() {
        const { period, showRangeModal, title, assetChartData, chartName, showRsiModal, rsiRange } = this.state;
        let poiData = assetChartData && assetChartData.poi;

        if (poiData && poiData.length !== 0 && assetChartData.closingprice[0].date !== poiData[0].date)
            poiData.reverse();

        return (
            title && chartName &&
                chartName === 'percentageGains' ?
                <div style={{ margin: '20px' }}>
                    <PercentageGainsChart
                        data={assetChartData.percentgains}
                        holdings={assetChartData.holdings}
                        showViewButton={false}
                        goBack={this.goBack}
                    />
                </div>
                :
                chartName === 'timingModel' ?
                    <div style={{ margin: '20px' }}>
                        <TimingsModelChart
                            data={assetChartData}
                            showViewButton={false}
                            goBack={this.goBack}
                        />
                    </div>
                    :
                    <div className="full-view-chart">
                        <Header history={this.props.history} />
                        <SynchChart
                            key="asset"
                            id={'assetChartId'}
                            title={title}
                            data={assetChartData}
                            smaRange={period}
                            showRangeModal={this.showRangeModal}
                            showViewButton={false}
                            goBack={this.goBack}
                            rsiData={this.getRsiValues()}
                            showRsiModal={this.showRsiModal}
                            rsiRange={rsiRange}
                            rspData={this.getRspValues()}
                            correlation={this.correlationValues()}
                            poiData={poiData}
                        />

                        <RangeSelectorModal
                            show={showRangeModal}
                            period={period}
                            onSave={this.onRangeChange}
                            onClose={this.closeModal}
                        />

                        <RsiRangeSelectorModal
                            show={showRsiModal}
                            period={rsiRange}
                            onSave={this.onRsiRangeChange}
                            onClose={this.closeModal}
                        />
                    </div>

        )
    }
}

export default ChartFullView
