import { MONTHS } from './const';
import { TIMING_VALUES } from './const';
import * as Moment from "moment";

// All date utils do not require conversion to EST; 
// Dates come in as strings and are converted to UTC strings somewhere else in the codebase

export function formatDate(date) {
    // let currentDate = new Date(date);
    let currentDate = Moment.tz(date, "UTC")

    let day = currentDate.date();
    let month = MONTHS[currentDate.month()];

    return day + ' ' + month
}
export function getTooltipText(key){
    let tooltipsJosn =JSON.parse(sessionStorage.getItem("tooltips"));
    if(tooltipsJosn)
    return tooltipsJosn[key];
}
export function formatFullDate(date) {
    // let currentDate = convertUTCDateToLocalDate(new Date(date));
    let currentDate = Moment.tz(date, "UTC")

    let day = currentDate.date();
    let month = MONTHS[currentDate.month()];
    let year = currentDate.year();

    return day + ' ' + month + ' ' + year
}

export function today() {
    let todayLocalTime = new Date();
    let today = Moment.tz(todayLocalTime, "UTC")
    let month = today.month() + 1;
    let date = today.date();

    if (month < 10)
        month = '0' + month;
    if (date < 10)
        date = '0' + date;
    let dateStr = today.year() + "-" + month + "-" + date;
    return dateStr
}

export function formatDownloadDate(date) {
    // let currentDate = new Date(date);
    let currentDate = Moment.tz(date, "UTC")

    let hours = currentDate.hours();
    let minutes = currentDate.minutes();
    let day = currentDate.date();
    let month = currentDate.month() + 1;
    let year = currentDate.year();

    let finalHour = hours < 10 && hours > 0 ? '0' + hours.toString() + ':' : hours === 0 ? '' : hours.toString() + ':';
    let finalMins = minutes < 10 && minutes > 0 ? '0' + minutes.toString() : minutes === 0 ? '' : minutes.toString();

    let finalDate = month.toString() + '/' + day.toString() + '/' + year.toString() + '-' + finalHour + finalMins;
    return finalDate;
}

export function timingDateFormat(date) {
    //TODO: find out where this gets invoked
    let currentDate = new Date(date);
    // let dateUTC = Moment.tz(date, "UTC")
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return currentDate.toLocaleDateString(undefined, options);
}

export function formatVflow(amount) {
    let formattedAmount = Math.abs(amount);
    let formattedString = '';

    if (formattedAmount > 1000000) {
        formattedAmount = (formattedAmount / 1000000).toFixed(2);
        formattedString = '$ ' + formatAmount(formattedAmount) + " M";
    }
    else if (formattedAmount > 1000) {
        formattedAmount = (formattedAmount / 1000).toFixed(2);
        formattedString = '$ ' + formatAmount(formattedAmount) + " k";
    }
    else {
        formattedString = '$ ' + formatAmount(formattedAmount);
    }

    if (amount < 0)
        return '-' + formattedString
    else
        return formattedString
}

export function formatVFlowTicks(amount) {
    let result = Math.floor(amount);

    if(Math.abs(amount) < 1000) result = amount.toFixed(2)
    if(Math.abs(amount) > 1000) result = Math.floor(amount/1000) + "k"
    if(Math.abs(amount) > 1000000) result = Math.floor(amount / 1000000) + "M"
    if(Math.abs(amount) > 1000000000) result = (amount / 1000000000).toFixed(2) + "B"
    if(Math.abs(amount) > 10000000000) result = Math.floor(amount / 1000000000) + "B"

    return formatAmount(result);
}

export function sortArray(a, b) {
    if (a && b) {
        let firstValue = a.symbol || a.watchlistname;
        let secondValue = b.symbol || b.watchlistname;
        let comparison = 0;
        if (firstValue.toLowerCase() > secondValue.toLowerCase())
            comparison = 1;
        else if (firstValue.toLowerCase() < secondValue.toLowerCase())
            comparison = -1;

        return comparison;
    }
}

export function sortArrayDesc(a, b) {
    if (a && b) {
        let firstValue = a.symbol || a.watchlistname;
        let secondValue = b.symbol || b.watchlistname;

        let comparison = 0;
        if (firstValue.toLowerCase() < secondValue.toLowerCase())
            comparison = 1;
        else if (firstValue.toLowerCase() > secondValue.toLowerCase())
            comparison = -1;

        return comparison;
    }
}

export function sortByAllocation(a, b) {
    if (a && b) {
        let firstValue = a.allocation;
        let secondValue = b.allocation;

        let comparison = 0;
        if (firstValue < secondValue)
            comparison = 1;
        else if (firstValue > secondValue)
            comparison = -1;

        return comparison;
    }
}

export function sortAllocation(a, b) {
    if (a && b) {
        let firstValue = a.allocation;
        let secondValue = b.allocation;

        let comparison = 0;
        if (firstValue > secondValue)
            comparison = -1;
        else if (firstValue < secondValue)
            comparison = 1;

        return comparison;
    }
}

export function rsiData(data, range) {
    let sum_up = 0;
    let sum_down = 0;
    let average_up = 0;
    let average_down = 0;
    let rsi = [];
    let size_data = 0;

    for (let i = 1; i < range; i++) {
        if (data[i] > data[i - 1]) {
            sum_up += (data[i] - data[i - 1]);
        }
        if (data[i] < data[i - 1]) {
            sum_down += (data[i] - data[i - 1]) * -1;
        }
    }

    average_up = sum_up / range;
    average_down = sum_down / range;

    let tmp = average_up + average_down;
    if (tmp === 0)
        tmp = 0.001;

    rsi[range - 1] = average_up / tmp * 100;
    size_data = data.length;

    for (let i = range; i < size_data; i++) {
        if (data[i] > data[i - 1]) {
            average_up = ((average_up * (range - 1)) + (data[i] - data[i - 1])) / range;
            average_down = ((average_down * (range - 1)) + 0) / range;
            rsi[i] = (average_up / (average_up + average_down)) * 100;
        }
        else if (data[i] < data[i - 1]) {
            average_up = ((average_up * (range - 1)) + 0) / range;
            average_down = ((average_down * (range - 1)) + (data[i] - data[i - 1]) * -1) / range;
            rsi[i] = (average_up / (average_up + average_down)) * 100;
        }
        else {
            rsi[i] = rsi[i - 1];
        }
    }

    return rsi;
}

export function getRspData(asset, family) {
    let rspData = [];
    let lastNonZeroValue = '';

    family.forEach((value, index) => {
        let rspValue = '';
        if (value !== 0) {
            if (asset[index] !== null)
                rspValue = parseFloat((asset[index] / value).toFixed(3));
            else
                rspValue = null;

            lastNonZeroValue = value;
        }
        else {
            rspValue = parseFloat((asset[index] / lastNonZeroValue).toFixed(3));
        }
        rspData.push(rspValue);
    })

    return rspData;
}

export function getCorrelationValues(asset, family, range) {
    let correlationCoefft = [];
    if (asset.length === 0) { return; }

    //calculate correlation coefficient for rest of the ranges
    for (let index = 0; index <= asset.length; index++) {
        let sum1 = 0;
        let sum2 = 0;
        let sumSq1 = 0;
        let sumSq2 = 0;
        let product = 0;

        for (let nextIndex = index; nextIndex < range + index; nextIndex++) {
            sum1 += asset[nextIndex];
            sum2 += family[nextIndex];
            sumSq1 += Math.pow(asset[nextIndex], 2);
            sumSq2 += Math.pow(family[nextIndex], 2);
            product += asset[nextIndex] * family[nextIndex];
        }

        let avg1 = sum1 / range;
        let avg2 = sum2 / range;
        let sumAvg1 = sumSq1 / range;
        let sumAvg2 = sumSq2 / range;
        let productAvg = product / range;

        let assetVariance = sumAvg1 - Math.pow(avg1, 2);
        let familyVariance = sumAvg2 - Math.pow(avg2, 2);
        let covariance = productAvg - (avg1 * avg2);
        let coefft = covariance / Math.sqrt(assetVariance * familyVariance);
        correlationCoefft[index] = coefft;
    }

    return correlationCoefft;
}

function formatAmount(amount) {
    return amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function validateEmail(username) {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(username)) {
        return true
    }
    else
        return false
}

export function validatePassword(password) {
    if (password.length < 8) {
        return true
    }
    else
        return false
}

export function getHeader(col) {
    switch (col) {
        case 'timing':
            return 'Timing';
        case 'vvol':
            return 'VVOL';
        case 'vflow':
            return 'VFLOW';
        case 'riskprofile':
            return 'Risk Profile';
        case 'riskprofilenumber':
            return 'Risk Profile Number';
        case 'pricemovementup':
            return 'Price Movement Up';
        case 'pricemovementdown':
            return 'Price Movement Down';
        case 'maxpriceup':
            return 'Max Price Up';
        case 'maxpricedown':
            return 'Max Price Down';
        case 'stddevup':
            return 'Standard Deviation Up';
        case 'stddevdown':
            return 'Standard Deviation Down';
        default:
            return col.toUpperCase()
    }

}

export function getSymbolsForTable(symbols) {
    let tableSymbols = [];
    symbols.forEach((asset) => {
        if (asset) {
            let symbol = asset.symbol;
            let splitSymbol = symbol.split('$');

            if (splitSymbol[0] !== '')
                tableSymbols.push(asset);
        }
    })

    return tableSymbols
}

export function asciiValueCheck(asciiValue) {
    if ((asciiValue >= 65 && asciiValue <= 90) || asciiValue === 46 || asciiValue === 94 || asciiValue === 36)
        return true
    else
        return false
}

export function watchlistNameCheck(asciiValue) {
    if ((asciiValue >= 65 && asciiValue <= 90) ||(asciiValue >= 97 && asciiValue <= 122)  || (asciiValue >= 48 && asciiValue <= 57) || asciiValue=== 32 || asciiValue===45 || asciiValue === 95)
        return true
    else
        return false
}

function returnGoMessage(latestTiming) {
    const { CASH, NEUTRAL } = TIMING_VALUES;
    let returnMessage = latestTiming === CASH || latestTiming === NEUTRAL ?
        'Go To CASH' :
        'Go ' + latestTiming
    return returnMessage;
}

function returnStayMessage(position) {
    const { CASH, NEUTRAL } = TIMING_VALUES;
    let returnMessage = position === CASH || position === NEUTRAL ?
        'Stay in CASH' :
        'Stay ' + position;
    return returnMessage;
}

export function getAlbertoAction(position, latestTiming, timingChange) {
    const { LONG, SHORT, CASH, NEUTRAL } = TIMING_VALUES;

    if (position === CASH) {
        if (timingChange === 'No') {
            return returnStayMessage(position)
        }
        else {
            if (latestTiming === NEUTRAL) {
                return returnStayMessage(position)
            }
            else if (latestTiming === LONG || latestTiming === SHORT) {
                return returnGoMessage(latestTiming)
            }
        }
    }
    else if (position === LONG) {
        if (timingChange === 'No') {
            if (latestTiming === NEUTRAL || latestTiming === SHORT) {
                return returnGoMessage(CASH)
            }
            else if (latestTiming === LONG) {
                return returnStayMessage(position)
            }
        }
        else if (timingChange === 'Yes') {
            if (latestTiming === NEUTRAL || latestTiming === SHORT)
                return returnGoMessage(latestTiming)
            else if (latestTiming === LONG)
                return returnStayMessage(position)
        }
    }
    else if (position === SHORT) {
        if (timingChange === 'No') {
            if (latestTiming === NEUTRAL || latestTiming === LONG)
                return returnGoMessage(CASH)
            else if (latestTiming === SHORT) {
                return returnStayMessage(position)
            }
        }
        else if (timingChange === 'Yes') {
            if (latestTiming === NEUTRAL || latestTiming === LONG)
                return returnGoMessage(latestTiming)
            else if (latestTiming === SHORT)
                return returnStayMessage(latestTiming)
        }
    }
}

function returnSentimentValue(sentiment) {
    if (sentiment <= -8 && sentiment >= -10) {
        return {
            value: 'Extremely Short', id: 'extremely-short'
        }
    }
    else if (sentiment <= -5 && sentiment >= -7) {
        return {
            value: 'Short', id: 'short'
        }
    }
    else if (sentiment <= -2 && sentiment >= -4) {
        return {
            value: 'Somewhat Short', id: 'somewhat-short'
        }
    }
    else if (sentiment >= -1 && sentiment <= 1) {
        return {
            value: 'Neutral', id: 'neutral'
        }
    }
    else if (sentiment >= 2 && sentiment <= 4) {
        return {
            value: 'Somewhat Long', id: 'somewhat-long'
        }
    }
    else if (sentiment >= 5 && sentiment <= 7) {
        return {
            value: 'Long', id: 'long'
        }
    }
    else if (sentiment >= 8) {
        return {
            value: 'Extremely Long', id: 'extremely-long'
        }
    }
}

export function calculateSentimentValue(longAssets, shortAssets, aggregate) {
    let longAssetsSum = 0;
    let shortAssetsSum = 0;

    longAssets.forEach((asset) => {
        longAssetsSum += asset.allocation;
    })

    shortAssets.forEach((asset) => {
        shortAssetsSum += asset.allocation;
    })

    let sentiment = Math.round((((longAssetsSum - shortAssetsSum) / aggregate) * 10));
    return returnSentimentValue(sentiment);
}

function array_sum(values) {
    let sum = 0;
    values.forEach((value) => {
        sum += value;
    })

    return sum;
}

function calculateStdDeviation(values) {
    let devs = [];
    if (values.length > 0) {
        let mean = array_sum(values) / values.length;
        values.forEach((value, index) => {
            devs[index] = Math.pow(value - mean, 2);
        })

        return Math.sqrt((array_sum(devs) / (devs.length)));
    }
    else
        return 0;
}

function array_sum_moves(moves) {
    let sum = 0;
    moves.forEach((move) => {
        sum += move[1];
    })

    return sum;
}

function calculateStdDeviationMoves(moves) {
    let devs = [];
    if (moves.length > 0) {
        let mean = array_sum_moves(moves) / moves.length;
        moves.forEach((move, index) => {
            devs[index] = Math.pow(move[1] - mean, 2);
        })

        return Math.sqrt((array_sum(devs) / (devs.length)));
    }
    else
        return 0;
}

//Sharpe Ratio is the average daily move divided by standard deviation of moves
export function calculateSharpeValue(moves) {
    if(Moment(moves[moves.length - 1][0]).diff(Moment(moves[0][0]), 'months') < 6) return "N/A"
    // console.log("sharpe ratio moves", moves.length, moves)
    //find average of daily moves
    const averageMove = moves.reduce((a, b) => a + b[1], 0) / moves.length
    const stDev = calculateStdDeviationMoves(moves);
    // console.log(stDev, averageMove)
    const test = moves.reduce((a, b) => a + parseFloat(b[1]), 0)
    // console.log(test)
    return averageMove / stDev * Math.sqrt(252)
}

// Longest number of days to reach a new max, takes in either compounded or cumulative data
export function calculateDaysToRecoverAndDrawdown(moves, calculatedValues, chartType) {
    if(Moment(calculatedValues[calculatedValues.length - 1][0]).diff(Moment(calculatedValues[0][0]), 'months') < 6) return "N/A"
    // console.log("CALCULATEDAYSTORECOVER DATA: ", calculatedValues)
    let daysToRecover = 0
    let drawdownArray = []
    let max = 0
    let daysBelowMax = 0
    calculatedValues.forEach((data,index) => {
        if((data[1] > max) || (index === 0)){
            // console.log("NEW MAX -> data, max, dataIndex : ", data[1], max, index)
            max = data[1]
            // if there is a new max days to recover, replace the current value and reset counter
            if(daysBelowMax > daysToRecover) daysToRecover = daysBelowMax
            daysBelowMax = 0
            drawdownArray.push(0)
        }else{
            if(chartType === "compounded") drawdownArray.push((drawdownArray[index-1]+1)*(moves[index][1]+1)-1) 
            if(chartType === "cumulative") drawdownArray.push(drawdownArray[index-1]+moves[index][1])
            // iterate daysBelowMax
            daysBelowMax += 1
            // if we're still iterating at the end of the moves array, the asset has not recovered yet
            if(index === calculatedValues.length-1 && daysBelowMax > daysToRecover) {
                // console.log(index, calculatedValues.length-1, "values", daysBelowMax, daysToRecover)
                daysToRecover = "N/A"
            }
        }
    })
    const drawdown = drawdownArray.reduce((a,b)=> Math.min(a, b))
    // console.log(drawdown, drawdownArray)
    // console.log("daystorecover at end ", daysToRecover)
    return [daysToRecover, drawdown*100]
}

export function calculateReturn(values) {
    let latestValue = values[values.length - 1];
    let firstValue = values[0];
    return latestValue - firstValue;
}

export function calculateWinRate(moves) {
    if (moves.length <120) return "N/A"
    
    let positiveCount = 0
    let negativeCount = 0
    moves.forEach((move) => {
        if(move[1] > 0) positiveCount += 1
        if(move[1] < 0) negativeCount +=1
    })
    const winRate = positiveCount*100/(positiveCount+negativeCount)

    return winRate
}

export function convertUTCDateToLocalDate(date) {
    let newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return newDate;
}

export function round(num, decimalPlaces) {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
}; 

export function addZerosToDecimalPlace(num, places) {
    //todo: make usable for 1 and 2 decimal places
    num = num.toString()
    const dec = num.split('.')[1]
    const len = dec && dec.length > places ? dec.length : places
    return Number(num).toFixed(len)
}

export function alphabetArray(){
   const alphabets = Array.apply(null,{length:26}).map((x,i)=>String.fromCharCode(65+i));
   alphabets.push("$");
   alphabets.push("^");
   return alphabets;
}