import React from 'react'
import { useTable, useSortBy, useExpanded } from 'react-table';

function TimingDiffTable({ data }) {
    const columns = React.useMemo(() => [
        {
            Header: 'Asset',
            accessor: 'asset',
            width: 100,
            sortType: "basic",
            Cell: props => (
                <div>
                    <div className="timing-diff-value">{props.cell.value}  <span className="timing-diff-date">({props.cell.row.original.allocation})</span></div>
                </div>
            )
        },
        {
            Header: "Previous Timing",
            accessor: 'yesterdayValue',
            sortType: "basic",
            Cell: props => (
                <div>
                    <div className="timing-diff-value">{props.cell.value}  <span className="timing-diff-date">({props.cell.row.original.previousDate})</span></div>
                </div>
            )
        },
        {
            Header: "Current Timing",
            accessor: 'todayValue',
            sortType: "basic",
            Cell: props => (
                <div>
                    <div className="timing-diff-value">{props.cell.value} <span className="timing-diff-date">({props.cell.row.original.currentDate})</span></div>                    
                </div>
            )
        },
        {
            Header: "Change",
            accessor: 'change',
            sortType: "basic"
        },
        {
            // Build our expander column
            id: "expander", // Make sure it has an ID
            disableSortBy: true,
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                <span {...getToggleAllRowsExpandedProps()}>
                </span>
            ),
            Cell: ({ row }) => (
                row.canExpand && row.original.asset.split('$')[0] === '' ? (
                    <span
                        {...row.getToggleRowExpandedProps({})}
                    >
                        {
                            row.isExpanded ?
                                <i className="fa fa-caret-up expanded-row-icon" aria-hidden="true"></i> :
                                <i className="fa fa-caret-down expanded-row-icon" aria-hidden="true"></i>
                        }
                    </span>
                ) : null
            )
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useExpanded
    )

    return (
        <div className="timing-diff-modal-table">
            <table {...getTableProps()} className="table">
                <colgroup>
                    <col width="20%" />
                    <col width="30%" />
                    <col width="30%" />
                    <col width="15%" />
                    <col width="1%" />
                </colgroup>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <div className="asset-table-header">
                                        <p className="m-b-0 th-text">
                                            {column.render('Header')}
                                        </p>
                                        {/* Add a sort direction indicator */}
                                        <span className="th-icon">
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <i className="fa fa-caret-down" aria-hidden="true"></i> // ' 🔽'
                                                    : <i className="fa fa-caret-up" aria-hidden="true"></i> // ' 🔼'
                                                : column.disableSortBy ? ""
                                                    : <i className="fa fa-caret-down non-sorted" aria-hidden="true"></i>}
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} id={row.depth === 1 ? 'expanded-row' : 'normal-row'} >
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell', { editable: false })}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default React.memo(TimingDiffTable)