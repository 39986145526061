import React, { Component } from "react";
import { connect } from "react-redux";
import packageJson from "../../package.json";
import firebaseObj from "../utils/firebase";
import { closeVersionUpdateModal} from "../actions/AboutAction";

class VersionUpdateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.versionUpdate,
    };
  }

  onLoginClick = () => {
   
    this.props.closeVersionUpdateModal();
  };

  render() {
    const { show } = this.state;
    const display = show ? "flex" : "none";
    const modalClass = show ? "in" : "";
    return (
      <>
        <div
          className={"modal fade bs-example-modal-sm " + modalClass}
          style={{ display: display,zIndex:10000 }}
        >
          <div className="modal-dialog modal-m">
            <div className="modal-content">
              <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">
                <h4 className="modal-title">
                  New build!
                  {/* <button
                    type="button"
                    // onClick={this.close}
                    className="close api-details-modal-close"
                  >
                    <i className="fa fa-times"></i>
                  </button> */}
                </h4>
              </div>

              <div
                className="modal-body p-t-0 p-l-30 p-b-30 p-r-30"
                id="confirm-modal-body"
              >
                There is a new build available. The page is going to reload to upgrade you to the latest version.
              </div>

              <span className="input-group-btn" id="view-log-group">
                <button
                  onClick={this.onLoginClick}
                  className="btn confirm-modal-btn"
                >
                  OK
                </button>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  versionUpdate: state.AboutReducer.versionUpdate,
});

export default connect(mapStateToProps, {
closeVersionUpdateModal,
})(VersionUpdateModal);
