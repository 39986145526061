import React, { Component } from 'react'
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';

import './Home.css'
import AssetData from '../heatMap/AssetData';
import { connect } from 'react-redux';
import ErrorModal from '../../layout/ErrorModal';
import ApiModal from '../../layout/ApiModal';
import { Link } from 'react-router-dom';

import { getMarketData } from '../../actions/MarketActions';
import firebaseObj from '../../utils/firebase';
import packageJson from '../../../package.json';
import uuid from 'react-uuid';
import { today } from '../../utils/helperFunctions';
import ForbiddenErrorModal from '../../layout/ForbiddenErrorModal';

const analytics = firebaseObj.analytics();

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      apiModalShow: false,
      apiDetails: [],
      apiResponse: [],
      loading: true,
      currentData: []
    }
  }

  componentDidMount() {
    const { loginDetails } = this.props.about;

    let tabCount = localStorage.getItem('tab');
    let autoLogin = localStorage.getItem('autoLogin');
    let reloadCheck = window.sessionStorage.getItem('reload');

    if ((loginDetails === null || Object.keys(loginDetails).length === 0) ||
      ((tabCount !== 'many' || autoLogin !== 'true') && reloadCheck !== 'true')) {
      this.props.history.push('/');
    }
    else {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version
      });
      analytics.logEvent('Market Overview');

      localStorage.setItem('currentRoute', 'home');
      window.sessionStorage.setItem('token', loginDetails.apikey);

      this.setState({
        loading: true
      })

      let currentState = this.props.history.location.state;
      if (currentState) {
        this.checkCurrentState(currentState);
      }
      else {
        this.setState({
          currentData: []
        })
        this.retrieveMarketData();
      }
    }
  }

  checkCurrentState = (currentState) => {
    if (currentState.assetData) {
      this.setState({
        currentData: currentState.assetData.children,
        loading: false
      })
    }
    else if (currentState.subsubcategory && !currentState.assetData) {
      this.setState({
        currentData: currentState.subsubcategory.children,
        loading: false
      })
    }
    else if (currentState.subcategory && !currentState.subsubcategory) {
      this.setState({
        currentData: currentState.subcategory.children,
        loading: false
      })
    }
    else if (currentState.category && !currentState.subcategory) {
      this.setState({
        currentData: currentState.category.children,
        loading: false
      })
    }
  }

  retrieveMarketData = () => {
    this.props.getMarketData('',
      () => {
        if (this.props.marketData.status === 500) {
          this.setErrorState(this.props.marketData, this.props.marketRequest);
        }
        else {
          this.setState({
            apiResponse: this.props.marketData,
            apiDetails: this.props.marketRequest,
            loading: false,
            currentData: this.props.marketData
          })
        }
      },
      (err, request) => {
        this.setErrorState(err, request);
      }
    );
  }

  setErrorState = (err, request) => {
    let apiDetails = [];
    let apiResponse = [];
    let error = err.message ? err.message : "Error Occured !!";
    let errorJson = err;
    apiDetails.push(request);
    apiResponse.push({ error });

    this.setState({
      loading: false,
      error,
      apiDetails,
      apiResponse,
      errorJson: errorJson
    });
  }

  getApiInfo = () => {
    this.setState({
      apiModalShow: true
    })
  }

  onApiModalClose = () => {
    this.setState({
      apiModalShow: false
    })
  }

  goToCategory = (data, parent, from) => {
    const { loginDetails } = this.props.about;

    if (data.children) {
      if (from === 'card') {
        analytics.setUserProperties({
          email: loginDetails.email,
          App_Version: packageJson.version
        });
        analytics.logEvent('Category', { 'asset': data.symbol });

        this.props.history.push({
          pathname: '/category',
          search: '?symbol=' + data.symbol,
          state: { data }
        })
      }
      else {
        let stateArray = [];

        if (parent.length !== 0) {
          stateArray = this.checkParentArray(parent);
        }
        this.checkStateArray(stateArray, data);
      }
    }
    else {
      let stateArray = [];
      if (parent.length !== 0) {
        stateArray = this.checkParentArray(parent);
      }

      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version
      });
      analytics.logEvent('Category', { 'asset': data.symbol });

      this.props.history.push({
        pathname: '/asset/' + data.symbol + '?uuid=' + uuid(),
        state: stateArray
      })
    }
  }

  checkParentArray = (parent) => {
    let stateArray = [];
    if (parent[0]) {
      stateArray.category = parent[0];
    }
    if (parent[1]) {
      stateArray.subcategory = parent[1];
    }
    if (parent[2]) {
      stateArray.subsubcategory = parent[2];
    }
    if (parent[3]) {
      stateArray.assetData = parent[3];
    }
    return stateArray;
  }

  checkStateArray = (prevState, data) => {
    const { loginDetails } = this.props.about;

    if (prevState.subsubcategory) {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version
      });
      analytics.logEvent('Sub Sub Category',
        {
          subcategory: prevState.subcategory.symbol,
          category: prevState.category.symbol,
          subsubcategory: prevState.subsubcategory.symbol,
          asset: data.symbol
        }
      );

      this.props.history.push({
        pathname: '/subsubcategoryAsset',
        search: '?subsubCategoryAsset=' + data.symbol,
        state: {
          subcategory: prevState.subcategory,
          category: prevState.category,
          subsubcategory: prevState.subsubcategory,
          assetData: data
        }
      })
      this.setState({
        currentData: data.children
      })
    }
    else if (prevState.subcategory) {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version
      });
      analytics.logEvent('Sub Category',
        {
          asset: data.symbol,
          subcategory: prevState.subcategory.symbol,
          category: prevState.category.symbol
        }
      );

      this.props.history.push({
        pathname: '/subsubcategory',
        search: '?subsubCategory=' + data.symbol,
        state: {
          subcategory: prevState.subcategory,
          category: prevState.category,
          subsubcategory: data
        }
      })
      this.setState({
        currentData: data.children
      })
    }
    else {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version
      });
      analytics.logEvent('Sub Category',
        {
          'asset': data.symbol,
          category: prevState.category.symbol
        }
      );

      this.props.history.push({
        pathname: '/subcategory',
        search: '?subCategory=' + data.symbol,
        state: {
          subcategory: data,
          category: prevState.category
        }
      })
      this.setState({
        currentData: data.children
      })
    }
  }

  onCardClick = (data, from) => {
    let prevState = this.props.history.location.state;
    const { loginDetails } = this.props.about;

    if (data.children) {
      if (prevState) {
        this.checkStateArray(prevState, data);
      }
      else {
        analytics.setUserProperties({
          email: loginDetails.email,
          App_Version: packageJson.version
        });
        analytics.logEvent('Category', { 'asset': data.symbol });

        this.props.history.push({
          pathname: '/category',
          search: '?symbol=' + data.symbol,
          state: { category: data }
        })

        this.setState({
          currentData: data.children
        })
      }
    }
    else {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version
      });
      analytics.logEvent('Category', { 'asset': data.symbol });

      this.props.history.push({
        pathname: '/asset/' + data.symbol + '?uuid=' + uuid(),
        state: prevState
      })
    }
  }

  getData() {
    return this.state.currentData.map((data, index) => {
      return (
        <AssetData
          key={index}
          index={index}
          data={data}
          goToCategory={this.goToCategory.bind(this)}
          onCardClick={this.onCardClick.bind(this)}
        />
      )
    })
  }

  viewLog = () => {
    this.setState({
      apiModalShow: true,
      error: false
    })
  }

  refreshData(data) {
    if (data === 'main') {
      if (this.props.marketData) {
        this.setState({
          currentData: this.props.marketData
        })
      }
      else {
        this.setState({
          currentData: [],
          loading: true
        })
        if (this.props.about.loginDetails !== null)
          this.retrieveMarketData();
      }
    }
    else {
      this.setState({
        currentData: data.children
      })
    }
  }

  getBreadCrumbData = () => {
    let locationData = this.props.history.location.state;
    const { loginDetails } = this.props.about;

    return (
      <>
        <li className="page-title">
          <Link to={{
            pathname: '/market',
            state: undefined,
            search: loginDetails === null || loginDetails.apikey === '' ? undefined : 'uuid=' + today()
          }}
            onClick={this.refreshData.bind(this, 'main')}>
            <i className="fa fa-home" id="home-image" aria-hidden="true"></i>
          </Link>
        </li>
        {
          locationData.category && locationData.subcategory ?
            <li className="page-title">
              <Link
                onClick={this.refreshData.bind(this, locationData.category)}
                to={{
                  pathname: "/category",
                  search: "?category=" + locationData.category.symbol,
                  state: {
                    category: locationData.category
                  }
                }}>
                {locationData.category.symbol}
              </Link>
            </li>
            :
            <li className="active page-title text-white">{locationData.category.symbol}</li>
        }
        {
          locationData.subcategory ?
            locationData.subsubcategory ?
              <li className="page-title">
                <Link onClick={this.refreshData.bind(this, locationData.subcategory)} to={{
                  pathname: "/subcategory",
                  search: "?subcategory=" + locationData.subcategory.symbol,
                  state: {
                    subcategory: locationData.subcategory,
                    category: locationData.category
                  }
                }}>
                  {locationData.subcategory.symbol}
                </Link>
              </li>
              :
              <li className="active page-title text-white">{locationData.subcategory.symbol}</li>
            : null
        }
        {
          locationData.subsubcategory ?
            locationData.assetData ?
              <>
                <li className="page-title">
                  <Link onClick={this.refreshData.bind(this, locationData.subsubcategory)} to={{
                    pathname: "/subsubcategory",
                    search: "?subsubcategory=" + locationData.subsubcategory.symbol,
                    state: {
                      subcategory: locationData.subcategory,
                      category: locationData.category,
                      subsubcategory: locationData.subsubcategory
                    }
                  }}>
                    {locationData.subsubcategory.symbol}
                  </Link>
                </li>
                <li className="active page-title text-white">{locationData.assetData.symbol}</li>
              </>
              :
              <li className="active page-title text-white">{locationData.subsubcategory.symbol}</li>
            : null
        }
      </>
    )
  }

  render() {
    const { history } = this.props;
    const { loginDetails } = this.props.about;
    const { apiModalShow, apiResponse, apiDetails, loading, error, currentData } = this.state;

    let errorView = !loading && error ? (
      <>
        <ErrorModal
          show={true}
          message={error}
          viewLog={this.viewLog}
          showViewLog={true}
        />
      </>
    ) : "";

    return (
      <>
        <Header history={history} refreshData={this.refreshData.bind(this)} />
        <div id="page-wrapper" className="m-l-0">
          <div className="container">
            {
              history.location.pathname.includes('subcategory') || history.location.pathname.includes('subsubcategory') ?
                <ol className="breadcrumb p-0 m-b-0 category-breadcrumb">
                  {this.getBreadCrumbData()}
                </ol>
                :
                history.location.pathname.includes('category') ?
                  <ol className="breadcrumb p-0 m-b-0 category-breadcrumb">
                    <li className="page-title">
                      <Link to={{
                        pathname: '/market',
                        state: undefined,
                        search: loginDetails === null || loginDetails.apikey === '' ? undefined : 'uuid=' + today()
                      }}
                        onClick={this.refreshData.bind(this, 'main')}>
                        <i className="fa fa-home" id="home-image" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li className="active page-title text-white">{history.location.state.category.name}</li>
                  </ol>
                  :
                  <ol className="breadcrumb p-0 m-b-0 empty-breadcrumb category-breadcrumb">
                  </ol>
            }

            <div className="row" id="market-data-row">
              {
                currentData && this.getData()
              }
              {
                loading &&
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" id="market-div-body">
                  <div className="card-loader card-loader--tabs h-125 m-b-30"></div>
                </div>
              }
            </div>
          </div>
          <Footer location="home" onClick={this.getApiInfo} />

          {errorView}
          <ApiModal
            show={apiModalShow}
            apiDetails={apiDetails}
            onClose={this.onApiModalClose}
            apiResponse={apiResponse}
            history={this.props.history}
            errorJson={this.state.errorJson}
            screen='home'
          />
          <ForbiddenErrorModal
          history={this.props.history}/>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  marketData: state.MarketReducer.marketData,
  marketRequest: state.MarketReducer.fetchRequest,
  about: state.AboutReducer
});

export default connect(mapStateToProps, { getMarketData })(Home)
