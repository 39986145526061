import React from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import PortfolioTable from "./PortfolioTable";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import {
  fetchPortfolio,
  fetchNewPortfolio,
  updateAssets,
  getTimingDiff,
  saveWatchlist,
  getSavedWatchlists,
  updateAllPerfValues,
  updateAllTimingValues,
  resetPortfolioChart,
  fetchFamilyChartData,
  fetchFamilyData,
  updatePortfolioProps,
  getBucketDetails,
  getWatchlistData,
  optimizeWatchlist,
  manageWatchlist,
  subscribeToWatchlist,
  clearPortfolio
} from "../../actions/PortfolioAction"; 

import {
  getSupportedSymbols,
  fetchPerformanceData,
  fetchTimingData,
  clearSingleData,
  updateValues,
  updateCompareValues,
  updateBenchmarkValues,
} from "../../actions/AssetAction";

import {
  getKoshaWatchlists,
  getTransactionSimulation,
  getTransactionSimulationSummary,
} from "../../actions/WatchlistTimingsAction";

import "./Portfolio.css";
import "../asset/Asset.css";
import "../asset/AssetList.css";

import ErrorModal from "../../layout/ErrorModal";
import ApiModal from "../../layout/ApiModal";
import SynchChart from "../charts/SynchChart";
import { CHART_TYPE } from "../../utils/const";
import {getTooltipText} from '../../utils/helperFunctions';
import {
  sortArray,
  sortByAllocation,
  asciiValueCheck,
  formatDownloadDate,
  calculateSentimentValue,
} from "../../utils/helperFunctions";
import AutocompleteField from "../AutocompleteField";
import RangeSelectorModal from "../../layout/RangeSelectorModal";
import ChartTabs from "../charts/Tabs";

import CSVReader from "react-csv-reader";
import { ExportToCsv } from "export-to-csv";
import { templateData, quantityTemplate } from "./template";
import ConfirmModal from "../../layout/ConfirmModal";
import TimingDiffModal from "../../layout/TimingDiffModal";
import firebaseObj from "../../utils/firebase";
import packageJson from "../../../package.json";
import ExtraAssetPopup from "../../layout/ExtraAssetPopup";
import SaveWatchlistModal from "../../layout/SaveWatchlist";
import SaveOrSubscribeModal from "../../layout/SaveOrSubscribeModal";
import uuid from "react-uuid";
import MyWatchlistModal from "../../layout/MyWatchlistModal";
import PortfolioQtyTable from "./PortfolioQtyTable";
import OptimizeModal from "../../layout/OptimizeModal";
import TransactionsModal from "../../layout/TransactionsModal";
import ForbiddenErrorModal from "../../layout/ForbiddenErrorModal";
import CustomTooltip from '../common/CustomTooltip'

const analytics = firebaseObj.analytics();

class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      assets: [
        {
          symbol: "",
          allocation: "",
          percentAllocation: "",
          assetValid: true,
          allocationValid: true,
        },
      ],
      valid: false,
      assetChartData: null,
      familyChartData: null,
      total: { symbol: "Aggregate", allocation: "" },
      spy: { symbol: "SPY", allocation: "" },
      symbol: "",
      allocation: "",
      analyzed: false,
      error: "",
      apiModalShow: false,
      apiDetails: [],
      apiResponse: [],
      isError: false,
      showChartData: false,
      compareSymbol: "SPY",
      symbols: [],
      tableSymbols: [],
      showViewLog: true,
      showRangeModal: false,
      smaRange: 50,
      portfolio: null,
      inputLength: "",
      holdings: [],
      fileLoading: false,
      symbolsLoading: false,
      showConfirmModal: false,
      showTimingDiff: false,
      singleAssetTiming: [],
      singleAssetPerformance: [],
      singleLoading: false,
      extraAssets: [],
      showExtraAsset: false,
      filename: "",
      showReloadModal: false,
      bucketLoading: false,
      showSaveWatchlistModal: false,
      showSaveOrSubscribeModal: false,
      saveButtonText: "Save Watchlist",
      showSavedWatchlistModal: false,
      savedWatchlist: [],
      watchlistTypes: [],
      watchlistModalTitle: "",
      watchlistsLoaded: false,
      selectedWatchlist: "",
      watchlistname: "",
      watchlistnotes: "",
      watchlistHashkey: "",
      watchlistEmail: "",
      publisherWatchlistName: "",
      publisherHashkey: "",
      publisherEmail: "",
      showOptimizeModal: false,
      weightQuantity: false,
      showBenchmarkChangeModal: false,
      koshaWatchlist: true,
      modalOpenCheck: false,
      showTransactionsModal: false,
      showSwitchModal: { show: false, type: "" },
      aboutCheck: false,
      watchlistChanged: false
    };
  }

  handleLeavePage(e) {
    e.preventDefault();
    const confirmationMessage = "Some message";
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  componentDidMount() {
    const { loginDetails } = this.props;

    let tabCount = localStorage.getItem("tab");
    let autoLogin = localStorage.getItem("autoLogin");
    let reloadCheck = window.sessionStorage.getItem("reload");

    if (
      loginDetails === null ||
      Object.keys(loginDetails).length === 0 ||
      ((tabCount !== "many" || autoLogin !== "true") && reloadCheck !== "true")
    ) {
      this.props.history.push("/");
    } else {
      analytics.setUserProperties({
        email: loginDetails?.email,
        App_Version: packageJson.version,
      });
      analytics.logEvent("Watchlist");

      window.scroll(0, 0);

      let prevRoute = localStorage.getItem("currentRoute");
      localStorage.setItem("currentRoute", "portfolio");
      window.sessionStorage.setItem("token", loginDetails?.apikey);

      if (!prevRoute) {
        prevRoute = "portfolio";
      }

      this._mounted = true;
      if (this.props.supportedSymbols?.length === 0) {
        this.setState({
          symbolsLoading: true,
        });

        this.props.getSupportedSymbols(
          () => {
            const { supportedSymbols } = this.props;

            let sortedSymbols = supportedSymbols.sort(sortArray);
            let tableSymbols = sortedSymbols;

            this.setState({
              symbols: sortedSymbols,
              symbolsLoading: false,
              tableSymbols,
            });
          },
          (err, request) => {
            this.setErrorState(err, request);
          }
        );
      } else {
        let sortedSymbols = this.props.supportedSymbols?.sort(sortArray);
        let tableSymbols = sortedSymbols;

        this.setState({
          symbols: sortedSymbols,
          tableSymbols,
        });
      }

      const {
        portfolioAssetChartData,
        portfolioFamilyChartData,
        assetData,
        familyData,
        averageData,
        portfolio,
        filename
      } = this.props;
      let total = averageData || this.state.total;
      let assets = this.state.assets;
      let spy = this.state.spy;
      let assetChartData = this.state.assetChartData;
      let familyChartData = this.state.familyChartData;

      if (assetData) {
        assets = assetData;

        if (familyData) {
          spy = familyData || this.state.spy;
        }
        if (portfolioAssetChartData) {
          assetChartData = portfolioAssetChartData;

          if (portfolioFamilyChartData) {
            familyChartData = portfolioFamilyChartData;
          }
        }
      }

      if (prevRoute === "fullView" || prevRoute === "backToWatchlist") {
        this.setState(
          {
            assets,
            spy,
            assetChartData,
            familyChartData,
            total,
            showChartData: true,
            showPercentage: true,
            portfolio,
            filename,
          },
          () => {
            this.setState({
              valid: this.isValid(),
            });
          }
        );
      } else if (prevRoute === "alberto") {
        let albertoAssets = JSON.parse(localStorage.getItem("albertoAssets"));
        if (albertoAssets) {
          let assets = [];

          albertoAssets.forEach((asset) => {
            assets.push({
              symbol: asset.symbol,
              allocation: "",
              percentAllocation: "",
              assetValid: true,
              allocationValid: true,
            });
          });

          this.setState(
            {
              assets,
              spy: { symbol: "SPY", allocation: "" },
              total: { symbol: "Aggregate", allocation: "" },
              portfolio: null,
              filename: "",
            },
            () => {
              this.setState({
                valid: this.isValid(),
              });
              localStorage.setItem("albertoAssets", null);
            }
          );
        }
      } else {
        this.setState(
          {
            assets: [
              {
                symbol: "",
                allocation: "",
                percentAllocation: "",
                assetValid: true,
                allocationValid: true,
              },
            ],
            spy: { symbol: "SPY", allocation: "" },
            total: { symbol: "Aggregate", allocation: "" },
            portfolio: null,
            filename: "",
          },
          () => {
            this.setState({
              valid: this.isValid(),
            });
          }
        );
      }
    }
  }

  onRangeChange = (smaRange) => {
    this.setState({
      smaRange,
      showRangeModal: false,
      modalOpenCheck: false,
    });
  };

  showRangeModal = () => {
    this.setState({
      showRangeModal: true,
      modalOpenCheck: true,
    });
  };

  isValid = () => {
    let valid = true;
    const { assets, symbols } = this.state;

    let validValueType = "allocationValid"

    if (assets.length !== 0) {
      let assetsLength = assets.length - 1;

      assets.map((asset, index) => {
        if (index === assetsLength && index !== 0) {
          if (asset.symbol === "" && asset["allocation"] === "") {
            valid = true;
          }
          if (asset.symbol === "" && asset["allocation"] !== "") {
            asset.assetValid = false;
            valid = false;
          }
          if (symbols.findIndex((sym) => sym.symbol === asset.symbol) === -1) {
            asset.assetValid = false;
            valid = false;
          }
          if (
            (asset["allocation"] === "" ||
              asset["allocation"] === undefined) &&
            asset.symbol !== ""
          ) {
            asset[validValueType] = false;
            valid = false;
          }
        } else {
          if (
            !asset.assetValid ||
            asset.symbol === "" ||
            asset["allocation"] === "" ||
            asset["allocation"] === undefined
          ) {
            valid = false;
          } else valid = true;
        }
      });
    } else {
      valid = false;
    }

    let invalidAssets = assets.filter((asset) => {
      return (
        !asset.assetValid ||
        !asset[validValueType] ||
        asset["allocation"] === ""
      );
    });

    if (
      (invalidAssets.length === assets.length ||
        (invalidAssets.length === 0 && assets.length === 1)) &&
      valid
    ) {
      if (assets.length === 1) {
        if (assets[0]["allocation"] && assets[0].assetValid)
          valid = true;
      } else {
        valid = false;
      }
    }

    if (assets.length === 2 && invalidAssets.length === 1) {
      if (
        invalidAssets[0].symbol === "" &&
        invalidAssets[0]["allocation"] === ""
      ) {
        valid = false;
      }
    }

    return valid;
  };

  addAsset = (check) => {
    const { portfolio } = this.state;
    let assets = [...this.state.assets];
    let compare = { symbol: this.state.compareSymbol, allocation: "" };
    let total = { symbol: "Aggregate", allocation: "" };

    if (portfolio?.managed) {
      this.setState({
        managedWatchlistAlert: true,
      });
    }

    let assetLength = assets.length - 1;

    if (
      assets.length !== 0 &&
      assets.length < 75 &&
      assets[assetLength].symbol !== "" &&
      check !== "analyze"
    ) {
      assets.push({
        symbol: "",
        allocation: "",
        percentAllocation: "",
        assetValid: true,
        allocationValid: true,
      });
    }

    this.props.updateAssets(assets);

    this.setState(
      {
        assets,
        valid: this.isValid(),
        symbol: "",
        allocation: "",
        showChartData: false,
        familyChartData: [],
        assetChartData: [],
        spy: compare,
        total,
      },
      () => {
        this.props.updatePortfolioProps(this.state.assets, this.state.filename);
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (
      !this.props.ForbiddenError &&
      this.state.assets.length !== 0 &&
      this.state.assets[0].symbol !== ""
    ) {
      window.addEventListener("beforeunload", this.handleLeavePage, {
        capture: true,
      });
    } else {
      window.removeEventListener("beforeunload", this.handleLeavePage, {
        capture: true,
      });
    }

    let prevAbout = prevProps.about;
    const { about } = this.props;

    if (prevAbout && about) {
      if (prevAbout.id !== about.id) {
        this.setState(
          {
            showReloadModal: true,
            modalOpenCheck: true,
          },
          () => {
            setTimeout(() => {
              localStorage.setItem("timeout", "reload");
              window.location.reload();
            }, 5000);
          }
        );
      }
    }

    let newLength = document.getElementsByClassName("autocomplete-input")
      .length;

    if (newLength !== this.state.inputLength && !this.state.symbolsLoading) {
      this.setState({
        inputLength: newLength,
      });
    }
  }

  goToAsset = () => {
    this.props.history.push("/asset?uuid=" + uuid());
  };

  deleteRow = (rowIndex, columnId) => {
    const { portfolio } = this.state;
    this.setState({watchlistChanged: true})


    if (portfolio?.managed) {
      this.setState({
        managedWatchlistAlert: true,
      });
    }
    this.setState(
      (state) => {
        return {
          assets: state.assets.filter((asset, index) => {
            return index !== rowIndex;
          }),
        };
      },
      () => {
        if (this.state.assets.length === 0) {
          this.setState({
            valid: false,
            total: { symbol: "Aggregate", allocation: "" },
            spy: { symbol: this.state.compareSymbol, allocation: "" },
            showChartData: false,
            assets: [
              {
                symbol: "",
                allocation: "",
                percentAllocation: "",
                assetValid: true,
                allocationValid: true,
              },
            ],
            showConfirmModal: false,
            modalOpenCheck: !this.state.modalOpenCheck,
            selectedWatchlist: "",
            symbol: "",
            hashkey: null,
            transactionWatchlist: "",
          });
        } else {
          let checkReturn = this.duplicateAssetCheck(this.state.assets);
          let duplicatesPresent = checkReturn[1];
          let checkedAssets = checkReturn[0];

          if (!duplicatesPresent) {
            checkedAssets.map((asset) => {
              if (asset.duplicate) asset.assetValid = true;
            });
          }

          this.setState(
            {
              assets: checkedAssets,
            },
            () => {
              this.setState({
                valid: this.isValid(),
                showChartData: false,
                familyChartData: [],
                assetChartData: [],
                total: { symbol: "Aggregate", allocation: "" },
              });
            }
          );
        }

        this.props.updatePortfolioProps(this.state.assets, this.state.filename);
      }
    );
  };

  updateMyData = (rowIndex, columnId, value) => {
    let oldValue = "";
    if (columnId === "allocation" && (value < -100 || value > 100)) {
      let errorDiv = document.getElementById("weight-error-div");
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    } else {
      this.setState(
        (state) => {
          return {
            assets: state.assets.map((asset, index) => {
              if (index === rowIndex) {
                // console.log(columnId)
                // console.log("oldValue details: ", rowIndex, state.assets[rowIndex])

                if (columnId === "symbol") {
                  let symbols = this.state.symbols;
                  let entered = value.toUpperCase();
                  let index = symbols.findIndex(
                    (sym) => sym && sym.symbol === entered
                  );
                  oldValue = state.assets[rowIndex]["symbol"];
                  // console.log("oldValue details: ", rowIndex, state.assets[rowIndex])

                  let valid = index !== -1 ? true : false;

                  if (oldValue === value) {
                    // console.log("no change in oldValue/newValue table")
                    //allocation should be renamed to weight
                    let allocation = state.assets[rowIndex]["allocation"];
                    if (allocation === undefined) {
                      // console.log("allocation is undefined", allocation)
                      allocation = 0;
                    }
                    // console.log("allocation is ", allocation)
                    let percentAllocation = state.assets[rowIndex]["percentAllocation"];
                    if (percentAllocation === undefined) {
                      // console.log("percentAllocation is undefined", percentAllocation)
                      percentAllocation = 0;
                    }
                    return {
                      ...state.assets[rowIndex],
                      [columnId]: value,
                      allocation: allocation,
                      percentAllocation: percentAllocation,
                      assetValid: valid,
                      allocationValid: true,
                    };
                  } else {
                    let allocation = state.assets[rowIndex]["allocation"];
                    let percentAllocation =
                      state.assets[rowIndex]["percentAllocation"];
                    if (allocation === undefined) {
                      allocation = 0;
                    }
                    return {
                      [columnId]: value,
                      allocation: allocation,
                      percentAllocation: percentAllocation,
                      assetValid: valid,
                      allocationValid: true,
                    };
                  }
                } else {
                  oldValue = state.assets[rowIndex]["allocation"];
                  let allocationValid = true;

                  return {
                    ...state.assets[rowIndex],
                    [columnId]: value,
                    allocationValid,
                  };
                }
              }
              return asset;
            }),
          };
        },
        () => {
          if (oldValue !== value && columnId === "allocation") {
            let { assets } = this.state;
            let total = { symbol: "Aggregate", allocation: "" };
            //oldValue is string but empty, value is number with the right number
            // console.log(oldValue, value, typeof(oldValue), typeof(value))
            this.setState({watchlistChanged: true})
            this.setState(
              {
                assets,
              },
              () => {
                this.setState(
                  {
                    valid: this.isValid(),
                    total,
                  },
                  () => {
                    this.props.updatePortfolioProps(
                      this.state.assets,
                      this.state.filename
                    );

                    this.setState({
                      valid: this.isValid(),
                      showChartData: false,
                      familyChartData: null,
                      assetChartData: null,
                    });
                  }
                );
              }
            );
          }
        }
      );
    }
  };

  handleChange = (e) => {
    let value = e.target.value.toUpperCase();
    this.setState({
      [e.target.id]: value,
    });
  };

  analyze = ({fetchError = false}) => {
    const { loginDetails } = this.props;
    const { compareSymbol, watchlistname, hashkey, watchlistEmail, publisherWatchlistName, publisherEmail, publisherHashKey } = this.state;
    if (!compareSymbol) {
      return this.setState({
        error: `Please select a Benchmark symbol.`,
        showViewLog: false,
        loading: false,
      });
    }
    this.props.resetPortfolioChart();

    let assets = this.state.assets;
    let assetLength = assets.length - 1;
    let lastAssetSymbol = assets.length !== 0 && assets[assetLength].symbol;
    let lastAssetValue = assets[assetLength].allocation
    let valueType = "allocation"
    let validValueType = "allocationValid"

    if (lastAssetSymbol === "" && lastAssetValue === "") {
      assets.pop();
    }

    let invalidAlloc = assets.filter((asset) => {
      return !asset[validValueType];
    });
    if (
      assets.filter((asset) => {
        if (parseFloat(asset.allocation) || parseFloat(asset.quantity)) {
          return true;
        }
      }).length === 0
    ) {
      return this.setState({
        error: `At least one of the weights of valid assets should be non-zero.`,
        showViewLog: false,
        loading: false,
      });
    }

    if (invalidAlloc.length !== 0) {
      let errorDiv = document.getElementById("weight-error-div");
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
      return false;
    }

    let checkReturn = this.duplicateAssetCheck(assets);
    let duplicatesPresent = checkReturn[1];
    let checkedAssets = checkReturn[0];

    if (duplicatesPresent) {
      this.setState(
        {
          error: "Duplicate symbols are not allowed.",
          showViewLog: false,
          loading: false,
          assets: checkedAssets,
        },
        () => {
          this.props.updatePortfolioProps(this.state.assets, "");

          let duplicateArray = checkedAssets.filter((asset) => {
            return asset.duplicate;
          });

          checkedAssets.map((asset) => {
            if (!asset.duplicate) duplicateArray.push(asset);
          });

          this.setState({
            assets: duplicateArray,
          });
        }
      );
    } else {
      checkedAssets.map((asset) => {
        if (asset.duplicate) asset.assetValid = true;
      });
    }

    if (!duplicatesPresent) {
      checkedAssets.map((asset) => {
        if (asset.allocation === "" || !asset.allocation)
          asset.allocationValid = false;
      });

      this.setState(
        {
          assets: checkedAssets,
        },
        () => {
          this.props.updateAssets(checkedAssets);
          this.props.updatePortfolioProps(this.state.assets, "");

          this.setState(
            {
              valid: this.isValid(),
              showBenchmarkChangeModal: false,
              modalOpenCheck: false,
            },
            () => {
              if (this.state.valid) {
                let newAssets = [];
                let maxallocation = this.state.portfolio?.maxallocation;
                let timingtype = this.state.portfolio?.timingtype;
                this.props.history.push("/watchlist?uuid=" + uuid());
                assets.map((asset) => {
                  if (asset.symbol) {
                    let allocation = asset.allocation || asset.quantity;
                    if (!allocation) allocation = 0;
                    newAssets.push({
                      symbol: asset.symbol,
                      allocation: allocation,
                    });
                  }
                });

                this.setState({
                  loading: true,
                  total: { symbol: "Aggregate", [valueType]: 0 },
                  assetChartData: null,
                  familyChartData: null,
                  spy: { symbol: this.state.compareSymbol, [valueType]: "" },
                  holdings: newAssets,
                });

                analytics.setUserProperties({
                  email: loginDetails?.email,
                  App_Version: packageJson.version,
                });

                analytics.logEvent("Analyze Watchlist", {
                  stocksCount: newAssets.length,
                });

                newAssets.forEach((asset) => {
                  if (asset) {
                    analytics.logEvent("Asset analyzed in Watchlist", {
                      watchlistAsset: asset.symbol,
                    });
                  }
                });
                
                //if not changed call refresh
                this.props.fetchNewPortfolio(
                  watchlistname,
                  watchlistEmail,
                  hashkey,
                  newAssets,
                  maxallocation,
                  timingtype,
                  publisherWatchlistName,
                  publisherHashKey,
                  publisherEmail,
                  this.state.watchlistChanged,
                  fetchError,
                  () => {
                    const {
                      assetData,
                      portfolioRequest,
                      portfolio,
                      averageData,
                      portfolioAssetChartData,
                    } = this.props;
                    let apiDetails = [];
                    let apiResponse = [];
                    apiDetails.push(portfolioRequest);
                    apiResponse.push(portfolio);

                    let total = averageData;

                    assetData.forEach((asset) => {
                      asset.assetValid = asset.code === 0 ? true : false;
                      asset.allocationValid = asset.code === 0 ? true : false;
                    });
                    portfolio.holdings.forEach((asset, index) => {
                      if (asset?.percentAllocation === undefined) {
                        assetData[index].percentAllocation = 0;
                      } else {
                        assetData[index].percentAllocation =
                          asset?.percentAllocation;
                      }
                    });
                    portfolio.maxallocation = this.state.portfolio?.maxallocation;
                    portfolio.timingtype = this.state.portfolio?.timingtype;
                    this.setState({
                      apiDetails,
                      apiResponse,
                      total,
                      assets: assetData,
                      showChartData: true,
                      portfolio,
                      assetChartData: portfolioAssetChartData,
                    });

                    this.props.fetchFamilyData(
                      this.state.spy,
                      () => {
                        let spy = this.props.familyData;
                        let vsip = [{ vsip: spy.vsip }];

                        let apiDetails = this.state.apiDetails;
                        let apiResponse = this.state.apiResponse;

                        apiDetails.push(this.props.familyDataRequest);
                        apiResponse.push(this.props.familyData);

                        this.setState({
                          apiDetails,
                          apiResponse,
                          spy,
                        });

                        this.props.fetchFamilyChartData(
                          vsip,
                          CHART_TYPE.FAMILY_CHART,
                          () => {
                            let familyChartData = this.props
                              .portfolioFamilyChartData;
                            const { performanceData, timingData } = this.props;

                            if (familyChartData) {
                              let apiDetails = this.state.apiDetails;
                              let apiResponse = this.state.apiResponse;

                              apiDetails.push(
                                this.props.portfolioFamilyChartRequest
                              );
                              apiResponse.push(familyChartData);

                              if (this._mounted) {
                                this.setState({
                                  assets: assetData,
                                  familyChartData,
                                  spy,
                                  total,
                                  apiDetails,
                                  apiResponse,
                                  analyzed: false,
                                  showPercentage: !this.state.showPercentage,
                                  valid: true,
                                });
                              }
                            }

                            if (
                              performanceData &&
                              performanceData.percentagegainsdata.length !== 0
                            ) {
                              if (
                                timingData &&
                                timingData.timingsdata.length !== 0
                              ) {
                                this.setState({
                                  loading: false,
                                  analyzed: true,
                                });
                              } else {
                                this.props.fetchTimingData(
                                  newAssets,
                                  "watchlist",
                                  () => {
                                    if (this._mounted) {
                                      const {
                                        timingData,
                                        timingRequest,
                                      } = this.props;
                                      apiDetails.push(timingRequest);
                                      apiResponse.push(timingData);
                                    }
                                  },
                                  (err, request) => {
                                    this.setErrorState(err, request);
                                  }
                                );
                              }
                            } else {
                              this.props.fetchPerformanceData(
                                newAssets,
                                "watchlist",
                                () => {
                                  if (this._mounted) {
                                    const {
                                      performanceData,
                                      performanceRequest,
                                    } = this.props;

                                    apiDetails.push(performanceRequest);
                                    apiResponse.push(performanceData);

                                    if (
                                      timingData &&
                                      timingData.timingsdata.length !== 0
                                    ) {
                                      this.setState({
                                        loading: false,
                                        analyzed: true,
                                      });
                                    } else {
                                      this.props.fetchTimingData(
                                        newAssets,
                                        "watchlist",
                                        () => {
                                          if (this._mounted) {
                                            const {
                                              timingData,
                                              timingRequest,
                                            } = this.props;
                                            apiDetails.push(timingRequest);
                                            apiResponse.push(timingData);

                                            this.setState({
                                              loading: false,
                                              apiDetails,
                                              apiResponse,
                                              analyzed: true,
                                            });
                                          }
                                        },
                                        (err, request) => {
                                          this.setErrorState(err, request);
                                        }
                                      );
                                    }
                                  }
                                },
                                (err, request) => {
                                  this.setErrorState(err, request);
                                }
                              );
                            }
                          },
                          (err, request) => {
                            this.setErrorState(err, request);
                          }
                        );
                      },
                      (err, request) => {
                        this.setErrorState(err, request);
                      }
                    );
                  },
                  (err, request) => {
                    this.setErrorState(err, request);
                  }
                );
              } else {
                this.setState({
                  error:
                    "Unsupported symbols and allocations are highlighted in red.",
                  showViewLog: false,
                  loading: false,
                });
                this.addAsset("analyze");
              }
            }
          );
        }
      );
    }
  };

  setErrorState = (err, request) => {
    if (this._mounted) {
      let apiDetails = [];
      let apiResponse = [];
      let error = err.message ? err.message : err?err:"Error Occured !!";
      apiDetails.push(request);
      apiResponse.push({ error });

      this.setState({
        loading: false,
        error,
        apiDetails,
        apiResponse,
      });
    }
  };

  closeError = () => {
    this.setState({
      error: "",
      showViewLog: true,
      showTemplate: false,
      loading:false,
    });
  };

  viewLog = () => {
    this.setState({
      apiModalShow: true,
      isError: true,
      error: false,
      modalOpenCheck: !this.state.modalOpenCheck,
    });
  };

  handleKeyPress = (type, e, count) => {
    let symbols = this.state.symbols;
    let entered = e.target.value.toUpperCase();
    let index = symbols.findIndex((value) => value && value.symbol === entered);

    if (e.target.value && e.key === "Enter") {
      if (type === "compareSymbol") {
        if (index === -1) {
          this.setState({
            error: "This Symbol is not supported!",
            showViewLog: false,
            compareSymbol: this.state.oldCompareSymbol,
          });
        }
      } else {
        if (index === -1 && type === "symbol") {
          this.setState({
            error: "This Symbol is not supported!",
            showViewLog: false,
            [type]: "",
          });
        }
        if (type === "allocation") {
          let { assets } = this.state;

          this.setState(
            {
              valid: this.isValid(),
              showChartData: false,
              familyChartData: [],
              assetChartData: [],
              total: { symbol: "Aggregate", allocation: "" },
              assets,
            },
            () => {
              this.props.updatePortfolioProps(
                this.state.assets,
                this.state.filename
              );
              let newLength = document.getElementsByClassName(
                "autocomplete-input"
              ).length;
              let lastInput = document.getElementsByClassName(
                "autocomplete-input"
              )[newLength - 1];

              if (lastInput.value === "") lastInput.focus();
            }
          );
        }
      }
    }
    if (e.target.value && e.key === "Backspace") {
      this.setState({
        [type]: entered,
      });
    }
  };

  onBlur = (e) => {
    if (e.target.value) {
      let symbols = this.state.symbols;
      let entered = e.target.value.toUpperCase();
      let index = symbols.findIndex(
        (value) => value && value.symbol === entered
      );

      if (index === -1) {
        this.setState({
          error: "This Symbol is not supported!",
          showViewLog: false,
          compareSymbol: this.state.oldCompareSymbol,
        });
      }
    }
  };

  getApiInfo = () => {
    this.setState({
      apiModalShow: true,
    });
  };

  onApiModalClose = () => {
    this.setState({
      apiModalShow: false,
      showTimingDiff: false,
      showExtraAsset: false,
      extraAssets: [],
      showSaveWatchlistModal: false,
      showSavedWatchlistModal: false,
      showOptimizeModal: false,
      loading: false,
      modalOpenCheck: !this.state.modalOpenCheck,
      bucketLoading: false,
      timingDiffData: null,
    });
  };

  onSearchChange = (e) => {
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let errorDiv = document.getElementById("error-div");
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }
    if (!valueCheck) {
      if (errorDiv) errorDiv.style.visibility = "hidden";

      if (!numberCheck && (asciiCheck || value === "")) {
        this.setState({watchlistChanged: true})
        this.setState(
          {
            symbol: value,
          },
          () => {
            this.props.updatePortfolioProps(
              this.state.assets,
              this.state.filename
            );

            this.setState({
              showChartData: false,
              assetChartData: null,
              familyChartData: null,
            });
          }
        );
      }
    } else {
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    }
  };

  onCompareSearchChange = (e) => {
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let errorDiv = document.getElementById("error-benchmark-div");
    let oldCompareSymbol = "";
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }

    if (this.state.analyzed) {
      oldCompareSymbol = this.state?.oldCompareSymbol;
    }
    if (!valueCheck) {
      if (errorDiv) errorDiv.style.visibility = "hidden";
      if (!numberCheck && (asciiCheck || value === "")) {
        this.setState({watchlistChanged: true})
        this.setState({
          compareSymbol: value,
          oldCompareSymbol,
        });
      }
    } else {
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    }
  };

  onItemSelect = (rowIndex, columnId, value) => {
    let validValueType = "allocationValid"

    this.setState(
      (state) => {
        return {
          assets: state.assets.map((asset, index) => {
            if (index === rowIndex) {
              if (columnId === "symbol") {
                return {
                  [columnId]: value,
                  allocation: state.assets[rowIndex]["allocation"],
                  percentAllocation: "",
                  assetValid: true,
                  [validValueType]: true,
                };
              } else {
                return {
                  ...state.assets[rowIndex],
                  [columnId]: value,
                  [validValueType]: true,
                };
              }
            }
            return asset;
          }),
        };
      },
      () => {
        this.setState(
          {
            valid: this.isValid(),
            showChartData: false,
            familyChartData: [],
            assetChartData: [],
            total: { 
              symbol: "Aggregate", 
              allocation: ""
            },
          },
          () => {
            this.props.updatePortfolioProps(
              this.state.assets,
              this.state.filename
            );
          }
        );
      }
    );
  };

  onCompareItemSelect = (compareSymbol) => {
    this.setState(
      {
        compareSymbol,
        oldCompareSymbol: compareSymbol,
      },
      () => {
        if (this.state.analyzed)
          this.setState({
            showBenchmarkChangeModal: true,
            modalOpenCheck: !this.state.modalOpenCheck,
          });
      }
    );
  };

  showChartInModal = (chartName) => {
    if (chartName) {
      this.props.history.push({
        pathname: "/chartView",
        state: {
          title: "Percentage Gains",
          assetChartData: this.state.portfolio,
          chartName: "percentageGains",
        },
        search: "uuid=" + uuid(),
      });
    } else {
      this.setState({
        portfolio: [],
      });
      this.props.history.push({
        pathname: "/chartView",
        state: {
          title: "Watchlist",
          assetChartData: this.state.assetChartData,
          smaRange: this.state.smaRange,
          familyChartData: this.state.familyChartData,
        },
        search: "uuid=" + uuid(),
      });
    }
  };

  showFamilyChart = () => {
    this.setState({
      portfolio: null,
    });

    this.props.history.push({
      pathname: "/chartView",
      state: {
        title: this.state.compareSymbol,
        assetChartData: this.state.familyChartData,
        smaRange: this.state.smaRange,
        familyChartData: this.state.assetChartData,
      },
      search: "uuid=" + uuid(),
    });
  };

  handleForce = (data, fileInfo) => {
    this.props.clearPortfolio()
    this.clearTable();

    let assets = [];
    let unsupportedAssets = [];
    let filename = fileInfo.name;
    let invalidCsv = false;
    let invalidSymbols = [];
    this.setState({watchlistname: null})

    const { loginDetails } = this.props;

    let tableAssets = [
      {
        symbol: "",
        allocation: "",
        percentAllocation: "",
        assetValid: true,
        allocationValid: true,
      },
    ];

    data.forEach((sym) => {
      let newSymbol = {};
      const headerArray = [...Object.keys(sym)];
      if (!headerArray.includes("asset")) {
        invalidCsv = true;
      } else {
        let symbols = this.state.symbols;
        let entered = sym.asset === true ? "TRUE" : sym?.asset?.toUpperCase();
        let index = symbols.findIndex(
          (value) => value && value.symbol === entered
        );

        if (index !== -1) {
          newSymbol.symbol = entered;
          let allocation =
            sym.weight || sym.weight____;

          if (
            allocation !== 0 &&
            allocation !== null &&
            allocation !== "null"
          ) {
            if (allocation === undefined) {
              allocation = 0;
            }
            newSymbol.assetValid = true;
            newSymbol.allocation = allocation.toFixed(2);
            newSymbol.allocationValid = true;
            if (
              allocation >= 100 ||
              allocation <= -100 ||
              allocation === undefined ||
              allocation === ""
            ) {
              newSymbol.allocationValid = false;
            }
            assets.push(newSymbol);
          }
        } else {
          if (entered && index !== -1) {
            newSymbol.symbol = entered;
            newSymbol.assetValid = false;
            newSymbol.allocation = "";
            newSymbol.allocationValid = true;
            unsupportedAssets.push(newSymbol);
          } else {
            invalidSymbols.push(entered);
          }
        }
      }
    });

    if (invalidCsv) {
      this.setState(
        {
          error:
            "This CSV format is not supported. Please click Download Template to see the supported format.",
          showViewLog: false,
          filename: "",
          showTemplate: true,
        },
        () => {
          if(document.getElementById('CSVReader'))
          document.getElementById("CSVReader").value = "";
        }
      );
      return false;
    }
    if (invalidSymbols.length && assets.length) {
      this.setState({
        invalidSymbols: invalidSymbols,
        showInvalidSymbolModal: true,
      });
    }
    else if(invalidSymbols.length && !assets.length){
      this.setState({
        assets: [
          {
            symbol: "",
            allocation: "",
            percentAllocation: "",
            assetValid: true,
            allocationValid: true,
          },
        ],
        invalidSymbols:invalidSymbols,
        showInvalidSymbolModal:true,
      })
      if(document.getElementById('CSVReader'))
      document.getElementById("CSVReader").value = "";
      return;
    }
    let sortedAssets = assets.sort(sortByAllocation);
    let finalAssets = [];
    let extraAssets = [];

    sortedAssets.forEach((asset, index) => {
      if (index < 75) finalAssets.push(asset);
      else extraAssets.push(asset);
    });

    //check for duplicates
    let checkReturn = this.duplicateAssetCheck(finalAssets);
    let duplicatesPresent = checkReturn[1];
    let checkedAssets = checkReturn[0];

    if (duplicatesPresent) {
      let duplicateArray = checkedAssets.filter((asset) => {
        return asset.duplicate;
      });

      checkedAssets.map((asset) => {
        if (!asset.duplicate) duplicateArray.push(asset);
      });

      finalAssets = [];
      finalAssets = duplicateArray;

      this.setState({
        error: "Duplicate symbols are not allowed.",
        showViewLog: false,
      });
    }

    let total = { symbol: "Aggregate", allocation: "" };
    let spy = { symbol: "SPY", allocation: "" };

    if (data.length !== 0 && data[0].asset) {
      this.setState(
        {
          assets: finalAssets,
          valid: true,
          assetChartData: null,
          familyChartData: null,
          total,
          spy,
          showChartData: false,
          portfolio: null,
          fileLoading: true,
          filename,
        },
        () => {
          this.props.updatePortfolioProps(this.state.assets, filename);
          let thisVal = this;
          let showExtraAsset = false;

          if (extraAssets.length !== 0) showExtraAsset = true;

          setTimeout(function () {
            thisVal.setState({
              fileLoading: false,
              extraAssets,
              showExtraAsset,
              modalOpenCheck: this.state && !this.state.modalOpenCheck,
            });
          }, 5000);
        }
      );
    }
    if(document.getElementById('CSVReader'))
    document.getElementById("CSVReader").value = "";

    analytics.setUserProperties({
      email: loginDetails?.email,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Upload CSV");
  };

  duplicateAssetCheck = (assets) => {
    let i, j;
    let duplicatesPresent = false;

    //check for duplicates
    for (i = 0; i < assets.length; i++) {
      for (j = 0; j < assets.length; j++) {
        if (i !== j) {
          if (assets[i].symbol === assets[j].symbol) {
            assets[i].assetValid = false;
            assets[j].assetValid = false;
            assets[i].duplicate = true;
            assets[j].duplicate = true;
            duplicatesPresent = true;
          }
        }
      }
    }

    return [assets, duplicatesPresent];
  };

  options = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  exportCsv = () => {
    const { loginDetails } = this.props;
    const { portfolio } = this.state;

    let assets = this.state.assets;
    let data = [];
    let currentDate = new Date();
    currentDate = formatDownloadDate(currentDate);
    let filename = "Kosha-Watchlist-" + currentDate;

    assets.forEach((asset, index) => {
      if (index === 0) {
        data.push({
          asset: asset.symbol,
          allocation: asset.allocation,
          percentAllocation: asset.percentAllocation
            ? asset.percentAllocation
            : "0",
          timing: asset.timing,
          vvol: asset.vvol,
          pricemovementup: asset.pricemovementup,
          pricemovementdown: asset.pricemovementdown,
          stddevup: asset.stddevup,
          stddevdown: asset.stddevdown,
          timingType: portfolio?.timingtype || "",
          maxAllocation: portfolio?.timingtype ? portfolio.maxallocation : "",
        });
      } else {
        data.push({
          asset: asset.symbol,
          allocation: asset.allocation,
          percentAllocation: asset.percentAllocation
            ? asset.percentAllocation
            : "0",
          timing: asset.timing,
          vvol: asset.vvol,
          pricemovementup: asset.pricemovementup,
          pricemovementdown: asset.pricemovementdown,
          stddevup: asset.stddevup,
          stddevdown: asset.stddevdown,
          timingType: "",
          maxAllocation: "",
        });
      }
    });

    let exportOptions = {
      fieldSeparator: ",",
      quoteStrings: "",
      decimalSeparator: ".",
      filename: filename,
      showLabels: true,
      showTitle: false,
      title: "Kosha Watchlist",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        "Asset",
        "Weight",
        "Allocation %",
        "Timing",
        "VVOL",
        "Avg Expected Move Up",
        "Avg Expected Move Down",
        "Std. Deviation Up",
        "Std. Deviation Down",
        "Timing Type",
        "Max Free Money Redistribution %",
      ],
    };

    analytics.setUserProperties({
      email: loginDetails?.email,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Export CSV");

    const csvExporter = new ExportToCsv(exportOptions);
    csvExporter.generateCsv(data);
  };

  downloadTemplate = (e) => {
    e.preventDefault();

    const { loginDetails } = this.props;
    let data = templateData;

    const options = {
      fieldSeparator: ",",
      quoteStrings: "",
      filename: "template",
      showLabels: false,
      showTitle: false,
      title: "Template",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    analytics.setUserProperties({
      email: loginDetails?.email,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Download Template");

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  clearTable = () => {

    let assets = [
      {
        symbol: "",
        allocation: "",
        percentAllocation: "",
        assetValid: true,
        allocationValid: true,
      },
    ];
    let assetChartData = null;
    let familyChartData = null;
    let total = { symbol: "Aggregate", allocation: "" };
    let spy = { symbol: "SPY", allocation: "" };

    if (assets.length === 0) {
      assets = [
        {
          symbol: "",
          allocation: "",
          percentAllocation: "",
          assetValid: true,
          allocationValid: true,
        },
      ];
    }

    this.props.clearSingleData(
      () => {},
      (err) => {
        console.log("err", err);
      }
    );

    this.setState(
      {
        assets,
        spy,
        total,
        showConfirmModal: false,
        modalOpenCheck: !this.state.modalOpenCheck,
        analyzeText: "Analyze",
        selectedWatchlist: "",
        filename: "",
        watchlistname: "",
        watchlistnotes: "",
        watchlistHashkey: "",
        symbol: "",
        hashkey: null,
        transactionWatchlist: "",
        analyzed: false,
        portfolio: null,
      },
      () => {
        // TODO: reset this.props.portfolio?.useremail in updatePortfolioProps
        this.props.clearPortfolio()
        this.props.updatePortfolioProps(this.state.assets, this.state.filename);

        this.setState({
          valid: this.isValid(),
          assetChartData,
          familyChartData,
          showChartData: false,
        });
      }
    );
  };

  confirmClear = () => {
    this.setState({
      showConfirmModal: true,
      // filename: "",
      modalOpenCheck: !this.state.modalOpenCheck,
    });
  };

  getTimingDiff = () => {
    this.setState({
      showTimingDiff: true,
      modalOpenCheck: !this.state.modalOpenCheck,
    });

    let newAssets = [...this.state.assets].map((asset) => {
      return {
        symbol: asset.symbol,
        allocation: asset.allocation,
      };
    });

    this.props.getTimingDiff(
      newAssets,
      () => {
        this.setState({
          modalOpenCheck: !this.state.modalOpenCheck,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  getKoshaWatchlist = () => {
    let email = null;
    if (!!this.props.loginDetails) {
      email = this.props.loginDetails?.email;
    }
    this.setState({
      showSavedWatchlistModal: true,
      savedWatchlist: [],
      watchlistModalTitle: "Kosha ",
      watchlistsLoaded: false,
      modalOpenCheck: !this.state.modalOpenCheck,
    });

    this.props.getKoshaWatchlists(
      email,
      (result) => {
        this.setState({
          savedWatchlist: result.portfolios,
          watchlistsLoaded: true,
          koshaWatchlist: true,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
    // else {
    //   this.props.history.push('/')
    // }
  };

  showKoshaWatchlists = () => {
    const { watchlistTypes } = this.state;

    return watchlistTypes.map((watchlist) => {
      return (
        <div
          className="watchlist-type"
          onClick={this.getSelectedWatchlist.bind(
            this,
            watchlist.watchlistname
          )}
        >
          {watchlist.watchlistname}
        </div>
      );
    });
  };

  getSelectedWatchlist = (selectedName) => {
    const { watchlistTypes } = this.state;

    let selected = watchlistTypes.filter((watchlist) => {
      return watchlist.watchlistname === selectedName;
    });
    this.showSelectedWatchlist(selected[0]);
  };

  getSavedWatchlists = async () => {
    // console.log("getSavedWatchlists Called")
    let email = null;
    if (!!this.props.loginDetails) {
      email = this.props.loginDetails?.email;
    }
    this.setState({
      showSavedWatchlistModal: true,
      savedWatchlist: [],
      watchlistModalTitle: "My Saved ",
      watchlistsLoaded: false,
      modalOpenCheck: !this.state.modalOpenCheck,
    });

    this.props.getSavedWatchlists(
      email,
      (result) => {
        this.setState({
          savedWatchlist: result.portfolios,
          // hashkey: result.portfolios.hashkey,
          koshaWatchlist: false,
          watchlistsLoaded:true
        });
        // setTimeout(()=>{
        //   this.setState({
        //     watchlistsLoaded:true,
        //   })
        // },2000)
      },
      (err) => {
        this.setErrorState(err, []);
      }
    );
  };

  //TODO: get rid of this function and refactor getSavedWatchlists to not always open a modal on function call
  getSavedWatchlistsWithoutModal = async () => {
    // console.log("getting saved watchlists without the modal")
    let email = null;
    if (!!this.props.loginDetails) {
      email = this.props.loginDetails?.email;
    }

    this.props.getSavedWatchlists(
      email,
      (result) => {
        this.setState({
          savedWatchlist: result.portfolios,
          // hashkey: result.portfolios.hashkey,
          koshaWatchlist: false,
          watchlistsLoaded:true
        });
        // setTimeout(()=>{
        //   this.setState({
        //     watchlistsLoaded:true,
        //   })
        // },2000)
      },
      (err) => {
        this.setErrorState(err, []);
      }
    );
  };

  manageWatchlist = (watchlist) => {
    const {
      watchlistname,
      watchlistnotes,
      holdings,
      maxallocation,
      timingtype,
      useremail,
      hashkey,
      managed,
    } = watchlist;
    let operation = "update";

    this.setState({
      watchlistsLoaded: false,
      loading: true,
      showChartData: false,
      assetChartData: [],
      familyChartData: [],
      analyzed: false,
    });

    this.props.manageWatchlist(
      watchlistname,
      watchlistnotes,
      holdings,
      operation,
      maxallocation,
      timingtype,
      useremail,
      hashkey,
      !managed,
      (result) => {
        const {
          assetData,
          portfolio,
          averageData,
          portfolioAssetChartData,
          familyData,
          portfolioFamilyChartData,
          performanceData,
          timingData,
        } = this.props;

        let spy = familyData;
        let familyChartData = portfolioFamilyChartData;

        let total = averageData;
        assetData.forEach((asset) => {
          asset.assetValid = asset.code === 0 ? true : false;
          asset.allocationValid = asset.code === 0 ? true : false;
        });

        if (result.message !== "Success") {
          if (assetData.length === 0) {
            result.portfolio.holdings.forEach((asset) => {
              asset.assetValid = true;
              assetData.push(asset);
            });
          }
        }

        result.portfolio.holdings.forEach((asset, index) => {
          // if (asset?.percentAllocation === undefined) {
          //   assetData[index].percentAllocation = 0;
          // } else {
          //   assetData[index].percentAllocation = asset?.percentAllocation;
          // }
          assetData.forEach(assetDatapoint => {
            if(asset.symbol === assetDatapoint.symbol){
              // TODO: See identical function in showSelectedWatchlist
              assetDatapoint.allocation = asset.allocation
              if (asset?.percentAllocation === undefined) {
                assetDatapoint.percentAllocation = 0;
              } else {
                assetDatapoint.percentAllocation =
                  asset?.percentAllocation;
              }
            }
          })
        });

        this.setState({
          total,
          assets: assetData,
          showChartData: true,
          portfolio,
          assetChartData: portfolioAssetChartData,
          spy,
          familyChartData,
          loading: false,
          analyzed: true,
          showSaveWatchlistModal: false,
          modalOpenCheck: !this.state.modalOpenCheck,
          watchlistsLoaded: true,
          showSavedWatchlistModal: false,
          transactionWatchlist: watchlist,
          filename: watchlist.watchlistname,
          watchlistnotes: watchlistnotes,
          watchlistChanged: false
        });

        let newAssets = [...this.state.assets].map((asset) => {
          return {
            symbol: asset.symbol,
            allocation: asset.allocation,
          };
        });

        if (!performanceData || !timingData) {
          this.props.fetchTimingData(
            newAssets,
            "watchlist",
            () => {
              if (this._mounted) {
                this.props.fetchPerformanceData(
                  newAssets,
                  "watchlist",
                  () => {
                    if (this._mounted) {
                      this.setState({
                        loading: false,
                        analyzed: true,
                        watchlistChanged: false
                      });
                    }
                  },
                  (err, request) => {
                    this.setErrorState(err, request);
                  }
                );
              }
            },
            (err, request) => {
              this.setErrorState(err, request);
            }
          );
        }
      },
      (err, request) => {
        this.setState({
          showSaveWatchlistModal: false,
          modalOpenCheck: !this.state.modalOpenCheck,
          watchlistsLoaded: true,
          showSavedWatchlistModal: false,
        });

        this.onApiModalClose();

        this.setErrorState(err, request);
      }
    );
  };

  showSelectedWatchlist = (watchlist) => {
    const {
      useremail,
      watchlistname,
      holdings,
      watchlistnotes,
      hashkey,
      maxallocation,
      timingtype,
    } = watchlist;

    // console.log(watchlist)

    let assets = [
      {
        symbol: "",
        allocation: "",
        percentAllocation: "",
        assetValid: true,
        allocationValid: true,
      },
    ];
    let assetChartData = null;
    let familyChartData = null;
    let total = { symbol: "Aggregate", allocation: "" };
    let spy = { symbol: "SPY", allocation: "" };

    if (assets.length === 0) {
      assets = [
        {
          symbol: "",
          allocation: "",
          percentAllocation: "",
          assetValid: true,
          allocationValid: true,
        },
      ];
    }

    this.props.clearSingleData(
      () => {},
      (err) => {
        console.log("err", err);
      }
    );

    console.log("assets before setState showSelectedWatchlist -> ", assets)

    this.setState(
      {
        assets,
        spy,
        total,
        showConfirmModal: false,
        modalOpenCheck: !this.state.modalOpenCheck,
        analyzeText: "Analyze",
        selectedWatchlist: holdings,
        watchlistname,
        watchlistnotes,
        watchlistEmail: useremail,
        loading: true,
        hashkey: hashkey,
        publisherWatchlistName: watchlist.publisherWatchlistName || "",
        publisherHashKey: watchlist.publisherHashKey || "",
        publisherEmail: watchlist.publisherEmail || "", 
        transactionWatchlist: watchlist,
        watchlistChanged: false
      },
      () => {
        console.log("state assets before updating portfolio props -> ", this.state.assets)
        this.props.updatePortfolioProps(this.state.assets, this.state.filename)

        this.setState(
          {
            valid: this.isValid(),
            assetChartData,
            familyChartData,
            showChartData: false,
          },
          () => {
            holdings.forEach((asset) => {
              asset.symbol = asset.symbol.toUpperCase();
              asset.assetValid = true;
              asset.allocationValid = true;
              asset.percentAllocation = ""
            });
            console.log("holdings before setState -> ", holdings)

            this.setState(
              {
                showSavedWatchlistModal: false,
                assets: holdings,
                loading: true,
                filename: watchlistname,
                modalOpenCheck: !this.state.modalOpenCheck,
                transactionWatchlist:watchlist,
              },
              () => {
                this.props.updatePortfolioProps(this.state.assets, "");
                this.setState({
                  valid: this.isValid(),
                });

                let newAssets = [...this.state.assets].map((asset) => {
                  // console.log("setting allocations (symbol, allocation) -> ", asset.symbol, asset.allocation)
                  // allocation means weight for some reason
                  return {
                    symbol: asset.symbol,
                    allocation: asset.allocation,
                  };
                });
               
                this.props.history.push("/watchlist?uuid=" + uuid());
                //TODO: need to add maxallocation and timingtype
                this.props.getWatchlistData(
                  useremail,
                  watchlistname,
                  maxallocation,
                  timingtype,
                  this.state.spy,
                  hashkey,
                  (result) => {
                    const {
                      assetData,
                      portfolio,
                      averageData,
                      portfolioAssetChartData,
                      familyData,
                      portfolioFamilyChartData,
                      performanceData,
                      timingData,
                    } = this.props;
                    
                    this.setState({
                      portfolio,
                      transactionWatchlist: watchlist,
                      analyzed: true,
                      compareSymbol: portfolio.benchmark,
                    });
                    watchlist.benchmark = portfolio.benchmark;

                    let spy = familyData;
                    let familyChartData = portfolioFamilyChartData;

                    let total = averageData;
                    assetData.forEach((asset) => {
                      asset.assetValid = asset.code === 0 ? true : false;
                      asset.allocationValid = asset.code === 0 ? true : false;
                    });

                    // Manual override for incorrect assetData values from props
                    result.portfolio.holdings.forEach((asset, index) => {
                      assetData.forEach(assetDatapoint => {
                        if(asset.symbol === assetDatapoint.symbol){
                          // TODO: Comment out this following line, there is an issue with how allocation (weights) are set for assetData in parent component
                          assetDatapoint.allocation = asset.allocation
                          // console.log("finding allocations -> ", asset, assetData, assetData[index].percentAllocation)
                          // asset.assetValid = true;
                          if (asset?.percentAllocation === undefined) {
                            assetDatapoint.percentAllocation = 0;
                          } else {
                            // console.log("assigning percentallocation to assetData -> ", assetData[index], asset?.percentAllocation)
                            assetDatapoint.percentAllocation =
                              asset?.percentAllocation;
                          }
                        }
                      })
                      // issue was no check for symbol matching, was relying hoping that lists were sorted identically without doing so manually
                      //   console.log("finding allocations -> ", asset, assetData[index], assetData[index].percentAllocation)
                      //   // asset.assetValid = true;
                      //   if (asset?.percentAllocation === undefined) {
                      //     assetData[index].percentAllocation = 0;
                      //   } else {
                      //     console.log("assigning percentallocation to assetData -> ", assetData[index], asset?.percentAllocation)
                      //     assetData[index].percentAllocation =
                      //       asset?.percentAllocation;
                      //   }
                      // this is good
                      // console.log("finding allocations -> ", asset, assetData[index], assetData[index].percentAllocation)
                      // // asset.assetValid = true;
                      // if (asset?.percentAllocation === undefined) {
                      //   assetData[index].percentAllocation = 0;
                      // } else {
                      //   console.log("assigning percentallocation to assetData -> ", assetData[index], asset?.percentAllocation)
                      //   assetData[index].percentAllocation =
                      //     asset?.percentAllocation;
                      // }
                    });
                    // wrong
                    console.log("asset data before push? -> ", assetData)

                    if (result.message !== "Success") {
                      if (assetData.length === 0) {
                        result.portfolio.holdings.forEach((asset) => {
                          asset.assetValid = true;
                          assetData.push(asset);
                        });
                      }
                    }
                    // this is wrong
                    // console.log("asset data before pushing setState -> ", assetData)

                    this.setState({
                      total,
                      assets: assetData,
                      showChartData: true,
                      portfolio,
                      assetChartData: portfolioAssetChartData,
                      spy,
                      familyChartData,
                      loading: false,
                      analyzed: true,
                      transactionWatchlist: watchlist,
                    });

                    if (!performanceData || !timingData) {
                      this.props.fetchTimingData(
                        newAssets,
                        "watchlist",
                        () => {
                          if (this._mounted) {
                            this.props.fetchPerformanceData(
                              newAssets,
                              "watchlist",
                              () => {
                                if (this._mounted) {
                                  this.setState({
                                    loading: false,
                                    analyzed: true,
                                    compareSymbol: watchlist.benchmark,
                                  });
                                }
                              },
                              (err, request) => {
                                this.setErrorState(err, request);
                              }
                            );
                          }
                        },
                        (err, request) => {
                          this.setErrorState(err, request);
                        }
                      );
                    }
                  },
                  (err) => {
                    if (err.portfolio) {
                      this.analyze(true);
                    } else {
                      this.setState({
                        loading: false,
                        error: "Something Went Wrong!",
                      });
                    }
                  }
                );
              }
            );
          },
          (err) => {
            if (err.portfolio) {
              this.analyze(true);
            } else {
              this.setState({
                loading: false,
                error: "Something Went Wrong!",
              });
            }
          }
        );
      }
    );
  };

  onSaveClick = async () => {
    // if(this.state.watchlistname)
    const {
      selectedWatchlist,
      koshaWatchlist,
      // watchlistTypeSelected,
      watchlistname,
      savedWatchlist
    } = this.state;

    let assets = this.state.assets;

    let assetLength = assets.length - 1;
    if (
      assets[assetLength].symbol === "" &&
      assets[assetLength].allocation === ""
    ) {
      assets.pop();
    }
    let validValueType = "allocationValid"
    // watchlistTypeSelected === "allocation"
    //   ? "allocationValid"
    //   : "quantityValid";
    let invalidAlloc = assets.filter((asset) => {
      return !asset[validValueType];
    });

    if (invalidAlloc.length !== 0) {
      let errorDiv = document.getElementById("weight-error-div");
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
      return false;
    }

    if (
      assets.filter((asset) => {
        if (parseFloat(asset.allocation) || parseFloat(asset.quantity)) {
          return true;
        }
      }).length === 0
    ) {
      return this.setState({
        error: `At least one of the weights of valid assets should be non-zero.`,
        showViewLog: false,
        loading: false,
      });
    }

    let checkReturn = this.duplicateAssetCheck(assets);
    let duplicatesPresent = checkReturn[1];
    let checkedAssets = checkReturn[0];

    if (duplicatesPresent) {
      this.setState(
        {
          error: "Duplicate symbols are not allowed.",
          showViewLog: false,
          loading: false,
          assets: checkedAssets,
        },
        () => {
          this.props.updatePortfolioProps(this.state.assets, "");

          let duplicateArray = checkedAssets.filter((asset) => {
            return asset.duplicate;
          });

          checkedAssets.map((asset) => {
            if (!asset.duplicate) duplicateArray.push(asset);
          });

          this.setState({
            assets: duplicateArray,
          });
        }
      );
    } else {
      checkedAssets.map((asset) => {
        if (asset.duplicate) asset.assetValid = true;
      });
    }

    if (!duplicatesPresent) {
      // let email = null;
      // if (!!this.props.loginDetails) {
      //   email = this.props.loginDetails?.email;
      // }

        let updatedWatchlist = [];
        if(selectedWatchlist){
        selectedWatchlist.map((asset) => {
          updatedWatchlist.push({
            symbol: asset.symbol,
            allocation: asset.allocation,
            symbolaction: "D",
            assetValid: true,
            allocationValid: true,
            percentAllocation: asset.percentAllocation,
          });
        });
      }
        assets.map((selected) => {
          updatedWatchlist.map((updated) => {
            if (updated.symbol === selected.symbol) {
              //This probably works? 
              // console.log("selected, updated", selected, updated)
              updated.symbolaction = "U";
              updated.percentAllocation = selected.percentAllocation || "0";
              updated.allocation = selected.allocation;
              //TODO: Uncomment if reverting back to beta code with all "A"s
              // if (updated.allocation !== selected.allocation) {
              //   updated.symbolaction = "U";
              //   updated.allocation = selected.allocation;
              //   updated.percentAllocation = selected.percentAllocation || "0";
              // } else {
              //   if (updated.symbolaction !== "U") updated.symbolaction = "A";
              // }
            }
          });
        });

        assets.map((asset) => {
          if (asset.symbol !== "" && asset.allocation !== "") {
            let findIndex = updatedWatchlist.findIndex(updatedAsset => updatedAsset.symbol ===asset.symbol)
            if (findIndex===-1) {
              updatedWatchlist.push({
                symbol: asset.symbol,
                allocation: asset.allocation,
                symbolaction: "A",
                assetValid: true,
                allocationValid: true,
                percentAllocation: asset.percentAllocation || "0",
              });
            }
          }
        });
        this.setState({
          updatedWatchlist,
          showSaveWatchlistModal: true,
          modalOpenCheck: !this.state.modalOpenCheck,
        });
    }
    //save watchlist with additional text at the end of it if already subscribed maybe "Copy of {watchlist_name}"
    if(this.props.portfolio?.useremail === "system@vistalytics.com" && !this.state.filename.includes('*')){
      // console.log("save or subscribe modal true", this.state.filename)
      this.setState({
        showSaveWatchlistModal: false,
        modalOpenCheck: !this.state.modalOpenCheck,
      });
      this.setState({showSaveOrSubscribeModal: true})
    }
    // console.log(selectedWatchlist, savedWatchlist, koshaWatchlist, watchlistname)
  };

  onOptimizeSelect = (option) => {
    const { assets, compareSymbol } = this.state;

    if (!compareSymbol) {
      return this.setState({
        error: `Please select a Benchmark symbol.`,
        showViewLog: false,
        loading: false,
        showOptimizeModal: false,
      });
    }

    let newAssets = [];
    assets.map((asset) => {
      if (asset.symbol) {
        newAssets.push({
          symbol: asset.symbol,
          allocation: asset.allocation || asset.quantity || 1,
        });
      }
    });

    this.setState({
      showOptimizeModal: false,
      loading: true,
      holdings: newAssets,
      // watchlistname: "",
      // watchlistnotes: "",
      // filename: "",
      modalOpenCheck: !this.state.modalOpenCheck,
    });

    this.props.optimizeWatchlist(
      newAssets,
      option,
      this.state.compareSymbol,
      (result) => {
        const {
          assetData,
          portfolioRequest,
          portfolio,
          message,
          averageData,
          portfolioAssetChartData,
        } = this.props;
        // const { watchlistTypeSelected } = this.state;
        let apiDetails = [];
        let apiResponse = [];
        apiDetails.push(portfolioRequest);
        apiResponse.push(portfolio);

        let total = averageData;
        let holdings = portfolio.holdings;
        let allocationChange = true;
        portfolio.maxallocation = this.state.transactionWatchlist?.maxallocation;
        portfolio.timingtype = this.state.transactionWatchlist?.timingtype;
        let aggregateAlloc = total.allocation;
        
        this.setState({watchlistChanged: true})
        // let filename =
        // this.state.filename !== "" && !this.state.filename.includes("*")
        //   ? this.state.filename + "*"
        //   : this.state.filename;

        if (assetData.length === 0) {
          return this.setState({
            error: result?.message,
            showViewLog: false,
            loading: false,
            showOptimizeModal: false,
          });
        }

        // if (watchlistTypeSelected === "allocation") {
          assetData.forEach((asset, index) => {
            asset.assetValid = asset.code === 0 ? true : false;
            asset.allocationValid = asset.code === 0 ? true : false;
            asset.allocation = holdings[index].allocation;
            asset.percentAllocation = holdings[index]?.percentAllocation || 0;
            if (asset.allocation >= 1) allocationChange = false;
          });

          if (allocationChange) {
            aggregateAlloc = 0;
            assetData.map((asset) => {
              let oldalloc = asset.allocation;
              let newAlloc = oldalloc * 10;
              asset.allocation = newAlloc;
              aggregateAlloc += newAlloc;
            });

            total.allocation = aggregateAlloc.toFixed(3);
          }
        // } else {
        //   assetData.forEach((asset) => {
        //     asset.assetValid = asset.code === 0 ? true : false;
        //     asset.quantityValid = asset.code === 0 ? true : false;
        //   });
        // }

        this.setState({
          apiDetails,
          apiResponse,
          total,
          assets: assetData,
          showChartData: true,
          portfolio,
          assetChartData: portfolioAssetChartData,
          // filename
        });

        let benchmarkData = { symbol: compareSymbol, allocation: "" };

        this.props.fetchFamilyData(
          benchmarkData,
          () => {
            let spy = this.props.familyData;
            let vsip = [{ vsip: spy.vsip }];

            let apiDetails = this.state.apiDetails;
            let apiResponse = this.state.apiResponse;

            apiDetails.push(this.props.familyDataRequest);
            apiResponse.push(this.props.familyData);

            this.setState({
              apiDetails,
              apiResponse,
              spy,
            });

            this.props.fetchFamilyChartData(
              vsip,
              CHART_TYPE.FAMILY_CHART,
              () => {
                let familyChartData = this.props.portfolioFamilyChartData;
                const { performanceData, timingData } = this.props;

                if (familyChartData) {
                  let apiDetails = this.state.apiDetails;
                  let apiResponse = this.state.apiResponse;

                  apiDetails.push(this.props.portfolioFamilyChartRequest);
                  apiResponse.push(familyChartData);

                  if (this._mounted) {
                    this.setState({
                      assets: assetData,
                      familyChartData,
                      spy,
                      total,
                      apiDetails,
                      apiResponse,
                      analyzed: false,
                      showPercentage: !this.state.showPercentage,
                      valid: true,
                    });
                  }
                }

                if (
                  performanceData &&
                  performanceData.percentagegainsdata.length !== 0
                ) {
                  if (timingData && timingData.timingsdata.length !== 0) {
                    this.setState({
                      loading: false,
                      analyzed: true,
                    });
                  } else {
                    this.props.fetchTimingData(
                      newAssets,
                      "watchlist",
                      () => {
                        if (this._mounted) {
                          const { timingData, timingRequest } = this.props;
                          apiDetails.push(timingRequest);
                          apiResponse.push(timingData);
                        }
                      },
                      (err, request) => {
                        this.setErrorState(err, request);
                      }
                    );
                  }
                } else {
                  this.props.fetchPerformanceData(
                    newAssets,
                    "watchlist",
                    () => {
                      if (this._mounted) {
                        const {
                          performanceData,
                          performanceRequest,
                        } = this.props;

                        apiDetails.push(performanceRequest);
                        apiResponse.push(performanceData);

                        if (timingData && timingData.timingsdata.length !== 0) {
                          this.setState({
                            loading: false,
                            analyzed: true,
                          });
                        } else {
                          this.props.fetchTimingData(
                            newAssets,
                            "watchlist",
                            () => {
                              if (this._mounted) {
                                const {
                                  timingData,
                                  timingRequest,
                                } = this.props;
                                apiDetails.push(timingRequest);
                                apiResponse.push(timingData);

                                this.setState({
                                  loading: false,
                                  apiDetails,
                                  apiResponse,
                                  analyzed: true,
                                });
                              }
                            },
                            (err, request) => {
                              this.setErrorState(err, request);
                            }
                          );
                        }
                      }
                    },
                    (err, request) => {
                      this.setErrorState(err, request);
                    }
                  );
                }
              },
              (err, request) => {
                this.setErrorState(err, request);
              }
            );
          },
          (err, request) => {
            this.setErrorState(err, request);
          }
        );
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  onOptimizeClick = () => {
    const { watchlistTypeSelected, assets } = this.state;
    if (assets.length <= 1) {
      this.setState({
        error:
          "Watchlist must have two or more assets to run the optimize function.",
        showViewLog: false,
        loading: false,
        showTemplate: false,
      });
      return false;
    }
    this.props.resetPortfolioChart();

    let assetLength = assets.length - 1;
    let lastAssetSymbol = assets[assetLength].symbol;
    let lastAssetValue = assets[assetLength].allocation
      // watchlistTypeSelected === "allocation"
      //   ? assets[assetLength].allocation
      //   : assets[assetLength].quantity;

    let validValueType = "allocationValid"
      // watchlistTypeSelected === "allocation"
      //   ? "allocationValid"
      //   : "quantityValid";

    if (lastAssetSymbol === "" && lastAssetValue === "") {
      assets.pop();
    }

    let invalidAlloc = assets.filter((asset) => {
      return !asset[validValueType];
    });

    if (invalidAlloc.length !== 0) {
      let errorDiv = document.getElementById("weight-error-div");
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
      return false;
    }

    let checkReturn = this.duplicateAssetCheck(assets);
    let duplicatesPresent = checkReturn[1];
    let checkedAssets = checkReturn[0];

    if (duplicatesPresent) {
      this.setState(
        {
          error: "Duplicate symbols are not allowed.",
          showViewLog: false,
          loading: false,
          assets: checkedAssets,
          showTemplate: false,
        },
        () => {
          this.props.updatePortfolioProps(this.state.assets, "");

          let duplicateArray = checkedAssets.filter((asset) => {
            return asset.duplicate;
          });

          checkedAssets.map((asset) => {
            if (!asset.duplicate) duplicateArray.push(asset);
          });

          this.setState({
            assets: duplicateArray,
          });
        }
      );
    } else {
      checkedAssets.map((asset) => {
        if (asset.duplicate) asset.assetValid = true;
      });
    }

    if (!duplicatesPresent) {
      let assetsCheck = this.state.assets.filter((asset) => {
        return !asset.assetValid;
      });

      if (assetsCheck.length !== 0) {
        this.setState({
          error:
            "Some assets in this watchlist are not supported for Optimizing.",
          showViewLog: false,
          loading: false,
          showTemplate: false,
        });
      } else {
        this.setState({
          showOptimizeModal: true,
          modalOpenCheck: !this.state.modalOpenCheck,
        });
      }
    }
  };

  saveWatchlist = (
    name,
    notes,
    operation,
    maxAllocation,
    timingType,
    hashkeyValue,
    watchlist,
    managed,
    afterWatchlistSaved
  ) => {
    let email = null;
    if (!!this.props.loginDetails) {
      email = this.props.loginDetails?.email;
    }
    let assets = this.state.assets;
    let prevAssets = [];
    let hashkey = undefined;
    //TODO: look at updated watchlist and see why it needs to exist
    if (operation === "update" && (this.state.updatedWatchlist || watchlist)) {
      prevAssets = this.state.updatedWatchlist || watchlist.holdings;
      hashkey = hashkeyValue|| this.state.hashkey;
    }

    let assetLength = assets.length - 1;
    if (
      assets.length !== 0 &&
      assets[assetLength].symbol === "" &&
      assets[assetLength].allocation === ""
    ) {
      assets.pop();
    }

    if (!this.state.updatedWatchlist && prevAssets.length !== 0) {
      prevAssets.map((asset) => {
        asset.symbolaction = "D";
        assets.push(asset);
      });
    } else {
      if (operation === "update") assets = this.state.updatedWatchlist;
    }

    this.setState({
      saveButtonText: "Saving ",
      showChartData: false,
      portfolio: [],
      assetChartData: [],
      familyChartData: [],
      performanceData: [],
      loading: true
    });

    //update watchlist -> send u's for assets that already exist in the watchlist

    let saveAssets = [];
    assets.forEach((asset) => {
      if (asset.symbol !== "" && asset.allocation !== "") {
        // console.log("saving asset -> ", asset)
        saveAssets.push({
          symbol: asset.symbol,
          allocation: asset.allocation,
          symbolaction: asset.symbolaction || "A",
          percentAllocation: asset.percentAllocation || "0",
        });
      }
    });

    this.props.saveWatchlist(
      name,
      notes,
      saveAssets,
      operation,
      maxAllocation,
      timingType,
      email,
      hashkey,
      this.state.compareSymbol,
      managed,
      (result) => {
        let thisVal = this;
        this.setState({
          saveButtonText: "Saved!",
        });
        this.props.history.push("/watchlist?uuid=" + uuid());

        let transactionWatchlist = [];
        let portfolioResult = result.portfolio;

        transactionWatchlist.push({
          holdings: portfolioResult?.holdings,
          maxallocation: portfolioResult?.maxallocation,
          benchmark: portfolioResult?.benchmark,
          managed: portfolioResult?.managed,
          hashkey: portfolioResult?.hashkey,
          optimization: portfolioResult?.optimization,
          timingtype: portfolioResult?.timingtype,
          useremail: portfolioResult?.useremail,
          watchlistname: name,
          watchlistnotes: notes,
        });

        setTimeout(() => {
          thisVal.setState({
            showSaveWatchlistModal: false,
            modalOpenCheck: !this.state.modalOpenCheck,
            saveButtonText: "Save Watchlist",
            transactionWatchlist: transactionWatchlist[0],
            watchlistname: name,
          });
          afterWatchlistSaved();
        }, 100);

        const {
          assetData,
          portfolio,
          averageData,
          portfolioAssetChartData,
          familyData,
          portfolioFamilyChartData,
          performanceData,
          timingData,
          portfolioRequest,
        } = this.props;

        let apiDetails = [];
        let apiResponse = [];
        apiDetails.push(portfolioRequest);
        apiResponse.push(portfolio);

        let total = averageData;
        let holdings = portfolio.holdings;
        let allocationChange = true;
        let aggregateAlloc = total.allocation;

          assetData.forEach((asset, index) => {
            asset.assetValid = asset.code === 0 ? true : false;
            asset.allocationValid = asset.code === 0 ? true : false;
            asset.allocation = holdings[index].allocation;
            if (asset.allocation >= 1) allocationChange = false;
          });

          if (allocationChange) {
            aggregateAlloc = 0;
            assetData.map((asset) => {
              let oldalloc = asset.allocation;
              let newAlloc = oldalloc * 10;
              asset.allocation = newAlloc;
              aggregateAlloc += newAlloc;
            });

            total.allocation = aggregateAlloc.toFixed(3);
          }

        let spy = familyData;
        let familyChartData = portfolioFamilyChartData;

        if (assetData.length === 0) {
          portfolio.holdings.forEach((asset) => {
            asset.assetValid = true;
            assetData.push(asset);
          });
        }

        result.portfolio.holdings.forEach((asset, index) => {
          if (asset?.percentAllocation === undefined) {
            assetData[index].percentAllocation = 0;
          } else {
            assetData[index].percentAllocation = asset?.percentAllocation;
          }
        });

        this.setState({
          total,
          assets: assetData,
          showChartData: true,
          portfolio,
          assetChartData: portfolioAssetChartData,
          spy,
          familyChartData,
          loading: false,
          analyzed: true,
          performanceData,
          timingData,
          filename: name,
          selectedWatchlist: assetData,
          watchlistChanged: false
        });
      },
      (err, request) => {
        let thisVal = this;
        this.setState({
          saveButtonText: "Not saved!",
        });
        afterWatchlistSaved();
        setTimeout(() => {
          thisVal.setState({
            showSaveWatchlistModal: false,
            modalOpenCheck: !this.state.modalOpenCheck,
            saveButtonText: "Save Watchlist",
          });
        }, 1000);
        this.setErrorState(err, request);
      }
    );
  };

  subscribeToWatchlist = (
    name,
    notes,
    operation,
    maxAllocation,
    timingType,
    hashkeyValue,
    watchlist,
    managed,
    afterWatchlistSaved
  ) => {
    // console.log("subscribe called: ", operation, this.state.watchlistname)
    let email = null;
    if (!!this.props.loginDetails) {
      email = this.props.loginDetails?.email;
    }
    let assets = this.state.assets;
    let prevAssets = [];
    let hashkey = undefined;
    //TODO: look at updated watchlist and see why it needs to exist
    if (operation === "update" && (this.state.updatedWatchlist || watchlist)) {
      prevAssets = this.state.updatedWatchlist || watchlist.holdings;
      hashkey = hashkeyValue|| this.state.hashkey;
    }

    let assetLength = assets.length - 1;
    if (
      assets.length !== 0 &&
      assets[assetLength].symbol === "" &&
      assets[assetLength].allocation === ""
    ) {
      assets.pop();
    }

    if (!this.state.updatedWatchlist && prevAssets.length !== 0) {
      prevAssets.map((asset) => {
        asset.symbolaction = "D";
        assets.push(asset);
      });
    } else {
      if (operation === "update") assets = this.state.updatedWatchlist;
    }

    this.setState({
      // saveButtonText: "Saving ",
      showChartData: false,
      portfolio: [],
      assetChartData: [],
      familyChartData: [],
      performanceData: [],
      // loading: true
    });

    let saveAssets = [];
    assets.forEach((asset) => {
      if (asset.symbol !== "" && asset.allocation !== "") {
        saveAssets.push({
          symbol: asset.symbol,
          allocation: asset.allocation,
          symbolaction: asset.symbolaction || "A",
          percentAllocation: asset.percentAllocation || "0",
        });
      }
    });

    this.props.subscribeToWatchlist(
      this.state.watchlistname,
      notes,
      saveAssets,
      operation,
      maxAllocation,
      timingType,
      email,
      hashkey,
      this.state.compareSymbol,
      managed,
      this.state.watchlistname,
      "system@vistalytics.com",
      this.props.portfolio.hashkey,
      true,
      (result) => {
        let thisVal = this;
        // this.setState({
        //   saveButtonText: "Saved!",
        // });
        this.props.history.push("/watchlist?uuid=" + uuid());

        let transactionWatchlist = [];
        let portfolioResult = result.portfolio;

        transactionWatchlist.push({
          holdings: portfolioResult?.holdings,
          maxallocation: portfolioResult?.maxallocation,
          benchmark: portfolioResult?.benchmark,
          managed: portfolioResult?.managed,
          hashkey: portfolioResult?.hashkey,
          optimization: portfolioResult?.optimization,
          timingtype: portfolioResult?.timingtype,
          useremail: portfolioResult?.useremail,
          watchlistname: name,
          watchlistnotes: notes,
        });

        // console.log(name)
        setTimeout(() => {
          thisVal.setState({
            showSaveOrSubscribeModal: false,
            modalOpenCheck: !this.state.modalOpenCheck,
            // saveButtonText: "Save Watchlist",
            transactionWatchlist: transactionWatchlist[0],
            watchlistname: name,
          });
          // afterWatchlistSaved();
        }, 100);

        const {
          assetData,
          portfolio,
          averageData,
          portfolioAssetChartData,
          familyData,
          portfolioFamilyChartData,
          performanceData,
          timingData,
          portfolioRequest,
        } = this.props;

        // const { watchlistTypeSelected } = this.state;
        let apiDetails = [];
        let apiResponse = [];
        apiDetails.push(portfolioRequest);
        apiResponse.push(portfolio);

        let total = averageData;
        let holdings = portfolio.holdings;
        let allocationChange = true;
        let aggregateAlloc = total.allocation;

        // if (watchlistTypeSelected === "allocation") {
          assetData.forEach((asset, index) => {
            asset.assetValid = asset.code === 0 ? true : false;
            asset.allocationValid = asset.code === 0 ? true : false;
            asset.allocation = holdings[index].allocation;
            if (asset.allocation >= 1) allocationChange = false;
          });

          if (allocationChange) {
            aggregateAlloc = 0;
            assetData.map((asset) => {
              let oldalloc = asset.allocation;
              let newAlloc = oldalloc * 10;
              asset.allocation = newAlloc;
              aggregateAlloc += newAlloc;
            });

            total.allocation = aggregateAlloc.toFixed(3);
          }

        let spy = familyData;
        let familyChartData = portfolioFamilyChartData;

        if (assetData.length === 0) {
          portfolio.holdings.forEach((asset) => {
            asset.assetValid = true;
            assetData.push(asset);
          });
        }

        result.portfolio.holdings.forEach((asset, index) => {
          if (asset?.percentAllocation === undefined) {
            assetData[index].percentAllocation = 0;
          } else {
            assetData[index].percentAllocation = asset?.percentAllocation;
          }
        });

        this.setState({
          total,
          assets: assetData,
          showChartData: true,
          portfolio,
          assetChartData: portfolioAssetChartData,
          spy,
          familyChartData,
          loading: false,
          analyzed: true,
          performanceData,
          timingData,
          filename: this.state.watchlistname,
          selectedWatchlist: assetData,
        });
      },
      (err, request) => {
        let thisVal = this;
        // this.setState({
        //   saveButtonText: "Not saved!",
        // });
        // afterWatchlistSaved();
        setTimeout(() => {
          thisVal.setState({
            showSaveorSubscribeModal: false,
            modalOpenCheck: !this.state.modalOpenCheck,
            saveButtonText: "Save Watchlist",
          });
        }, 1000);
        this.setErrorState(err, request);
      }
    );
  };

  handover = () => {
    const { assets } = this.state;
    localStorage.setItem("albertoAssets", JSON.stringify(assets));
    this.props.history.push("/alberto?uuid=" + uuid());
  };

  getSingleAsset = (symbol) => {
    if (symbol === null) {
      this.props.clearSingleData(
        () => {},
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      this.setState({
        singleLoading: true,
      });
      let holdings = [{ symbol: symbol, allocation: "100" }];
      const { apiDetails, apiResponse } = this.state;

      this.props.fetchTimingData(
        holdings,
        "single",
        () => {
          if (this._mounted) {
            const { singleAssetTiming } = this.props;

            this.props.fetchPerformanceData(
              holdings,
              "single",
              () => {
                if (this._mounted) {
                  const { singleAssetPerformance } = this.props;

                  this.setState({
                    loading: false,
                    apiDetails,
                    apiResponse,
                    analyzed: true,
                    singleAssetTiming,
                    singleAssetPerformance,
                    singleLoading: false,
                  });
                }
              },
              (err, request) => {
                this.setErrorState(err, request);
              }
            );
          }
        },
        (err, request) => {
          this.setErrorState(err, request);
        }
      );
    }
  };

  getSentimentValue = (data) => {
    let longAssets = data.filter((asset) => {
      return asset.timing === "Long";
    });

    let shortAssets = data.filter((asset) => {
      return asset.timing === "Short";
    });

    let aggregate = 0;
    data.forEach((asset) => {
      aggregate += asset.allocation;
    });

    let sentimentValue = calculateSentimentValue(
      longAssets,
      shortAssets,
      aggregate
    );

    if (sentimentValue) {
      return (
        <>
        <div className="tooltipx">
          <h3 className="box-title">Watchlist Sentiment</h3>
          <div className="tooltiptext-div">{getTooltipText("watchlistSentiment")}</div>
        </div>
        <div className="sentiment-gradient-div">
          <div className="sentiment-indicator" id={sentimentValue.id}></div>
        </div>
        <h3 className="box-title m-t-10" id="sentiment">
          {sentimentValue.value}
        </h3>
        </>
      );
    } else {
      return "";
    }
  };

  onDownloadTrading = () => {
    this.setState({
      bucketLoading: true,
    });

    const { transactionWatchlist } = this.state;
    let maxAllocation = transactionWatchlist
      ? transactionWatchlist.maxallocation || "0"
      : "0";
    let timingType = transactionWatchlist
      ? transactionWatchlist.timingtype || ""
      : "";

    if (maxAllocation && timingType) {
      let newAssets = [...this.state.assets].map((asset) => {
        return {
          symbol: asset.symbol,
          allocation: asset.allocation,
        };
      });

      this.onGetBucketDetails(newAssets, maxAllocation, timingType);
    } else {
      this.setState({
        showTransactionsModal: true,
      });
    }
  };

  onSubmitBucketDetails = (maxAllocation, timingType) => {
    this.setState({
      showTransactionsModal: false,
      bucketLoading: true,
    });
    let newAssets = [...this.state.assets].map((asset) => {
      return {
        symbol: asset.symbol,
        allocation: asset.allocation,
      };
    });

    this.onGetBucketDetails(newAssets, maxAllocation, timingType);
  };

  onGetBucketDetails = (newAssets, maxAllocation, timingType) => {
    const { portfolio } = this.state;
    this.props.getBucketDetails(
      newAssets,
      maxAllocation,
      timingType,
      () => {
        const { bucketDetailsData } = this.props;
        let data = [];
        let currentDate = new Date();
        currentDate = formatDownloadDate(currentDate);
        let filename = "Kosha-Bucket-Trading-" + currentDate;

        bucketDetailsData.forEach((asset, index) => {
          if (index > 0) {
            if (index === 1) {
              data.push({
                asset: asset.asset,
                allocation: asset.allocation,
                timing: asset.timing,
                timingType: timingType || "LONG_ONLY",
                maxAllocation: maxAllocation || "0",
              });
            } else {
              data.push({
                asset: asset.asset,
                allocation: asset.allocation,
                timing: asset.timing,
                timingType: "",
                maxAllocation: "",
              });
            }
          }
        });

        const exportOptions = {
          fieldSeparator: ",",
          quoteStrings: "",
          decimalSeparator: ".",
          filename: filename,
          showLabels: true,
          showTitle: false,
          title: "Kosha Bucket Trading Details",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          headers: [
            "Asset",
            "Allocation %",
            "Timing",
            "Timing Type",
            "Max Free Money Redistribution %",
          ],
        };

        // analytics.setUserProperties({
        //   email: loginDetails.email,
        //   App_Version: packageJson.version
        // });
        // analytics.logEvent('Export CSV');

        const csvExporter = new ExportToCsv(exportOptions);
        csvExporter.generateCsv(data);

        this.setState({
          bucketLoading: false,
        });
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  updateValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.performanceData.return;

    if (prevReturn === newReturn) {
      return false;
    } else {
      this.props.updateValues(
        newReturn,
        newDrawdown,
        newRecovery,
        newSharpe,
        "portfolio",
        () => {
          if (prevReturn !== newReturn) this.forceUpdate();
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  updateCompareValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.timingData.return;

    if (prevReturn === newReturn) {
      return false;
    } else {
      this.props.updateCompareValues(
        newReturn,
        newDrawdown,
        newRecovery,
        newSharpe,
        "portfolio",
        () => {
          if (prevReturn !== newReturn) this.forceUpdate();
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  updateBenchmarkValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.portfolioFamilyChartData.performance.return;

    if (prevReturn === newReturn) {
      return;
    } else {
      this.props.updateBenchmarkValues(
        newReturn,
        newDrawdown,
        newRecovery,
        newSharpe,
        "portfolio",
        () => {
          // if (prevReturn !== newReturn)
          //   this.forceUpdate();
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  updateAllPerfValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.compareportfolioPerformance.return;

    if (prevReturn === newReturn) {
      return false;
    } else {
      this.props.updateAllPerfValues(
        newReturn,
        newDrawdown,
        newRecovery,
        newSharpe,
        () => {
          // if (prevReturn !== newReturn)
          // this.forceUpdate();
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  updateAllTimingValues = (newReturn, newDrawdown, newRecovery, newSharpe) => {
    let prevReturn = this.props.comparePortfolioTiming.return;

    if (prevReturn === newReturn) {
      return false;
    } else {
      this.props.updateAllTimingValues(
        newReturn,
        newDrawdown,
        newRecovery,
        newSharpe,
        () => {
          if (prevReturn !== newReturn) this.forceUpdate();
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  };

  onTypeClickWithConfirm = (type) => {
    let showSwitchModal = this.state.showSwitchModal;
    if (this.state.assets.length !== 0 && this.state.assets[0].symbol !== "")
      this.setState({
        showSwitchModal: { ...showSwitchModal, show: true, type: type },
      });
    else this.onTypeClick(type);
  };

  onTypeClick = (type) => {
    let weightQuantity = this.state.weightQuantity;

    // let type = !this.state.weightQuantity?"quantity":"allocation";
    let currentAssets = this.state.assets;
    let finalAssets = [];
    if (type === "quantity") {
      currentAssets.forEach((asset) => {
        finalAssets.push({
          symbol: asset.symbol,
          quantity: "",
          assetValid: asset.assetValid,
          quantityValid: true,
        });
      });
      // finalAssets = [
      //   { symbol: "", quantity: "", assetValid: true, quantityValid: true },
      // ];
    } else {
      currentAssets.forEach((asset) => {
        finalAssets.push({
          symbol: asset.symbol,
          allocation: "",
          assetValid: asset.assetValid,
          allocationValid: true,
        });
      });
    }

    this.setState(
      {
        weightQuantity: !weightQuantity,
        assets: finalAssets,
        total: { symbol: "Aggregate", [type]: "" },
        spy: { symbol: "SPY", [type]: "" },
        filename: "",
        analyzeText: "Analyze",
        showSwitchModal: { show: false, type: "" },
      },
      () => {
        this.props.updatePortfolioProps(this.state.assets, this.state.filename);

        this.setState({
          valid: this.isValid(),
          assetChartData: null,
          familyChartData: null,
          showChartData: false,
        });
      }
    );
  };

  showDeleteConfirm = (watchlist) => {
    this.setState({
      showDeleteModel: true,
      deleteWatchlist: watchlist,
      showSavedWatchlistModal: false,
      modalOpenCheck: !this.state.modalOpenCheck,
    });
  };

  onDeleteClick = () => {
    let email = null;
    if (!!this.props.loginDetails) {
      email = this.props.loginDetails?.email;
    }
    const { deleteWatchlist, portfolio } = this.state;

    let assets = deleteWatchlist.holdings;
    let benchmark = "";
    let managed = portfolio?.managed;
    let saveAssets = [];
    assets.forEach((asset) => {
      if (asset.symbol !== "" && asset.allocation !== "") {
        saveAssets.push({
          symbol: asset.symbol,
          allocation: asset.allocation,
          symbolaction: "D",
        });
      }
    });

    this.props.saveWatchlist(
      deleteWatchlist.watchlistname,
      "",
      saveAssets,
      "delete",
      "0",
      "LONG_ONLY",
      email,
      deleteWatchlist.hashkey,
      benchmark,
      managed,
      () => {
        let thisVal = this;
        if (deleteWatchlist.watchlistname === thisVal.state.filename) {
          thisVal.clearTable();
        }

        thisVal.getSavedWatchlists();
          thisVal.setState({
            showSaveWatchlistModal: false,
            saveButtonText: "Save Watchlist",
            showSavedWatchlistModal: true,
            showDeleteModel: false,
            modalOpenCheck: !this.state.modalOpenCheck,
          });
      },
      (err, requestBody) => {
        this.setErrorState(err, requestBody);
      }
    );
  };

  onTransactionsClick = () => {
    this.setState({
      showTransactionsModal: true,
    });
  };

  render() {
    const {
      valid,
      loading,
      assets,
      total,
      spy,
      analyzed,
      error,
      assetChartData,
      familyChartData,
      showViewLog,
      apiDetails,
      apiModalShow,
      apiResponse,
      isError,
      showChartData,
      compareSymbol,
      symbols,
      showRangeModal,
      smaRange,
      fileLoading,
      symbolsLoading,
      showConfirmModal,
      showTimingDiff,
      singleLoading,
      tableSymbols,
      extraAssets,
      showExtraAsset,
      filename,
      showReloadModal,
      bucketLoading,
      showSaveWatchlistModal,
      showSaveOrSubscribeModal,
      saveButtonText,
      showSavedWatchlistModal,
      savedWatchlist,
      watchlistTypes,
      watchlistModalTitle,
      showOptimizeModal,
      csvLoading,
      watchlistsLoaded,
      watchlistnotes,
      showDeleteModel,
      deleteWatchlist,
      showBenchmarkChangeModal,
      oldCompareSymbol,
      koshaWatchlist,
      modalOpenCheck,
      showTransactionsModal,
      showSwitchModal,
      transactionWatchlist,
      showTemplate,
      watchlistname,
      portfolio,
      managedWatchlistAlert,
    } = this.state;

    const {
      timingData,
      performanceData,
      holdings,
      singleAssetPerformance,
      singleAssetTiming,
      timingDiffData,
      comparePortfolioTiming,
      compareportfolioPerformance,
      about,
    } = this.props;

    let poiData = assetChartData && assetChartData.poi;
    let familyPoiData = familyChartData && familyChartData.poi;

    if (
      poiData &&
      poiData.length !== 0 &&
      assetChartData.closingprice[0].date !== poiData[0].date
    )
      poiData.reverse();
    if (
      familyPoiData &&
      familyPoiData.length !== 0 &&
      familyChartData.closingprice[0].date !== familyPoiData[0].date
    )
      familyPoiData.reverse();

    let tableView = (
      <>
        <div className="row add-button-div">
          <div
            className="col-md-2 col-sm-4 col-xs-6 file-input-div"
            id={loading || fileLoading ? "disabled-input-btn" : ""}
          >
            <CustomTooltip title={getTooltipText("uploadCsv")}>
            {/* <div className ="tooltipx"> */}
              <div>
                <CSVReader
                  inputId="CSVReader"
                  cssClass={
                    loading || fileLoading
                      ? "react-csv-input-disabled"
                      : "react-csv-input"
                  }
                  label="Upload CSV"
                  onFileLoaded={this.handleForce}
                  parserOptions={this.options}
                  disabled={loading || fileLoading}
                />
              </div>
            {/* <div className="tooltiptext-div">{getTooltipText("uploadCsv")}</div>
            </div> */}
            </CustomTooltip> 
          </div>
        <div className="col-md-3 col-sm-4 col-xs-6 no-padding info-text-div">
          <CustomTooltip title={getTooltipText("downloadTemplate")}>
            <i onClick={this.downloadTemplate} className="fa fa-info-circle" style={{width: "fit-content"}}>
              <span className="template-text">Download Template</span>
            </i>
          </CustomTooltip>
          {/* <div className ="tooltipx">
          <i onClick={this.downloadTemplate} className="fa fa-info-circle">
            <span className="template-text">Download Template</span>
          </i>
          <div className="tooltiptext-div">{getTooltipText("downloadTemplate")}</div>
          </div> */}
          <span className="watchlist-disclaimer">
            {" "}
            *Watchlist is limited to 75 assets.
          </span>
        </div>

          <div className="table-buttons col-md-7 col-sm-12 col-xs-12">
            <CustomTooltip title={getTooltipText("koshaWatchlists")}>
              <button
                  onClick={this.getKoshaWatchlist}
                  disabled={loading || fileLoading}
                  className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                >
                Kosha Watchlists
              </button>
            </CustomTooltip>  
            {/* <div className ="tooltipx">
              <button
                onClick={this.getKoshaWatchlist}
                disabled={loading || fileLoading}
                className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
              >
                Kosha Watchlists
              </button>
              <div className="tooltiptext-div">{getTooltipText("koshaWatchlists")}</div>
            </div> */}
            <CustomTooltip title={getTooltipText("myWatchlists")}>
              <button
                  onClick={this.getSavedWatchlists}
                  disabled={loading || fileLoading}
                  className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                >
                My Watchlists
              </button>
            </CustomTooltip>
            {/* <div className ="tooltipx">
              <button
                onClick={this.getSavedWatchlists}
                disabled={loading || fileLoading}
                className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
              >
                My Watchlists
              </button>
              <div className="tooltiptext-div">{getTooltipText("myWatchlists")}</div>
            </div> */}

            {assets[0] && assets[0].symbol !== "" && (
              <>
                <CustomTooltip title={getTooltipText("clearAllEntries")}>
                  <button
                    onClick={this.confirmClear}
                    disabled={loading || fileLoading}
                    className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                  >
                    Clear All Entries
                  </button>
                </CustomTooltip>
               {/* <div className ="tooltipx">
                <button
                  onClick={this.confirmClear}
                  disabled={loading || fileLoading}
                  className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                >
                  Clear All Entries
                </button>
                <div className="tooltiptext-div">{getTooltipText("clearAllEntries")}</div>
                </div> */}
              </>
            )}
            <CustomTooltip title={getTooltipText("clearAllEntries")}>
              <button
                onClick={this.addAsset}
                disabled={loading || fileLoading || (assets.length === 75) }
                className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
              >
                Add Row
              </button>
            </CustomTooltip>
            {/* <div className ="tooltipx">
            <button
              onClick={this.addAsset}
              disabled={loading || fileLoading || (assets.length === 75) }
              className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
            >
              Add Row
            </button>
            <div className="tooltiptext-div">{getTooltipText("addRow")}</div>
            </div> */}
          </div>
          {filename !== null && filename !== "" && (
            <div className="col-md-12 col-sm-12 col-xs-12 file-name-text">
              {loading ? 
                (<div>Loading...</div>):(
                  <>
                  {watchlistnotes && <CustomTooltip title={watchlistnotes} variant="long">
                    {/* <div className={watchlistnotes && "tooltipx"}> */}
                      {/* Set Filename on screen */}
                      {/* filename disappears when going between routes */}
                      <div style={{width: "fit-content", cursor: "pointer"}}>
                        {filename} 
                        {this.state.watchlistChanged && <span>*</span>}
                        {(this.props.portfolio?.useremail === "system@vistalytics.com") && <span>  ( Kosha Watchlist )</span>}
                        {(this.props.portfolio?.useremail !== "system@vistalytics.com" && this.props.portfolio?.isSubscribed === false) && <span>  ( My Watchlist )</span>}
                        {(this.props.portfolio?.useremail !== "system@vistalytics.com" && this.props.portfolio?.isSubscribed === true) && <span>  ( My Watchlist - Subscribed )</span>}
                        <i className="fa fa-info-circle"></i>
                      </div>
                      {/* <div className="tooltiptext-div">{watchlistnotes}</div> */}
                    {/* </div> */}
                  </CustomTooltip>}
                  {!watchlistnotes && 
                    <div style={{width: "fit-content"}}>
                      {filename} 
                      {this.state.watchlistChanged && <span>*</span>}
                      {(this.props.portfolio?.useremail === "system@vistalytics.com") && <span>  ( Kosha Watchlist )</span>}
                      {(this.props.portfolio?.useremail !== "system@vistalytics.com" && this.props.portfolio?.isSubscribed === false) && <span>  ( My Watchlist )</span>}
                      {(this.props.portfolio?.useremail !== "system@vistalytics.com" && this.props.portfolio?.isSubscribed === true) && <span>  ( My Watchlist - Subscribed )</span>}
                    </div>}
                      {/* {watchlistnotes && 
                        <CustomTooltip title={getTooltipText(watchlistnotes)}>
                          <i className="fa fa-info-circle"></i>
                        </CustomTooltip>
                        // <div className="tooltipx">
                        //   <i className="fa fa-info-circle"></i>
                        //   <div className="tooltiptext-div">{watchlistnotes}</div>
                        // </div>
                      } */}
                  </>
                )}
              </div>
          )}
        </div>

        <div className="row kosha-watchlist-row hide" id="kosha-watchlist">
          {watchlistTypes.length !== 0 && this.showKoshaWatchlists()}
        </div>

        {!fileLoading ? (
          <>
            <PortfolioTable
              data={assets}
              total={total}
              spy={spy}
              history={this.props.history}
              updateMyData={this.updateMyData}
              deleteRow={this.deleteRow}
              symbols={tableSymbols}
              onSearchChange={this.onSearchChange}
              onItemSelect={this.onItemSelect}
              handleKeyPress={this.handleKeyPress}
              disabled={loading}
            />
            {((assets.length !== 0 && valid) || transactionWatchlist) && (
              <>
                {!loading && analyzed && (
                  <div className="m-l-10 analyze-portfolio-btn">
                    <div className="col-md-12 col-sm-12 col-xs-12 file-name-text">
                      <div className="tooltipx analyze-portfolio-btn">
                        Timing Type: {portfolio?.timingtype || "LONG_ONLY"}
                      <div className="tooltiptext-div">{getTooltipText("timingType")}</div>
                      </div>
                      <br />
                      <div className="tooltipx analyze-portfolio-btn">
                        Max Free Money Redistribution: {portfolio?.maxallocation || 0}%
                      <div className="tooltiptext-div">{getTooltipText("maxFreeMoneyRedistribution")}</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-3 portfolio-sentiment-div">
                  {performanceData &&
                    timingData &&
                    !fileLoading &&
                    showChartData &&
                    !loading &&
                    this.getSentimentValue(assets)}
                </div>

                <div
                  className={`${
                    analyzed && showChartData
                      ? "col-md-9 m-t-10 no-padding"
                      : analyzed && !showChartData
                      ? "col-md-12 m-t-5 no-padding"
                      : "col-md-9 m-t-20 no-padding"
                  }`}
                >
                    <>
                      <div className="analyze-portfolio-btn">
                        <CustomTooltip title={getTooltipText("saveWatchlist")}>
                          <button
                              onClick={this.onSaveClick}
                              disabled={loading || fileLoading || !this.isValid() || this.props.portfolio?.isSubscribed === true}
                              className="btn btn-outline waves-effect waves-light  m-l-10"
                            >
                            Save Watchlist
                          </button>
                        </CustomTooltip>
                      </div>
                      {/* <div className = "tooltipx analyze-portfolio-btn">
                        <button
                          onClick={this.onSaveClick}
                          disabled={loading || fileLoading || !this.isValid() || this.props.portfolio?.isSubscribed === true}
                          className="btn btn-outline waves-effect waves-light  m-l-10"
                        >
                          Save Watchlist
                        </button>
                        <div className="tooltiptext-div">{getTooltipText("saveWatchlist")}</div>
                      </div> */}
                      {/* <div className = "tooltipx analyze-portfolio-btn">                     
                        <button
                          onClick={this.onOptimizeClick}
                          disabled={loading || fileLoading || !this.isValid() || this.props.portfolio?.isSubscribed === true}
                          className="btn btn-outline waves-effect waves-light  m-l-10"
                        >
                          Optimize
                        </button> 
                        <div className="tooltiptext-div">{getTooltipText("optimize")}</div>
                      </div> */}
                    </>

                  {performanceData &&
                    timingData && 
                    !fileLoading &&
                    showChartData &&
                    !loading && (
                      <>
                        <CustomTooltip title={getTooltipText("exportToCSV")} className="analyze-portfolio-btn">
                          <button
                              onClick={this.exportCsv}
                              disabled={loading || fileLoading}
                              className="btn btn-outline waves-effect waves-light analyze-portfolio-btn m-l-10"
                            >
                            Export to CSV
                          </button>
                        </CustomTooltip>
                        {/* <div className ="tooltipx analyze-portfolio-btn">
                          <button
                            onClick={this.exportCsv}
                            disabled={loading || fileLoading}
                            className="btn btn-outline waves-effect waves-light analyze-portfolio-btn m-l-10"
                          >
                            Export to CSV
                          </button>
                          <div className="tooltiptext-div">{getTooltipText("exportToCSV")}</div>
                        </div> */}

                        {/* <button
                          onClick={this.handover}
                          className="btn btn-outline waves-effect waves-light analyze-portfolio-btn m-l-10"
                        >
                          Handover to Alberto
                        </button> */}
                        <CustomTooltip title={getTooltipText("timingDifference")} className="analyze-portfolio-btn">
                          <button
                              onClick={this.getTimingDiff}
                              className="btn btn-outline waves-effect waves-light analyze-portfolio-btn m-l-10"
                            >
                              Timing Difference
                          </button>
                        </CustomTooltip>
                        {/* <div className ="tooltipx analyze-portfolio-btn">
                          <button
                            onClick={this.getTimingDiff}
                            className="btn btn-outline waves-effect waves-light analyze-portfolio-btn m-l-10"
                          >
                            Timing Difference
                          </button>
                          <div className="tooltiptext-div">{getTooltipText("timingDifference")}</div>
                        </div>  */}
                      </>
                    )}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="card-loader card-loader--tabs m-b-30"></div>
        )}
      </>
    );

    let assetChartView =
      assetChartData && assets && assets.length > 0 && familyChartData ? (
        <SynchChart
          key="asset"
          title={"Watchlist"}
          id="assetChart"
          data={assetChartData}
          smaRange={smaRange}
          showRangeModal={this.showRangeModal}
          showChartInModal={this.showChartInModal}
          showViewButton={true}
          familyChartData={familyChartData}
          poiData={poiData}
        />
      ) : loading ? (
        <div className="card-loader card-loader--tabs m-b-10 h-565"></div>
      ) : (
        ""
      );

    let familyChartView =
      familyChartData && assets && assets.length > 0 ? (
        <SynchChart
          key="family"
          title={this.state.compareSymbol}
          id="familyChart"
          data={familyChartData}
          smaRange={smaRange}
          showRangeModal={this.showRangeModal}
          showChartInModal={this.showFamilyChart}
          showViewButton={true}
          poiData={familyPoiData}
        />
      ) : loading ? (
        <div className="card-loader card-loader--tabs m-b-10 h-565"></div>
      ) : (
        ""
      );

    let dataView = (
      <>
        <div className="row">
          <div className="col-md-12">{tableView}</div>
        </div>
      </>
    );

    let chartView = assets && assets.length > 0 && showChartData && (
      <>
        <div className="row">
          <div className="col-md-6">{assetChartView}</div>

          <div className="col-md-6">{familyChartView}</div>
        </div>
      </>
    );

    let errorView = error ? (
      <ErrorModal
        show={error ? true : false}
        onClose={this.closeError}
        message={error}
        viewLog={this.viewLog}
        showViewLog={showViewLog}
        showTemplate={showTemplate}
        downloadTemplate={this.downloadTemplate}
      />
    ) : (
      ""
    );

    const analyzeText = !analyzed ? "Analyze " : 
      (this.state.watchlistChanged ? "Re-Analyze" : "Refresh");
    const boxSubtitle = assets && assets.length > 0 ? "" : "";

    return (
      <>
        {/* {!this.props.forbiddenError && (
          <Prompt
            message={(location) => {
              if (
                !(
                  location.pathname.includes("/watchlist") ||
                  location.pathname.includes("/chartView")
                ) &&
                this.state.assets.length !== 0 &&
                this.state.assets[0].symbol !== ""
              )
                return loading
                  ? "Analyze is still running. Are you sure you want to leave the page and lose any unsaved data?"
                  : "There are unsaved changes in this Watchlist. Do you want to leave now and lose any unsaved changes?";
            }}
          />
        )} */}
        <Header history={this.props.history} />
        {errorView}
        <div id="page-wrapper" className="m-l-0">
          <div className="container">
            <div className="row p-t-10 p-b-10 b-0"></div>
            {!symbolsLoading ? (
              <>
                <div className="white-box analyze-asset-white-box">
                  <div className="row portfolio-container">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <h4 className="page-title">Watchlists</h4>
                      <h3 className="box-subtitle m-b-5">{boxSubtitle}</h3>
                    </div>

                    <div className="col-md-5 col-sm-12 col-xs-12"></div>

                    <div
                      className="col-md-2 col-sm-6 col-xs-6"
                      id="autocomplete-input"
                    >
                      <h5 className="section-list-item">Benchmark</h5>
                      <AutocompleteField
                        wrapperStyle={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          width: "130px",
                        }}
                        placeholder="Benchmark Symbol"
                        onSearchChange={this.onCompareSearchChange}
                        onItemSelect={this.onCompareItemSelect}
                        symbols={symbols}
                        search={compareSymbol}
                        onKeyUp={this.handleKeyPress.bind(
                          this,
                          "compareSymbol"
                        )}
                        onBlur={this.onBlur}
                        onPaste={(e) => {
                          e.preventDefault();
                        }}
                        onDrop={(e) => {
                          e.preventDefault();
                        }}
                        disabled={loading}
                      />
                      <div className="symbol-data-div">
                        <span className="tooltiptext" id="error-benchmark-div">
                          Maximum length is 6 characters.
                        </span>
                      </div>
                    </div>

                    <div className="col-md-2 col-sm-6 col-xs-6">
                      <h5 className="section-list-item empty-item"> </h5>
                      <button
                        disabled={!this.isValid() || loading || fileLoading}
                        onClick={this.analyze}
                        className="btn btn-outline waves-effect waves-light m-b-5 m-t-10 analyze-portfolio-btn"
                      >
                        {analyzeText}
                        {loading && <i className="fa fa-spinner fa-spin"></i>}
                      </button>
                    </div>
                  </div>
                </div>

                {assets.length !== 0 && (
                  <div className="white-box watchlist-table-white-box">
                    <div className="row">
                      <div className="col-md-12">{dataView}</div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="card-loader card-loader--tabs m-b-30"></div>
            )}

            {chartView}

            {showChartData && performanceData && timingData && !loading ? (
              <div className="row">
                <div className="col-md-12" style={{ marginBottom: "30px" }}>
                  <ChartTabs
                    performanceData={performanceData}
                    holdings={holdings}
                    showViewButton={true}
                    showChartInModal={this.showChartInModal}
                    timingData={timingData}
                    showMultiSelect={true}
                    getSingleAsset={this.getSingleAsset}
                    singleAssetPerformance={singleAssetPerformance}
                    singleAssetTiming={singleAssetTiming}
                    singleLoading={singleLoading}
                    currentRoute="portfolio"
                    updateValues={this.updateValues.bind(this)}
                    updateCompareValues={this.updateCompareValues.bind(this)}
                    updateAllPerfValues={this.updateAllPerfValues.bind(this)}
                    updateAllTimingValues={this.updateAllTimingValues.bind(
                      this
                    )}
                    updateBenchmarkValues={this.updateBenchmarkValues.bind(
                      this
                    )}
                    familyChartData={familyChartData}
                    benchmark={compareSymbol}
                    compareportfolioPerformance={compareportfolioPerformance}
                    comparePortfolioTiming={comparePortfolioTiming}
                    selectedAssets={assets}
                    apiModalShow={apiModalShow}
                    modalOpenCheck={modalOpenCheck}
                    showRangeModal={showRangeModal}
                    showConfirmModal={showConfirmModal}
                    showBenchmarkChangeModal={showBenchmarkChangeModal}
                    showReloadModal={showReloadModal}
                    showDeleteModel={showDeleteModel}
                    showExtraAsset={showExtraAsset}
                    showTimingDiff={showTimingDiff}
                    showSaveWatchlistModal={showSaveWatchlistModal}
                    showSavedWatchlistModal={showSavedWatchlistModal}
                    showOptimizeModal={showOptimizeModal}
                    showTransactionsModal={showTransactionsModal}
                    onTransactionsClick={this.onTransactionsClick.bind(this)}
                    timingDiffData={showTimingDiff ? timingDiffData : null}
                    aboutData={this.props.about?.id}
                    aboutCheck={this.state.aboutCheck}
                  />
                </div>
              </div>
            ) : (
              loading && (
                <div className="card-loader card-loader--tabs m-b-30"></div>
              )
            )}
          </div>

          <Footer onClick={this.getApiInfo} location="watchlist" />

          <ApiModal
            show={apiModalShow}
            apiDetails={apiDetails}
            onClose={this.onApiModalClose}
            apiResponse={apiResponse}
            isError={isError}
            screen="portfolio"
          />

          <RangeSelectorModal
            show={showRangeModal}
            period={smaRange}
            onSave={this.onRangeChange}
          />

          <ConfirmModal
            show={showConfirmModal}
            confirm={this.clearTable}
            cancel={() => {
              this.setState({ showConfirmModal: false, modalOpenCheck: false });
            }}
          />
          <ConfirmModal
            show={this.state.showInvalidSymbolModal}
            unsupportedAssets={this.state.invalidSymbols}
            confirm={() => {
              this.setState({
                showInvalidSymbolModal: false,
                invalidSymbols: null,
                modalOpenCheck: false,
              });
            }}
            cancel={() => {
              this.setState({
                showInvalidSymbolModal: false,
                showConfirmModal: false,
                modalOpenCheck: false,
              });
            }}
          />
          <ConfirmModal
            show={showBenchmarkChangeModal}
            confirm={this.analyze}
            reloadModal={false}
            benchmarkChange={true}
            cancel={() => {
              this.setState({
                showBenchmarkChangeModal: false,
                compareSymbol: oldCompareSymbol,
                modalOpenCheck: false,
              });
            }}
          />

          <ConfirmModal
            show={showReloadModal}
            reloadModal={true}
            confirm={this.clearTable}
            cancel={() => {
              this.setState({ showReloadModal: false, modalOpenCheck: false });
            }}
          />

          <ConfirmModal
            show={showSwitchModal.show}
            switchModal={true}
            switchType={showSwitchModal.type}
            confirm={() => this.onTypeClick(showSwitchModal.type)}
            cancel={() => {
              this.setState({
                showSwitchModal: { ...showSwitchModal, show: false },
                modalOpenCheck: false,
              });
            }}
          />
          <ConfirmModal
            show={managedWatchlistAlert}
            managedWatchlistAlert={managedWatchlistAlert}
            confirm={() => {
              this.setState({
                managedWatchlistAlert: false,
                modalOpenCheck: false,
              });
            }}
            cancel={() => {
              this.setState({
                managedWatchlistAlert: false,
                modalOpenCheck: false,
              });
            }}
          />
          <ConfirmModal
            show={showDeleteModel}
            confirm={this.onDeleteClick}
            showDeleteModel={true}
            deleteWatchlist={deleteWatchlist && deleteWatchlist.watchlistname}
            cancel={() => {
              this.setState({
                showDeleteModel: false,
                showSavedWatchlistModal: true,
                modalOpenCheck: !this.state.modalOpenCheck,
              });
            }}
          />

          <ExtraAssetPopup
            show={showExtraAsset}
            data={extraAssets}
            onClose={this.onApiModalClose}
          />

          <TimingDiffModal
            show={showTimingDiff}
            onClose={this.onApiModalClose}
            data={timingDiffData}
            assetData={assets}
            onDownloadTrading={this.onDownloadTrading}
            bucketLoading={bucketLoading}
          />

          {showSaveWatchlistModal && <SaveWatchlistModal
            show={showSaveWatchlistModal}
            onClose={this.onApiModalClose}
            onSave={this.saveWatchlist.bind(this)}
            buttonName={saveButtonText}
            disabled={!this.isValid()}
            exsistingWatchlistSave={this.exsistingWatchlistSave}
            watchlistname={this.state.watchlistname}
            // getSavedWatchlists = {this.getSavedWatchlistsWithoutModal.bind(this)}
            savedWatchlist={JSON.parse(JSON.stringify(savedWatchlist))}
            watchlistnotes={watchlistnotes}
            koshaWatchlist={koshaWatchlist}
            transactionWatchlist={transactionWatchlist}
          />}

          {showSaveOrSubscribeModal && <SaveOrSubscribeModal
            show={showSaveOrSubscribeModal}
            onClose={() => {
              this.setState({
                showSaveOrSubscribeModal: false
              })
            }}
            onSave={() => {
              // this.onApiModalClose()
              this.setState({
                showSaveOrSubscribeModal: false,
                showSaveWatchlistModal: true,
                modalOpenCheck: !this.state.modalOpenCheck,
              });
            }}
            onSubscribe={this.subscribeToWatchlist.bind(this)}
            savedWatchlist={JSON.parse(JSON.stringify(savedWatchlist))}
            watchlistname={this.state.watchlistname}
            watchlistnotes={watchlistnotes}
            koshaWatchlist={koshaWatchlist}
            transactionWatchlist={transactionWatchlist}
          />}

          {showSavedWatchlistModal && <MyWatchlistModal
            show={showSavedWatchlistModal}
            onClose={this.onApiModalClose}
            onSelect={this.showSelectedWatchlist.bind(this)}
            manageWatchlist={this.manageWatchlist.bind(this)}
            assets={JSON.parse(JSON.stringify(savedWatchlist))}
            watchlistModalTitle={watchlistModalTitle}
            watchlistsLoaded={watchlistsLoaded}
            deleteWatchlist={this.showDeleteConfirm.bind(this)}
          />}

          <OptimizeModal
            show={showOptimizeModal}
            onClose={() => {
              this.setState({
                showOptimizeModal: false,
                loading: false,
                modalOpenCheck: !this.state.modalOpenCheck,
              });
            }}
            onSelect={this.onOptimizeSelect.bind(this)}
          />

          {showTransactionsModal && <TransactionsModal
            show={showTransactionsModal}
            bucketLoading={bucketLoading}
            holdings={this.props.holdings}
            benchmark={this.state.compareSymbol}
            savedWatchlist={JSON.parse(JSON.stringify(savedWatchlist))}
            transactionWatchlist={transactionWatchlist}
            cancel={() => {
              this.setState({
                showTransactionsModal: false,
                bucketLoading: false,
              });
            }}
            onSubmitForBucket={this.onSubmitBucketDetails.bind(this)}
          />}

          <ForbiddenErrorModal history={this.props.history} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  portfolioAssetChartData: state.PortfolioReducer.portfolioAssetChartData,
  portfolioFamilyChartData: state.PortfolioReducer.portfolioFamilyChartData,
  assetData: state.PortfolioReducer.assetData,
  filename: state.PortfolioReducer.filename,
  familyData: state.PortfolioReducer.familyData,
  portfolioRequest: state.PortfolioReducer.portfolioRequest,
  portfolio: state.PortfolioReducer.portfolio,
  portfolioAssetChartRequest: state.PortfolioReducer.portfolioAssetChartRequest,
  portfolioFamilyChartRequest:
    state.PortfolioReducer.portfolioFamilyChartRequest,
  portfolioMove: state.PortfolioReducer.portfolioMove,
  averageData: state.PortfolioReducer.averageData,
  familyDataRequest: state.PortfolioReducer.familyDataRequest,
  supportedSymbols: state.AssetReducer.supportedSymbols,
  supportedSymbolsRequest: state.AssetReducer.supportedSymbolsRequest,
  timingData: state.PortfolioReducer.portfolioTiming,
  performanceData: state.PortfolioReducer.portfolioPerformance,
  timingRequest: state.PortfolioReducer.timingRequest,
  performanceRequest: state.PortfolioReducer.performanceRequest,
  holdings: state.PortfolioReducer.holdings,
  loginDetails: state.AboutReducer.loginDetails,
  singleAssetPerformance: state.PortfolioReducer.singleAssetPerformance,
  singleAssetTiming: state.PortfolioReducer.singleAssetTiming,
  about: state.AboutReducer.about,
  timingDiffData: state.PortfolioReducer.timingDiffData,
  bucketDetailsData: state.PortfolioReducer.bucketDetailsData,
  savedWatchlists: state.PortfolioReducer.savedWatchlists,
  comparePortfolioTiming: state.PortfolioReducer.comparePortfolioTiming,
  compareportfolioPerformance:
    state.PortfolioReducer.compareportfolioPerformance,
  forbiddenError: state.AboutReducer.forbiddenError,
  koshaWatchlists: state.PortfolioReducer.koshaWatchlists
});

export default connect(mapStateToProps, {
  fetchPortfolio,
  fetchNewPortfolio,
  clearPortfolio,
  fetchFamilyData,
  updateValues,
  updateCompareValues,
  getKoshaWatchlists,
  updateAssets,
  resetPortfolioChart,
  fetchFamilyChartData,
  updatePortfolioProps,
  getSupportedSymbols,
  getWatchlistData,
  fetchPerformanceData,
  fetchTimingData,
  clearSingleData,
  getTimingDiff,
  getBucketDetails,
  saveWatchlist,
  getSavedWatchlists,
  updateBenchmarkValues,
  updateAllPerfValues,
  updateAllTimingValues,
  optimizeWatchlist,
  manageWatchlist,
  getTransactionSimulation,
  getTransactionSimulationSummary,
  subscribeToWatchlist
})(Portfolio);
