import React, { Component } from 'react'

import {
    Box,
    Button,
    Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';


class Banner extends Component {
    // For now we only use this for ETL banners, everything will be standard color-wise
    // Change later to be more reusable if needed

    constructor(props){
        super(props)
        this.state = {
            text: '',
            bgColor: '#0288d1'
        }
    }

    componentDidMount(){
        if(this.props.variant === "refresh"){
            this.setState({
                bgColor: '#0288d1'
            })
        }else if(this.props.variant === "reload"){
            // const RefreshButton = <button>Click Here</button>
            this.setState({
                bgColor: '#0288d1'
            })
        }
    }

    render() {
        return (
            <Box sx={{
                backgroundColor: this.state.bgColor, width: '100%', padding: '1.5rem 0 1.5rem 0',
                display: 'flex', justifyContent: 'center', alignItems: 'center', position:'fixed', marginTop: '55px', zIndex:'100'}}>
                <InfoOutlinedIcon sx={{marginRight:'5px', color: '#fff', fontSize:'1em'}} />
                {this.props.variant === "refresh" &&
                    <Typography sx={{ color: "#fff", fontSize:'1em'}}>A Server Refresh is in progress. Kosha will be unavailable while this banner is visible. Please check back later to continue using Kosha.</Typography>}
                {this.props.variant === "reload" &&
                    <>
                        <Typography sx={{ color: "#fff", fontSize:'1em'}}>The Kosha Servers have restarted. Please Reload the page ensure up to date data.</Typography>
                        <Button onClick={() => window.location.reload(true)} style={{border: 'solid 1px #fff', borderRadius: '3px', margin: '0 1rem', color: '#fff', fontSize: '0.7em', background: this.state.bgColor}}>Reload Page</Button> 
                    </>}
                <Typography sx={{ color: "#fff", fontSize:'1em'}}>{this.state.text}</Typography>
            </Box>
        )
    }
}

export default Banner
