import { ACTIONS } from '../actions/ActionTypes';

const initialState = {
    about: null,
    aboutChange: null,
    fetchRequest: null,
    loginDetails: null,
    training: null,
    checkCognito: null,
    forbiddenError:null,
    fcmRespose:null,
    versionUpdate:false,
    tooltipsJson:null,
    isOperator:false,
    serverRestart: true
};

export function AboutReducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.ABOUT:
            // add check for (this.state.about?.status == 501 && action.payload.result.status === 0) to show refresh banner
            const shouldServerRestart = (state.about?.status === 511 && action.payload.result.status === 0)
            
            console.log(shouldServerRestart, state.about, action.payload.result)
            if(shouldServerRestart){
                return {
                    ...state,
                    about: action.payload.result,
                    fetchRequest: action.payload.fetchRequest,
                    serverRestart: shouldServerRestart
                };
            }else{
                return {
                    ...state,
                    about: action.payload.result,
                    fetchRequest: action.payload.fetchRequest,
                }
            }
        case ACTIONS.CREATE_KEY:
            return {
                ...state,
                loginDetails: action.payload.result
            }

        case ACTIONS.VALIDATE_KEY: {
            localStorage.setItem("loginDetails", JSON.stringify(action.payload.result));
            return {
                ...state,
                loginDetails: action.payload.result
            }
        }
        case ACTIONS.CLEAR_LOGIN:
            return initialState;

        case ACTIONS.LOGIN:
            return {
                ...state,
                about: null,
                loginDetails: action.payload
            }

        case ACTIONS.GET_LOGS:
            return { ...state };

        case ACTIONS.GET_TRAINING_VIDEO:
            return {
                ...state,
                training: action.payload.result
            }

        case ACTIONS.COGNITO_CHECK:
            let checkCognito = action.payload.result.result.status;
            let details = checkCognito === 0 ?
                { username: action.payload.result.username, password: action.payload.result.password } : null

            return {
                ...state,
                loginDetails: details,
                checkCognito: checkCognito
            }
        case ACTIONS.FORBIDDEN_ERROR:{
            if(action.payload!==null){
                localStorage.setItem("forbidden",true);
            }
            return {
                ...state,
                forbiddenError:action.payload
            }
        }
        case ACTIONS.VERSION_UPDATE:{
            return {
                ...state,
                versionUpdate:action.payload
            }
        }
        case ACTIONS.TOOLTIPS_JSON:{
            sessionStorage.setItem('tooltips',JSON.stringify(action.payload.result.tooltips.tooltips));
            return {
                ...state,
                tooltipsJson:true,
            }
        }
        case ACTIONS.FIREBASE_TOKEN_SEND:{
            return {
                ...state,
                fcmRespose:action.payload,
            }
        }
        case ACTIONS.SET_USER_GROUP:{
            console.log(action.payload)
            if(action.payload === 'operator'){
                return {
                    ...state,
                    isOperator: true
                }
            } else {
                return {...state}
            }
        }
        default:
            return state;
    }
}