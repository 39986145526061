import React, { Component } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const CustomLongTooltip = styled(({ className, maxWidth=200, ...props }) => (
  <Tooltip {...props} 
    classes={{ popper: className }} 
    placement="bottom-start"/>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize:"1.2rem",
    background:"#96a2b4",
    color: "#313345",
    fontFamily:"NunitoSans-Regular",
    padding:"1rem"
  },
});

export const CustomDefaultTooltip = styled(({ className, maxWidth=200, ...props }) => (
  <Tooltip {...props} 
    classes={{ popper: className }} 
    placement="bottom"
    arrow/>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 170,
    fontSize:"1.2rem",
    // background:"rgba(37, 39, 52, 0.9)"
    background:"#96a2b4",
    // color: "#000",
    color:"#313345",
    textAlign: "center",
    fontFamily:"NunitoSans-Regular",
    padding:"1rem"
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#96a2b4'
  }
});


export default class CustomTooltip extends Component {

  // componentDidMount(){
  //   console.log("tooltip props -> ", this.props)
  // }

  render() {
    return (
      <>
        {(this.props.variant==="long") &&
          <CustomLongTooltip title={this.props.title} placement="bottom-start">
            {this.props.children}
          </CustomLongTooltip>}
        {(this.props.variant!=="long") &&
          <CustomDefaultTooltip title={this.props.title} placement="bottom-start">
            {this.props.children}
          </CustomDefaultTooltip>}
      </>
    )
  }
}