import React, { Component } from 'react';

class OptimizeModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
        }
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            show: false
        });
    }

    onSelect = (option) => {
        this.props.onSelect(option);
    }

    render() {
        const { show } = this.props;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : "";

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm password-modal-dialog">
                        <div className="modal-content" style={{ height: 'auto', minHeight: 'auto' }}>
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title saved-modal-title">Optimize Watchlist
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-20 p-b-30 p-r-20">
                                <div className="saved-watchlist-div">
                                    <button
                                        onClick={this.onSelect.bind(this, 'backward')}
                                        className="btn confirm-modal-btn">
                                        Sharpe Backward
                                    </button>

                                    <button
                                        onClick={this.onSelect.bind(this, 'forward')}
                                        className="btn confirm-modal-btn">
                                        Sharpe Forward
                                    </button>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default OptimizeModal
