import React from "react";
import {round}from '../../utils/helperFunctions'
export default function AnalyzeDataCard({
  title,
  assetValue,
  familyValue,
  vdiffValue,
  assetSymbol,
  familySymbol,
  benchmarkSymbol,
  benchmarkValue,
  currentRoute,
  displayChartData 
}) {
  return (
    <div className="col-md-3 col-sm-3 col-xs-12 data-card-div">
      <div className={currentRoute !== "asset" ? "tooltipx" : ""}>
      {/* <div className={"tooltipx"}> */}
        <div className="white-box py-5">
          <div className="row">
            {title!=="Market Risk" && (
            <div className="col-md-12 col-sm-12 col-xs-12 box-title-div">
              <h3 className="box-data-title">{title} {!displayChartData && <span>*</span>}</h3>
            </div>
            )}
            {title === "Market Risk" ? (
              <>
              {/* <div className="col-md-1 col-sm-1 col-xs-1 no-padding">
                <div className="box-data-text box-divider"></div>
              </div> */}
              <div  className="col-md-12 col-sm-12 col-xs-12 box-title-div" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div className="box-data-title tooltipx" style={{width:'50%', textAlign:'center'}}>{title}
                  <span className="tooltiptext-div">
                  <div>
                    <h5>{title}</h5>
                    <p>{` Kosha Market Risk ranges between 1 (low risk) and 9 (high risk).`}</p>
                  </div>
                  </span>
                </div>
                <div className="box-data-title tooltipx" style={{width:'50%', textAlign:'center',paddingLeft:'16px'}}>
                  {"VDIFF"}
                  <span className="tooltiptext-div">
                    <div>
                      <h5>{"VDIFF"}</h5>
                      <p>{` VDIFF > 0 indicates overpriced options and VDIFF < 0 indicates underpriced options. VDIFF close to zero indicates fairly priced options.`}</p>
                    </div>
                  </span>
                </div> 
              </div>
              <div  style={{display:'flex',flexDirection:'row',marginBottom:'1px',paddingTop:'4px', width:'100%',justifyContent:'center'}}>
                <div className="col-md-4 col-sm-11 col-xs-12 family-data-div no-padding" style={{marginBottom:'14px',width:'50%'}}>
                  <h3 className="box-data-text box-text box-center-text">
                  {currentRoute==="asset"?familyValue:title ==="Sharpe Ratio"?round(assetValue,2).toFixed(2):(title === "Days to Recover"?round(assetValue,1): round(assetValue,1).toFixed(1))}
                  </h3>
                </div>

                <div className="col-md-1 col-sm-1 col-xs-1 no-padding">
                  <div className="box-data-text box-divider"></div>
                </div>
                <div className="col-md-3 col-sm-11 col-xs-12 family-data-div no-padding" style={{marginBottom:'14px',width:'50%'}} >
                  <h3 className="box-data-text box-text">
                    {title ==="Sharpe Ratio"?round(vdiffValue,2).toFixed(2):(title === "Days to Recover"?round(vdiffValue,1).toString(): round(vdiffValue,1).toFixed(1).toString())}
                  </h3>
                </div>
              </div>
            </>
              // <div className="col-md-12 col-sm-12 col-xs-12 analyzed-data-box">
              //   {familySymbol && (
              //     <>
              //      <h3 className="box-data-title">{title}</h3>
              //       <div className="col-md-12 col-sm-12 col-xs-12 family-data-div no-padding">
              //         <h3 className="box-data-text box-text box-center-text">
              //           {currentRoute==="asset"?assetValue:title ==="Sharpe Ratio"?round(assetValue,2):round(assetValue,1)}
              //         </h3>

              //       {/* <div className="col-md-1 col-sm-1 col-xs-1 no-padding"> */}
                      
              //       {/* </div> */}
              //         {/* <h3 className="box-data-text box-symbol-text box-center-text">
              //           SPY
              //         </h3> */}
              //       </div>
              //     </>
              //   )}
              // </div>
            ):(
            <>
            {/* {title==="Market Risk" && vdiffValue && (
              <div className="col-md-12 col-sm-12 col-xs-12 analyzed-data-box" style={{marginBottom:'16px'}}>
                  <>
                    <div className="col-md-12 col-sm-12 col-xs-12 h-100 family-data-div no-padding box-center-text">
                      <h3 className="box-data-text box-text box-center-text">
                        {currentRoute==="asset"?assetValue:title ==="Sharpe Ratio"?round(vdiffValue,2):round(vdiffValue,1)}
                      </h3>
                    </div>
                  </>
              </div>
            )} */}
              <div className="col-md-12 col-sm-12 col-xs-12 analyzed-data-box">
                <div
                  className={
                    familySymbol && benchmarkSymbol
                      ? "col-md-3 col-sm-11 col-xs-12 asset-data-div no-padding"
                      : benchmarkSymbol || familySymbol
                      ? "col-md-5 col-sm-5 col-xs-5 asset-data-div no-padding"
                      : "col-md-12 col-sm-12 col-xs-12 asset-data-div no-padding"
                  }
                >
                  <h3
                    style={
                      !familySymbol && !benchmarkSymbol
                        ? { textAlign: "center" }
                        : null
                    }
                    className="box-data-text box-text"
                  >
                    {currentRoute === "asset" ? assetValue: title ==="Sharpe Ratio" ? round(assetValue,2).toFixed(2):(title === "Days to Recover"?round(assetValue,1).toString(): round(assetValue,1).toFixed(1).toString())}
                  </h3>
                  <h3 className="box-data-text box-symbol-text">
                    {assetSymbol}
                  </h3>
                </div>

                {familySymbol && benchmarkSymbol && (
                  <>
                    <div className="col-md-1 col-sm-1 col-xs-1 no-padding">
                      <div className="box-data-text box-divider"></div>
                    </div>

                    <div className="col-md-4 col-sm-11 col-xs-12 family-data-div no-padding">
                      <h3 className="box-data-text box-text box-center-text">
                      {currentRoute==="asset"?familyValue:title ==="Sharpe Ratio"?round(familyValue,2).toFixed(2):(title === "Days to Recover"?round(familyValue,1).toString(): round(familyValue,1).toFixed(1).toString())}
                      </h3>
                      <h3 className="box-data-text box-symbol-text box-center-text">
                        {familySymbol}
                      </h3>
                    </div>

                    <div className="col-md-1 col-sm-1 col-xs-1 no-padding">
                      <div className="box-data-text box-divider"></div>
                    </div>

                    <div className="col-md-3 col-sm-11 col-xs-12 family-data-div no-padding">
                      <h3 className="box-data-text box-text">
                        {title ==="Sharpe Ratio"?round(benchmarkValue,2).toFixed(2):(title === "Days to Recover"?round(benchmarkValue,1).toString(): round(benchmarkValue,1).toFixed(1).toString())}
                      </h3>
                      <h3 className="box-data-text box-symbol-text">
                        {benchmarkSymbol}
                      </h3>
                    </div>
                  </>
                )}
                {benchmarkSymbol && !familySymbol && (
                  <>
                    <div className="col-md-2 col-sm-2 col-xs-1 no-padding">
                      <div className="box-data-text box-divider"></div>
                    </div>

                    <div className="col-md-5 col-sm-5 col-xs-5 family-data-div no-padding">
                      <h3 className="box-data-text box-text">
                        {title ==="Sharpe Ratio"?round(benchmarkValue,2).toFixed(2):(title === "Days to Recover"?round(benchmarkValue,1): round(benchmarkValue,1).toFixed(1))}
                      </h3>
                      <h3 className="box-data-text box-symbol-text">
                        {benchmarkSymbol}
                      </h3>
                    </div>
                  </>
                )}
                {familySymbol && !benchmarkSymbol && (
                  <>
                    <div className="col-md-2 col-sm-2 col-xs-1 no-padding">
                      <div className="box-data-text box-divider"></div>
                    </div>

                    <div className="col-md-5 col-sm-5 col-xs-5 family-data-div no-padding">
                      <h3 className="box-data-text box-text"> 
                      {currentRoute==="asset"?familyValue:title ==="Sharpe Ratio"?round(familyValue,2).toFixed(2):(title === "Days to Recover"?round(familyValue,1): round(familyValue,1).toFixed(1))}
                      </h3>
                      <h3 className="box-data-text box-symbol-text">
                        {familySymbol}
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </>
            )}
          </div>
        </div>
        <span className="tooltiptext-div">
          {title === "Days to Recover" ? (
            <div>
              <h5>{title}</h5>
              {/* <p>{` Find the largest price drop in the selected period. Days to recover is how many days it takes for the price to return to the level it was just prior to this drop.`}</p> */}
              <p>The number of days spent reachieving a previous peak.</p>
            </div>
          ) : title === "Drawdown %" ? (
            <div>
              <h5>{title}</h5>
              <p>The largest price drop in the selected period.</p>
            </div>
          ) : title === "Return %" ? (
            <div>
              <h5>{title}</h5>
              <p>
                The total cumulative gain/loss for the selected period.
              </p>
            </div>
          ) : title === "Win Rate %" ? (
            <div>
              <h5>{title}</h5>
              <p>
                The percentage of trading days which resulted in a positive return.
              </p>
            </div>
          ): title === "Sharpe Ratio" ? (
            <div>
              <h5>{title}</h5>
              <p>
                {/* This is the total return for the selected period divided by the
                standard deviation of the daily returns. */}
                The average daily return for the selected period divided 
                by the standard deviation of the daily returns.
              </p>
            </div>
          ) : null}
        </span>
      </div>
    </div>
  );
}
