import React, { Component } from 'react'
import Header from '../../layout/Header'
import Footer from '../../layout/Footer'
import ReactJson from 'react-json-view'
import { connect } from 'react-redux';

import { getLogs } from '../../actions/AboutAction';

class Logs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: ''
        }
    }

    componentDidMount() {
        this.props.getLogs(
            () => {
                let page = this.props.location.search.split('=');
                this.setState({
                    page: page[1]
                })
            },
            () => {
                console.log('fail');
            }
        );
    }

    getApiDetails() {
        let apiDetails = [];
        let apiResponse = [];
        let { page } = this.state;

        let { assetRequest, assetChartRequest, familyRequest, familyChartRequest,
            data, assetChartData, familyData, familyChartData, portfolioRequest, portfolioFamilyDataRequest,
            portfolioFamilyChartRequest, portfolio, portfolioFamilyData, portfolioFamilyChartData } = this.props;

        if (page === 'home') {
            apiDetails = this.props.marketRequest;
            apiResponse = this.props.marketData;
        }
        else if (page === 'asset') {
            apiDetails.push(
                assetRequest,
                assetChartRequest,
                familyRequest,
                familyChartRequest
            )
            apiResponse.push(
                data,
                assetChartData,
                familyData,
                familyChartData
            )
        }
        else if (page === 'watchlist') {
            apiDetails.push(
                portfolioRequest,
                portfolioFamilyDataRequest,
                portfolioFamilyChartRequest
            );
            apiResponse.push(
                portfolio,
                portfolioFamilyData,
                portfolioFamilyChartData
            );
        }

        if (Array.isArray(apiDetails)) {
            return apiDetails.map((detail, index) => {
                return (
                    <>
                        <div className="api-details-body">
                            <div className="api-details-header">Request {index + 1}</div>
                            <ReactJson
                                src={detail}
                                theme="harmonic"
                                collapsed={true}
                            />
                        </div>
                        <div className="api-details-body">
                            <div className="api-details-header">Response {index + 1}</div>
                            <ReactJson
                                src={apiResponse[index] || this.props.errorJson}
                                theme="harmonic"
                                collapsed={true}
                            />
                        </div>
                    </>
                )
            })
        }
        else {
            return (
                <>
                    <div className="api-details-body">
                        <div className="api-details-header">Request 1</div>
                        <ReactJson
                            src={apiDetails}
                            theme="harmonic"
                            collapsed={true}
                        />
                    </div>
                    <div className="api-details-body">
                        <div className="api-details-header">Response 1</div>
                        <ReactJson
                            src={this.props.errorJson || apiResponse}
                            theme="harmonic"
                            collapsed={true}
                        />
                    </div>
                </>
            )
        }
    }

    render() {
        const { history } = this.props;

        return (
            <>
                <Header history={history} />
                <div id="page-wrapper" className="m-l-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 api-details-row">
                                {this.getApiDetails()}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    data: state.AssetReducer.asset,
    assetRequest: state.AssetReducer.assetRequest,
    assetChartData: state.AssetReducer.assetChartData,
    assetChartRequest: state.AssetReducer.assetChartRequest,
    familyChartRequest: state.AssetReducer.familyChartRequest,
    familyChartData: state.AssetReducer.familyChartData,
    familyData: state.AssetReducer.familyData,
    familyRequest: state.AssetReducer.familyRequest,
    marketData: state.MarketReducer.marketData,
    marketRequest: state.MarketReducer.fetchRequest,
    portfolioRequest: state.PortfolioReducer.portfolioRequest,
    portfolio: state.PortfolioReducer.portfolio,
    portfolioFamilyData: state.PortfolioReducer.familyData,
    portfolioFamilyDataRequest: state.PortfolioReducer.familyDataRequest,
    portfolioFamilyChartData: state.PortfolioReducer.portfolioFamilyChartData,
    portfolioFamilyChartRequest: state.PortfolioReducer.portfolioFamilyChartRequest,
})

export default connect(mapStateToProps, { getLogs })(Logs)
