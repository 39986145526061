import React, { Component } from 'react';

class ExtraAssetPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
        }
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            show: false
        });
    }

    getAssetsList = () => {
        const { data } = this.props;
        return data.map((asset) => {
            return (
                <div className="extra-asset-list">
                    <div className="extra-asset-item">
                        {asset.symbol || asset.asset}
                    </div>
                    <div className="extra-asset-item">
                        {asset.allocation}
                    </div>
                </div>
            )
        })
    }

    render() {
        const { show, data } = this.props;

        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : ""

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content extra-assets-content-div">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title">Assets not uploaded
                                <span className="watchlist-disclaimer"> *Watchlist is limited to 75 assets.</span>
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                                {
                                    data.length !== 0 &&
                                    <div className="extra-asset-div">
                                        <div className="extra-asset-list">
                                            <div className="extra-asset-item">
                                                Asset
                                            </div>
                                            <div className="extra-asset-item">
                                                Weight
                                            </div>
                                        </div>
                                        {this.getAssetsList()}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ExtraAssetPopup
