import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { asciiValueCheck, sortArray, sortArrayDesc } from '../utils/helperFunctions';
import { ExportToCsv } from 'export-to-csv';
import AlphabeticSlider from './AlphabeticSlider';
class SymbolsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            currentIndex: 0,
            nextDisabled: false,
            prevDisabled: true,
            search: '',
            currentPage: 1,
            pageCount: 0,
            sortType: 'asc',
            sortLabel: 'Sort (A-Z)',
            activeAlpha:"A",
        }
    }

    close = () => {
        if (this.props.cancel) {
            this.props.cancel();
        }
        this.setState({
            show: false
        });
    }

    onSymbolClick = (asset) => {
        this.setState({currentPage:1})
        this.props.onAssetClick(asset);
    }

    getAssets = () => {
        let assets = this.props.symbols;
        if (assets.length !== 0) {
            const { currentPage, search } = this.state;
            let finalAssets = this.getFinalAssets(assets);

            let finalPageIndex = (currentPage * 18);
            let firstPageIndex = (currentPage - 1) * 18;
            let paginatedAssets = [];

            if (search === '') {
                // paginatedAssets = finalAssets.filter((asset, index) => {
                //     return index < finalPageIndex && index >= firstPageIndex
                // })
                paginatedAssets = finalAssets?.filter((asset, index) => {
                    return asset?.symbol?.charAt(0).includes(this.state.activeAlpha);
                })
            }
            else {
                paginatedAssets = finalAssets;
            }

            if (paginatedAssets.length === 0) {
                return (
                    <div className="no-results-message">No matching results found</div>
                )
            }

            return paginatedAssets.map((asset) => {
                return (
                    <div className="asset-div" key={asset.symbol}>
                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3">
                            <div className="white-box" onClick={this.onSymbolClick.bind(this, asset)}>
                                <h2>{asset.symbol}</h2>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    }

    getFinalAssets = (assets) => {
        const { search, sortType } = this.state;
        let final = [];
        let finalSymbols = [];
        if (search !== '') {
            final = assets.filter((asset) => {
                return asset.symbol.toUpperCase().indexOf(search.toUpperCase()) > -1 &&  asset.symbol.toUpperCase().indexOf(search.toUpperCase())<1;
            })
        }
        else
            final = assets;

        if (sortType === 'asc')
            finalSymbols = final.sort(sortArray);
        else
            finalSymbols = final.sort(sortArrayDesc);

        return finalSymbols;
    }

    handleChange = (e) => {
        let search = e.target.value.toUpperCase();
        let asciiValue = search.charCodeAt(search.length - 1);
        let asciiCheck = asciiValueCheck(asciiValue);

        if (asciiCheck || search === '') {
            this.setState({
                search
            })
        }
    }

    handlePageClick = (data) => {
        this.setState({
            currentPage: data.selected + 1
        })
    }

    onSortClick = () => {
        if (this.state.sortType === 'asc') {
            this.setState({
                sortType: 'desc',
                sortLabel: 'Sort (Z-A)'
            })
        }
        else {
            this.setState({
                sortType: 'asc',
                sortLabel: 'Sort (A-Z)'
            })
        }
    }
    onAlphaChange =(alpha)=>{
        this.setState({
            activeAlpha:alpha
        })
    }
    download = () => {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '',
            filename: 'supportedSymbols',
            showLabels: false,
            showTitle: false,
            title: 'Supported Symbols',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: false,
        };

        const { symbols } = this.props;
        let data = [];
        symbols.forEach((asset) => {
            let symbol = asset.symbol
            data.push({ symbol })
        })

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }

    render() {
        const { show, symbolsReady, symbols } = this.props;
        const { search, sortLabel, sortType } = this.state;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : "";

        let pageCountValue = Math.ceil(symbols?.length / 18);

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm tutorial-modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title symbols-modal-title">Supported Symbols
                                    <span
                                        onClick={this.download}
                                        className="download-csv-btn">
                                        <i className="fa fa-download"></i>
                                    Download Full List of Supported Symbols</span>
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">

                                <div className="login-form symbols-modal-asset-search">
                                    <div className="symbols-modal-sort" onClick={this.onSortClick}>
                                        <span className="th-icon">
                                            {sortLabel} <i
                                                className={sortType === 'asc' ? "fa fa-caret-down sort-icon" :
                                                    'fa fa-caret-up sort-icon'}
                                                aria-hidden="true">
                                            </i>
                                        </span>
                                    </div>

                                    <input
                                        value={search}
                                        autoComplete="off"
                                        id="search"
                                        type="search"
                                        placeholder="Search Asset Symbol"
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </div>
                                <div className="clearfix"></div>
                                {
                                    symbolsReady ?
                                        <>
                                            <div className="symbols-main-div row">
                                                {this.getAssets()}
                                            </div>
                                            <div className={search === '' ? "symbols-pagination" :
                                                "symbols-pagination symbols-pagination-hidden"}>
                                                    <AlphabeticSlider onAlphaChange={this.onAlphaChange}
                                                    activeAlpha={this.state.activeAlpha}/>
                                                {/* <ReactPaginate
                                                    previousLabel={'<'}
                                                    nextLabel={'>'}
                                                    breakLabel={'...'}
                                                    breakClassName={'break-me'}
                                                    pageCount={pageCountValue}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination'}
                                                    subContainerClassName={'pages pagination'}
                                                    activeClassName={'active'}
                                                /> */}
                                            </div>
                                        </>
                                        :
                                        <div className="card-loader card-loader--tabs m-l-30 m-r-20 m-b-10 video-load-tabs"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default SymbolsModal
