import React from "react";
import { useTable, useSortBy } from "react-table";
import uuid from "react-uuid";

import searchIcon from "../../assets/img/lens-light.png";
import { asciiValueCheck,getTooltipText } from "../../utils/helperFunctions";
import AutocompleteField from "../AutocompleteField";
import "./PortfolioTable.css";

const PERCENTAGE_COLUMNS = [
  "Avg Expected Move Up",
  "Avg Expected Move Down",
  "Max Expected Move Up",
  "Max Expected Move Down",
  "Allocation",
];

const COLUMNS = Object.freeze({
  ASSET: "Asset",
  ALLOCATION: "Weight",
  ACTION: "",
  PERCENTAGE_ALLOCATION: "Allocation",
});

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  disabled,
  onItemSelect,
  onSearchChange,
  handleKeyPress,
  symbols,
  deleteRow,
  editable,
  placeholder,
  type,
  history,
  analyzed,
  action,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }
    if (type === "text") {
      if (!valueCheck) {
        if (!numberCheck && (asciiCheck || value === "")) {
          setValue(value);
        }
      }
      onSearchChange(e);
    } else {
      let quantity = parseFloat(value, 10);
      if (e.target.value === "") {
        setValue(e.target.value);
        updateMyData(index, id, e.target.value);
      } else {
        if (
          (quantity === 0 || quantity) &&
          asciiValue !== 69 &&
          !isNaN(quantity)
        ) {
          let decimalIndex = e.target.value.indexOf(".");
          if (
            decimalIndex !== -1 &&
            e.target.value.length - decimalIndex >= 3
          ) {
            let val = e.target.value.substr(0, decimalIndex + 3);
            setValue(val);
            updateMyData(index, id, val);
          } else {
            updateMyData(index, id, quantity);
          }
        } else if (quantity === "-0") {
          setValue(quantity);
          updateMyData(index, id, quantity);
        } else return;
      }
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    if (id === "symbol" && value !== "") {
      handleKeyPress(id, e, index);
      updateMyData(index, id, value);
    } else {
      updateMyData(index, id, value);
    }
  };

  const onClick = () => {
    deleteRow(index, id);
  };

  const searchSymbol = () => {
    history.push(`/watchlist/${value}?uuid=${uuid()}`);
    // history.push(`/asset/${value}?uuid=${uuid()}`);
  };

  const onSelect = (e) => {
    onItemSelect(index, id, e);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (id === "symbol") {
        let valueIndex = symbols.findIndex(
          (symbolValue) => symbolValue && symbolValue.symbol === value
        );
        if (valueIndex === -1) {
          setValue("");
          handleKeyPress(id, e);
        }
      } else {
        handleKeyPress(id, e);
        updateMyData(index, id, value);
      }
    } else {
      if (e.key === "-") {
        setValue("-0");
        updateMyData(index, id, "-0");
      }
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    if (action && !disabled) {
      return <i className="fa fa-trash" onClick={onClick}></i>;
    }
    return value ? `${value}` : `${""}`;
  }
  if (type === "text") {
    return (
      <div
        className="portfolio-editable-cell asset-edit-cell"
        id="portfolio-autocomplete-input"
      >
        <img
          className="portfolio-search"
          style={{
            visibility:
              type === "text" && analyzed && !disabled ? "visible" : "hidden",
          }}
          onClick={searchSymbol}
          height="20"
          width="20"
          src={searchIcon}
          alt="Symbol"
        ></img>

        <AutocompleteField
          placeholder={placeholder}
          onSearchChange={onChange}
          onItemSelect={onSelect}
          symbols={symbols}
          search={value}
          onKeyUp={onKeyPress}
          onBlur={onBlur}
          menuStyle={{
            position: "absolute",
            top: "100%",
            left: 0,
            height: "100px",
            overflow: "auto",
          }}
          onPaste={(event) => {
            event.preventDefault();
          }}
          onDrop={(event) => {
            event.preventDefault();
          }}
          disabled={disabled}
        />
        <div className="symbol-data-div">
          <span className="tooltiptext" id="error-div">
            Maximum length is 6 characters.
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="portfolio-editable-cell allocation-edit-cell">
        <input
          type={type}
          onWheel={(event) => event.currentTarget.blur()}
          onKeyDown={(evt) =>
            ["e", "E", "+"].includes(evt.key) && evt.preventDefault()
          }
          placeholder={placeholder}
          maxLength={5}
          step={"0.01"}
          className="b-0 cell-input"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          onKeyUp={onKeyPress}
          onPaste={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
          }}
        />
        <div className="symbol-data-div">
          <span className="tooltiptext" id="weight-error-div">
            Weight must be between -100.00 and 100.00
          </span>
        </div>
      </div>
    );
  }
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

function PortfolioTable({
  data,
  total,
  spy,
  history,
  updateMyData,
  deleteRow,
  symbols,
  onSearchChange,
  onItemSelect,
  handleKeyPress,
  disabled,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Asset",
        accessor: "symbol",
        width: 100,
        sortType: "basic",
      },
      {
        Header: "Weight",
        accessor: "allocation",
        sortType: "basic",
      },
      {
        Header: "Allocation",
        accessor: "percentAllocation",
        sortType: "basic",
        Cell: (props) =>
          props.cell.value
            ? `${props.cell.value} %`
            : props.cell.value === 0
            ? "0 %"
            : "",
      },
      {
        Header: "Timing",
        accessor: "timing",
        sortType: "basic",
      },
      {
        Header: "VVOL",
        accessor: "vvol",
        sortType: "basic",
        Cell: (props) =>
          props.cell.value
            ? `${props.cell.value}`
            : props.cell.value === 0
            ? "0"
            : "",
      },
      {
        Header: "Avg Expected Move Up",
        accessor: "pricemovementup",
        sortType: "basic",
        Cell: (props) => (props.cell.value ? `${props.cell.value} %` : ""),
      },
      {
        Header: "Avg Expected Move Down",
        accessor: "pricemovementdown",
        sortType: "basic",
        Cell: (props) => (props.cell.value ? `${props.cell.value} %` : ""),
      },
      {
        Header: "Std. Deviation Up",
        accessor: "stddevup",
        sortType: "basic",
      },
      {
        Header: "Std. Deviation Down",
        accessor: "stddevdown",
        sortType: "basic",
      },
      {
        Header: COLUMNS.ACTION,
        accessor: "action",
        disableSortBy: true,
      },
    ],
    []
  );

  React.useEffect(() => console.log(data))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      updateMyData,
      disabled,
      deleteRow,
    },
    useSortBy
  );
  return (
    <>
      <div className="table-responsive m-t-10" id="portfolio-table">
        <table {...getTableProps()} className="table">
          <colgroup>
            <col width="12%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="10%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="4%" />
          </colgroup>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="asset-table-header">
                      <div className="tooltipx">
                      <p className="m-b-0 th-text">
                        {column.render("Header")}
                      </p>
                      <div className="tooltiptext-div">{getTooltipText(column.render("Header"))}</div>
                      </div>
                      {/* Add a sort direction indicator */}
                      <span className="th-icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i
                              className="fa fa-caret-down"
                              aria-hidden="true"
                            ></i> // ' 🔽'
                          ) : (
                            <i
                              className="fa fa-caret-up"
                              aria-hidden="true"
                            ></i>
                          ) // ' 🔼'
                        ) : column.disableSortBy ? (
                          ""
                        ) : (
                          <i
                            className="fa fa-caret-down non-sorted"
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.Header === COLUMNS.ASSET) {
                      // console.log()
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            data[i] && data[i].assetValid
                              ? "asset-name-column"
                              : "asset-name-column invalid-asset"
                          }
                        >
                          {cell.render("Cell", {
                            editable: true,
                            placeholder: "Symbol",
                            type: "text",
                            history: history,
                            analyzed: data[i] && data[i].vsip ? true : false,
                            symbols: symbols,
                            onSearchChange,
                            onItemSelect,
                            handleKeyPress,
                          })}
                        </td>
                      );
                    } else if (cell.column.Header === COLUMNS.ALLOCATION) {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={
                            data[i] && data[i].allocation === "-0"
                              ? "asset-name-column invalid-asset"
                              : "asset-name-column"
                          }
                        >
                          {cell.render("Cell", {
                            editable: true,
                            placeholder: "Weight",
                            type: "number",
                            handleKeyPress,
                          })}
                        </td>
                      );
                    } else if (cell.column.Header === COLUMNS.ACTION) {
                      return (
                        <td className="text-center" {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: false,
                            action: "delete",
                          })}
                        </td>
                      );
                    } else if (
                      cell.column.Header === COLUMNS.PERCENTAGE_ALLOCATION
                    ) {
                      return (
                        <td className="text-center" {...cell.getCellProps()}>
                          {cell.render("Cell", {
                            editable: false,
                          })}
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render("Cell", { editable: false })}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
            <tr className="total-tr">
              {columns.map((column, i) => {
                let padding =
                  COLUMNS.ASSET === column.Header
                    ? "p-l-35 asset-name-column"
                    : COLUMNS.ALLOCATION === column.Header
                    ? "allocation-column"
                    : "";

                return (
                  <td className={`${padding}`} key={i}>
                    {PERCENTAGE_COLUMNS.includes(column.Header)
                      ? total[column.accessor] && `${total[column.accessor]} %`
                      : total[column.accessor]}
                  </td>
                );
              })}
            </tr>
            <tr>
              {columns.map((column, i) => {
                let padding = COLUMNS.ASSET === column.Header ? "p-l-35" : "";

                return (
                  <td key={i} className={`spy-th ${padding}`}>
                    {PERCENTAGE_COLUMNS.includes(column.Header)
                      ? spy[column.accessor] && `${spy[column.accessor]} %`
                      : spy[column.accessor]}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default React.memo(PortfolioTable);
