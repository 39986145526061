import React from "react";
import { connect } from "react-redux";

import Header from "../../layout/Header";
import Footer from "../../layout/Footer";

import {
  updateAssets,
  updatePortfolioProps,
} from "../../actions/PortfolioAction";

import {
  getSupportedSymbols,
  fetchTimingData,
} from "../../actions/AssetAction";

import "../portfolio/Portfolio.css";
import "../asset/Asset.css";
import ErrorModal from "../../layout/ErrorModal";
import ApiModal from "../../layout/ApiModal";
import {
  sortArray,
  asciiValueCheck,
  getAlbertoAction,
  formatDownloadDate,
} from "../../utils/helperFunctions";

import CSVReader from "react-csv-reader";
import { ExportToCsv } from "export-to-csv";
import { albertoTemplate } from "../portfolio/template";
import ConfirmModal from "../../layout/ConfirmModal";
import firebaseObj from "../../utils/firebase";
import packageJson from "../../../package.json";
import ExtraAssetPopup from "../../layout/ExtraAssetPopup";
import uuid from "react-uuid";
import AlbertoTable from "./AlbertoTable";
import ForbiddenErrorModal from "../../layout/ForbiddenErrorModal";

const analytics = firebaseObj.analytics();

class Alberto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      assets: [{ symbol: "", position: "", valid: true }],
      valid: false,
      symbol: "",
      position: "",
      analyzed: false,
      error: "",
      apiModalShow: false,
      apiDetails: [],
      apiResponse: [],
      isError: false,
      symbols: [],
      tableSymbols: [],
      showViewLog: true,
      showRangeModal: false,
      fileLoading: false,
      symbolsLoading: false,
      showConfirmModal: false,
      extraAssets: [],
      showExtraAsset: false,
      filename: "",
      showReloadModal: false,
      fileUploadErrorModal: false,
      headerMismatch: false,
    };
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    const { loginDetails } = this.props;

    let tabCount = localStorage.getItem("tab");
    let autoLogin = localStorage.getItem("autoLogin");
    let reloadCheck = window.sessionStorage.getItem("reload");

    if (
      loginDetails === null ||
      Object.keys(loginDetails).length === 0 ||
      ((tabCount !== "many" || autoLogin !== "true") && reloadCheck !== "true")
    ) {
      this.props.history.push("/");
    } else {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version,
      });
      analytics.logEvent("Alberto");

      window.scroll(0, 0);

      let prevRoute = localStorage.getItem("currentRoute");
      localStorage.setItem("currentRoute", "alberto");
      window.sessionStorage.setItem("token", loginDetails.apikey);

      if (!prevRoute) {
        prevRoute = "alberto";
      }

      this._mounted = true;
      if (this.props.supportedSymbols?.length === 0) {
        this.setState({
          symbolsLoading: true,
        });

        this.props.getSupportedSymbols(
          () => {
            const { supportedSymbols } = this.props;

            let sortedSymbols = supportedSymbols.sort(sortArray);
            let tableSymbols = sortedSymbols;

            this.setState({
              symbols: sortedSymbols,
              symbolsLoading: false,
              tableSymbols,
            });
          },
          (err, request) => {
            this.setErrorState(err, request);
          }
        );
      } else {
        let sortedSymbols = this.props.supportedSymbols?.sort(sortArray);
        let tableSymbols = sortedSymbols;

        this.setState({
          symbols: sortedSymbols,
          tableSymbols,
        });
      }

      if (prevRoute === "portfolio") {
        let albertoAssets = JSON.parse(localStorage.getItem("albertoAssets"));
        if (albertoAssets) {
          let assets = [];

          albertoAssets.forEach((asset) => {
            let position = asset.timing.toUpperCase();
            if (position === "NEUTRAL") position = "CASH";
            assets.push({ symbol: asset.symbol, position, valid: true });
          });

          this.setState(
            {
              assets,
              filename: "",
            },
            () => {
              this.setState({
                valid: this.isValid(),
              });
              localStorage.setItem("albertoAssets", null);
            }
          );
        }
      } else {
        this.setState(
          {
            assets: [{ symbol: "", position: "", valid: true }],
            filename: "",
          },
          () => {
            this.setState({
              valid: this.isValid(),
            });
          }
        );
      }
    }
  }

  isValid = () => {
    let valid = true;
    const { assets, symbols } = this.state;

    if (assets.length !== 0) {
      assets.forEach((asset) => {
        let index = symbols.findIndex(
          (sym) => sym && sym.symbol === asset.symbol
        );
        if (!asset.symbol || asset.position === "") {
          valid = false;
        }
        if (asset.symbol && index === -1) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }

    return valid;
  };

  addAsset = () => {
    let assets = [...this.state.assets];
    let symbol = "";
    let position = "";
    let assetsLength = assets.length - 1;

    if (assets.length !== 50) {
      if (
        assets[assetsLength].symbol !== ""
        // assets[assetsLength].position !== ""
      )
        assets.push({ symbol, position, valid: true });
      // else{
      //   this.setState({
      //     error:'Please select a position for the current asset before adding a new asset.',
      //     showViewLog: false,
      //   })
      // }

      this.props.updateAssets(assets);

      this.setState(
        {
          assets,
          valid: this.isValid(),
          symbol: "",
          position: "",
        },
        () => {
          this.props.updatePortfolioProps(
            this.state.assets,
            this.state.filename
          );
        }
      );
    }
  };

  goToAsset = () => {
    this.props.history.push("/asset?uuid=" + uuid());
  };

  deleteRow = (rowIndex, columnId) => {
    this.setState(
      (state) => {
        return {
          assets: state.assets.filter((asset, index) => {
            return index !== rowIndex;
          }),
        };
      },
      () => {
        if (this.state.assets.length === 0) {
          this.setState({
            valid: false,
            total: { symbol: "Aggregate", position: "" },
            spy: { symbol: this.state.compareSymbol, position: "" },
            showChartData: false,
            assets: [{ symbol: "", position: "", valid: true }],
          });
        } else {
          this.setState({
            valid: this.isValid(),
            showChartData: false,
            familyChartData: [],
            assetChartData: [],
            total: { symbol: "Aggregate", position: "" },
          });
        }

        this.props.updatePortfolioProps(this.state.assets, this.state.filename);
      }
    );
  };

  updateMyData = (rowIndex, columnId, value) => {
    let oldValue = "";
    this.setState(
      (state) => {
        return {
          assets: state.assets.map((asset, index) => {
            if (index === rowIndex) {
              if (columnId === "symbol") {
                let symbols = this.state.symbols;
                let entered = value.toUpperCase();
                let index = symbols.findIndex(
                  (sym) => sym && sym.symbol === entered
                );
                oldValue = state.assets[rowIndex]["symbol"];

                let valid = index === -1 ? false : true;
                if (oldValue === value) {
                  return {
                    ...state.assets[rowIndex],
                    [columnId]: value,
                    position: state.assets[rowIndex]["position"],
                    valid,
                  };
                } else {
                  return {
                    [columnId]: value,
                    position: state.assets[rowIndex]["position"],
                    valid,
                  };
                }
              } else {
                return {
                  ...state.assets[rowIndex],
                  [columnId]: value,
                  valid: state.assets[rowIndex]["valid"],
                };
              }
            }
            return asset;
          }),
        };
      },
      () => {
        if (oldValue !== value) {
          this.setState({
            valid: this.isValid(),
          });

          this.props.updatePortfolioProps(
            this.state.assets,
            this.state.filename
          );
        }
      }
    );
  };

  handleChange = (e) => {
    let value = e.target.value.toUpperCase();
    this.setState({
      [e.target.id]: value,
    });
  };

  analyze = () => {
    const { loginDetails } = this.props;
    this.setState({
      loading: true,
    });

    let assets = this.state.assets;
    let assetsLength = assets.length - 1;
    if (
      assets[assetsLength].symbol === "" &&
      assets[assetsLength].position === ""
    ) {
      assets.pop();
    }

    let invalidAssets = assets.filter((value) => {
      return !value.valid;
    });

    if (invalidAssets.length !== 0) {
      this.setState({
        error: "Some assets are not supported!",
        showViewLog: false,
        loading: false,
      });
      return false;
    }

    let newAssets = [...this.state.assets].map((asset) => {
      return {
        symbol: asset.symbol,
        // position: asset.position
        allocation: 10,
      };
    });

    this.setState({
      loading: true,
    });

    if (this.isValid()) {
      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version,
      });

      analytics.logEvent("Albertize", {
        stocksCount: newAssets.length,
      });

      newAssets.forEach((asset) => {
        analytics.logEvent("Assets analyzed in Alberto", {
          albertizeAsset: asset.symbol,
        });
      });

      this.props.fetchTimingData(
        newAssets,
        "alberto",
        () => {
          if (this._mounted) {
            const { albertoTimingData, timingRequest } = this.props;
            let apiDetails = [];
            let apiResponse = [];
            let assetData = [];

            assets.forEach((asset, key) => {
              let albertoTiming =
                albertoTimingData.timingsdata[key+1]?.timingschangedatalist;
              let position = asset.position?.toUpperCase();
              let latestTiming = albertoTiming[0]?.timing.toUpperCase();
              let timingChange=null;
              if(albertoTiming.length!==0){
              timingChange =
                albertoTiming[0].timing !== albertoTiming[1]?.timing
                  ? "Yes"
                  : "No";
              }
              assetData.push({
                symbol: asset.symbol,
                valid: true,
                position,
                latestTiming,
                timingChange,
                userAction: getAlbertoAction(
                  position,
                  latestTiming,
                  timingChange
                ),
              });
            });

            apiDetails.push(timingRequest);
            apiResponse.push(albertoTimingData);

            this.setState({
              assets: assetData,
              apiDetails,
              apiResponse,
              loading: false,
              analyzeText: "Re-albertize",
              analyzed: true,
            });
          }
        },
        (err, request) => {
          this.setErrorState(err, request);
        }
      );
    } else {
      console.log("not valid");
    }
  };

  setErrorState = (err, request) => {
    if (this._mounted) {
      let apiDetails = [];
      let apiResponse = [];
      let error = err.message ? err.message : "Error Occured !!";
      apiDetails.push(request);
      apiResponse.push({ error });

      this.setState({
        loading: false,
        error,
        apiDetails,
        apiResponse,
      });
    }
  };

  closeError = () => {
    this.setState({
      error: "",
      showViewLog: true,
    });
  };

  viewLog = () => {
    this.setState({
      apiModalShow: true,
      isError: true,
      error: false,
    });
  };

  handleKeyPress = (type, e) => {
    let symbols = this.state.symbols;
    let entered = e.target.value.toUpperCase();
    let index = symbols.findIndex((value) => value && value.symbol === entered);

    if (e.target.value && e.key === "Enter") {
      if (index === -1) {
        this.setState({
          error: "This Symbol is not supported!",
          showViewLog: false,
          [type]: "",
        });
      }
    }
    if (e.target.value && e.key === "Backspace") {
      this.setState({
        [type]: entered,
      });
    }
  };

  onBlur = (e) => {
    if (e.target.value) {
      let symbols = this.state.symbols;
      let entered = e.target.value.toUpperCase();
      let index = symbols.findIndex(
        (value) => value && value.symbol === entered
      );

      if (index === -1) {
        this.setState({
          error: "This Symbol is not supported!",
          showViewLog: false,
        });
      }
    }
  };

  getApiInfo = () => {
    this.setState({
      apiModalShow: true,
    });
  };

  onApiModalClose = () => {
    this.setState({
      apiModalShow: false,
      showExtraAsset: false,
      extraAssets: [],
    });
  };

  onSearchChange = (e, type) => {
    let value = e.target.value.toUpperCase();
    let asciiValue = value.charCodeAt(value.length - 1);
    let asciiCheck = asciiValueCheck(asciiValue);
    let errorDiv = document.getElementById("error-div");
    let numberCheck = /\d/.test(value);
    let valueCheck = value.length > 6;
    if (value.length > 1 && (asciiValue === 36 || asciiValue === 94)) {
      asciiCheck = false;
    }
    if (type === "Symbol") {
    if (!valueCheck) {
      if (errorDiv) errorDiv.style.visibility = "hidden";
      
      if (!numberCheck && (asciiCheck || value === "")) {
        this.setState({
          symbol: value,
        });
      }
    }
    else {
      if (errorDiv) {
        errorDiv.style.visibility = "visible";
        setTimeout(() => {
          errorDiv.style.visibility = "hidden";
        }, 5000);
      }
    }
    } else {
      this.setState({
        position: value,
      });
    }
  };

  onItemSelect = (rowIndex, columnId, value) => {
    this.setState(
      (state) => {
        return {
          assets: state.assets.map((asset, index) => {
            if (index === rowIndex) {
              if (columnId === "symbol") {
                return {
                  [columnId]: value,
                  position: state.assets[rowIndex]["position"],
                  valid: true,
                };
              } else {
                return {
                  ...state.assets[rowIndex],
                  [columnId]: value,
                };
              }
            }
            return asset;
          }),
        };
      },
      () => {
        this.setState({
          valid: this.isValid(),
        });

        this.props.updatePortfolioProps(this.state.assets, this.state.filename);
      }
    );
  };

  handleForce = (data, fileInfo) => {
    let assets = [];
    let unsupportedAssets = [];
    let filename = fileInfo.name;

    const { loginDetails } = this.props;

    data.forEach((sym) => {
      const headerArray = [...Object.keys(sym)];
      if (
        !(
          headerArray.includes("asset") &&
          (headerArray.includes("position") || headerArray.includes("timing"))
        )
      ) {
        this.setState({
          headerMismatch: true,
          assets: [{ symbol: "", position: "", valid: true }],
          showViewLog: false,
        });
        document.getElementById("CSVReader").value = "";
        return false;
      }

      if (!sym.asset && (!sym.position || !sym.timing)) {
        this.setState({
          fileUploadErrorModal: true,
          assets: [{ symbol: "", position: "", valid: true }],
          showViewLog: false,
        });
        return false;
      } else {
        let symbols = this.state.symbols;
        let entered = sym.asset === true ? "TRUE" : sym.asset.toUpperCase();
        let index = symbols.findIndex(
          (value) => value && value.symbol === entered
        );
        let position =
          sym.position && sym.position !== "undefined"
            ? sym.position.toUpperCase()
            : sym.timing
            ? sym.timing.toUpperCase()
            : "";

        if (index !== -1) {
          if (position !== 0) {
            sym.symbol = entered;
            sym.valid = true;
            sym.position = position;

            if (sym.position === "NEUTRAL") sym.position = "CASH";

            assets.push(sym);
          }
        } else {
          sym.symbol = entered;
          sym.valid = false;
          sym.position = 0;
          unsupportedAssets.push(sym);
        }
      }
    });

    let sortedAssets = assets;
    let finalAssets = [];
    let extraAssets = [];

    if (sortedAssets.length !== 0) {
      sortedAssets.forEach((asset, index) => {
        if (index < 50) finalAssets.push(asset);
        else extraAssets.push(asset);
      });

      if (unsupportedAssets.length !== 0 && finalAssets.length < 50) {
        finalAssets.forEach((asset, index) => {
          if (asset.position === 0) {
            delete finalAssets[index];
          }
        });

        unsupportedAssets.forEach((asset) => {
          finalAssets.unshift(asset);
        });
      }

      if (data[0].asset) {
        this.setState(
          {
            assets: finalAssets,
            portfolio: [],
            fileLoading: true,
            filename,
          },
          () => {
            this.props.updatePortfolioProps(this.state.assets, filename);
            let thisVal = this;
            let showExtraAsset = false;

            if (extraAssets.length !== 0) showExtraAsset = true;

            setTimeout(function () {
              thisVal.setState({
                fileLoading: false,
                extraAssets,
                showExtraAsset,
                valid: thisVal.isValid(),
              });
            }, 5000);
          }
        );
      }

      document.getElementById("CSVReader").value = "";

      analytics.setUserProperties({
        email: loginDetails.email,
        App_Version: packageJson.version,
      });
      analytics.logEvent("Upload Alberto CSV");
    }
  };

  options = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  exportCsv = () => {
    const { loginDetails } = this.props;
    let assets = this.state.assets;
    let data = [];

    let currentDate = new Date();
    currentDate = formatDownloadDate(currentDate);
    let filename = "Kosha-Alberto-" + currentDate;

    assets.forEach((asset) => {
      data.push({
        asset: asset.symbol,
        position: asset.position || "",
        timingChange: asset.timingChange || "",
        latestTiming: asset.latestTiming || "",
        userAction: asset.userAction || "",
      });
    });

    const exportOptions = {
      fieldSeparator: ",",
      quoteStrings: "",
      decimalSeparator: ".",
      filename: filename,
      showLabels: true,
      showTitle: false,
      title: "Kosha Alberto",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: [
        "Asset",
        "Position",
        "Timing Change",
        "Latest Timing",
        "Action",
      ],
    };

    analytics.setUserProperties({
      email: loginDetails.email,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Export Alberto CSV");

    const csvExporter = new ExportToCsv(exportOptions);
    csvExporter.generateCsv(data);
  };

  downloadTemplate = (e) => {
    e.preventDefault();

    const { loginDetails } = this.props;

    const options = {
      fieldSeparator: ",",
      quoteStrings: "",
      filename: "template",
      showLabels: false,
      showTitle: false,
      title: "Alberto Template",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    analytics.setUserProperties({
      email: loginDetails.email,
      App_Version: packageJson.version,
    });
    analytics.logEvent("Download Alberto Template");

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(albertoTemplate);
  };

  clearTable = () => {
    let assets = [{ symbol: "", position: "", valid: true }];

    if (assets.length === 0)
      assets = [{ symbol: "", position: "", valid: true }];

    this.setState(
      {
        assets,
        showConfirmModal: false,
        analyzeText: "Albertize",
        analyzed: false,
        filename: "",
      },
      () => {
        this.props.updatePortfolioProps(this.state.assets, "");
        this.setState({
          valid: this.isValid(),
        });
      }
    );
  };

  confirmClear = () => {
    this.setState({
      showConfirmModal: true,
      filename: "",
    });
  };

  handover = () => {
    const { assets } = this.state;
    localStorage.setItem("albertoAssets", JSON.stringify(assets));
    this.props.history.push("/watchlist?uuid=" + uuid());
  };

  render() {
    const {
      valid,
      loading,
      assets,
      analyzed,
      error,
      showViewLog,
      apiDetails,
      apiModalShow,
      apiResponse,
      isError,
      fileLoading,
      symbolsLoading,
      showConfirmModal,
      tableSymbols,
      extraAssets,
      showExtraAsset,
      filename,
      fileUploadErrorModal,
      headerMismatch,
    } = this.state;

    let tableView = (
      <>
        <div className="row add-button-div">
          <div
            className="col-md-2 col-sm-4 col-xs-6 file-input-div"
            id={loading || fileLoading ? "disabled-input-btn" : ""}
          >
            <CSVReader
              inputId="CSVReader"
              cssClass={
                loading || fileLoading
                  ? "react-csv-input-disabled"
                  : "react-csv-input"
              }
              label="Upload CSV"
              onFileLoaded={this.handleForce}
              parserOptions={this.options}
              disabled={loading || fileLoading}
            />
          </div>

          <div className="col-md-3 col-sm-4 col-xs-6 no-padding info-text-div">
            <i onClick={this.downloadTemplate} className="fa fa-info-circle">
              <span className="template-text">Download Template</span>
            </i>
            <span className="watchlist-disclaimer">
              {" "}
              *Alberto is limited to 75 assets.
            </span>
          </div>

          <div className="table-buttons col-md-7 col-sm-12 col-xs-12">
            {assets[0] && assets[0].symbol !== "" && (
              <>
                <button
                  onClick={this.confirmClear}
                  disabled={loading || fileLoading}
                  className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                >
                  Clear All Entries
                </button>

                <button
                  onClick={this.exportCsv}
                  disabled={loading || fileLoading}
                  className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
                >
                  Export to CSV
                </button>
              </>
            )}
            <button
              onClick={this.addAsset}
              disabled={loading || fileLoading}
              className="btn btn-outline waves-effect waves-light m-b-5 m-t-10"
            >
              Add Symbol and Position
            </button>
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 file-name-text">
            {filename}
          </div>
        </div>

        {!fileLoading ? (
          <>
            <AlbertoTable
              data={assets}
              history={this.props.history}
              updateMyData={this.updateMyData}
              deleteRow={this.deleteRow}
              symbols={tableSymbols}
              onSearchChange={this.onSearchChange}
              onItemSelect={this.onItemSelect}
              handleKeyPress={this.handleKeyPress}
            />
            {/* {analyzed && (
              <button
                onClick={this.handover}
                className="btn btn-outline waves-effect waves-light analyze-portfolio-btn"
              >
                Handover to Watchlist
              </button>
            )} */}
          </>
        ) : (
          <div className="card-loader card-loader--tabs m-b-30"></div>
        )}
      </>
    );

    let dataView = (
      <>
        <div className="row">
          <div className="col-md-12">{tableView}</div>
        </div>
      </>
    );

    let errorView = error ? (
      <ErrorModal
        show={error ? true : false}
        onClose={this.closeError}
        message={error}
        viewLog={this.viewLog}
        showViewLog={showViewLog}
      />
    ) : (
      ""
    );

    const analyzeText = analyzed ? "Re-Albertize " : "Albertize ";
    const boxSubtitle =
      assets && assets.length > 0
        ? "Select row to edit"
        : "Add assets and position to begin";

    return (
      <>
        <Header history={this.props.history} />
        {errorView}

        <div id="page-wrapper" className="m-l-0">
          <div className="container">
            <div className="row p-t-10 p-b-10 b-0"></div>
            {!symbolsLoading ? (
              <>
                <div className="white-box analyze-asset-white-box">
                  <div className="row portfolio-container">
                    <div className="col-md-7 col-sm-12 col-xs-12">
                      <h4 className="page-title">Alberto</h4>
                      <h3 className="box-subtitle m-b-5">{boxSubtitle}</h3>
                    </div>

                    <div
                      className="col-md-3 col-sm-6 col-xs-6"
                      id="autocomplete-input"
                    ></div>

                    <div className="col-md-2 col-sm-6 col-xs-6">
                      <h5 className="section-list-item empty-item"> </h5>
                      <button
                        disabled={!valid || loading || fileLoading}
                        onClick={this.analyze}
                        className="btn btn-outline waves-effect waves-light m-b-5 analyze-alberto-btn"
                      >
                        {analyzeText}
                        {(loading || fileLoading) && (
                          <i className="fa fa-spinner fa-spin"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                {assets.length !== 0 && (
                  <div className="white-box">
                    <div className="row">
                      <div className="col-md-12">{dataView}</div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="card-loader card-loader--tabs m-b-30"></div>
            )}
          </div>

          <Footer onClick={this.getApiInfo} location="watchlist" />

          <ApiModal
            show={apiModalShow}
            apiDetails={apiDetails}
            onClose={this.onApiModalClose}
            apiResponse={apiResponse}
            isError={isError}
            screen="portfolio"
          />

          <ConfirmModal
            show={showConfirmModal}
            confirm={this.clearTable}
            cancel={() => {
              this.setState({ showConfirmModal: false });
            }}
          />
          <ConfirmModal
            show={fileUploadErrorModal || headerMismatch}
            fileUploadError={true}
            headerMismatch={headerMismatch}
            downloadTemplate={this.downloadTemplate}
            confirm={() => {
              this.setState({ fileUploadErrorModal: false , headerMismatch:false });
            }}
            cancel={() => {
              this.setState({ fileUploadErrorModal: false , headerMismatch:false });
              this.clearTable();
            }}
          />

          <ExtraAssetPopup
            show={showExtraAsset}
            data={extraAssets}
            onClose={this.onApiModalClose}
          />
          <ForbiddenErrorModal
          history={this.props.history}/>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filename: state.PortfolioReducer.filename,
  supportedSymbols: state.AssetReducer.supportedSymbols,
  supportedSymbolsRequest: state.AssetReducer.supportedSymbolsRequest,
  timingRequest: state.PortfolioReducer.timingRequest,
  loginDetails: state.AboutReducer.loginDetails,
  albertoTimingData: state.PortfolioReducer.albertoTimingData,
  about: state.AboutReducer.about,
});

export default connect(mapStateToProps, {
  updateAssets,
  updatePortfolioProps,
  getSupportedSymbols,
  fetchTimingData,
})(Alberto);
