import { CognitoUser } from "amazon-cognito-identity-js";
import poolData from "../components/login/UserPool";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const accessTokenUpdate = () => {
  const userEmail = JSON.parse(localStorage.getItem("loginDetails"))?.email;
  const refreshToken = localStorage.getItem("refreshToken");
  const sessionTime = window.sessionStorage.getItem("SESSION_TIME");
  const timeDifference = Math.floor((Date.now() - sessionTime) / (60 * 1000));

  // if (timeDifference >= 5) {
    // Refresh AccessToken every 15mins
    let userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const user = new CognitoUser({
      Username: userEmail,
      Pool: userPool,
    });
    const token = new AmazonCognitoIdentity.CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    user.refreshSession(token, (err, session) => {
      if (err) {
        console.log(err);
      } else {
        // console.log(session)
        localStorage.setItem("accessToken", session?.accessToken?.jwtToken);
        localStorage.setItem("refreshToken", session?.refreshToken?.token);
        localStorage.setItem("idToken", session?.idToken.jwtToken);
        window.sessionStorage.setItem("SESSION_TIME", Date.now());
      }
    });
  // }
};

export default accessTokenUpdate;
