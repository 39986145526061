import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete'

class AutocompleteField extends Component {
    onSearchChange = (e) => {
        this.props.onSearchChange(e);
    }

    onItemSelect = (search) => {
        this.props.onItemSelect(search);
    }

    render() {
        const { placeholder, symbols, search, wrapperStyle, onKeyUp,onPaste,onDrop, onBlur, id, menuStyle, disabled } = this.props;
        return (
            <>
                <Autocomplete
                    inputProps={{
                        placeholder,
                        onKeyUp,
                        onBlur,
                        id,
                        onPaste,
                        onDrop,
                        className: "autocomplete-input",
                        disabled
                    }}
                    wrapperStyle={wrapperStyle || { position: 'relative' }}
                    menuStyle={
                        menuStyle ||
                        {
                            position: 'absolute', top: '78px', left: '15px', height: '150px', overflow: 'auto'
                        }}
                    getItemValue={(item) => item.symbol}
                    items={symbols}
                    renderItem={(item, isHighlighted) =>
                        <div className="autocomplete-menu-div" 
                            style={{
                                background: isHighlighted ? '#96a2b4' : '#313345',
                                padding: '5px 10px',
                                color: isHighlighted ? '#313345' : '#9ba2ab'
                            }}
                            key={`${item.symbol}_${isHighlighted}`}
                        >
                            {item ? item.symbol : null}
                        </div>
                    }
                    shouldItemRender={(item, value) => {
                        if (value.length >= 1 && item && item.symbol.toUpperCase().indexOf(value.toUpperCase()) > -1) {
                            let splitValue = item.symbol.slice(0, value.length);
                            if (splitValue === value)
                                return item
                        }
                    }
                    }
                    id="search"
                    value={search}
                    onChange={this.onSearchChange}
                    onSelect={this.onItemSelect}
                />
            </>
        )
    }
}

export default AutocompleteField
