import React, { Component } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currBranch } from '../../../utils/const';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize';

export default class UserCommunicationModal extends Component {

    constructor(props){
        super(props)
        this.state = {
            messageType: 'email',
            emailSubject: '',
            emailBody: '',
            emailUsers: [],
            smsBody: '',
            smsUsers: [],
            allOrSelected: 'ALL'
        }
    }

    onConfirm = () => {
        console.log(this.state.messageType)
        this.props.setMessageType(this.state.messageType)
        if(this.state.messageType === 'email'){
            this.props.setMessageSubject(this.state.emailSubject)
            this.props.setMessageBody(this.state.emailBody)
        }else{
            this.props.setMessageSubject('')
            this.props.setMessageBody(this.state.smsBody)
        }
        let message = `Send ${(this.state.messageType==='email') ? `an email `: `an sms `} to all users. 
            ${(this.state.messageType==='email') ? `Message Subject: "${this.state.emailSubject}". Message Body: "${this.state.emailBody}"`: ''}
            ${(this.state.messageType === 'text') ? `Message Body: "${this.state.smsBody}"`: ''}`
        this.props.setConfirmationMessage(message)
        this.props.showConfirmSelectionModal()
        this.props.onClose()
    }

    render() {
        return (
            <Modal
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={this.props.style}>
                    <Box>
                        <Typography sx={{fontSize: '1.6em'}}>Send A Message to Users on  {currBranch}:</Typography>

                        <FormControl onSubmit={this.onConfirm} required>
                            <FormLabel sx={{color: "white", fontSize: '1em', display: 'flex'}}><Typography sx={{fontSize: '1em !important'}}>Select Which Type of Message You Would Like To Send:</Typography></FormLabel>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label-message-type"
                                defaultValue= "email"
                                name="radio-buttons-group-message-type"
                                value={this.state.messageType}
                                onChange={(event) => this.setState({messageType: event.currentTarget.value})}
                            >
                                <FormControlLabel value="email" control={<Radio />} label="Email" sx={{fontSize: '1em !important'}}/>
                                <FormControlLabel value="text" control={<Radio />} label="SMS/Text" sx={{fontSize: '1em !important'}}/>
                            </RadioGroup>

                            {this.state.messageType === 'email' && 
                                <Box style={{marginLeft: '22px'}}>
                                    <Typography>Email Subject:</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Email Subject"
                                        maxRows={4}
                                        style={{ width: 200 }}
                                        value={this.state.emailSubject}
                                        onChange={(event) => this.setState({emailSubject: event.currentTarget.value})}
                                    />
                                    <Typography sx={{marginTop: '2rem'}}>Email Body: (Formatting will not be included)</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Email Body"
                                        maxRows={4}
                                        style={{ width: 200 }}
                                        value={this.state.emailBody}
                                        onChange={(event) => this.setState({emailBody: event.currentTarget.value})}
                                    />
                                </Box>}
                            {this.state.messageType === 'text' && 
                                <Box style={{marginLeft: '22px'}}>
                                    <Typography>Text Body: (Formatting will not be included)</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Text Body"
                                        maxRows={4}
                                        style={{ width: 200 }}
                                        value={this.state.textBody}
                                        onChange={(event) => this.setState({textBody: event.currentTarget.value})}
                                    />
                                </Box>}
                            
                            
                        </FormControl>
                    </Box>


                    <Box sx={{display: 'flex', justifyContent: 'space-between', margin: 'auto'}}>
                        <button className="btn waves-effect waves-light m-b-5 m-t-10" 
                            disabled={
                                this.state.symbolListError || 
                                this.state.checkboxError || 
                                (this.state.allOrSelected==="selected" && this.state.symbolList.length ===0)} 
                            onClick={this.onConfirm}
                            >Confirm</button>
                        <button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.props.onClose}>Cancel</button>
                    </Box>
                </Box>
            </Modal>
        )
    }
}
