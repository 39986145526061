import { ACTIONS } from '../actions/ActionTypes';

const initialState = {
    marketData: null,
    fetchRequest: null
};

export function MarketReducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.FETCH_MARKET_DATA:
            return {
                ...state,
                marketData: action.payload.result.responselist,
                fetchRequest: action.payload.fetchRequest
            };

        case ACTIONS.GET_LOGS:
            return { ...state };

        default:
            return state;
    }
}
