import React, { Component } from 'react'

class AboutModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.setState({
            show: this.props.show
        });
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            show: false
        });
    }

    getData = (data) => {
        if (data) {
            let about = data?.releasenotes?.releasedetails;

            if (about) {
                return about?.map((release, index) => {
                    return (
                        <div key={index}>
                            <div className="version-number-date">{release.releasenumanddate}</div>
                            <div className="release-notes-body">
                                {this.getReleaseContent(release.releasecontent)}
                            </div>
                        </div>
                    )
                })
            }
        }
    }

    getReleaseContent = (content) => {
        return content.map((data, index) => {
            return (
                <div key={index}>
                    <div className="section-header-text">{data.releaseHeading}</div>
                    <ul key={index} className="section-list">
                        {this.getReleaseList(data.releasetext)}
                    </ul>
                </div>
            )
        })
    }

    getReleaseList = (text) => {
        return text.map((item, index) => {
            return <li key={index} className="section-list-item">{item}</li>
        })
    }

    render() {
        const { show, data } = this.props;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : "";

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} id="release-notes-modal" style={{ display: display }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">

                                <h4 className="modal-title">Product Team Release Notes
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>
                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30" id="release-notes-modal-body">
                                {
                                    data !== null && data.status !== 500 ?
                                        this.getData(data)
                                        :
                                        <div className="card-loader card-loader--tabs m-b-30"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AboutModal
