import { ACTIONS } from '../actions/ActionTypes';
import { CHART_TYPE } from '../utils/const';

const initialState = {
  portfolioAssetChartData: null,
  portfolioFamilyChartData: null,
  assetData: null,
  familyData: null,
  portfolioRequest: null,
  portfolioAssetChartRequest: null,
  portfolioFamilyChartRequest: null,
  averageData: null,
  familyDataRequest: null,
  portfolioTiming: null,
  portfolioPerformance: null,
  timingRequest: null,
  performanceRequest: null,
  holdings: null,
  singleAssetPerformance: null,
  singleAssetTiming: null,
  filename: null,
  albertoTimingData: null,
  timingDiffData: null,
  bucketDetailsData: null,
  savedWatchlists: null,
  comparePortfolioTiming: null,
  compareportfolioPerformance: null,
  koshaWatchlists: [],
  userWatchlists: []
};

export function PortfolioReducer(state = initialState, action) {

  switch (action.type) {
    case ACTIONS.LOGIN:
      return initialState;

    case ACTIONS.CREATE_KEY:
      return initialState;

    case ACTIONS.SINGLE_ASSET_PERFORMANCE:
      return {
        ...state,
        singleAssetPerformance: action.payload.result
      }

    case ACTIONS.SINGLE_ASSET_TIMING:
      return {
        ...state,
        singleAssetTiming: action.payload.result
      }

    case ACTIONS.CLEAR_SINGLE_DATA:
      return {
        ...state,
        singleAssetPerformance: null,
        singleAssetTiming: null
      }

    case ACTIONS.FETCH_PORTFOLIO:
      const assetData = action.payload.result.map((payload, index) => {

        let assetAnalyzed = payload.analyzed.assetdata;
        const assetInput = action.assets[index];

        if (assetInput) {
          assetAnalyzed = {
            ...assetAnalyzed,
            symbol: action.assets[index].symbol,
            allocation: action.assets[index].allocation
          };
        }

        return assetAnalyzed;
      });

      const familyData = action.payload.result.map(payload => payload.analyzed.familydata);

      return {
        ...state,
        portfolio: action.payload.result,
        assetData: assetData,
        familyData: familyData,
        portfolioRequest: action.payload.fetchRequest,
        portfolioTiming: null,
        portfolioPerformance: null,
        timingRequest: null,
        performanceRequest: null,
        timingDiffData: null,
        comparePortfolioTiming: null,
        compareportfolioPerformance: null
      };

    case ACTIONS.FETCH_PORTFOLIO_NEW:
      let data = action.payload.result;

      let aggregateAllocation = 0;
      data.holdings.forEach((asset) => {
        aggregateAllocation += Math.abs(asset.allocation)
      })

      aggregateAllocation = parseFloat(aggregateAllocation.toFixed(3));

      let averageData = {
        vflow: parseFloat(data.vflow.toFixed(3)),
        vvol: parseFloat(data.vvol.toFixed(3)),
        maxpricedown: parseFloat(data.wtavgmaxpricedown.toFixed(3)),
        maxpriceup: parseFloat(data.wtavgmaxpriceup.toFixed(3)),
        pricemovementdown: parseFloat(data.wtavgpricemovementdown.toFixed(3)),
        pricemovementup: parseFloat(data.wtavgpricemovementup.toFixed(3)),
        riskprofilenumber: data.wtavgriskprofilenumber,
        symbol: 'Aggregate',
        allocation: aggregateAllocation,
        stddevup: parseFloat(data.wtstddevup.toFixed(3)),
        stddevdown: parseFloat(data.wtstddevdown.toFixed(3))
      };

      return {
        ...state,
        assetData: action.payload.result.assets.map((ele, i) => {
          ele['allocation'] = Number(action.payload.result.holdings[i].allocation);
          return ele;
        }),
        portfolioAssetChartData: action.payload.result.daily,
        averageData: averageData,
        portfolioRequest: action.payload.fetchRequest,
        portfolio: action.payload.result,
        holdings: action.payload.holdings,
        timingDiffData: null,
        portfolioTiming: data.daily.timings,
        portfolioPerformance: data.daily.performance,
        comparePortfolioTiming: data.daily.timings,
        compareportfolioPerformance: data.daily.performance,
      }

    case ACTIONS.CLEAR_PORTFOLIO:
      return {
        ...state,
        portfolio: null
      }
      
    case ACTIONS.FETCH_FAMILY_DATA:
      return {
        ...state,
        familyData: action.payload.result.analyzed.assetdata,
        familyDataRequest: action.payload.fetchRequest,
      }

    case ACTIONS.FETCH_FAMILY_CHART_DATA:
      return {
        ...state,
        portfolioFamilyChartRequest: action.payload.fetchRequest,
        portfolioFamilyChartData: action.payload.result,
      }

    case ACTIONS.FETCH_MULTIPLE_CHART_DATA:
      if (action.chartType === CHART_TYPE.ASSET_CHART) {
        return {
          ...state,
          portfolioAssetChartRequest: action.payload.fetchRequest,
          portfolioAssetChartData: action.payload.result.map((ele, i) => {
            ele['allocation'] = Number(state.assetData[i].allocation);
            return ele;
          })
        };
      } else {
        return {
          ...state,
          portfolioFamilyChartRequest: action.payload.fetchRequest,
          portfolioFamilyChartData: action.payload.result.map((ele, i) => {
            ele['allocation'] = Number(state.assetData[i].allocation);
            return ele;
          })
        };
      };

    case ACTIONS.UPDATE_ASSETS:
      return {
        ...state,
        assetData: action.payload,
        timingDiffData: null
      };

    case ACTIONS.RESET_PORTFOLIO_CHART:
      return {
        ...state,
        portfolioAssetChartData: null,
        portfolioFamilyChartData: null,
        portfolioChartRequest: null,
        holdings: null,
        timingDiffData: null
      }

    case ACTIONS.UPDATE_PORTFOLIO_PROPS:
      return {
        ...state,
        portfolioAssetChartData: null,
        portfolioFamilyChartData: null,
        portfolioChartRequest: null,
        assetData: action.payload.assets,
        filename: action.payload.filename,
        familyData: null,
        averageData: { symbol: "Aggregate", allocation: "" },
        holdings: null,
        timingDiffData: null,
        singleAssetPerformance: null,
        singleAssetTiming: null,
        portfolioTiming: null
      }

    case ACTIONS.WATCHLIST_TIMING_DATA:
      return {
        ...state,
        portfolioTiming: action.payload.result,
        timingRequest: action.payload.fetchRequest,
        singleAssetPerformance: null,
        singleAssetTiming: null
      }

    case ACTIONS.WATCHLIST_PERFORMANCE_DATA:
      return {
        ...state,
        portfolioPerformance: action.payload.result,
        performanceRequest: action.payload.fetchRequest,
        singleAssetPerformance: null,
        singleAssetTiming: null
      }

    case ACTIONS.FETCH_ASSET:
      return {
        ...state,
        singleAssetPerformance: null,
        singleAssetTiming: null
      }

    case ACTIONS.ALBERTO_TIMING_DATA:
      return {
        ...state,
        albertoTimingData: action.payload.result,
        timingRequest: action.payload.fetchRequest
      }

    case ACTIONS.FETCH_TIMING_DATA:
      return {
        ...state,
        timingDiffData: action.payload.result
      }

    case ACTIONS.GET_BUCKET_DETAILS:
      return {
        ...state,
        bucketDetailsData: action.payload.result
      }

    case ACTIONS.GET_LOGS:
      return { ...state };

    case ACTIONS.UPDATE_WATCHLIST_VALUES:
      let newPerformanceData = state.portfolioPerformance;

      if (newPerformanceData) {
        newPerformanceData.return = action.payload[0];
        newPerformanceData.drawdown = action.payload[1];
        newPerformanceData.daystorecover = action.payload[2];
        newPerformanceData.sharpratio = action.payload[3];
      }

      return {
        ...state,
        portfolioPerformance: newPerformanceData,
      }

    case ACTIONS.UPDATE_WATCHLIST_BENCHMARK_VALUES:
      let newBenchmarkData = state.portfolioFamilyChartData;

      if (newBenchmarkData.performance) {
        newBenchmarkData.performance.return = action.payload[0];
        newBenchmarkData.performance.drawdown = action.payload[1];
        newBenchmarkData.performance.daystorecover = action.payload[2];
        newBenchmarkData.performance.sharpratio = action.payload[3];
      }

      return {
        ...state,
        portfolioFamilyChartData: newBenchmarkData,
      }

    case ACTIONS.UPDATE_WATCHLIST_COMPARE_VALUES:
      let newTimingData = state.portfolioTiming;

      if (newTimingData) {
        newTimingData.return = action.payload[0];
        newTimingData.drawdown = action.payload[1];
        newTimingData.daystorecover = action.payload[2];
        newTimingData.sharpratio = action.payload[3];
      }

      return {
        ...state,
        portfolioTiming: newTimingData
      }

    case ACTIONS.UPDATE_WATCHLIST_TIMING_VALUES:
      let newWatchlistTimingData = state.comparePortfolioTiming;

      if (newWatchlistTimingData) {
        newWatchlistTimingData.return = action.payload[0];
        newWatchlistTimingData.drawdown = action.payload[1];
        newWatchlistTimingData.daystorecover = action.payload[2];
        newWatchlistTimingData.sharpratio = action.payload[3];
      }

      return {
        ...state,
        comparePortfolioTiming: newWatchlistTimingData,
      }

    case ACTIONS.UPDATE_WATCHLIST_PERF_VALUES:
      let newWatchlistPerfData = state.compareportfolioPerformance;

      if (newWatchlistPerfData) {
        newWatchlistPerfData.return = action.payload[0];
        newWatchlistPerfData.drawdown = action.payload[1];
        newWatchlistPerfData.daystorecover = action.payload[2];
        newWatchlistPerfData.sharpratio = action.payload[3];
      }

      return {
        ...state,
        compareportfolioPerformance: newWatchlistPerfData
      }

    case ACTIONS.SAVE_WATCHLIST:
      return {
        ...state
      }

    case ACTIONS.GET_WATCHLIST:
      return {
        ...state,
        savedWatchlists: action.payload.result
      }

    case ACTIONS.GET_WATCHLIST_DATA:
      let watchlistData = action.payload.result.portfolio;

      let wAggregateAllocation = 0;
      watchlistData.holdings.forEach((asset) => {
        wAggregateAllocation += Math.abs(asset.allocation)
      })

      wAggregateAllocation = parseFloat(wAggregateAllocation.toFixed(3));

      let wAverageData = {
        vflow: parseFloat(watchlistData.vflow.toFixed(3)),
        vvol: parseFloat(watchlistData.vvol.toFixed(3)),
        maxpricedown: parseFloat(watchlistData.wtavgmaxpricedown.toFixed(3)),
        maxpriceup: parseFloat(watchlistData.wtavgmaxpriceup.toFixed(3)),
        pricemovementdown: parseFloat(watchlistData.wtavgpricemovementdown.toFixed(3)),
        pricemovementup: parseFloat(watchlistData.wtavgpricemovementup.toFixed(3)),
        riskprofilenumber: watchlistData.wtavgriskprofilenumber,
        symbol: 'Aggregate',
        allocation: wAggregateAllocation,
        stddevup: parseFloat(watchlistData.wtstddevup.toFixed(3)),
        stddevdown: parseFloat(watchlistData.wtstddevdown.toFixed(3))
      };

      // console.log(action.payload.result.portfolio.daily.performance)

      return {
        ...state,
        assetData: action.payload.result.portfolio.assets.map((ele, i) => {
          // console.log("portfolioReducer ele ->", ele)
          ele['allocation'] = Number(action.payload.result.portfolio.holdings[i].allocation);
          return ele;
        }),
        portfolioAssetChartData: action.payload.result.portfolio.daily,
        averageData: wAverageData,
        portfolioRequest: action.payload.fetchRequest,
        portfolio: action.payload.result.portfolio,
        holdings: action.payload.result.portfolio.holdings,
        timingDiffData: null,
        portfolioFamilyChartData: action.payload.result.benchmarkdaily,
        familyData: action.payload.result.benchmarkasset,
        portfolioTiming: action.payload.result.portfolio.daily.timings,
        portfolioPerformance: action.payload.result.portfolio.daily.performance,
        comparePortfolioTiming: action.payload.result.portfolio.daily.timings,
        compareportfolioPerformance: action.payload.result.portfolio.daily.performance,
      }

    case ACTIONS.GET_KOSHA_WATCHLISTS:
      return {
        ...state,
        koshaWatchlists: action.payload.result.portfolios
      }
      
    case ACTIONS.GET_USER_WATCHLISTS:
      return {
        ...state,
        userWatchlists: action.payload.result.portfolios
      }

    default:
      return state;
  }
}
