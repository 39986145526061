import React, { Component } from "react";
import { connect } from "react-redux";

import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";

import * as Moment from "moment";
import * as MomentTimeZone from "moment-timezone";

import "../charts/SynchChart.css";
import { MONTHS } from "../../utils/const";

import {
  sortAllocation,
  calculateSharpeValue,
  calculateDaysToRecoverAndDrawdown,
  calculateWinRate,
  formatFullDate,
  addZerosToDecimalPlace
} from "../../utils/helperFunctions";
import AnalyzeDataCard from "../asset/AnalyzeDataCard";
import Select from "react-select";
import { ExportToCsv } from "export-to-csv";

import {
  fetchPerformanceData,
  fetchTimingData,
  updateChartZoomValues
} from "../../actions/AssetAction";

import {
  getKoshaWatchlists,
  getTransactionSimulation,
  getTransactionSimulationSummary,
} from "../../actions/WatchlistTimingsAction";

import {Typography} from '@mui/material'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Popover from '@mui/material/Popover';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';



import styled from '@emotion/styled'
import { Button } from "react-bootstrap";

window.moment = Moment
MomentTimeZone();

class PercentageGainsChart extends Component {
  //name matching hashkey
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();

    this.state = {
      selectedValues: [],
      chartNames: [],
      portfolioIndex: null,
      chartValues: [],
      chartWithoutPortfolio: [],
      showPercentage: false,
      newZoomValues: [],
      zoomReset: "",
      singleLoading: false,
      showTransactionsModal: false,
      aboutId: "",
      calendarStartValue: Moment(-500000000000).format(),
      calendarEndValue: Moment(500000000000).format(),
      calendarStartBounds: {min: Moment(-500000000000).format(), max: Moment(500000000000).format()},
      calendarEndBounds: {min: Moment(-500000000000).format(), max: Moment(500000000000).format()},
      chartSeries: {},
      chartData: [],
      assetList: [],
      chartRoute: '',
      chartLabel: '',
      chartType: 'cumulative',
      chartCurrentMin: 0,
      chartCurrentMax: 0,
      benchmark: '',
      benchmarkValues:{
        return: 0,
        drawdown: 0,
        daysToRecover: 0,
        sharpe: 0,
        winRate: 0
      },
      watchlistTimingValues: {
        return: 0,
        drawdown: 0,
        daysToRecover: 0,
        sharpe: 0,
        winRate: 0
      },
      watchlistPerfValues: {
        return: 0,
        drawdown: 0,
        daysToRecover: 0,
        sharpe: 0,
        winRate: 0
      },
      displayChartDetails: true,
      seriesLength: 601, //Will be set after compileMoveData is called
      openPopover: false,
      popoverAnchorEl: null,
      options: {
        chart: {
          backgroundColor: "#37394d",
          height: "450px",
          zoomType: "x",
          alignAxes: true,
          // resetZoomButton: {
          //   position: {
          //     x: 0,
          //     y: -35,
          //     align: "left",
          //   },
          // },
          resetZoomButton: {
            theme: {
              display: 'none'
            }
          },
          events: {
            selection: this.onSelection,
          }
        },
  
        colors: [
          "#6c3bde",
          "#C03cde",

          "#fff59d",
          "#ffeb3b",

          "#945959",
          "#Bc8f8f",
        ],
  
        title: {
          useHTML: true,
          // this provides space for the tooltips:
          text: `<h3 class="vista-chart-title" style="height:100px"></h3>`,
        },
  
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
          gridLineColor: "#4b4d5e",
          gridLineDashStyle: "Dot",
          // endOnTick: true,
          // startOnTick: true,
          // minRange: 2678400000, //milliseconds in 31 days
          min: null,
          minRange: 2937600000,
          crosshair: {
            snap: false,
          },
          labels: {
            formatter: function () {
              let dateUTC = Moment.tz(this.value, 'UTC')
              return (
                `${MONTHS[dateUTC.month()]} ${dateUTC.date()}, <br /> ${dateUTC.year()}`
              );
            },
            enabled: true,
            style: {
              color: "#9ba2ab",
            },
          },
          events: {
            afterSetExtremes: this.afterSetExtremes
          },
        },
  
        tooltip: {
          shared: true,
          borderWidth: 0,
          backgroundColor: "none",
          headerFormat: "",
          shadow: false,
          useHTML: true,
          hideDelay: 5000,
          positioner: function () {
            return {
              x: (this.chart.chartWidth - this.label.width) / 2,
              y: -0, // align to title
            };
          },
          // formatter: this.formatTooltip,
          formatter: function(){
            // let s = "<div class=" + mainClass + ">";
            let s = "<div class=tooltip-single-main>";

            let date = new Date(this.points[0].x);
            let dateUTC = Moment.tz(date, "UTC")
            s += 
              `<div class="tooltip-header-date">
                ${MONTHS[dateUTC.month()]} ${dateUTC.date()}, ${dateUTC.year()}
              </div>
              <table style="border: 1px solid #4b4d5e">
                <tr>;`
  
            this.points.forEach((point) => {
              let title = point.series.name;
              s +=
                '<th class="tooltip-row"><span style="font-size: 18px;color:' +
                point.color +
                ';" >\u25CF </span > ' +
                title +
                "</th > ";
            });
            s += "</tr><tr>";

            this.points.forEach((point) => {
              s += '<td class="tooltip-row tooltip-center">' + point.y.toFixed(1) + "%</td>";
            });
            s += "</tr></table></div>";

            return s
          }
        },

        time: {
          useUTC: true,
        },
  
        yAxis: {
          visible: true,
          gridLineColor: "#4b4d5e",
          gridLineDashStyle: "Dot",
          plotLines: [
            {
              value: 0,
              color: "red",
              dashStyle: "solid",
              width: 1,
            },
          ],
          title: {
            enabled: false,
            text: "Percentage Gain",
          },
          labels: {
            formatter: function () {
              let data = addZerosToDecimalPlace(this.value,1)
              return `${data}%`
            },
            style: {
              color: "#9ba2ab",
            },
          },
          // min: calibratedMin,
          // max: calibratedMax,
          min:-10,
          max:400,
        },
  
        legend: {
          enabled: false,
        },
  
        credits: {
          enabled: false,
        },
  
        // series: chartData,
        series: this.state?.chartData,
  
        plotOptions: {
          area: {
            marker: {
              enabled: true,
              radius: 0,
            },
          },
          series: {
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            dataLabels: {
              allowOverlap: false,
              overflow: "none",
              crop: false,
            },
            turboThreshold: 5000,
            threshold: null,
            borderWidth: 0,
          },
        },
  
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
      }
    };

    this.style = {
      chips: {
        background: "#313345",
        border: "solid 1px #3bc796",
        color: "#3bc796",
      },
      multiselectContainer: {
        color: "#3bc796",
      },
    };
  }

  StyledRadio = styled(Radio)`
    color: #fff;
    bgcolor: #3BC796;
    font-size: 1.4em;
    // :hover {
    //   color: #000;
    // }

    // below doesn't work
    .Mui-checked {
      bgcolor: #3BC796;
      height: 1000px !important;
      width: 1000px;
      color: #3BC796 !important;
      outline: '5px auto #3BC796';
      outlineOffset: 2;
    },
  `

  StyledFormControlLabel = styled(FormControlLabel)`
    & .Mui-checked {
      color: #3bc796 !important;
    }
    & .MuiFormControlLabel-label {
      font-size: 0.8em;
    }
    `
  // StyledPopOver= styled(Popover)`
  //   &.MuiPopover-paper {
  //     bgcolor: #3BC796;
  //   }
  // `

  StyledTextField = styled(TextField)`
    & .MuiInputLabel-root{
      color: #fff;
    }
    & .MuiInputLabel-root.Mui-focused{
      color: #3bc796;
    }
    & .MuiOutlinedInput-root{
      color: #fff;
      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: #3bc796 !important;
      },
      & .MuiOutlinedInput-notchedOutline{
        border-color: #96a2b4;
        &:hover {
          border-color: #3bc796 !important;
        }
      }
    }
    & .MuiOutlinedInput-root.Mui-focused{
      & .MuiOutlinedInput-notchedOutline{
        border-color: #3bc796;
      }
    }
    & .MuiInputAdornment-root{
      & .MuiButtonBase-root{
        color: #3bc796;
      }
    }
  `

  // dropdown cover: #313345

  StyledDatePicker = styled(DatePicker)`
    color:#fff !important
    `

  componentDidMount(){
    this.getValues()
    this.setState({chartRoute: this.props.currentRoute})

    if(this.props.currentRoute === "asset"){
      this.setState({chartLabel: "Asset"}, () => {
        this.compileMoveData(-5000000000000000000000000000000000,5000000000000000000000000000000000)
      })
    } else {
      this.setState({chartLabel: "Watchlist"}, () => {
        this.compileMoveData(-5000000000000000000000000000000000,5000000000000000000000000000000000)
      })
    }
    // console.log(this.props)
    // set up calendar max/min values
    let calendarStartBoundsMin = Moment.utc(this.props.performance.timingsdata[0].timingsdatadetailslist[0].date)
    // let calendarStartBoundsMax = Moment(this.state.calendarEndValue)

    // let calendarEndBoundsMin = calendarStartBoundsMin
    let calendarEndBoundsMax = Moment.utc(this.props.performance.timingsdata[0].timingsdatadetailslist[this.props.performance.timingsdata[0].timingsdatadetailslist.length - 1].date)

    // console.log(Moment(series[0].data[0][0]).format(), Moment(series[0].data[0][0]), calendarStartBoundsMin)
    // console.log("calendarStartBounds -> ", calendarStartBoundsMin, calendarEndBoundsMax)
    // console.log("calendarEndBounds -> ", calendarStartBoundsMin, calendarEndBoundsMax)
    // console.log("calendar bounds to number string -> ", calendarStartBoundsMin.format("x"), calendarEndBoundsMax.format("x"))
    // console.log("Min x value on chart -> ", this.props.performance.timingsdata[0].timingsdatadetailslist[0].date)

    this.setState({
      calendarStartValue: calendarStartBoundsMin,
      calendarEndValue: calendarEndBoundsMax,
      calendarStartBounds: {min: calendarStartBoundsMin, max: calendarEndBoundsMax},
      calendarEndBounds: {min: calendarStartBoundsMin, max: calendarEndBoundsMax},
      options: {
        xAxis: {
          min: Date.parse(this.props.performance.timingsdata[0].timingsdatadetailslist[0].date),
          max: Date.parse(this.props.performance.timingsdata[0].timingsdatadetailslist[this.props.performance.timingsdata[0].timingsdatadetailslist.length - 1].date)
        }
      }
    }) 
  }

  onSelection = async (event) => {
    if (event.resetSelection) {
      // console.log("Reset chart -> compiling move data")
      this.compileMoveData(-5000000000000000000000000000000000,5000000000000000000000000000000000)
    }
  }

  handleResetZoom = (event) => {
    const [min, max] = this.compileMoveData(-5000000000000000000000000000000000,5000000000000000000000000000000000) //added optional min/max x values to compileMoveData
    this.chartRef.current.chart.xAxis[0].setExtremes(min, max) //manually set extremes
    //TODO: see if using chartCurrentMax, chartCurrentMin instead of this creates bugs?
  }

  onValueSelect = (selectedList, selectedItem) => {
    //TODO: see if using chartCurrentMax, chartCurrentMin instead of this creates bugs?
    if(selectedItem.action === 'select-option'){
      this.setState({assetList: [...this.state.assetList, selectedItem.option.value]}, () => {
        this.compileMoveData(
          this.state.options.series[0].data[0][0],
          this.state.options.series[0].data[this.state.options.series[0].data.length - 1][0])
      })
    } else if(selectedItem.action === 'remove-value'){
      this.setState({assetList: this.state.assetList.filter(asset => asset !== selectedItem.removedValue.value)}, () => {
        this.compileMoveData(
          this.state.options.series[0].data[0][0],
          this.state.options.series[0].data[this.state.options.series[0].data.length - 1][0])
      })
    }else if(selectedItem.action === 'clear'){
      this.setState({assetList: []}, () => {
        this.getValues("clear")
        this.compileMoveData(
          this.state.options.series[0].data[0][0],
          this.state.options.series[0].data[this.state.options.series[0].data.length - 1][0])
      })
    }
      
    // console.log('selectedList,', selectedList)
    // console.log('selectedItem,', selectedItem)
    let action = selectedItem.action;
    let chartValues = this.state.chartValues;
    let newChartValues = [];
    let chartWithoutPortfolio = this.state.chartWithoutPortfolio;
    let { tabName } = this.props;

    if (action === "clear") {


      // console.log("CLEAR", action)
      // this.getValues("clear");

      // if (tabName === "versus") this.props.getSinglePerformance(null);
    } else {
      if (action === "remove-value") {
        newChartValues = chartValues.filter((name) => {
          return name?.toUpperCase() !== selectedItem.removedValue.value;
        });

        if (selectedList && selectedList.length < 3) {
          chartWithoutPortfolio.forEach((value) => {
            value.isDisabled = false;
          });
        }
        // selectedListItem = true;
        // versusSingleData = false;
        this.setState(
          {
            selectedValues: selectedList,
            chartValues: newChartValues,
            chartWithoutPortfolio,
          },
          () => {
            if (tabName === "versus") this.props.getSinglePerformance(null);
          }
        );
      } else {
        chartValues.push(selectedItem.option.value);
        // selectedListItem = true;
        if (selectedList && selectedList.length === 3) {
          chartWithoutPortfolio.forEach((value) => {
            selectedList.forEach((symbol) => {
              if (value.label !== symbol.label) value.isDisabled = true;
            });
          });
        }
        if (tabName === "versus") {
          //clear single performance and timing data
          this.props.getSinglePerformance(null);
          // versusSingleData = false;
        }
        // }

        this.setState({
          selectedValues: selectedList,
          chartValues,
          chartWithoutPortfolio,
        });
      }
    }
  };

  getValues(clear) {
    const {
      tabName,
      data,
      compareData,
      holdings,
      assetSymbol,
      singleAssetPerformance
    } = this.props;
    let selectedValues = [];

    let chartData = data || compareData.percentagegainsdata;

    if (chartData) {
      let chartNames = chartData.map((value) => {
        return value.symbol;
      });

      if (tabName === "versus") {
        chartNames = [];
        chartNames.push("portfolio", "timing");
        if (singleAssetPerformance !== null && clear !== "clear") {
          let assetName = singleAssetPerformance?.percentagegainsdata[1]?.symbol.toUpperCase();
          chartNames.push(assetName);

          selectedValues.push({
            label: assetName,
            value: assetName,
            isDisabled: false,
          });
        }
      }

      let holdingsData = holdings.sort(sortAllocation);
      let assetSym = assetSymbol;

      if (holdingsData.length === 0) {
        holdingsData = [{ symbol: assetSym, allocation: "100" }];
      }

      let chartValues = [];
      let chartWithoutPortfolio = holdingsData.map((asset, index) => {
        if (index < 4)
          return {
            label: asset.symbol,
            value: asset.symbol,
            isDisabled: false,
          };
        else
          return {
            label: asset.symbol,
            value: asset.symbol,
            isDisabled: false,
          };
      });

      let chartNamesWithoutPortfolio = holdingsData.map((asset) => {
        return asset.symbol;
      });

      // console.log("chartNamesWithoutPortfolio", chartNamesWithoutPortfolio)

      if (chartNamesWithoutPortfolio.length > 4) {
        chartValues = chartNamesWithoutPortfolio.slice(0, 4);
      } else {
        chartValues = chartNamesWithoutPortfolio;
      }

      if (tabName === "performance") chartValues = ["portfolio"];
      else if (tabName === "timing") chartValues = ["portfolio"];
      else chartValues = ["portfolio", "timing"];

      this.setState(
        {
          selectedValues,
          chartValues,
          chartWithoutPortfolio,
          // chartNames,
        },
        () => {
          if (
            tabName === "versus" &&
            singleAssetPerformance !== null &&
            clear !== "clear"
          ) {
            let selectedItem = {
              action: "select-option",
              name: undefined,
              option: selectedValues[0],
            };

            this.onValueSelect(selectedValues, selectedItem);
          }
        }
      );
    }
  };

  onFullView = () => {
    this.props.showChartInModal("percentageGains");
  };

  goBack = () => {
    this.props.goBack();
  };

  //Passing in series where each line contains a data (moves), and calculatedMoves (calculatedMoves)
  getChartBoxDetails(series) {
    const { familyChartData } = this.props;
    let benchmarkSymbol = `${familyChartData.performance.percentagegainsdata[1].symbol.toUpperCase()}`;

    //Displays 'N/A' for less than 120 days -> helperFunctions.js.
    series.forEach((line, index) => {
      const [calculatedDaysToRecover, calculatedDrawdown] = calculateDaysToRecoverAndDrawdown(line.data, line.calculatedMoves, this.state.chartType)

      if(line.name.includes(benchmarkSymbol)){
        this.setState({benchmarkValues: {
          return: line.calculatedMoves[line.calculatedMoves.length - 1][1],
          daysToRecover: calculatedDaysToRecover,
          drawdown: calculatedDrawdown,
          sharpe: calculateSharpeValue(line.data),
          winRate: calculateWinRate(line.data)
        }})
      } else if(line.name.includes("Watchlist Return") || line.name.includes("Asset Return")){
        this.setState({watchlistPerfValues: {
          return: line.calculatedMoves[line.calculatedMoves.length - 1][1],
          daysToRecover: calculatedDaysToRecover,
          drawdown: calculatedDrawdown,
          sharpe: calculateSharpeValue(line.data),
          winRate: calculateWinRate(line.data)
        }})
      } else if(line.name.includes("Watchlist Timing") || line.name.includes("Asset Timing")){
        this.setState({watchlistTimingValues: {
          return: line.calculatedMoves[line.calculatedMoves.length - 1][1],
          daysToRecover: calculatedDaysToRecover,
          drawdown: calculatedDrawdown,
          sharpe: calculateSharpeValue(line.data),
          winRate: calculateWinRate(line.data)
        }})
      }
    })
  }

  downloadData = (chart) => {
    let performance = [];
    let startDate = "";
    let endDate = "";
    let filename = "Kosha-Watchlist-Chart-Data-";

    this.state.options.series.forEach((series, index) => {
      // console.log(series)
      let valueName = series.name
      series.data.forEach((data, key) => {
        if(performance[key]){
          performance[key][valueName] = data[1]
        } else{
          performance[key] = {
            Date: formatFullDate(data[0]),
            [valueName]: data[1]
          }
        }
      })
    })

    startDate = performance[0].Date.replace(" ","-")
    endDate = performance[performance.length - 1].Date.replace(" ","-")
    filename = "Kosha-Watchlist-Chart-Data-" + startDate + "-to-" + endDate;

    const exportOptions = {
      fieldSeparator: ",",
      quoteStrings: "",
      decimalSeparator: ".",
      filename: filename,
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(exportOptions);
    // console.log("performance values on download", performance, performance[performance.length-1])

    csvExporter.generateCsv(performance);
  };

  afterSetExtremes = (e) => {
    // console.log(e)
    if(e.trigger === "zoom" ) {
      this.compileMoveData(e.min, e.max)
    }
  }

  //TODO: take in array of symbols and loop through to plot graphs?
  compileMoveData = (min, max) => {
    this.setState({chartCurrentMax: max, chartCurrentMin: min})
    console.log("compile move data min/max -> ", min, max)

    //todo: cleanup, can get rid of duplicate code and set name dynamically based on props array being used
    if(min == null || max == null) return
    let series = []
    let dataArray = []
    let seriesMin = 0
    let seriesMax = 0
    //Watchlist Return Data
    if(this.props.tabName === "versus" || this.props.tabName === "performance"){
      let data = []
      let lineMin = 0
      let lineMax = 0
      this.props.performance.percentagegainsdata[0].performancedatalist.forEach((point) => {
        let pointDateTime = new Date(point.date).getTime()
        if(pointDateTime <= max && pointDateTime >= min){
          data.push([new Date(point.date).getTime(), point.move])
        }
      })
      let calculatedMoves = []
      if (this.state.chartType === 'compounded'){
        calculatedMoves = this.calculateCompoundedMoves(data)
      }else{
        calculatedMoves = this.calculateCumulativeMoves(data)
      }
      calculatedMoves.forEach((point) => {
        if(point[1] > lineMax) lineMax = point[1]
        if(point[1] < lineMin) lineMin = point[1]
      })
      dataArray.push({
        name: `${this.state.chartLabel} Return`,
        data:data,
        calculatedMoves: calculatedMoves
      })
      series.push({
        name: `${this.state.chartLabel} Return`,
        id: `${this.state.chartLabel}_Return`,
        // color: "#29916c",
        // color: "#3cb0c7",
        color: "#d5f2d8",
        data: calculatedMoves,
        visible: true,
        lineWidth: 1,
        min: lineMin,
        max: lineMax
      })
      if(lineMin < seriesMin) seriesMin = lineMin
      if(lineMax > seriesMax) seriesMax = lineMax
      this.state.assetList.forEach((asset) => {
        if(asset !== "SPY"){
          let data = []
          let lineMin = 0
          let lineMax = 0
          let performanceData = this.props.performance.percentagegainsdata.filter((percentageGainsDataAsset) => percentageGainsDataAsset.symbol === asset)
          performanceData[0].performancedatalist.forEach((point) => {
            let pointDateTime = new Date(point.date).getTime()
            if(pointDateTime <= max && pointDateTime >= min){
              data.push([new Date(point.date).getTime(), point.move])
            }
          })
          let calculatedMoves = []
          if (this.state.chartType === 'compounded'){
            calculatedMoves = this.calculateCompoundedMoves(data)
          }else{
            calculatedMoves = this.calculateCumulativeMoves(data)
          }
          calculatedMoves.forEach((point) => {
            if(point[1] > lineMax) lineMax = point[1]
            if(point[1] < lineMin) lineMin = point[1]
          })
          series.push({
            name: `${asset} Return`,
            id: `${asset}_Return`,
            data: calculatedMoves,
            visible: true,
            lineWidth: 1,
            min: lineMin,
            max: lineMax
          })
          if(lineMin < seriesMin) seriesMin = lineMin
          if(lineMax > seriesMax) seriesMax = lineMax
        }
      })
    }
    //Watchlist/Asset Timings Data
    if(this.props.tabName === "versus" || this.props.tabName === "timing"){
      let data = []
      let lineMin = 0
      let lineMax = 0
      this.props.performance.timingsdata[0].timingsdatadetailslist.forEach((point) => {
        let pointDateTime = new Date(point.date).getTime()
        if(pointDateTime <= max && pointDateTime >= min){
          data.push([new Date(point.date).getTime(), point.move])
        }
      })
      // console.log("timings data before push", data)
      let calculatedMoves = []
      if (this.state.chartType === 'compounded'){
        calculatedMoves = this.calculateCompoundedMoves(data)
      }else{
        calculatedMoves = this.calculateCumulativeMoves(data)
      }
      calculatedMoves.forEach((point) => {
        if(point[1] > lineMax) lineMax = point[1]
        if(point[1] < lineMin) lineMin = point[1]
      })
      // console.log("timings compondeddata before push", calculatedMoves)
      dataArray.push({
        name: `${this.state.chartLabel} Timing Model Return`,
        data:data,
        calculatedMoves: calculatedMoves
      })
      series.push({
        name: `${this.state.chartLabel} Timing Model Return`,
        id: `${this.state.chartLabel}_Timing_Model_Return`,
        color: "#3BC796",
        data: calculatedMoves,
        visible: true,
        lineWidth: 1,
        min: lineMin,
        max: lineMax
      })
      // console.log("min",min)
      if(lineMin < seriesMin) seriesMin = lineMin
      if(lineMax > seriesMax) seriesMax = lineMax

      this.state.assetList.forEach((asset) => {
        let data = []
        let lineMin = 0
        let lineMax = 0
        let timingsData = this.props.performance.timingsdata.filter((timingsDataAsset) => timingsDataAsset.symbol === asset)
        timingsData[0].timingsdatadetailslist.forEach((point) => {
          let pointDateTime = new Date(point.date).getTime()
          if(pointDateTime <= max && pointDateTime >= min){
            data.push([new Date(point.date).getTime(), point.move])
          }
        })
        let calculatedMoves = []
        if (this.state.chartType === 'compounded'){
          calculatedMoves = this.calculateCompoundedMoves(data)
        }else{
          calculatedMoves = this.calculateCumulativeMoves(data)
        }
        calculatedMoves.forEach((point) => {
          if(point[1] > lineMax) lineMax = point[1]
          if(point[1] < lineMin) lineMin = point[1]
        })
        if(asset === this.state.benchmark){
          series.push({
            name: `${asset} Timing Model Return`,
            id: `${asset}_Timing_Model_Return`,
            color: "#dfd5f2",
            data: calculatedMoves,
            visible: true,
            lineWidth: 1,
            min: lineMin,
            max: lineMax
          })
        }else{
          series.push({
            name: `${asset} Timing Model Return`,
            id: `${asset}_Timing_Model_Return`,
            data: calculatedMoves,
            visible: true,
            lineWidth: 1,
            min: lineMin,
            max: lineMax
          })
        }
        if(lineMin < seriesMin) seriesMin = lineMin
        if(lineMax > seriesMax) seriesMax = lineMax
      })
    }
    //SPY Return for each chart
    let data = []
    let lineMin = 0
    let lineMax = 0
    this.props.familyChartData.performance.percentagegainsdata[1].performancedatalist.forEach((point) => {
      let pointDateTime = new Date(point.date).getTime()
      if(pointDateTime <= max && pointDateTime >= min){
        data.push([new Date(point.date).getTime(), point.move])
      }
    })
    let benchmark = this.props.familyChartData.performance.percentagegainsdata[1].symbol
    this.setState({benchmark: benchmark})
    // console.log("data before push", data)
    let calculatedMoves = []
    if (this.state.chartType === 'compounded'){
      calculatedMoves = this.calculateCompoundedMoves(data)
    }else{
      calculatedMoves = this.calculateCumulativeMoves(data)
    }
    calculatedMoves.forEach((point) => {
      if(point[1] > lineMax) lineMax = point[1]
      if(point[1] < lineMin) lineMin = point[1]
    })
    // Make Benchmark name dynamic for analyze asset page.
    dataArray.push({
      name: `${benchmark} Return`,
      data:data,
      calculatedMoves: calculatedMoves
    })
    series.push({
      name: `${benchmark} Return`,
      id: `${benchmark}_Return`,
      // color: "#FFCC98",
      // color: "#d4eef2",
      color: "#fa8828",
      data: calculatedMoves,
      visible: true,
      lineWidth: 1,
      min: lineMin,
      max: lineMax
    })
    if(lineMin < seriesMin) seriesMin = lineMin
    if(lineMax > seriesMax) seriesMax = lineMax

    let monthsDisplayed = Moment(series[0].data[series[0].data.length - 1][0]).diff(Moment(series[0].data[0][0]), 'months')
    // console.log(series[0].data[0][0], series[0].data[series[0].data.length - 1][0])
    // console.log(Moment(series[0].data[0][0]).format())
    // console.log("Setting new Date Ranges -> Start: ", this.state.calendarStartValue, this.state.calendarEndValue)

    this.setState({
      calendarStartValue:Moment.utc(series[0].data[0][0]).format(),
      calendarEndValue:Moment.utc(series[0].data[series[0].data.length - 1][0]).format(),
      calendarStartBounds: {...this.state.calendarStartBounds, max: Moment.utc(series[0].data[series[0].data.length - 1][0]).format()},
      calendarEndBounds: {...this.state.calendarEndBounds, min: Moment.utc(series[0].data[0][0]).format()},
      displayChartDetails: monthsDisplayed >= 6,
      seriesLength: series[0].data.length, 
      options: {
        series: series,
        yAxis: {
          min: seriesMin,
          max: seriesMax
        }
      }
    }, () =>{
      // this.onStartDatePickerChange(this.state.startValue)
      this.onEndDatePickerChange(Moment.utc(this.state.calendarEndValue))
      // console.log("Setting new Date Ranges -> End: ", this.state.calendarStartValue, this.state.calendarEndValue)
    })
    this.getChartBoxDetails(dataArray)
    return [series[0].data[0][0], series[0].data[series[0].data.length - 1][0]]
  }

  calculateCompoundedMoves = (data) => {
    let compoundedValues = []
    data.forEach((d, index) => {
      //if move is -1, all values are 0
      let calculatedValue = 0
      if(index === 0) {
        calculatedValue = 0
      } else {
        calculatedValue = (parseFloat(d[1])+1)*(parseFloat(compoundedValues[index-1][1])+1)-1
      }
      compoundedValues.push([d[0], parseFloat(calculatedValue)])
    })

    if (compoundedValues.length !== 0) {
      compoundedValues.forEach((data,i ) => {
        compoundedValues[i][1] = compoundedValues[i][1]*100;
      })
      return compoundedValues 
    }
  }

  calculateCumulativeMoves = (data) => {
    // console.log("CUMULATIVE MOVES BEING CALCULATED", data)
    let cumulativeValues = []
    data.forEach((d, index) => {
      let calculatedValue = 0
      if (index === 0){
        calculatedValue = 0
      }else{
        calculatedValue = cumulativeValues[index-1][1]+ d[1]
      }
      cumulativeValues.push([d[0], parseFloat(calculatedValue)])
    })

    cumulativeValues.forEach((data, index) => {
      cumulativeValues[index][1] = cumulativeValues[index][1]*100
    })
    return cumulativeValues 
  }

  handleChangeChartType = () => {
    if(this.state.chartType === "compounded"){
      // console.log("changing state: compounded -> cumulative")
      this.setState({chartType: "cumulative"}, 
        () => this.compileMoveData(this.state.chartCurrentMin, this.state.chartCurrentMax))
    }else{
      // console.log("changing state: cumulative -> compounded")
      this.setState({chartType: "compounded"}, 
        () => this.compileMoveData(this.state.chartCurrentMin, this.state.chartCurrentMax))
    }
  }

  onTransactionsClick = () => {
    this.props.onTransactionsClick();
  };

  handleOpenPopover = (event) => {
    // bugs when both setStates are put in the same this.setState
    this.setState((prevState) => ({openPopover: !prevState.openPopover}))
    this.setState({popoverAnchorEl: event.currentTarget})
  }

  handleClosePopover = () => {
    this.setState((prevState) => ({openPopover: !prevState.openPopover}))
    // this.setState({popoverAnchorEl: null})
  }

  onStartDatePickerChange = (newValue) => {
    let dateDiff = Moment.utc(this.state.calendarEndValue).diff(Moment.utc(newValue), 'months')
    if(Moment.utc(newValue)._isValid && dateDiff >= 1){
      // console.log("newValue -> ", newValue)
      this.setState({calendarStartValue: Moment.utc(newValue)}, () =>{
        const [min, max] = this.compileMoveData(Moment.utc(this.state.calendarStartValue).valueOf(), Moment.utc(this.state.calendarEndValue).valueOf())
        this.chartRef.current.chart.xAxis[0].setExtremes(min, max) //manually set extremes
      })
    }
  }

  onEndDatePickerChange = (newValue, shouldCompileMoveData=false) => {
    // console.log(shouldCompileMoveData)
    // TODO: if date is less than a month apart, display red borders on inputs and display message to user asking to pick new vals
    let dateDiff = Moment.utc(newValue).diff(Moment.utc(this.state.calendarStartValue), 'months')
    if(Moment.utc(newValue)._isValid && dateDiff >= 1){
      // console.log("newValue, keyboardInputVal -> ", newValue)
      this.setState({calendarEndValue: Moment.utc(newValue)}, () =>{
        // console.log("after onchange setstate end value -> ", this.state.calendarEndValue)
        if(shouldCompileMoveData){
          const [min, max] = this.compileMoveData(Moment.utc(this.state.calendarStartValue).valueOf(), Moment.utc(this.state.calendarEndValue).valueOf())
          this.chartRef.current.chart.xAxis[0].setExtremes(min, max) //manually set extremes
        }
      })
    }
  }

  isWeekday = (date) => {
    // disable weekends
    // TODO: Disable market holidays too
    return (Moment.utc(date).day()===0 || Moment.utc(date).day()===6)
  }

  render() {
    const { selectedValues, chartValues, chartWithoutPortfolio } = this.state;

    let chartData = [];

    // if (chartValues.length === 0) {
    //   this.getValues();
    // }

    return (
      <>
        <div className="white-box p-0 percentage-gains-white-box p-t-10">
          <div className="row chart-title">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <h3 className="box-title" style={{marginBottom: "0px"}}>
              {this.props.tabName === "versus" && <div className="other-chart-values">
                <AnalyzeDataCard
                  title="Days to Recover"
                  assetValue={this.state.watchlistPerfValues.daysToRecover}
                  familyValue={this.state.watchlistTimingValues.daysToRecover}
                  assetSymbol={this.state.chartLabel}
                  familySymbol="Timing"
                  benchmarkValue={this.state.benchmarkValues.daysToRecover}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Drawdown %"
                  assetValue={this.state.watchlistPerfValues.drawdown}
                  familyValue={this.state.watchlistTimingValues.drawdown}
                  assetSymbol={this.state.chartLabel}
                  familySymbol="Timing"
                  benchmarkValue={this.state.benchmarkValues.drawdown}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Return %"
                  assetValue={this.state.watchlistPerfValues.return}
                  familyValue={this.state.watchlistTimingValues.return}
                  assetSymbol={this.state.chartLabel}
                  familySymbol="Timing"
                  benchmarkValue={this.state.benchmarkValues.return}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {true}
                />
                <AnalyzeDataCard
                  title="Win Rate %"
                  assetValue={this.state.watchlistPerfValues.winRate}
                  familyValue={this.state.watchlistTimingValues.winRate}
                  assetSymbol={this.state.chartLabel}
                  familySymbol="Timing"
                  benchmarkValue={this.state.benchmarkValues.winRate}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Sharpe Ratio"
                  assetValue={this.state.watchlistPerfValues.sharpe}
                  familyValue={this.state.watchlistTimingValues.sharpe}
                  assetSymbol={this.state.chartLabel}
                  familySymbol="Timing"
                  benchmarkValue={this.state.benchmarkValues.sharpe}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
              </div>}
              {this.props.tabName === "performance" && <div className="other-chart-values">
                <AnalyzeDataCard
                  title="Days to Recover"
                  assetValue={this.state.watchlistPerfValues.daysToRecover}
                  assetSymbol={this.state.chartLabel}
                  benchmarkValue={this.state.benchmarkValues.daysToRecover}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Drawdown %"
                  assetValue={this.state.watchlistPerfValues.drawdown}
                  assetSymbol="Watchlist"
                  benchmarkValue={this.state.benchmarkValues.drawdown}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Return %"
                  assetValue={this.state.watchlistPerfValues.return}
                  assetSymbol={this.state.chartLabel}
                  benchmarkValue={this.state.benchmarkValues.return}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {true}
                />
                <AnalyzeDataCard
                  title="Win Rate %"
                  assetValue={this.state.watchlistPerfValues.winRate}
                  assetSymbol={this.state.chartLabel}
                  benchmarkValue={this.state.benchmarkValues.winRate}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Sharpe Ratio"
                  assetValue={this.state.watchlistPerfValues.sharpe}
                  assetSymbol={this.state.chartLabel}
                  benchmarkValue={this.state.benchmarkValues.sharpe}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
              </div>}
              {this.props.tabName === "timing" && <div className="other-chart-values">
                <AnalyzeDataCard
                  title="Days to Recover"
                  assetValue={this.state.watchlistTimingValues.daysToRecover}
                  assetSymbol={"Timing"}
                  benchmarkValue={this.state.benchmarkValues.daysToRecover}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Drawdown %"
                  assetValue={this.state.watchlistTimingValues.drawdown}
                  assetSymbol={"Timing"}
                  benchmarkValue={this.state.benchmarkValues.drawdown}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Return %"
                  assetValue={this.state.watchlistTimingValues.return}
                  assetSymbol={"Timing"}
                  benchmarkValue={this.state.benchmarkValues.return}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {true}
                />
                <AnalyzeDataCard
                  title="Win Rate %"
                  assetValue={this.state.watchlistTimingValues.winRate}
                  assetSymbol={"Timing"}
                  benchmarkValue={this.state.benchmarkValues.winRate}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
                <AnalyzeDataCard
                  title="Sharpe Ratio"
                  assetValue={this.state.watchlistTimingValues.sharpe}
                  assetSymbol={"Timing"}
                  benchmarkValue={this.state.benchmarkValues.sharpe}
                  benchmarkSymbol={this.state.benchmark}
                  currentRoute={"watchlist"}
                  displayChartData = {this.state.displayChartDetails}
                />
              </div>}
              </h3>
            </div>
            {/* {this.props.showMultiSelect && ( */}
              <div className="percentage-gains-chart-div" style={{display:"flex", alignItems: "center", justifyContent: "flex-start", width: "95%", margin: "0 2.5% 1rem"}}>

                {/* <div  style={{display: "flex", justifyContent: "end", fontSize: "0.8em" }}> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  {/* <Typography sx={{marginRight: '1rem'}}>Date Range: </Typography> */}
                  <this.StyledDatePicker
                    label="Start Date"
                    shouldDisableDate={this.isWeekday}
                    className="highcharts-container"
                    value={Moment.utc(this.state.calendarStartValue)}
                    minDate ={Moment.utc(this.state.calendarStartBounds.min)}
                    maxDate={Moment.utc(this.state.calendarStartBounds.max).subtract(1, 'month').startOf('day')}
                    allowSameDateSelection
                    // TODO: needs error handling if dates are less than one month apart
                    onChange={this.onStartDatePickerChange}
                    PaperProps={{className:"date-picker-paper"}}
                    renderInput={(params) => <this.StyledTextField {...params} />}
                  />
                  <Typography sx={{marginRight: '1rem', marginLeft: '1rem'}}>To</Typography>
                  <this.StyledDatePicker
                    label="End Date"
                    shouldDisableDate={this.isWeekday}
                    value={Moment.utc(this.state.calendarEndValue)}
                    minDate={Moment.utc(this.state.calendarEndBounds.min).add(1, 'month').startOf('day')}
                    maxDate={Moment.utc(this.state.calendarEndBounds.max)}
                    allowSameDateSelection
                    onChange={(newVal) => this.onEndDatePickerChange(newVal, true)}
                    renderInput={(params) => <this.StyledTextField {...params} />}
                  />
                  {this.state.seriesLength < 600 && <Button className="btn-outline waves-effect waves-light reset-button" onClick={this.handleResetZoom} style={{marginLeft: '1rem'}}>Reset Zoom</Button>}
                  {/* reset zoom placeholder */}
                  {!(this.state.seriesLength < 600) && <span style={{fontSize: "0.8em", margin: "0 auto 0 0", minWidth: "1px"}}/>}
                </LocalizationProvider>
                {this.props.showMultiSelect && ( <Select
                  className="react-select-container asset-select-container"
                  onChange={this.onValueSelect}
                  options={chartWithoutPortfolio}
                  isMulti={true}
                  isSearchable={true}
                  placeholder="Select Symbols"
                  value={selectedValues}
                />)}

                <div className="tooltipx">
                  <Button
                    onClick={this.handleOpenPopover}
                    className=" btn-outline waves-effect waves-light popover-button"
                  >
                    {/* <MoreVertOutlinedIcon /> */}
                    <span style={{fontSize: "0.8em"}}>More Options <ArrowDropDownOutlinedIcon /></span>
                  </Button>

                  <Popover
                    open={this.state.openPopover}
                    onClose={this.handleClosePopover}
                    anchorEl={this.state.popoverAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    disableEnforceFocus={true}
                    container={this.state.popoverAnchorEl?.parentNode}
                    style={{margin: "5px", color: "#fff"}}
                  >
                    <div style={{margin:"5px", display: "flex", flexDirection: "column"}}>
                      <FormControl style={{borderBottom: "2px solid #4b4d5e"}}>
                        <Typography sx={{fontSize: "1em", color: "#fff", margin: "1rem 0 0.5rem 1rem"}}>Chart Type:</Typography>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={this.state.chartType}
                          onChange={this.handleChangeChartType.bind(this)}
                          style={{margin: "0 0 0.5rem auto", paddingRight: "1rem"}}
                        >
                          <this.StyledFormControlLabel value="cumulative" control={<this.StyledRadio />} label="Cumulative" style={{marginBottom: '0px', color: "#fff"}}/>
                          <this.StyledFormControlLabel value="compounded" control={<this.StyledRadio />} label="Compounded" style={{marginRight: '0px', marginBottom: '0px', color: "#fff"}}/>
                        </RadioGroup>
                      </FormControl>
                      <Button
                        onClick={this.onTransactionsClick.bind(this)}
                        className=" btn-outline waves-effect waves-light"
                        style={{fontSize:"0.8em", margin:"8px 8px 4px 8px"}}
                      >
                        <i className="fa fa-download"></i> Download Transactions
                      </Button>
                      <Button
                        onClick={this.downloadData.bind(this, chartData)}
                        className=" btn-outline waves-effect waves-light"
                        style={{fontSize:"0.8em", margin:"4px 8px 8px 8px"}}
                      >
                        <i className="fa fa-download"></i> Export Chart To CSV
                      </Button>
                    </div>
                  </Popover>
                  <span className="tooltiptext">More Options</span>
                </div>
              </div>
              {/* </div>)} */}
              {/* TODO: make this and corresponding asterisks white */}
              {(!this.state.displayChartDetails) && (<div className="col-md-12 col-sm-12 col-xs-12"><p style={{marginBottom: '1rem', marginLeft: '1rem'}}>*Details are not available for periods less than 6 months.</p></div>)}
            
          </div>
          <div className="row" 
            // The React Datepicker input component does not lose blur when clicking on highcharts
            // This is a workaround as the input does not visually update if it is in focus and the 
            // onSelection event is triggered.
            onMouseDown={() => document.activeElement.blur()}
            >
            {
              <div className="col-md-12" id="percentage-gains-div">
                <HighchartsReact
                  id={0}
                  className={"highcharts-container"}
                  highcharts={Highcharts}
                  constructorType={"chart"}
                  options={this.state.options}
                  ref={ this.chartRef }
                />
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  singleAssetPerformance: state.PortfolioReducer.singleAssetPerformance,
  singleAssetTiming: state.PortfolioReducer.singleAssetTiming,
  portfolioTiming: state.PortfolioReducer.portfolioTiming,
  portfolioPerformance: state.PortfolioReducer.portfolioPerformance,
  portfolioFamilyChartData: state.PortfolioReducer.portfolioFamilyChartData,
  zoomedChartSeries: state.AssetReducer.zoomedChartSeries
});

export default connect(mapStateToProps, {
  fetchTimingData,
  fetchPerformanceData,
  getTransactionSimulation,
  getTransactionSimulationSummary,
  getKoshaWatchlists,
  updateChartZoomValues,
})(PercentageGainsChart);