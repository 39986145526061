import React, { Component } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { currBranch } from '../../../utils/const';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SelectTable from '../SelectTable/SelectTable'

export class RegenerateWatchlistsModal extends Component {
    constructor(props) {
		super(props);

		this.state = {
			reason: 'No reason given.',
			otherReason: '',
		};
	}
	
	onConfirm = () => {
		// Call API
		// this.setState({showConfirmationModal: true})
		this.props.setConfirmAllOrSelected('all')
		this.props.setMessage(`${this.state.reason}${this.state.reason === "Other" ? `: ${this.state.otherReason}`: ''}`)
		this.props.setConfirmationMessage(`
			Regenerate Watchlists.
			Reason: ${this.state.reason === '' ? "No Reason Given": this.state.reason}
			${this.state.reason === "Other" ? `Additional Message to be included in Email: ${this.state.otherReason}`: ''}`)
		this.props.setConfirmType('watchlist')
		this.props.onClose()
		this.props.showConfirmSelectionModal()
	}

    render(){
		return(
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				>
				<Box sx={this.props.style}>
					<Box sx={{marginBottom: '10px'}}>
						<Typography sx={{fontSize: '1.6em !important'}}>Regenerate Watchlists on {currBranch}</Typography>

                        {/* <SelectTable /> */}

						<FormControl onSubmit={this.onConfirm}>
							<FormLabel sx={{color: "white", fontSize: '1em'}}><Typography sx={{fontSize: '1em !important'}}>Select the reason for this change:</Typography></FormLabel>
							<RadioGroup
								aria-labelledby="radio-buttons-group-label-branches"
								defaultValue= 'No reason given.'
								name="radio-buttons-group-branches"
								value={this.state.reason}
								onChange={(event) => this.setState({reason: event.currentTarget.value})}
							>
								<FormControlLabel value="No reason given." control={<Radio />} label="No Reason" sx={{fontSize: '1em !important'}}/>
								<FormControlLabel value="Today, the Failsafe has been triggered and all symbols have been set to Neutral." control={<Radio />} label="Today, the Failsafe has been triggered and all symbols have been set to Neutral." />
								<FormControlLabel value="Other" control={<Radio />} label="Other Reason" />
							</RadioGroup>
							<TextareaAutosize
								disabled={this.state.reason!=="Other"}
								aria-label="empty textarea"
								placeholder="Empty"
								style={{ width: 200, marginLeft: '22px' }}
								value={this.state.otherReason}
								onChange={(event) => this.setState({otherReason: event.currentTarget.value})}
							/>
						</FormControl>
					</Box>

					<Box sx={{display: 'flex', justifyContent: 'space-between', margin: 'auto'}}>
						<button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.onConfirm}>Confirm</button>
						<button className="btn waves-effect waves-light m-b-5 m-t-10" onClick={this.props.onClose}>Cancel</button>
					</Box>
				</Box>
			</Modal>
		)
	}
}

export default RegenerateWatchlistsModal