import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ReactJson from 'react-json-view';

class ApiModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.setState({
            show: this.props.show
        });
    }

    close = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            show: false
        });
    }

    getApiDetails() {
        let details = this.props.apiDetails;
        if (Array.isArray(details)) {
            return this.props.apiDetails.map((detail, index) => {
                return (
                    <div key={`api_detail_${index}`}>
                        <div className="api-details-body">
                            <div className="api-details-header">Request {index + 1}</div>
                            <ReactJson
                                src={detail}
                                theme="harmonic"
                                collapsed={true}
                            />
                        </div>
                        <div className="api-details-body">
                            <div className="api-details-header">Response {index + 1}</div>
                            <ReactJson
                                src={this.props.apiResponse[index] || this.props.errorJson}
                                theme="harmonic"
                                collapsed={true}
                            />
                        </div>
                    </div>
                )
            })
        }
        else {
            return (
                <>
                    <div className="api-details-body">
                        <div className="api-details-header">Request 1</div>
                        <ReactJson
                            src={details}
                            theme="harmonic"
                            collapsed={true}
                        />
                    </div>
                    <div className="api-details-body">
                        <div className="api-details-header">Response 1</div>
                        <ReactJson
                            src={this.props.errorJson || this.props.apiResponse}
                            theme="harmonic"
                            collapsed={true}
                        />
                    </div>
                </>
            )
        }
    }

    render() {
        const { show, apiDetails, screen } = this.props;
        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : "";
        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass}
                    id="api-details-modal"
                    style={{ display: display }}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header b-0 p-l-30 p-t-30 p-r-30">

                                <h4 className="modal-title">API Details
                                    {
                                        apiDetails.length !== 0 ?
                                            <Link to={{
                                                pathname: "/logs?page=" + screen,
                                            }}
                                                target="_blank"
                                                className="logs-link"
                                            >
                                                <i className="fa fa-external-link external-link-img"></i>
                                            </Link>
                                            : null
                                    }

                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>
                            {
                                apiDetails.length !== 0 ?
                                    this.getApiDetails()
                                    :
                                    <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30" id="api-details-modal-body">
                                        <div className="api-details-header api-details-error">No Details to show</div>
                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ApiModal
