import React from 'react';
import { formatDate } from '../../utils/helperFunctions';
import HeatMap from '../heatMap/HeatMap';

export default function AssetDataCard({ title, sentiment, sentimentValue, chartData, assetClick }) {
    function getAsset(e) {
        e.preventDefault();
        assetClick(title);
    }

    return (
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" id="market-div-body">
            <div className="market-div">
                <div className="py-5">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <h3
                                className="box-data-title home-page-card-title"
                                onClick={getAsset}
                            >
                                <span className="card-title-text" id="asset-name-title">{title}</span>
                            </h3>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                            <div className="box-data-title home-page-card-title">
                                <>
                                    <div className="sentiment-gradient-div">
                                        <div className="sentiment-indicator" id={sentiment}></div>
                                    </div>
                                    <h3 className="box-title m-t-10" id="sentiment">{sentimentValue}</h3>
                                </>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
                {
                    chartData &&
                    <div className="heat-map-div">
                        <div className="heat-map-header">
                            <h3 className="box-data-title heat-map-date-title">{formatDate(chartData[chartData.length - 1].date)}</h3>
                            <h3 className="box-data-title heat-map-title">VFLOW</h3>
                            <h3 className="box-data-title heat-map-date-title">{formatDate(chartData[0].date)}</h3>
                        </div>
                        <HeatMap data={chartData} />
                    </div>
                }

            </div>
        </div >
    )
}