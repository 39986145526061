import React, { Component } from 'react';
import Dropdown, {
    MenuItem,
} from '@trendmicro/react-dropdown';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';

class TutorialModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            currentIndex: 0,
            nextDisabled: false,
            prevDisabled: true
        }
    }

    close = () => {
        if (this.props.cancel) {
            this.props.cancel();
        }
        this.setState({
            show: false
        });
    }

    getNextVideo = (index) => {
        this.setState({
            currentIndex: index + 1
        }, () => {
            if (this.state.currentIndex === this.props.videos.videos.length - 1) {
                this.setState({
                    nextDisabled: true,
                })
            }
            if(this.state.currentIndex!==0){
                this.setState({
                    prevDisabled:false
                })
            }
        })
    }

    getPrevVideo = (index) => {
        this.setState({
            currentIndex: index - 1
        }, () => {
            if (this.state.currentIndex === 0) {
                this.setState({
                    prevDisabled: true,
                    nextDisabled: false
                })
            }
            else{
                this.setState({
                    prevDisabled:false,
                    nextDisabled:false
                })
            }
        })
    }

    getCurrentVideo = (index) => {
        this.setState({
            currentIndex: index
        }, () => {
            // console.log(index,"index")
            if (this.state.currentIndex === 0) {
                this.setState({
                    prevDisabled: true,
                    nextDisabled: false
                })
            }
           else if (this.state.currentIndex === this.props.videos.videos.length - 1) {
                this.setState({
                    nextDisabled: true,
                    prevDisabled: false
                })
            }
            else {
                this.setState({
                    nextDisabled:false,
                    prevDisabled:false
                })
            }
        })
    }

    getAllTitles = (videos) => {
        return videos.map((video, index) => {
            return (
                <MenuItem className="dropdown-menu-header-item" onClick={this.getCurrentVideo.bind(this, index)}>
                    <h5 className="dropdown-menu-header">
                        {video.name}
                    </h5>
                </MenuItem>
            )
        })
    }

    getVideos = (videos) => {
        if (videos.length !== 0) {
            const { currentIndex, prevDisabled, nextDisabled } = this.state;
            let playVideo = videos[currentIndex];

            return (
                <div className="video-gallery">
                    <div
                        className={prevDisabled ? 'video-prev-btn disabled' : 'video-prev-btn'}
                        onClick={prevDisabled ? null : this.getPrevVideo.bind(this, currentIndex)}>
                        <i className="fa fa-angle-left"></i>
                    </div>

                    <div className="video-player">
                        <Dropdown
                            autoOpen={true}
                            pullRight={true}
                        >
                            <Dropdown.Toggle
                                title={playVideo.name}
                                noCaret={false}
                            />
                            <Dropdown.MenuWrapper>
                                <Dropdown.Menu className="drop-down-menu">
                                    {this.getAllTitles(videos)}
                                </Dropdown.Menu>
                            </Dropdown.MenuWrapper>
                        </Dropdown>

                        <h5 className="modal-description">{playVideo.description}</h5>
                        <div className="video-div">
                            <video key={playVideo.url} controls disablePictureInPicture controlsList="nodownload">
                                <source src={playVideo.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>

                    <div
                        className={nextDisabled ? 'video-next-btn disabled' : 'video-next-btn'}
                        onClick={nextDisabled ? null : this.getNextVideo.bind(this, currentIndex)}>
                        <i className="fa fa-angle-right"></i>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { show, videos, videoReady } = this.props;

        const display = show ? "flex" : "none";
        const modalClass = show ? "in" : ""

        return (
            <>
                <div className={"modal fade bs-example-modal-sm " + modalClass} style={{ display: display }}>
                    <div className="modal-dialog modal-sm tutorial-modal-dialog">
                        <div className="modal-content tutorial-modal-content">
                            <div className="modal-header b-0 p-l-20 p-t-30 p-r-20">
                                <h4 className="modal-title">Video Tutorials
                                    <button
                                        type="button"
                                        onClick={this.close}
                                        className="close api-details-modal-close">
                                        <i className="fa fa-times"></i>
                                    </button>
                                </h4>
                            </div>

                            <div className="modal-body p-t-0 p-l-30 p-b-30 p-r-30">
                                {
                                    videoReady ?
                                        this.getVideos(videos.videos)
                                        :
                                        <div className="card-loader card-loader--tabs m-l-30 m-r-20 m-b-10 video-load-tabs"></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default TutorialModal
